import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import classes from './ModifyUser.module.css';
import EditUser from './EditUser';
import axios from 'axios';
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import Swal from 'sweetalert2';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Close } from '@mui/icons-material';
import { getCookie } from "../../Services/Cookie/Cookie"
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import './hideScrollbar.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';




const ModifyUser = () => {
  const [users, setUsers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [adminName, setAdminName] = useState('')
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('')

  const [isLoaded, setIsLoaded] = useState(false)
  const [editLoaded, setEditLoaded] = useState(false)

  useEffect(() => {
    fetchUserDetails()
  }, [])


  const [userPrivileges, setUserPrivileges] = useState({})

  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserPrivileges(data.global_privileges)
      setAdminName(data.username)
    }
    catch (error) {
      console.error(error)
    }
  }

  const getUserDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/ALL`, config);
      setUsers(response.data);
      setIsLoaded(true)
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const [updated, setUpdated] = useState(0)

  useEffect(() => {
    setGlobalFilterValue('')
    getUserDetails();
  }, [updated]);

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  // const [toggleIndex,setToggleIndex]=useState(null);


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#C80036' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },

  }));
  const actionBodyTemplate = (rowData, actionType, rowIndex) => {
    let icon = '';
    let actionClass = '';
    switch (actionType) {
      case 'view':
        icon = 'pi pi-eye';
        actionClass = 'icon-size';
        return <Button style={{ color: 'black' }} type="button" onClick={() => { populateUserLogs(rowData) }} icon={icon} className={`${classes[actionClass]} p-button-rounded p-button-text`} />;
      case 'edit':
        icon = 'pi pi-pencil';
        actionClass = 'icon-size';
        return (
          <EditIcon onClick={() => { populateUserDetails(rowData) }} className={`${classes['icon-size']} p-button-rounded p-button-text`} />
        );

      case 'user_status':
        icon = 'pi pi-ban';
        actionClass = 'pi-ban';
        return (
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={rowData.is_active === 1}
                onClick={(event) => {
                  handleDisableUser(rowData, rowIndex - 1);
                }}
              />
            }
          />
        );
      default:
        break;
    }
  }


  const [userLogs, setUserLogs] = useState([])
  const [userName, setUserName] = useState('')
  const [open, setOpen] = useState(false)
  const closeModal = (event, reason) => {
    setPage(0)
    setSearchQuery('')
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLogs, setFilteredLogs] = useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const populateUserLogs = async (userDetails) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      let action_id = 3
      let username = userDetails.username
      let response = await axios.get(`/logs/get_login_logs/${username}/${action_id}`, config);
      let logs = response.data

      const updatedLogs = logs.map(entry => ({
        ...entry,
        name: userDetails.name
      }));
      setUserLogs(updatedLogs)
      setFilteredLogs(updatedLogs)
      setUserName(userDetails.name)
      setOpen(true)

    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };


  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = userLogs.filter((log) =>
      columns.some((column) =>
        log[column.id].toString().toLowerCase().includes(query)
      )
    );
    setFilteredLogs(filtered);
  };


  const [showModal, setShowModal] = useState(false);
  const [editingUser, setEditingUser] = useState({});

  const populateUserDetails = (userDetails) => {
    setEditingUser(userDetails);
    setShowModal(true);
    setEditLoaded(true)
  };

  const handleDisableUser = (userDetails, rowIndex) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${userDetails.is_active ? 'disable' : 'enable'} ${userDetails.username}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        disableUser(userDetails, rowIndex);
      }
    });
  };



  const disableUser = async (userDetails, rowIndex) => {
    const active_status = userDetails.is_active ? 0 : 1;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    const data = {
      user_id: userDetails.user_id,
      disabled_user: active_status,
      username: userDetails.username
    };
    try {
      const response = await axios.post('/backend/dh-disable-user', data, config);

      if (rowIndex !== -1) {
        const updatedUsers = [...users]
        updatedUsers[rowIndex] = { ...updatedUsers[rowIndex], last_edited_by: adminName, is_active: active_status }
        setUsers(updatedUsers)
      }
      setSnackbarOpen(true)
      setMessage('User Status Updated Successfully')
      setSeverity('success')
      // getUserDetails(); // Refresh user data after disabling/enabling user not needed now
    } catch (error) {
      setSnackbarOpen(true)
      setMessage('Failed to Update Status')
      setSeverity('error')
    }
  };

  const header = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div></div>
      <div>Manage Users </div>
      <div className={`p-input-icon-right ${classes['search-bar-container']}`}>
        {/* <i className="pi pi-search" style={{ marginLeft: '1em' }} /> */}
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className={classes['data-search']} />
      </div>
    </div>
  );

  const rowClassName = () => {
    return classes['custom-datatable-row'];
  };

  // Function to handle custom filtering
  const customFilter = (value, filter) => {
    if (!value) return false; // Handle undefined or null values
    return value.toLowerCase().includes(filter.toLowerCase());
  };


  // Prime react filtering
  const filteredUsers = users.filter(user =>
    (user.name && customFilter(user.name, globalFilterValue)) ||
    (user.username && customFilter(user.username, globalFilterValue)) ||
    (user.created_by && customFilter(user.created_by, globalFilterValue)) ||
    (user.last_edited_by && customFilter(user.last_edited_by, globalFilterValue))
  );



  const columns = [
    { id: 'name', label: 'Name', width: '10em' },
    { id: 'by_username', label: 'Email', width: '20em' },
    {
      id: 'timestamp',
      label: 'Login',
      width: '40%'
    },
  ];


  const [login, setLogin] = useState(false)

  const [totalRecords, setTotalRecords] = useState(0);


  // Future scope to download login CSV
  const downloadCSV = () => {
    const csv = filteredUsers.map(user =>
      `${user.name},${user.username},${user.created_by},${user.last_edited_by}`
    ).join('\n');
    const csvData = `Name,Email,Created By,Last Edited By\n${csv}`;
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'users.csv';
    a.click();
  };

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={getUserDetails} />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={downloadCSV} />;


  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedLogs = React.useMemo(() => {
    return [...filteredLogs].sort((a, b) => {
      if (orderBy) {
        if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
  }, [filteredLogs, order, orderBy]);



  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        maxWidth="md"
        fullWidth
        sx={{ borderRadius: '1rem', padding: 0 }}

        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent sx={{ padding: '8px 15px' }} >
          <div className='d-flex justify-content-between align-items-center'>
            <div style={{ fontSize: '1em' }}><strong>{userName} </strong>- User Logs</div>
            <IconButton edge="end" color="inherit" onClick={() => closeModal()} aria-label="close">
              <Close />
            </IconButton>
          </div>
          <div className='d-flex justify-content-end align-items-center' >
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchQuery}
              onChange={handleSearch}
              sx={{ width: '10em' }}
              size="small"
            />
          </div>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }} className={`${classes['logs-container']}`}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: `${column.width}`, textAlign: 'center', backgroundColor: '#fefbf5' }}
                      >
                        {index === columns.length - 1 ? (
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={(event) => handleRequestSort(event, column.id)}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <span style={{ display: 'none' }}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}

                        {/* {column.label} */}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {filteredLogs */}
                  {sortedLogs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align} style={{ textAlign: 'center' }}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredLogs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                '& .MuiTablePagination-toolbar': {
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px',
                  padding: '8px',
                },
                '& .MuiTablePagination-selectLabel': {
                  marginBottom: 0,
                },
                '& .MuiTablePagination-displayedRows': {
                  marginBottom: 0,
                }
              }}
            />
          </Paper>


        </DialogContent>
      </Dialog>



      <div className={classes.content}>
        {isLoaded ? (
          <>
            {editLoaded ? (
              <>
                <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', zIndex: '1000' }}>
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                </div>
              </>

            ) : (
              <>
              </>
            )
            }
            <div className={classes['modify-user-container']}>
              <div className="card p-mt-2">
                <div className={classes['datatable-container']}>
                  <DataTable
                    value={filteredUsers}
                    header={header}
                    scrollable
                    scrollHeight="65vh"
                    globalFilterFields={['name', 'username', 'user_created_by', 'last_edited_by']}
                    emptyMessage="No Users found."
                    filterMode="lenient"
                    className={`${classes['p-datatable']} ${classes['custom-datatable']} hide-scrollbar`}
                    rowClassName={rowClassName}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    totalRecords={totalRecords}
                    removableSort
                  >
                    <Column field="name" header="Name" sortable
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="username" header="Email" sortable
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      filterPlaceholder="Search by email" style={{ minWidth: '12rem' }} />
                    <Column field="created_by" header="Created By" sortable
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      filterPlaceholder="Search by created by" style={{ minWidth: '12rem' }} />
                    <Column field="last_edited_by" header="Last Edited By" sortable
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      filterPlaceholder="Search by last edited by" style={{ minWidth: '12rem' }} />
                    <Column header="View" body={(rowData, options) => actionBodyTemplate(rowData, 'view', options.rowIndex + 1)}
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      style={{ width: '8rem', textAlign: 'center' }} />
                    <Column header="Edit" body={(rowData, options) => actionBodyTemplate(rowData, 'edit', options.rowIndex + 1)}
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      style={{ width: '8rem', textAlign: 'center' }} />
                    <Column header="User Status" body={(rowData, options) => actionBodyTemplate(rowData, 'user_status', options.rowIndex + 1)}
                      bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align'}
                      style={{ width: '9rem', textAlign: 'center' }} />
                  </DataTable>
                </div>
              </div>
            </div>
            {showModal && (<EditUser setEditLoaded={setEditLoaded} setSnackbarOpen={setSnackbarOpen} setSeverity={setSeverity} setMessage={setMessage} setUpdated={setUpdated} editingUser={editingUser} setUsers={setUsers} showModal={showModal} setShowModal={setShowModal} />)}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Alert
                onClose={handleClose}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </>
        ) :
          (
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </div>

          )
        }
      </div>


    </>
  );
};

export default ModifyUser;


