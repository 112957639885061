import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Button,
  Box,
  IconButton,
  Typography,
  Snackbar,
  LinearProgress,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Radio,
  TableBody,
  Popover,
  Tooltip, DialogActions,
  Grid,
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormControlLabel, FormGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { ReactComponent as DomainHead } from "../../Assets/DomainHead.svg";
import { getCookie } from '../../Services/Cookie/Cookie';
import Navbar from '../Navbar/Navbar';
import { TableVirtuoso } from 'react-virtuoso';
import Swal from 'sweetalert2';
import { Card, CardContent } from '@mui/material';
import classes from "./TaskPage.module.css";
import CircularProgress from '@mui/material/CircularProgress';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';

import { InputText } from 'primereact/inputtext'
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import "../Admin/hideScrollbar.css"

import dayjs from 'dayjs';
import UserTitle from '../Admin/UserTitle'
import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, Select, ListItemText } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(31px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  }
}));



const TaskPage = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const currentDate = new Date().toISOString().slice(0, 16);
  const [taskName, setTaskName] = useState('');
  const [editTask, setEditTask] = useState(false)
  const [taskDescription, setTaskDescription] = useState('');
  const [domainList, setDomainList] = useState([]);
  const [projectList, setProjectList] = useState([])
  const [selectedDomain, setSelectedDomain] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [repeat, setRepeat] = useState(0);
  const [dueDate, setDueDate] = useState(getCurrentDate());
  const [assignedOn, setAssignedOn] = useState([]);
  const [status, setStatus] = useState([]);
  const [date, setDate] = useState('')
  const [users, setUsers] = useState([])
  const [projectDueDate, setProjectDuedate] = useState('')
  const [userDetails, setUserDetails] = useState()

  const [notes, setNotes] = useState([])

  const [assignTypeUser, setAssignTypeUser] = useState([])



  const [interval, setInterval] = useState(1);
  const [timeUnit, setTimeUnit] = useState('days');
  const [endOption, setEndOption] = useState('never');
  const [endDate, setEndDate] = useState(null);

  const currentDay = new Date().getDay();

  const adjustedCurrentDay = currentDay === 0 ? 7 : currentDay;


  const [selectedDays, setSelectedDays] = useState([]);

  const [critical, setCritical] = useState(0)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const handleSnackClose = () => {
    setSnackbarOpen(false);
  };



  const handleCritical = (event) => {
    setCritical(event.target.checked ? 1 : 0);
  };
  const handleTimeUnitChange = (e) => {
    setTimeUnit(e.target.value);
  };

  const handleEndOptionChange = (e) => {
    setEndOption(e.target.value);
    if (e.target.value === 'never') {
      setEndDate(''); // Reset the endDate when 'Never' is selected
    }
  };

  const handleDayChange = (e) => {
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(e)) {
        return prevSelectedDays.filter((day) => day !== e);
      } else {
        return [...prevSelectedDays, e];
      }
    });
  };

  const dashboard = {
    "Dashboard": "Task",
    "Info": "Create Task for your Project"
  }

  const dateStyles = {
    height: '2.5em',
    border: '1px solid gainsboro', // Corrected spelling of 'gainsboro'
    borderRadius: '2px', // Uncomment and set borderRadius if needed
  };


  const [subtasks, setSubtasks] = useState([])
  const subTaskContainerStyle = {
    marginBottom: '20px',
    padding: '2px',
    backgroundColor: 'rgba(217, 217, 217, 0.22)',
    borderRadius: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '99%',
    marginLeft: '0.20em',
    paddingBottom: '10px'


  }
  const [domainId, setDomain] = useState('')
  const sortedUsers = Array.isArray(users) ? users.slice().sort((a, b) => {
    if (selectedDomain == null) {
      return []
    }
    if (a.core_domain === selectedDomain.value) return -1;
    if (b.core_domain === selectedDomain.value) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;

  const assingSortedUser = Array.isArray(assignTypeUser) ? assignTypeUser.slice().sort((a, b) => {
    if (selectedDomain == null) {
      return []
    }
    if (a.core_domain === selectedDomain.value) return -1;
    if (b.core_domain === selectedDomain.value) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;


  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
      setIsLoaded(true)
    }
    catch (error) {
      console.error("Error Occured")
    }
  }


  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    fetchDomain()
    fetchAllUser()
    fetchAssignType()
    fetchUserDetails()
  }, [])




  const [isCompleteDate, setIsCompleteDate] = useState(false);

  const handleDateChange = (e) => {
    const value = e.target.value;
    setDueDate(value);
  
    if (value.length === 10) { 
      setIsCompleteDate(true);
    } else {
      setIsCompleteDate(false);
    }
  };
  
  const handleDateBlur = () => {
    if (isCompleteDate && dueDate < getCurrentDate()) {
      setSnackbarOpen(true);
      setMessage(`Task due date cannot be less than current date`);
      setSeverity('error');
      setDueDate(''); 
      return
    }else if (isCompleteDate && dueDate > projectDueDate) {

      setSnackbarOpen(true);
      setMessage(`Task due date cannot be greater than project date`);
      setSeverity('error');
      setDueDate('');
      return
    }
  };
  




  const fetchAssignTypeUser = async (assing_id) => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get(`/backend/get_all_skill_user/${assing_id}`, config);

      // setAssignUser(response.data)
      setAssignTypeUser(response.data)
    } catch (error) {
      console.error('Error creating task', error);
    }
  }

  const [domainsID, setDomainsId] = useState('')


  const setDomainId = async (event, value) => {
    setSelectedDomain(value)
    setSelectedUser(null)
    setProjectId(null)
    setProjectDuedate('')
    const updatedSubtasks = Object.keys(subtasks).reduce((acc, key) => {
      acc[key] = {
        ...subtasks[key],
        sub_task_assign_to: null, 
        sub_task_assign_type:null,
      };
      return acc;
    }, {});
    setSubtasks(updatedSubtasks);


    if (value == null) {
      setDomain(null)
      setProjectList([])
      return

    }
    setDomain(value.domain_id)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let is_active_ = 1

      // let projectResponse = await axios.get(`/backend/get_all_projects_list/${value.domain_id}/${is_active_}`, config);
      let projectResponse = await axios.get(`/backend/get_user_task_project_access/${value.value}`, config);

      let projectData = projectResponse.data;
      projectData = Object.entries(projectData).map(([key, value]) => ({
        label: value[0],
        dueDate:value[1],
        value: key
      }));

      setProjectList(projectData)
    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }

  }

  const [projectUser, setProjectUser] = useState()

  const [assignOwner, setAssignOwner] = useState([])



  const handleSelectUser = (value) => {

    setSelectedUser(value)

    setAssignOwner([])

    if (value) {
      let project_user = false
      let domainAccess = value.personal_privileges?.[domainId]?.access;
      // if((domainAccess != 'view' && domainAccess != 'view|a' && domainAccess != undefined )){
      //   domainAccess = true
      // }
      // else{
      //   domainAccess = false
      // }
      let userDomainOwner = allDomainList.filter((item) => {
        return item.owner.trim().toLowerCase() === userDetails.username.trim().toLowerCase();
      }); 
      let doesUsernameExist = userDomainOwner.some(item => item.domain_id === value.core_domain);

      if ((projectUserList.includes(value.username) || doesUsernameExist )) {
        project_user = true
      }

      const result = project_user;
      if (!result) {
        setAssignOwner(prevExtendedList => {
          const newDomain = {
            domain_id: value.core_domain,
            domain_name: value.domain_name,
            head: allDomainList.find(domainItem => domainItem.domain_id === value.core_domain)?.owner || '',
            total_users: 1,
            project_owner: 1,
            user_list: [value.username]

          };
          return [...prevExtendedList, newDomain];

        });
      }


    }

  }


  const setProjectId = async (event, value) => {

    setSelectedProject(value)

    setSelectedUser(null)
    const updatedSubtasks = Object.keys(subtasks).reduce((acc, key) => {
      acc[key] = {
        ...subtasks[key],
        sub_task_assign_to: null, 
        sub_task_assign_type:null,
      };
      return acc;
    }, {});
    setSubtasks(updatedSubtasks);

    setProjectDuedate('')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      const response = await axios.get(`/backend/get_project_details/${value.value}`, config);
      setProjectUserList(response.data.project_users_list)
      setProjectDuedate(value.dueDate)
    }
    catch {

    }
  }

  const fetchAllUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/ALL`, config);

      setUsers(response.data)
      // fetchProjectDetails()
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  const [allDomainList, setAllDomainList] = useState([])

  const fetchDomain = async () => {
    let is_active = 'All';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let allDomainResponse = await axios.get(
        `/backend/get_all_domain_list/${is_active}`,
        config
      );

      // let domainResponse = await axios.get(
      //   `/backend/get_user_domain_list/${is_active}`,
      //   config
      // );
      let domainResponse = await axios.get(
        `/backend/get_user_task_domain_access`,
        config
      );

      let domainData = domainResponse.data;
      domainData = Object.entries(domainData).map(([key, value]) => ({
        label: value,
        value: key
      }));


      setDomainList(domainData)
      setAllDomainList(allDomainResponse.data)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };

  const [assignList, setAssignList] = useState([])
  const [assingType, setAssignType] = useState(null)

  const isRecurringValid = (recurringType, selectedDays) => {
    const today = new Date();
    const endDate = new Date(recurringType.end);
    const currentWeekList = [];
  
    // Calculate dates for the current week
    for (let dts = today.getDay() + 1; dts < 8; dts++) {
      const curDate = new Date(today.getTime() + (dts - today.getDay()) * 24 * 60 * 60 * 1000);
      const curDateStr = curDate.toISOString().split('T')[0];
      if (curDateStr < recurringType.end) {
        currentWeekList.push(curDateStr);
      }
    }
  
    // Check if the current week includes any of the selected days
    let isRepeatInWeek = false;
    for (const dts of selectedDays) {
      const dateDayOfWeek = new Date(today.getTime() - (today.getDay() - dts) * 24 * 60 * 60 * 1000);
      const dateDayOfWeekStr = dateDayOfWeek.toISOString().split('T')[0];
  
      if (currentWeekList.includes(dateDayOfWeekStr)) {
        isRepeatInWeek = true;
        break;
      }
    }
  
    if (!isRepeatInWeek) {
      // Calculate next week’s potential run date
      try {
        const nextWeekStart = new Date(today.getTime() + (7 - today.getDay()) * 24 * 60 * 60 * 1000);
        const nextWeekRunDate = new Date(nextWeekStart.getTime() + (recurringType.repeatEvery ) * 7 * 24 * 60 * 60 * 1000);
        const minRepeatDay = Math.min(...selectedDays);
        nextWeekRunDate.setDate(nextWeekRunDate.getDate() + minRepeatDay );
        const nextWeekRunDateStr = nextWeekRunDate.toISOString().split('T')[0];
  
        if (endDate < new Date(nextWeekRunDateStr) || endDate > projectDueDate) {
          return false; // Invalid recurring end date
        }
      } catch (error) {
        console.error(error);
        return false; // Error in calculation
      }
    }
  
    return true; // Valid recurring event
  };


  const fetchAssignType = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let response = await axios.get(
        `/backend/get_skill_set_list`,
        config
      );

      let assingType = response.data;

      setAssignList(assingType)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  }
  const [projectUserList, setProjectUserList] = useState([])

  const fetchProjectDetails = async (project_id, project_owner, project_name) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };

    try {
      const response = await axios.get(`/backend/get_project_details/${project_id}`, config);
      let data = response.data
      let project_user_list = response.data.order
      let projectUserList = data.project_users_list
      setProjectUserList(response.data.project_users_list)


    } catch (error) {
    }

  }



  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [isRecurring, setIsRecurring] = useState(0)
  const [recurringType, setRecurringType] = useState()
  const [recurryingDialog, setRecurringDialog] = useState(false)

  const [finalRecurringStatus, setFinalRecurringStatus] = useState({})
  const handleSaveReoccurence = () => {
    if (timeUnit == "days" || timeUnit =='day') {
      if (endOption == "on" && (endDate == null || endDate == undefined)) {

        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }

    else if (timeUnit == 'week' || timeUnit =='weeks') {
      if ((endOption == "on" && (endDate == null || endDate == undefined)) && (selectedDays.length == 0)) {
        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }

    if (timeUnit == "days" || timeUnit == 'day') {
      if (interval == "" || interval == null || interval == undefined) {

        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }

    else if (timeUnit == 'week' || timeUnit == 'weeks') {
      if (interval == "" || interval == null || interval == undefined) {
        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }
  

    if (endDate != null && endDate != undefined && endOption == "on" && (timeUnit == 'days' || timeUnit == "day")) {

      const parsedEndDate = new Date(endDate);
      const repeatEvery = interval;
      const today = new Date();
      const futureDate = new Date(today.getTime() + repeatEvery * 24 * 60 * 60 * 1000);
      const projectEndDate = new Date(projectDueDate)
      if ((endDate < getCurrentDate())) {
        setMessage("Please select a valid recurring date")

        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }

      if ((parsedEndDate < futureDate || parsedEndDate > projectEndDate)) {
        setMessage("Please select a valid recurring date")

        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }
    if (endDate != null && endDate != undefined && endOption == "on" && (timeUnit == 'weeks' || timeUnit == "week")) {
      let recurringType = {
        end: endDate,
        repeatEvery: interval,
        repeatOn: selectedDays
      }
      if(endDate > projectDueDate){
        setMessage("Please select a valid recurring date")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }

      if (!(isRecurringValid(recurringType, selectedDays))) {
        setMessage("Please select a valid recurring date")
        setSeverity("warning")
        setSnackbarOpen(true)
        return

      }
    }



    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    let weeklyDays = '';

    if (timeUnit == "weeks") {
      selectedDays.forEach((index) => {

        weeklyDays += days[index] + ',';

      });
    }

    if (weeklyDays.endsWith(',')) {
      weeklyDays = weeklyDays.slice(0, -1);
    }

    let newOptionText = ''

    if ((timeUnit == "days" || timeUnit == "day") && (!(endDate == undefined || endDate == null) && !(endDate == ''))) {
      if (interval == '1') {
        newOptionText = `Daily Until ${endDate}`
      }
      else {
        newOptionText = `Every ${interval} days Until ${endDate}`
      }
    }
    else if ((timeUnit == "days" || timeUnit == "day") && (endDate == undefined || endDate == null || endDate == '')) {
      if (interval == '1') {
        newOptionText = `Daily `
      }
      else {
        newOptionText = `Every ${interval} days `
      }
    }
    else if ((timeUnit == "weeks" || timeUnit == "week") && (!(endDate == undefined || endDate == null) && !(endDate == ''))) {
      if (interval == '1') {
        newOptionText = `Every weeks on ${weeklyDays} until ${endDate}`
      }
      else {
        newOptionText = `Every ${interval} weeks on ${weeklyDays} until ${endDate}`
      }
    }
    else if ((timeUnit == "weeks" || timeUnit == "week") && (endDate == undefined || endDate == null || endDate == '')) {
      if (interval == 1) {
        newOptionText = `Every weeks on ${weeklyDays} `
      }
      else {
        newOptionText = `Every ${interval} weeks on ${weeklyDays} `
      }
    }


    const newOption = { value: 2, label: newOptionText };

    const updatedOptions = options.filter(option => option.value !== 2);

    // Add the new dynamic option
    updatedOptions.push(newOption);

    setOptions(updatedOptions);
    setRepeat(2)
    setRecurringDialog(false);
    setFinalRecurringStatus({
      type: timeUnit,
      repeat_every: interval,
      repeat_on: selectedDays,
      end: endDate,
      endOption: endOption,
    });
    setReoccured(true)

  }


  const handleReoccuringDialogClose = () => {
    setRecurringDialog(false)
    setTimeUnit(finalRecurringStatus['type'])
    setInterval(finalRecurringStatus['repeat_every'])
    setSelectedDays(finalRecurringStatus['repeat_on'])
    setEndDate(finalRecurringStatus['end'])
    setEndOption(finalRecurringStatus['endOption'])

    setTimeUnit('day')
    setEndOption('never')
    setInterval(1)
    setEndDate(null)
    setSelectedDays([])


    if (Object.keys(finalRecurringStatus).length > 0) {
      if (isRecurring) {
        setRepeat(2);
      }
      else {
        setRepeat(0);
        setReoccured(false)
      }
    } else {
      setRepeat(0);
      setRecurring(0);
      setIsRecurring(0);
      setReoccured(false)
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = '00';
    const minutes = '00';
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const createTask = async () => {
    // const subTasksArray = Object.values(subtasks);
    let newTimeUnit = ''
    if (timeUnit === "days") {
      newTimeUnit = 'day'
    } else if (timeUnit === "weeks") {
      newTimeUnit = 'week'
    }
    else {
      newTimeUnit = timeUnit
    }

    let recurringDict = {
      "type": newTimeUnit,
      "repeat_every": interval,
      "repeat_on": selectedDays,
      "end": endDate
    }
    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }
    else if (dueDate < getCurrentDate() || dueDate > projectDueDate) {
      setSnackbarOpen(true)
      setMessage('Please Select a valid  Due Date to assign ')
      setSeverity('error')
      return
    }


    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }

    for (let key in subtasks) {
      if (subtasks.hasOwnProperty(key)) {
        let subTask = subtasks[key];

        if (subTask.hasOwnProperty('start_datetime')) {
          subTask.start_datetime = getCurrentDate();
        }

        if (subTask.hasOwnProperty('end_datetime')) {
          subTask.end_datetime = getCurrentDate();
        }
      }
    }



    const data = {
      domain_id: selectedDomain.value,
      project_id: selectedProject.value,
      task_name: taskName,
      task_description: taskDescription,
      note: notes,
      task_owner: selectedUser['username'],
      is_recurring: isRecurring,
      recurring_type: recurringDict,
      status: "not picked",
      deadline: isRecurring ? getCurrentDate() : dueDate,
      sub_task: subtasks,
      is_critical: critical
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    uploadedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });


    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,

      },
    };

    try {
      const response = await axios.post('/backend/create_task', formData, config);
      setSnackbarOpen(true)
      setMessage('Task Created Successfully')
      setSeverity('success')

      setTaskName('')
      setTaskDescription('')
      setSelectedDomain(null)
      setSelectedProject(null)
      setSelectedUser(null)
      setRepeat(0)
      setDueDate('')
      setNotes('')
      setInterval(1)
      setTimeUnit('day')
      setEndOption('never')
      setEndDate(null)
      setSubtasks([])
      setFinalRecurringStatus({})

    } catch (error) {
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  };

  const [extendedDomainUser, setExtendedDomainUser] = useState([])
  const handleBlur = (index, field, value) => {
    if ((field === "start_datetime" || field === "end_datetime") && value.length === 10) {
      if (value < getCurrentDate()) {
        const fieldName = field === "start_datetime" ? 'start date' : 'end date';
        setSnackbarOpen(true);
        setMessage(`Subtask ${index} ${fieldName} cannot be less than the current date`);
        setSeverity('error');
        value = ''
        handleSubtaskChange(index, field, value);
        return;
      }
    }
  
    handleSubtaskChange(index, field, value);
  };



  const handleSubtaskChange = (index, field, value) => {

    if (field === "start_datetime" || field === "end_datetime") {
      if (value > dueDate) {
        setSnackbarOpen(true)
        setMessage('Please Select a Valid Date')
        setSeverity('error')
        return;
      }
    }
    const updatedSubtasks = { ...subtasks };
    updatedSubtasks[index][field] = value;
    setSubtasks(updatedSubtasks);
    setSubtask(false);

    if (field == 'sub_task_name' || field == 'sub_task_description' || field == "remarks") {
      let transform_value = toSentenceCase(value)
      updatedSubtasks[index] = {
        ...updatedSubtasks[index],
        [field]: transform_value,
      };
    }


    // if (field === "sub_task_assign_type") {
    //   if (value == null) {
    //     setAssignType(null)
    //   }
    //   setAssignType(value.skill_id)
    //   fetchAssignTypeUser(value.skill_id)

    // }

    if (field === "sub_task_assign_type") {

      if (value == null) {
        setAssignType(null)
      }
      if (value) {
        setAssignType(value.skill_id)
        fetchAssignTypeUser(value.skill_id)
      }
      else {
        setAssignTypeUser([])
        updatedSubtasks[index] = {
          ...updatedSubtasks[index],
          ['sub_task_assign_to']: null,
        };
        setSubtasks(updatedSubtasks);
        setSubtask(false)
      }
    }



    if (field === 'sub_task_assign_to') {
      if (value) {

        let domainAccess = value.personal_privileges?.[domainId]?.access;
        // if (value.core_domain != domainId && !(taskUserList.includes(value.username) || domainAccess || (projectUserList.includes(value.username)))) {
          if((domainAccess != 'view' && domainAccess != 'view|a' && domainAccess != undefined )){
            domainAccess = true
          }
          else{
            domainAccess = false
          }
          let userDomainOwner = allDomainList.filter((item) => {
            return item.owner.trim().toLowerCase() === userDetails.username.trim().toLowerCase();
          }); 
          let doesUsernameExist = userDomainOwner.some(item => item.domain_id === value.core_domain);


        if (value.core_domain != domainId && doesUsernameExist || (projectUserList.includes(value.username))) {
          setExtendedDomainUser(prevExtendedDomainUser => {
            const updatedExtendedDomainList = [...prevExtendedDomainUser];
            const existingDomainIndex = updatedExtendedDomainList.findIndex(domain => domain.domain_id === value.core_domain);

            if (existingDomainIndex !== -1) {
              if (!updatedExtendedDomainList[existingDomainIndex].user_list.includes(value.username)) {
                updatedExtendedDomainList[existingDomainIndex].user_list.push(value.username);
              }
            } else {
              const newDomain = {
                domain_id: value.core_domain,
                domain_name: allDomainList.find(domainItem => domainItem.domain_id === value.core_domain)?.domain_name || '',
                user_list: [value.username],
                head: allDomainList.find(domainItem => domainItem.domain_id === value.core_domain)?.owner || '',
              };

              updatedExtendedDomainList.push(newDomain);
            }

            return updatedExtendedDomainList;
          });
        }
      }

    }
  };



  // const handleDeleteSubtask = (index) => {
  //   const updatedSubtasks = subtasks.filter((_, subtaskIndex) => subtaskIndex !== index);
  //   setSubtasks(updatedSubtasks);
  //   setSubtask(false)

  // };
  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }


  const handleDeleteSubtask = (index) => {
    const updatedSubtasks = { ...subtasks };
    delete updatedSubtasks[index];
    setSubtasks(updatedSubtasks);

    const updatedUploadedFiles = uploadedFiles.filter(file => !file.name.startsWith(`${index}-`));
    setUploadedFiles(updatedUploadedFiles);

    setSubtask(false);
  };



  const [subtaskLength, setSubtaskLength] = useState(1)
  const [innerSubFiles, setInnerSubFiles] = useState({})
  const [reoccured, setReoccured] = useState(false)

  
  const [newSubtaskKey, setNewSubtaskKey] = useState(null);
  const newSubtaskRef = useRef(null);


  // New one
  const handleAddSubtask = () => {
    let newTimeUnit
    if (timeUnit === "days") {
      newTimeUnit = 'day'
    } else if (timeUnit === "weeks") {
      newTimeUnit = 'week'
    }
    else {
      newTimeUnit = timeUnit
    }
    let recurringDict = {
      "type": newTimeUnit,
      "repeat_every": interval,
      "repeat_on": selectedDays,
      "end": endDate
    }
    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }

    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }



    const newSubtask = {
      sub_task_name: '',
      sub_task_description: '',
      start_datetime: '',
      effort_hours: 0,
      end_datetime: '',
      remarks: '',
      sub_task_assign_to: null,
      is_file_attached: 0,
      file_name: []
    };

    // const newSubtaskKey = (Object.keys(subtasks).length + 1).toString();
    setSubtaskLength(prevState => prevState + 1);
    setSubtasks({
      ...subtasks,
      [subtaskLength]: newSubtask
    });
    setNewSubtaskKey(subtaskLength);

    setTimeout(() => {
      if (newSubtaskRef.current) {
        newSubtaskRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);


  };

  useEffect(() => {
    if (newSubtaskKey) {
      const element = document.getElementById(newSubtaskKey);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [newSubtaskKey]);

  // Updated logic from task
  const handleFileUpload = (index, files) => {
    const maxSizeInBytes = 512 * 1024; 

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSizeInBytes) {
        setSnackbarOpen(true);
        setMessage(`The file "${file.name}" exceeds the maximum size of 512 KB.`);
        setSeverity('warning');
      
        return;
      }
    }

    const updatedSubtasks = { ...subtasks };
    const subtask = updatedSubtasks[index];
    let value;

    // Update innerSubFiles state
    if (!innerSubFiles.hasOwnProperty(index)) {
      let initialvalue = 0;
      setInnerSubFiles(prevState => ({
        ...prevState,
        [index]: initialvalue
      }));
      value = 0;
    } else {
      value = innerSubFiles[index] + 1;
      setInnerSubFiles(prevState => ({
        ...prevState,
        [index]: value
      }));
    }

    const existingFileCount = value;

    // Generate new file names with unique identifiers
    const newFileNames = Array.from(files).map((file, i) => {
      const unixTime = Math.floor(Date.now() / 1000);
      const fileNameWithoutExtension = file.name.replace(/\s+/g, '_').replace(/(.*)\.(.*)/, `$1||${unixTime}.$2`);
      return `${index}-${existingFileCount + i + 1}-${fileNameWithoutExtension}`;
    });

    // Update subtask file names
    subtask.file_name = subtask.file_name.filter(name => {
      const [fileIndex, , ...fileNameParts] = name.split('-');
      const baseFileName = fileNameParts.join('-').split('||')[0]; // Extract the base file name
      return !(fileIndex == index && newFileNames.some(newName => {
        const [newIndex, , ...newFileNameParts] = newName.split('-');
        const newBaseFileName = newFileNameParts.join('-').split('||')[0]; // Extract the new base file name
        return fileIndex == newIndex && baseFileName === newBaseFileName;
      }));
    });

    subtask.file_name = subtask.file_name.concat(newFileNames);
    subtask.is_file_attached = subtask.file_name.length;

    setSubtasks(updatedSubtasks);

    // Create updated File objects with new file names
    const updatedFiles = Array.from(files).map((file, i) => {
      const newFileName = newFileNames[i];
      return new File([file], newFileName, { type: file.type });
    });

    // Update the uploadedFiles state
    const updatedUploadedFiles = uploadedFiles.filter(file => {
      const [fileIndex, , ...fileNameParts] = file.name.split('-');
      const baseFileName = fileNameParts.join('-').split('||')[0]; // Extract the base file name
      return !(fileIndex == index && updatedFiles.some(newFile => {
        const [newIndex, , ...newFileNameParts] = newFile.name.split('-');
        const newBaseFileName = newFileNameParts.join('-').split('||')[0]; // Extract the new base file name
        return fileIndex == newIndex && baseFileName === newBaseFileName;
      }));
    }).concat(updatedFiles);

    setUploadedFiles(updatedUploadedFiles);
  };




  const handleFileDelete = (subtaskIndex, fileIndex) => {
    const updatedSubtasks = { ...subtasks };
    const subtask = updatedSubtasks[subtaskIndex];

    subtask.file_name = subtask.file_name.filter((_, idx) => idx !== fileIndex);
    subtask.is_file_attached = subtask.file_name.length;

    setSubtasks(updatedSubtasks);

    const updatedFiles = uploadedFiles.filter((_, idx) => idx !== fileIndex);
    setUploadedFiles(updatedFiles);
  };

  const [subtask, setSubtask] = useState(false)
  const [recurrenceType, setRecurrenceType] = useState('');
  const [weeklyDays, setWeeklyDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });



  const [approval, setApproval] = useState(false)


  const DomainList = ({ domainList }) => {
    return (
      <div>
        {domainList.map((domain, index) => (
          <div key={index} className="domain-container m-2 p-2" style={{ border: '1px solid black' }}>
            <div className="domain-item p-1">Domain: <strong>{domain.domain_name}</strong></div>
            <div className="domain-item p-1"><strong>Head: {domain.head}</strong></div>
            <div className="domain-item p-1">
              <strong>UserList: </strong>
              {domain.user_list && domain.user_list.length > 0 ? (
                <div className="user-list">
                  {domain.user_list.map((user, userIndex) => (
                    <span key={userIndex} className="user-item">
                      {user}{userIndex < domain.user_list.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </div>
              ) : (
                <span>No users</span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleApprovalClose = () => {
    setApproval(false); // Close dialog on click outside or ESC key
  };

  const handleApprovalConfirm = () => {
    setApproval(false);
    createTask()
  };


  const confirmbuttonStyles = {
    border: "1px solid black",
    backgroundColor: "rgba(28, 28, 28, 1)",
    borderRadius: "0.75rem",
    color: "rgba(238, 225, 213, 1)",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '9em'
  }
  const confirmHoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  }
  const buttonStyles2 = {
    border: "1px solid black",
    backgroundColor: "#EBEBEB",
    borderRadius: "0.75rem",
    color: "black",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '9em'
  };

  const hoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  };



  const [mergedApproval, setMergedApproval] = useState([])
  const mergeAndCleanDomains = (domains, domainsWithUsers) => {


    // Initialize the merged array with the domains array
    const merged = domains.map(domain => {
      const matchingDomainWithUsers = domainsWithUsers.find(domainWithUsers => domainWithUsers.domain_id === domain.domain_id);
      if (matchingDomainWithUsers) {
        let updatedUserList = matchingDomainWithUsers.user_list.filter(user => user !== domain.head);
        domains.forEach(domain => {
          // Extract usernames from the user_list
          let usernames = domain.user_list;
          updatedUserList = [...updatedUserList, ...usernames];
        });


        updatedUserList = [...new Set(updatedUserList)];
        return {
          ...matchingDomainWithUsers,
          user_list: updatedUserList
        };
      } else {
        return {
          ...domain,
          user_list: domain.user_list ? [...new Set([...domain.user_list])] : [domain.head]
        };
      }
    });

    // Adding the domains from domainsWithUsers which are not present in domains
    domainsWithUsers.forEach(domainWithUsers => {
      const matchingDomain = domains.find(domain => domain.domain_id === domainWithUsers.domain_id);
      if (!matchingDomain) {
        const userListWithHead = domainWithUsers.user_list && domainWithUsers.user_list.length > 0
          ? domainWithUsers.user_list
          : [domainWithUsers.head];

        // Remove duplicates using a Set
        const uniqueUserList = [...new Set(userListWithHead)];

        merged.push({
          ...domainWithUsers,
          user_list: uniqueUserList
        });
      }
    });

    return merged;
  };


  const validateSubtasks = (subtasks) => {
    let index = 1;
    for (const subtask of Object.values(subtasks)) {
      if (!subtask.sub_task_name || subtask.sub_task_name.trim() === '') {
        return `Subtask ${index} is missing the Subtask Name`;
      }
      if (!subtask.sub_task_description || subtask.sub_task_description.trim() === '') {
        return `Subtask ${index} is missing the Subtask Description`;
      }
      if (subtask.sub_task_assign_to === null || Object.keys(subtask.sub_task_assign_to).length === 0) {
        return `Subtask ${index} is missing the Assignee`;
      }
      if (subtask.effort_hours > 20 || subtask.effort_hours === null || subtask.effort_hours === 0 || isNaN(subtask.effort_hours) || subtask.effort_hours == '') {
        return `The maximum permitted efforts hours for subtask ${index} is 20 hours`;
      }
      if ((subtask.start_datetime && !subtask.end_datetime) || (!subtask.start_datetime && subtask.end_datetime)) {
        return `Subtask ${index} must have both Start Date and End Date if one is provided`;
      }
      if ((subtask.performance_score > 5)) {
        return `Subtask ${index} performance score is not valid`;
      }
      
      if (!(editTask)) {
        if (subtask.start_datetime && ((subtask.start_datetime < getCurrentDate() || subtask.start_datetime > dueDate))) {
          return `Subtask ${index} start date is not valid`;
        }
        if (subtask.end_datetime && ((subtask.end_datetime < getCurrentDate() || subtask.end_datetime > dueDate))) {
          return `Subtask ${index} end date is not valid`;
        }
        if ((subtask.start_datetime && subtask.end_datetime) && subtask.start_datetime > subtask.end_datetime) {
          return `Subtask ${index} date is not valid`;
        }
      }

      index++;
    }
    return null;
  };



  const handleCreateTask = async () => {

    // const subTasksArray = Object.values(subtasks);
    let usernames = [];
    let newMergedApproval = []

    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }
    else if (dueDate < getCurrentDate() || dueDate > projectDueDate) {
      setSnackbarOpen(true)
      setMessage('Please Select a valid  Due Date to assign ')
      setSeverity('error')
      return
    }


    Object.keys(subtasks).forEach(key => {
      const subtask = subtasks[key];
      if (subtask.sub_task_assign_to && typeof subtask.sub_task_assign_to === 'object') {
        const username = subtask.sub_task_assign_to.username;
        usernames.push(username);
      }
    });

    let filteredExternalDomainUser = extendedDomainUser.filter(domain => {
      const hasMatchingUser = domain.user_list.some(ele => usernames.includes(ele));
      return hasMatchingUser;
    });
    const shouldMergeAssignOwner = assignOwner.some(owner =>
      owner.user_domain_id !== selectedDomain.value &&
      owner.user_list &&
      !(owner.user_list.some(user => projectUserList.includes(user)))
    );

    if (shouldMergeAssignOwner) {
      filteredExternalDomainUser = mergeAndCleanDomains(assignOwner, filteredExternalDomainUser);

      newMergedApproval = [...filteredExternalDomainUser];
    }
    else {
      newMergedApproval = filteredExternalDomainUser
    }



    const getUsernames = (subtasks) => {
      return Object.values(subtasks).map(subtask => subtask?.sub_task_assign_to?.username);
    };

    const userNames = getUsernames(subtasks);
    userNames.push(selectedUser['username'])

  
    const filterUserLists = (domains, allowedUsers) => {
      return domains.map(domain => {
        return {
          ...domain,
          user_list: domain.user_list.filter(user => allowedUsers.includes(user))
        };
      });
    };

    // Get the filtered domains
    const filteredDomains = filterUserLists(newMergedApproval, userNames);

    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }

    setMergedApproval(filteredDomains)
    if (newMergedApproval.length !== 0) {
      if (selectedDomain == null) {
        setSnackbarOpen(true)
        setMessage('Select a Domain')
        setSeverity('error')
        return
      }
      else if (selectedProject == null) {
        setSnackbarOpen(true)
        setMessage('Select a Project')
        setSeverity('error')
        return
      }
      else if ((taskName == '' || taskName == null)) {
        setSnackbarOpen(true)
        setMessage('Enter Task Name')
        setSeverity('error')
        return
      }
      else if ((taskDescription == '' || taskName == null)) {
        setSnackbarOpen(true)
        setMessage('Enter Task Description')
        setSeverity('error')
        return
      }
      else if (selectedUser == null) {
        setSnackbarOpen(true)
        setMessage('Please Select a User to Assign Task')
        setSeverity('error')
        return
      }
      else if ((dueDate == null || dueDate == '') && repeat == 0) {
        setSnackbarOpen(true)
        setMessage('Please Select a due Date to assign ')
        setSeverity('error')
        return
      }

      else if (dueDate < getCurrentDate() || dueDate > projectDueDate) {
        setSnackbarOpen(true)
        setMessage('Please Select a valid  Due Date to assign ')
        setSeverity('error')
        return
      }

      
      const subtaskError = validateSubtasks(subtasks);
      if (subtaskError) {
        setSnackbarOpen(true);
        setMessage(subtaskError);
        setSeverity('error');
        return;
      }

      setApproval(true);
      return
    }

    createTask()
  };

  const [recurring, setRecurring] = useState(true)


  const handleRecurring = (e) => {
    if (e.target.value) {
      setRecurringDialog(true)
      setIsRecurring(e.target.value)
      setRepeat(e.target.value)
      setRecurring(false)
      setReoccured(true)
    } else {
      setIsRecurring(e.target.value)
      setRepeat(e.target.value)
      setRecurring(true)
      setReoccured(false)
    }
  }

  const [options, setOptions] = useState([
    { value: 0, label: "Don't Repeat" },
    { value: 1, label: 'Custom' }
  ]);


  const handlePositiveNumberChange = (e, key) => {
    const value = parseFloat(e.target.value);
    if (value < 0) {
      return
    }
    if (value >= 0) {
      handleSubtaskChange(key, 'effort_hours', value);
    }
    else {
      handleSubtaskChange(key, 'effort_hours', value)
    }
  };

  const formatFileName = (fileNames) => {
    let firstHyphenIndex = fileNames.indexOf('-');
    let trimmedFilename = fileNames.substring(firstHyphenIndex + 3);

    let lastUnderscoreIndex = trimmedFilename.lastIndexOf('||');
    let finalFilename = trimmedFilename.substring(0, lastUnderscoreIndex) + '.csv';
    return finalFilename
  }




  return (
    <>
      <Dialog open={approval} onClose={handleApprovalClose}
        PaperProps={{
          sx: {
            width: '30em',
            maxWidth: '30em',
          },
        }}
      >
        <DialogTitle>
          <strong>
            Proceed with the Approval Request ?
          </strong></DialogTitle>
        <DialogContent dividers style={{ height: '13em', overflowY: 'scroll' }}>
          <p>Approval required from the requested domain head:</p>
          <DomainList domainList={mergedApproval} />
        </DialogContent>
        <div className="modal-actions d-flex justify-content-evenly mb-3">
          <Button onClick={handleApprovalConfirm} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
            Send Request
          </Button>
          <Button onClick={handleApprovalClose} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
            Cancel
          </Button>

        </div>

      </Dialog>

      {/* Handle recurring type */}
      <Dialog open={recurryingDialog} onClose={handleReoccuringDialogClose}
        PaperProps={{
          sx: {
            width: '27em',
            // maxWidth: '30em',
            height: '27em'
          },
        }}
      >
        <DialogTitle>
          <strong>
            Custom recurrence
          </strong></DialogTitle>
        <DialogContent dividers style={{ height: '13em', overflowY: 'scroll' }}>
          <div className={classes['recurrence-input']}>
            <div className={`${classes['field']} d-flex  align-items-center`}>
              <div className='m-2'>Repeat Every : </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="number"
                  id="repeatInterval"
                  value={interval}
                  onChange={(e) => {
                    const value = e.target.value;
                    setInterval(value === '' ? '' : Math.max(1, Number(value)));
                  }}
                  min="1"
                  style={{ color: 'black', padding: '0.2em', width: '3em', backgroundColor: 'rgb(241, 243, 244)', border: 'none', }}
                />
                <select className='p-1 m-2' style={{ backgroundColor: 'rgb(241, 243, 244)', border: 'none' }} value={timeUnit} onChange={handleTimeUnitChange}>
                  <option value="day">
                    {interval > 1 ? "Days" : "Day"}
                  </option>
                  <option value="week">
                    {interval > 1 ? "Weeks" : "Week"}
                  </option>
                </select>
              </div>
            </div>

            {timeUnit === 'week' && (
              <div className={classes['field']}>
                <label className='mb-3'>Repeats On</label>
                <div className={classes['day-selector']}>
                  {['M', 'T', 'W', 'Th', 'F', 'S', 'Su'].map((day, index) => (
                    <div
                      key={index}
                      className={`${classes['day-circle']} ${selectedDays.includes(index + 1) ? `${classes['selected']}` : ''}`}
                      onClick={() => handleDayChange(index + 1)}
                    >
                      {day}
                    </div>
                  ))}
                </div>
              </div>
            )}


            <FormControl component="fieldset">
              <FormLabel component="legend">Ends</FormLabel>
              <RadioGroup
                aria-label="end-options"
                name="end-options"
                value={endOption}
                onChange={handleEndOptionChange}
              >
                <FormControlLabel
                  value="never"
                  control={<Radio />}
                  label="Never"
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '22em' }}>
                  <FormControlLabel
                    value="on"
                    control={<Radio />}
                    label="On"
                  />
                  {endOption === 'on' && (
                    <TextField
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      min={getCurrentDate()}

                    />
                  )}
                </div>
              </RadioGroup>
            </FormControl>

          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{ border: '1px solid black', padding: '0.35em' }} onClick={() => handleReoccuringDialogClose()}>Cancel</Button>
          <Button style={{ border: '1px solid black', padding: '0.35em' }} onClick={() => handleSaveReoccurence()}>Save</Button>
        </DialogActions>
      </Dialog>


      <div className={`${classes['content']} d-flex flex-column`}>
        <Navbar userDetails={userDetails} dashboard={dashboard} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
        {/* <Typography variant="h6" className={`${classes['task-label']}`} style={{ marginBottom: '1em', padding: '0.5em' }}>
          <div className="p-2" style={{ textAlign: 'center', fontWeight: 'bold', borderBottom: '1px solid black' }}>Create a new Task</div>
        </Typography> */}

        {isLoaded ?
          (
            <>
              <div className={`${classes['task-container']} mt-3`}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography className={`${classes['task-label']}`} variant="subtitle1">Select Domain<span className={`${classes['required-asterisk']}`}>*</span></Typography>
                    <Autocomplete
                      value={selectedDomain}
                      onChange={setDomainId}
                      options={domainList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Select Name"
                          variant="outlined"
                          margin="normal"
                          required
                          size="small"
                          fullWidth
                        />
                      )}
                    />


                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={`${classes['task-label']}`} variant="subtitle1">Select Project<span className={`${classes['required-asterisk']}`}>*</span></Typography>
                    <Autocomplete
                      value={selectedProject}
                      onChange={setProjectId}
                      options={projectList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Select Name"
                          variant="outlined"
                          margin="normal"
                          required
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography style={{ marginBottom: '1em' }} className={`${classes['task-label']}`} variant="subtitle1">Select Task<span className={`${classes['required-asterisk']}`}>*</span></Typography>
                    <TextField
                      label="Enter Task Name"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={`${classes['task-label']}`} variant="subtitle1">Task Description<span className={`${classes['required-asterisk']}`}>*</span></Typography>
                    <TextField
                      label="Enter Task Description"
                      multiline
                      rows={4}
                      value={taskDescription}
                      onChange={(e) => setTaskDescription(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={`${classes['task-label']}`} variant="subtitle1">Select User<span className={`${classes['required-asterisk']}`}>*</span></Typography>
                    <Autocomplete
                      id="user-domain"
                      // options={users && users.length > 0 ? users.sort((a, b) => -b.core_domain.localeCompare(a.core_domain)) : []}

                      // options={sortedUsers}
                      // groupBy={(option) => option.domain_name}
                      // getOptionLabel={(option) => option.name}
                      // value={selectedUser}

                      options={sortedUsers}
                      groupBy={(option) => option.domain_name}
                      getOptionLabel={(option) => option.name}
                      value={selectedUser}
                      isOptionEqualToValue={(option, value) => {
                        if (value === null) return false; 
                        return option.user_id === value.user_id;
                      }}


                      onChange={(event, value) => { handleSelectUser(value) }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className={`${classes['task-label']}`} variant="subtitle1">
                      Repeat<span className={`${classes['required-asterisk']}`}>*</span>
                    </Typography>
                    <FormControl fullWidth>
                      <Select size="small" value={repeat} onChange={handleRecurring}>
                        {options.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className={`${classes['task-label']} text-center`} variant="subtitle1">
                      Critical
                    </Typography>
                    <FormControlLabel
                      sx={{ marginRight: 0 }}
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={critical === 1} onChange={handleCritical}
                      />}
                    />
                  </Grid>


                  <Grid item xs={2} className={`${(reoccured) ? 'd-none' : ''}`} >
                    <div className={'form-group d-flex flex-column'}>
                      <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Due Date <span className={`${classes['required-asterisk']}`}>*</span></label>
                      <input
                        // type="datetime-local"
                        type="date"
                        id="dateInput"
                        value={dueDate}
                        // onChange={(e) => setDueDate(e.target.value)}
                        onChange={handleDateChange}

                        onBlur={handleDateBlur}
                        // InputLabelProps={{ shrink: true }}
                        fullWidth
                        style={{ ...dateStyles }}
                        min={getCurrentDate()}
                        max={projectDueDate}
                      />
                    </div>

                  </Grid>

                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '1em' }}>
                  {!subtask && Object.keys(subtasks).length === 0 && (
                    <>
                      <Button variant="contained" style={{ backgroundColor: 'grey', padding: 0, width: '9em' }}>
                        <IconButton title="Add Subtask" onClick={handleAddSubtask} style={{ width: '100%', padding: '0.14em', display: 'flex', justifyContent: 'space-evenly' }}>
                          <AddCircleOutlineIcon style={{ color: 'white' }} />
                          <span style={{ marginLeft: '0.2em', fontSize: '0.7em', color: 'white' }}>SubTask</span>
                        </IconButton>
                      </Button>

                      <Button onClick={handleCreateTask} style={{ border: '1px solid green', backgroundColor: 'green', color: 'white' }}>
                        {editTask ? 'Update Task' : 'Create Task'}
                      </Button>

                    </>
                  )}


                </Grid>



                {Object.keys(subtasks).map((key, index) => {
                  const subtask = subtasks[key];

                  const isLastSubtask = index === Object.keys(subtasks).length - 1;

                  return (
                    <Grid container className="mt-3" 
                    id={key} ref={key === newSubtaskKey ? newSubtaskRef : null}

                    spacing={2} key={key} style={{ ...subTaskContainerStyle }}>
                      <Grid item xs={12}>
                        <div className='d-flex justify-content-between align-items-center'>
                          <Typography variant="h6">Subtask {index + 1} </Typography>
                          <IconButton title={"Delete SubTask"} color="secondary" onClick={() => handleDeleteSubtask(key)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </div>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`} >Subtask Name<span style={{ color: 'red' }}>*</span></Typography>
                        <TextField
                          // label="Subtask Name"
                          value={subtask.sub_task_name}
                          onChange={(e) => handleSubtaskChange(key, 'sub_task_name', e.target.value)}
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`} >Assign Type<span style={{ color: 'red' }}>*</span></Typography>
                        <Autocomplete
                          value={subtask.sub_task_assign_type || null}
                          options={assignList}
                          getOptionLabel={(option) => option.skill_name}
                          onChange={(event, newValue) => handleSubtaskChange(key, 'sub_task_assign_type', newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Assign Type"
                              variant="outlined"
                              margin="normal"
                              required
                              size="small"
                              fullWidth
                              style={{ margin: 0 }}
                            />
                          )}
                        />
                      </Grid>


                      <Grid item xs={5}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`} >Assign User<span style={{ color: 'red' }}>*</span></Typography>
                        <Autocomplete
                          value={subtask.sub_task_assign_to || null}
                          options={assingSortedUser}
                          groupBy={(option) => option.domain_name}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => handleSubtaskChange(key, 'sub_task_assign_to', newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Assign User"
                              variant="outlined"
                              margin="normal"
                              required
                              size="small"
                              fullWidth
                              style={{ margin: 0 }}
                            />
                          )}
                        />
                      </Grid>


                      <Grid item xs={12}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`}>Subtask Description<span style={{ color: 'red' }}>*</span></Typography>
                        <TextField
                          // label="Subtask Description"
                          multiline
                          rows={4}
                          value={subtask.sub_task_description}
                          onChange={(e) => handleSubtaskChange(key, 'sub_task_description', e.target.value)}
                          fullWidth
                        />
                      </Grid>                
                      <Grid item xs={3} className={`${(reoccured) ? 'd-none' : ''}`}>
                        <div className={'form-group d-flex flex-column'}>
                          <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Start Date</label>
                          <input
                            // type="datetime-local"
                            type="date"
                            id="dateInput"
                            value={subtask.start_datetime}
                            // onChange={(e) => handleSubtaskChange(key, 'start_datetime', e.target.value)}
                            onChange={(e) => handleSubtaskChange(key, 'start_datetime', e.target.value)}
                            onBlur={(e) => handleBlur(key, 'start_datetime', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            className="form-control"
                            style={{ ...dateStyles, background: 'rgba(217, 217, 217, 0.22)' }}
                            max={dueDate}
                            min={getCurrentDate()}
                          />
                        </div>


                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`} >Estimation Time (Hrs)<span style={{ color: 'red' }}>*</span></Typography>
                        <TextField
                          // label="Estimation Time (Hrs)"
                          type="number"
                          value={subtask.effort_hours}
                          // onChange={(e) => handleSubtaskChange(key, 'effort_hours', e.target.value)}
                          onChange={(e) => { handlePositiveNumberChange(e, key) }}
                          fullWidth
                          size="small"
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                      <Grid item xs={3} className={`${(reoccured) ? 'd-none' : ''}`} >
                        <div className={'form-group d-flex flex-column'}>
                          <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Completed Date</label>
                          <input
                            // type="datetime-local"
                            type="date"
                            id="dateInput"
                            value={subtask.end_datetime}
                            // onChange={(e) => handleSubtaskChange(key, 'end_datetime', e.target.value)}
                            onChange={(e) => handleSubtaskChange(key, 'end_datetime', e.target.value)}
                            onBlur={(e) => handleBlur(key, 'end_datetime', e.target.value)}

                            className="form-control"
                            style={{ ...dateStyles, background: 'rgba(217, 217, 217, 0.22)' }}
                            max={dueDate}
                            min={getCurrentDate()}
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>


                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`} >Upload Files</Typography>
                        <label htmlFor={`file-upload-${index}`} style={{ cursor: 'pointer' }}>
                          <input
                            id={`file-upload-${index}`}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileUpload(key, e.target.files)}
                            accept=".csv, image/*"
                            multiple
                          />
                          <IconButton component="span">
                            <CloudUploadIcon />
                          </IconButton>
                        </label>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="subtitle1" className={`${classes['task-label']}`} >Uploaded Files</Typography>
                        <div>
                          {subtask.file_name && subtask.file_name.length > 0 ? (
                            subtask.file_name.map((fileName, fileIndex) => (
                              <div key={fileIndex} style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%'
                                  }}
                                >
                                  {formatFileName(fileName)}
                                </span>
                                <IconButton onClick={() => handleFileDelete(key, fileIndex)}>
                                  <CancelIcon />
                                </IconButton>
                              </div>
                            ))
                          ) : (
                            <span>No files uploaded</span>
                          )}
                        </div>
                      </Grid>

                      {isLastSubtask && (
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '1em' }}>
                          <Button variant="contained" style={{ backgroundColor: 'grey', padding: 0, width: '9em' }}>
                            <IconButton title="Add Subtask" onClick={handleAddSubtask} style={{ padding: '0.14em' }}>
                              <AddCircleOutlineIcon style={{ color: 'white' }} />
                              <span style={{ marginLeft: '0.2em', fontSize: '0.7em', color: 'white' }}>SubTask</span>
                            </IconButton>
                          </Button>
                          <Button title={"Save Subtask"} variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleCreateTask(key)}>
                            Save
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}

              </div>
            </>
          ) : (
            <>
              <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            </>
          )}



        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleSnackClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

      </div>
    </>
  )
}

export default TaskPage