import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import classes from "./CreateUser.module.css";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";

import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { exact, func } from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import UserTitle from '../Admin/UserTitle'

import { ToastContainer, toast } from 'react-toastify';
import { getCookie } from "../../Services/Cookie/Cookie"

import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import isValidEmail from '../../Services/Validators/emailValidator';
import DOMPurify from 'dompurify';
import Swal from 'sweetalert2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';


import {

  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import ModifyUser from './ModifyUser'



function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const Content = () => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [value, setValue] = useState(0);


  const [arrowDirections, setArrowDirections] = useState({
    skillset: 'down',
    coreDomain: 'down',
    globalPrivilege: 'down'
  });


  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('')

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const validateUserName = (name) => {
    const regex = /^[a-zA-Z\s]*$/; // Regular expression to allow only letters and spaces
    return regex.test(name);
  };

  

  const handleCreateUser = async (event) => {
    if (!userName) {
      setSnackbarOpen(true)
      setMessage('Please enter your username')
      setSeverity('error')
      return
    }

    if (!validateUserName(userName)) {
      setSnackbarOpen(true);
      setMessage('Username should only contain letters and spaces');
      setSeverity('error');
      return;
    }

  
    if (!email) {
      setSnackbarOpen(true)
      setMessage('Please enter your email.')
      setSeverity('error')
      return
    }
    if (!isValidEmail(email)) {
      setSnackbarOpen(true)
      setMessage('Invalid Email')
      setSeverity('error')
      return
    }

    if (!finalSkill || Object.keys(finalSkill).length === 0) {
      setSnackbarOpen(true)
      setMessage('Please select the skill set of the user')
      setSeverity('error')
      return

    }

    if (!finalDomain || Object.keys(finalDomain).length === 0) {
      setSnackbarOpen(true)
      setMessage('Please select a core domain')
      setSeverity('error')
      return
    }


    const data = {
      "username": DOMPurify.sanitize(email),
      "name": DOMPurify.sanitize(userName),
      "is_active": 1,
      "core_domain": DOMPurify.sanitize(Object.keys(finalDomain)[0]),
      "skill_set": finalSkill,
      "global_privileges": selectedValues,
      "password": ''
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    }
    try {
      let response = await axios.post('/backend/dh-signup-user', data, config);
      setSnackbarOpen(true)
      setMessage('User Created Successfully')
      setSeverity('success')
      setEmail('')
      setUserName('')
      setSkillCheck({})
      setAbbrevation({})
      setAbbrevationSkill(false)
      setDomain()
      setFinalDomain({})
      setSkillLevelCheck({})
      fetchData()
      setArrowDirections({
        skillset: 'down',
        coreDomain: 'down',
        globalPrivilege: 'down'
      })
    } catch (error) {

      const message = error.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)
    }



  }

  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [selectedValues, setSelectedValues] = useState({});
  const [skills, setSkills] = useState({});
  const [globalPrivileges, setGlobalPrivileges] = useState([]);
  const [coreDomain, setCoreDomain] = useState([]);
  const [activeSection, setActiveSection] = useState(["coreDomain", "skillset", "globalPrivilege"])
  const [skillLevel, setSkillLevel] = useState([])
  const [open, setOpen] = useState(false);
  const [abbrevationSkill, setAbbrevationSkill] = useState(false)
  const [finalSkill, setFinalSkill] = useState({})
  const [globalAccess, setGlobalAccess] = useState()

  const [level, setLevel] = useState({})


  // Navbar Changes
  const [dashboard, setDashboard] = useState({
    "Dashboard": "User Management",
    "Info": "Manage users, roles, and access permissions within Dash"
  })

  const transformData = (input) => {
    const [obj] = input; // Extract the single object from the array
    return Object.keys(obj).map(key => ({ [key]: obj[key] }));
  };


  useEffect(() => {
    fetchData();
  }, []);





  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };


    try {
      const response = await axios.get("/backend/get_all_options", config)
      let data = response.data

      let is_active = 1
      const domainResponse = await axios.get(`/backend/get_all_domain_list/${is_active}`, config)
      let domainData = domainResponse.data
      const modifyDomain = domainData.map(item => ({ [item.domain_id]: item.domain_name }));

      const skillsResponse = transformData([data['skill-set']]);

      const privilegesResponse = transformData([data['global_privileges']]);
      const skillLevel = transformData([data['skill-levels']]);
      const globalAccess = data['access-level']
      setGlobalAccess(globalAccess)
      setSkills(skillsResponse);
      setGlobalPrivileges(privilegesResponse);
      setCoreDomain(modifyDomain);
      setSkillLevel(skillLevel);


      const initialSelectedValues = privilegesResponse.reduce((acc, privilege) => {
        let privilege_2 = Object.keys(privilege)[0]
        acc[privilege_2] = 'none';
        return acc;
      }, {});

      setSelectedValues(initialSelectedValues);
      setIsLoaded(true)
    }
    catch (error) {
      console.error(error)
    }


  };


  let userSection = ['coreDomain', 'globalPrivilege', 'skillset']
  const handleArrowClick = (section) => {
    if (activeSection.length == 1) {
      setActiveSection(userSection)
    }
    else {
      let currentActiveSection = [section]
      setActiveSection(currentActiveSection)
    }

    setArrowDirections(prevDirections => {
      const newDirections = { ...prevDirections };
      newDirections[section] = prevDirections[section] === 'down' ? 'up' : 'down';
      return newDirections;
    });
  };


  const handleRadioChange = (functionName, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [functionName]: value
    }));
  };

  const chipStyles = {
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    padding: '0.2em',
    margin: '0.8em',
    cursor: 'pointer',
    border: '1px solid black',
    borderRadius: '20px'
  };
  const chipHoverStyle = {
    backgroundColor: '#EEEEEE'
  };

  const closeModal = (event, reason) => {
    setSkillLevelCheck(level)
    setOpen(false)
    setSkillButton(true)
    setAbbrevationSkill(true)
  }

  const [modalOpenIndex, setModalOpenIndex] = useState(null)
  const [abbrivation, setAbbrevation] = useState({})

  const handleOpen = () => {
    setOpen(true)
  };

  const [skillCheck, setSkillCheck] = useState({})
  const [skillLevelCheck, setSkillLevelCheck] = useState({})

  const [skillName, setSkillName] = useState('')
  const handleSkillLevelClick = (index, key, value) => {
    setSkillName(value)

    setModalOpenIndex(index)
    const filteredState1 = Object.keys(skillLevelCheck)
      .filter(key => key in level)
      .reduce((obj, key) => {
        obj[key] = skillLevelCheck[key];
        return obj;
      }, {});

    if (Object.keys(filteredState1).length == 0) {
      setSkillLevelCheck(level)
    }
    else {

      setSkillLevelCheck(level);
    }

    setOpen(true)

  }
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  };


  const handleSkillClick = (key, skill, skillLevelIndex) => {

    setSkillCheck({})
    setSkillCheck((prevSkillCheck) => ({
      ...prevSkillCheck,
      [key]: skillLevelIndex
    }));

    setSkillLevelCheck((prevState) => ({
      ...prevState,
      [modalOpenIndex]: key
    }))
    setSkillButton(false)
  };

  const handleSkill = (keyToRemove, index, indexKey, skillIndex, skillLabel, skillName) => {

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to Remove the ${skillName} Skill Set?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        setAbbrevation(prevState => {
          const newState = { ...prevState };
          if (newState[keyToRemove]) {
            delete newState[keyToRemove];
          }
          return newState;
        });
        setSkillLevelCheck(prevState => {
          const newState = { ...prevState };
          delete newState[keyToRemove]
          return newState
        })
        setLevel(prevState => {
          const newState = { ...prevState };
          delete newState[keyToRemove]
          return newState
        })
        setFinalSkill(prevState => {
          const newState = { ...prevState };
          delete newState[indexKey]
          return newState
        })
      }
      else {
        return
      }
    });
  }


  const addSkill = () => {

    setAbbrevation((prevState) => ({
      ...prevState,
      [modalOpenIndex]: skillCheck
    }));

    setLevel((prevState) => ({
      ...prevState,
      [modalOpenIndex]: Object.keys(skillCheck)[0]
    }));
    setAbbrevationSkill(true);


    setFinalSkill((prevValues) => ({
      ...prevValues,
      [Object.keys(skills[modalOpenIndex])[0]]: Object.keys(skillCheck)[0]
    }));

    closeModal();
  };


  const [domain, setDomain] = useState()
  const [finalDomain, setFinalDomain] = useState({})

  const handleDomain = (value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to Remove the ${value} Domain?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        setDomain()
        setFinalDomain({})
      }
      else {
        return
      }
    });


  }
  const handleCoreDomain = (index) => {
    setDomain(index + 1)
    setFinalDomain(coreDomain[index])
  }


  const [privilege, setPrivilege] = useState({})


  const [defaultOptionSet, setDefaultOptionSet] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCreateUser();
    }
  };



  const [skillButton, setSkillButton] = useState(true)

  const [globalInfo, setGlobalInfo] = useState({
    "domain_management": "Domain management privilege enables you to manage project-level access assigned to users.",
    "user_management": " User management privilege allows you to create, edit, or archive users.",
    "sprint": "Sprint privilege grants you the ability to create, edit, or delete sprints.",
    "notes": "create Notes",
    "domain_creation": "Domain creation privilege enables you to create, delete, or archive domains.",
    "perf_score": "Performance score privilege allows you to assign performance scores to individual users"
  })


  const handleSearch = (event) => {

  };


  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        maxWidth="sm"
        fullWidth
        sx={{ borderRadius: '1rem' }}
        style={{ top: '-10%' }}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '20em' }}>
          <div className='d-flex justify-content-between align-items-center'>
            <div>Select the Skillset Level for  <strong> {skillName} </strong> Skill</div>
            <div>
              <IconButton edge="end" color="inherit" onClick={() => closeModal()} aria-label="close">
                <Close />
              </IconButton>
            </div>

          </div>
          <div className="d-flex ">
            <div className={`${classes['custom-input-container']} ${classes['chip-container']} d-flex flex-column justify-content-around`}>
              <div className='d-flex justify-content-between  flex-column flex-wrap' style={{ width: '100%' }}>
                {skillLevel && skillLevel.map((skill, index) => {
                  const key = Object.keys(skill)[0];
                  const value = skill[key];
                  const isActive = skillLevelCheck.hasOwnProperty(modalOpenIndex) && skillLevelCheck[modalOpenIndex] === key;
                  const activeStyle = { cursor: 'pointer', borderBottom: '1px solid black', color: 'black', fontWeight: 'bold', backgroundColor: '#EEEEEE' };
                  const defaultStyle = { cursor: 'pointer', borderBottom: '1px solid black', color: '#898383' };

                  return (
                    <div
                      key={index}
                      style={isActive ? activeStyle : defaultStyle}
                      onClick={() => handleSkillClick(key, value, index)}
                      className={classes['skill-level']}
                    >
                      {value}
                    </div>
                  );
                })}

              </div>
            </div>

          </div>

          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={addSkill} disabled={skillButton}>
              <AddIcon sx={{ color: 'white', fontWeight: 'bold' }} /> Add
            </Button>

          </div>


        </DialogContent>
      </Dialog>


      <div className={classes['content']}>
        {isLoaded ? (
          <>
            <div className={`${classes['create-user-container']}  mt-3`}>
              <div className={classes['user-creation-container']}>
                <div className='p-3 d-flex flex-column justify-content-between' style={{ minHeight: '38em' }}>
                  <div className='d-flex justify-content-between align-items-center' style={{ width: '100%' }}>
                    <TextField
                      // required
                      size="small"
                      id="user-name"
                      // label="Name"
                      label={
                        <span>
                          Full Name<span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      sx={{ width: '48%', backgroundColor: 'white' }}
                      onChange={(event) => {
                        setUserName(event.target.value)
                      }}
                      value={userName}
                      onKeyDown={handleKeyDown}
                    />
                    <TextField
                      // required
                      size="small"
                      id="user-email"
                      // label="Email"
                      label={
                        <span>
                          Email<span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      sx={{ width: '48%', backgroundColor: 'white' }}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      onKeyDown={handleKeyDown}
                    />

                  </div>

                  {/* Add Skillset */}
                  {(arrowDirections.skillset === 'down' || arrowDirections.skillset === 'up') && (
                    <div className="d-flex flex-column align-items-center mt-4" >
                      <div className={`${classes['custom-input-container']} d-flex justify-content-between align-items-center`} >
                        {Object.keys(abbrivation).length > 0 && abbrevationSkill ? (
                          <>
                            <div style={{ width: '100%', padding: '0.5em' }} onClick={() => handleArrowClick('skillset')} >
                              <div className='d-flex justify-content-between align-item-center'   >
                                <span style={{ color: 'black', fontWeight: '600' }}>Skillset</span>
                                <span className={`${classes['arrow']}`}>
                                  {arrowDirections.skillset === 'down' ? '▼' : '▲'}
                                </span>
                              </div>
                              <div className={`d-flex mt-2 flex-wrap`}>
                                {Object.entries(abbrivation).map(([key, value]) => {
                                  const index = key;
                                  const indexKey = Object.keys(skills[key])[0]
                                  const skillIndex = Object.values(value)[0];
                                  const skillLabel = skillLevel[skillIndex] ? Object.values(skillLevel[skillIndex])[0] : '';
                                  const skillName = skills[key] ? Object.values(skills[key])[0] : '';

                                  return (
                                    // New code
                                    <div className={classes['skill-container']} style={{ ...chipStyles, "&:hover": chipHoverStyle }}>
                                      <Chip
                                        key={key}
                                        // label={`(${skillLabel[0]}) ${skillName}`}
                                        label={
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                              sx={{
                                                width: 24,
                                                height: 24,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#f0f0f0',
                                                marginRight: 1,
                                                fontSize: 14,
                                              }}
                                            >
                                              {skillLabel[0]}
                                            </Box>
                                            {skillName}
                                          </Box>
                                        }
                                        variant="outlined"
                                        sx={{ margin: 0.2, padding: 0, border: 'none' }}
                                      />
                                      {/* <CancelIcon onClick={() => { handleSkill(key, index, indexKey, skillIndex, skillLabel, skillName) }} /> */}
                                      <CancelIcon onClick={(e) => {
                                        e.stopPropagation();
                                        handleSkill(key, index, indexKey, skillIndex, skillLabel, skillName);
                                      }}
                                      />
                                    </div>
                                  );
                                })}

                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='d-flex justify-content-between align-item-center' onClick={() => handleArrowClick('skillset')} style={{ width: '100%', padding: '0.5em' }}>
                              <span style={{ color: '#898383' }}>Add Skillset<span style={{ color: 'red' }}>*</span></span>
                              <span className={`${classes['arrow']}`}>
                                {arrowDirections.skillset === 'down' ? '▼' : '▲'}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {arrowDirections.skillset === 'up' && (
                    <div className="d-flex mt-4">
                      <div className={`${classes['custom-input-container']} ${classes['chip-container']} d-flex justify-content-around`}>
                        <div className='d-flex  flex-wrap' style={{ width: '95%' }}>
                          {skills.map((skill, index) => {
                            const [key, value] = Object.entries(skill)[0];
                            const isAbbreviation = index in abbrivation;
                            const Icon = isAbbreviation ? CheckIcon : AddIcon;

                            return (
                              <Chip
                                key={index}
                                label={value}
                                value={key}
                                avatar={<Icon sx={{ color: 'black', fontWeight: 'bold' }} />}
                                variant="outlined"
                                className={classes.chip}
                                sx={isAbbreviation ? { backgroundColor: '#EEEEEE', margin: '0.5em', ...chipStyles, "&:hover": chipHoverStyle } : { margin: '0.5em', ...chipStyles, "&:hover": chipHoverStyle, }}
                                onClick={() => handleSkillLevelClick(index, key, value)}
                              />
                            );
                          })}
                        </div>
                        <div className='right' style={{ padding: '0.5em', width: '3%' }} onClick={() => handleArrowClick('skillset')}>
                          <IconButton edge="end" color="inherit" aria-label="close">
                            <Close />
                          </IconButton>
                        </div>

                      </div>
                    </div>
                  )}

                  {/* Add Core Domain */}
                  {(arrowDirections.coreDomain === 'down' || arrowDirections.coreDomain === 'up') && (
                    <div className="d-flex flex-column align-items-center mt-4">
                      <div className={`${classes['custom-input-container']} d-flex justify-content-between align-items-center`} >
                        {domain ? (
                          <div className={`${classes['domain-container']} d-flex justify-content-between`} style={{ width: '100%', padding: '0.5em' }} onClick={() => handleArrowClick('coreDomain')} >
                            <div className='d-flex '   >
                              <div style={{ color: 'black', width: '50%', borderRight: '1px solid black', width: '100%', padding: '0.5em', fontWeight: '500' }} className='d-flex justify-content-center align-items-center'>Core Domain</div>
                              <div style={{ width: '100%' }} className='ms-2' >
                                <div className="d-flex justify-content-center align-items-center" style={{ ...chipStyles, "&:hover": chipHoverStyle, width: 'fit-content' }}>
                                  <Chip
                                    key={domain}
                                    label={Object.values(coreDomain[domain - 1])[0]}
                                    variant="outlined"
                                    sx={{ minWidth: '6em', maxWidth: '20em', margin: 0, padding: 0, border: 'none' }}
                                  />
                                  <CancelIcon onClick={(e) => {
                                    e.stopPropagation();
                                    handleDomain(Object.values(coreDomain[domain - 1])[0])
                                  }} />
                                </div>
                              </div>

                            </div>

                            <div>
                              <span className={`${classes['arrow']}`}>
                                {arrowDirections.coreDomain === 'down' ? '▼' : '▲'}
                              </span>
                            </div>
                          </div>
                          // </div>
                        ) : (
                          <>
                            <div className='d-flex justify-content-between align-items-center' onClick={() => handleArrowClick('coreDomain')} style={{ width: '100%', padding: '0.5em' }}>
                              <span style={{ color: '#898383' }}>Add Core Domain <span style={{ color: 'red' }}>*</span></span>
                              <span className={`${classes['arrow']}`}>
                                {arrowDirections.coreDomain === 'down' ? '▼' : '▲'}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {arrowDirections.coreDomain === 'up' && (
                    <div className="d-flex align-items-center mt-4">
                      <div className={`${classes['custom-input-container']} ${classes['chip-container']} d-flex  justify-content-around`}>

                        <div className='d-flex flex-wrap' style={{ width: '95%' }}>
                          {coreDomain.map((cdomain, index) => {
                            const [key, value] = Object.entries(cdomain)[0];
                            const isLastDomain = index === domain - 1;
                            const Icon = isLastDomain ? CheckIcon : AddIcon;

                            return (
                              <Chip
                                key={index}
                                label={value}
                                avatar={<Icon sx={{ color: 'black', fontWeight: 'bold' }} />}
                                variant="outlined"
                                sx={{ ...chipStyles, "&:hover": chipHoverStyle, ...(isLastDomain && { backgroundColor: '#EEEEEE' }) }}
                                onClick={!isLastDomain ? () => handleCoreDomain(index) : undefined}
                              />
                            );
                          })}
                        </div>
                        <div className='right' style={{ width: '1%' }} onClick={() => handleArrowClick('coreDomain')}>
                          <IconButton edge="end" color="inherit" aria-label="close">
                            <Close />
                          </IconButton>
                        </div>

                      </div>
                    </div>
                  )}
                  <div className="d-flex align-items-center mt-2 flex-column">
                    <div className='d-flex' style={{ width: '100%' }}>
                      <span style={{ color: 'black', padding: '0.5em', textAlign: 'start' }}>Add Global Privileges</span>
                    </div>

                    <div className={`${classes['custom-input-container']} ${classes['global-privilege-container']} d-flex  flex-column justify-content-between align-items-center`}>
                      <div className='d-flex flex-wrap' style={{ width: '100%', padding: '1em' }}>
                        <table className={classes['content-table']} >
                          <thead>
                            <tr>
                              <th>Privileges Name</th>
                              {globalAccess && globalAccess.map((value, index) => (
                                <th key={index}>{value.toUpperCase()}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {globalPrivileges.map((privilegeObj, index) => {
                              let privilege = Object.values(privilegeObj)[0];
                              let key = Object.keys(privilegeObj)[0]
                              return (
                                <tr key={index}>
                                  {/* checked={selectedValues[privilege] === 'none'}  */}
                                  <td className={`${classes['function-name']}`}>{privilege}
                                    <Tooltip
                                      title={globalInfo[key]}
                                      classes={classes.customTooltip}
                                      arrow
                                      slotProps={{
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -14],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                      placement='right'
                                    >
                                      <IconButton className={classes.customIconButton} sx={{ padding: '0.25em' }}>
                                        <InfoIcon sx={{ fontSize: '0.55em', color: 'grey' }} className={classes.infoIcon} />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                  <td><input type="radio" name={privilege} className={`${classes['radio-pointer']}`} checked={!defaultOptionSet && selectedValues[key] === 'none'} onChange={() => handleRadioChange(key, 'none')} /></td>
                                  <td><input type="radio" name={privilege} className={`${classes['radio-pointer']}`} checked={!defaultOptionSet && selectedValues[key] === 'edit'} onChange={() => handleRadioChange(key, 'edit')} /></td>
                                  <td><input type="radio" name={privilege} className={`${classes['radio-pointer']}`} checked={!defaultOptionSet && selectedValues[key] === 'full'} onChange={() => handleRadioChange(key, 'full')} /></td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                  <div className='mt-3'>
                    <Button variant="contained" style={{ backgroundColor: 'rgb(9, 94, 173)' }} onClick={() => { handleCreateUser() }}>Add User</Button>
                  </div>
                </div>
              </div>
            </div>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </div>
        )
        }
      </div>

    </>
  );

};

export default Content;
