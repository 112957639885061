import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Calendar } from 'primereact/calendar';
import { Autocomplete, TextField, Chip, Box, Typography, IconButton, } from '@mui/material';
import DashboardTitle from '../Dashboard/DashboardTitle';
import classes from "./Sprint.module.css";
import Navbar from "../Navbar/Navbar";
import CloseIcon from '@mui/icons-material/Close';
import { getCookie } from '../../Services/Cookie/Cookie';
import axios from 'axios';
import { ReactComponent as CreateSprint } from '../../Assets/CreateSprint.svg';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SprintTable from './SprintTable';
import Swal from 'sweetalert2';
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as SprintView } from "../../Assets/SprintView.svg";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  DialogTitle,
  Alert,

} from '@mui/material';
import { Checkbox } from 'primereact/checkbox';



export default function SprintDataTable() {

  const [sprints, setSprints] = useState([]);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false)
  const [userDomainList, setUserDomainList] = useState([])
  const [userFilterdDomain, setUserFilteredDomain] = useState([])
  const [userDetails, setUserDetails] = useState(null)


  useEffect(() => {
    // Initialize dummy data
    // setSprints(getDummyData());
    fetchUserDetails()
    initFilters();
    fetchUserAccessDomain()
    fetchUserDomain()

    fetchSprintList()
  }, []);


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#C80036' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },

  }));



  const fetchUserDomain = async () => {
    let is_active = "ALL";
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let domainResponse = await axios.get(`/backend/get_user_edit_domain_list/${is_active}`, config);
      let domainData = domainResponse.data;
      setDomains(domainData);

    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }
  };


  const addDomainNames = (sprints) => {
    return sprints.map(sprint => {
      const domainNames = sprint.domain_id_list.map(domainId => sprint.all_domain_summary[domainId]?.name).filter(name => name);
      return {
        ...sprint,
        domain_name: domainNames.join(', ')
      };
    });
  };

  const [originalSprintList, setOriginalSprintList] = useState([])
  const fetchSprintList = async () => {
    let is_active = "ALL";
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let sprintResponse = await axios.get(`/backend/get_all_sprint_list/ALL`, config);
      let sprintData = sprintResponse.data;
      const sprintsWithDomainNames = addDomainNames(sprintData);
      setOriginalSprintList(sprintsWithDomainNames)
      setSprints(sprintsWithDomainNames);
      setIsLoaded(true)
    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
      setIsLoaded(true)
    }
    catch (error) {

      if (error.response.status == 401) {
        const message = error.response.data.details || 'An error occurred';
      }
    }
  }


  const fetchUserAccessDomain = async () => {
    let is_active = "ALL";
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let domainResponse = await axios.get(`/backend/get_user_domain_list/${is_active}`, config);
      let domainData = domainResponse.data;
      setUserDomainList(domainData);


    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }
  };




  const formatDate = (value) => {
    return value.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    // let _filters = { ...filters };
    // _filters['global'].value = value;
    // setFilters(_filters);
    setGlobalFilterValue(value);
  };




  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      sprint_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      created_by: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      domain: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.IN }] }
    });
    setGlobalFilterValue('');
  };


  const onDomainFilterChange = (event, value) => {
    setSelectedDomains(value);
  };
  const onDomainSprintEdit = (event, value) => {
    setEditDomainSprintList(value);
  };


  const onDomainFiltertable = (event, value) => {

    setUserFilteredDomain(value);
    if (value == null || value == "" || value == undefined) {
      setSprints(originalSprintList)
      setGlobalFilterValue('')
    }
    else {
      const domainNamesString = value.map(values => values.domain_name).join(',');
      const domainNamesArray = domainNamesString.split(',');
      const filteredData = originalSprintList.filter(item =>
        domainNamesArray.some(domain => item.domain_name.includes(domain.trim()))
      );
      if (filteredData.length == 0) {
        setGlobalFilterValue("    ")

      }
      else {
        setGlobalFilterValue("")
        setSprints(filteredData);
      }

      // setGlobalFilterValue(domainNamesString)
    }

  };

  const handleCreateSprint = () => {
    setStep(1)
    setOpen(true)

  }

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between autocomplete-filter">
        <Autocomplete
          multiple
          id="multiple-limit-tags"
          options={userDomainList}
          getOptionLabel={(option) => option.domain_name}
          value={userFilterdDomain}
          onChange={onDomainFiltertable}
          renderTags={(value, getTagProps) =>
            value.length > 1
              ? [
                <Chip sx={{ width: '8em' }} key={value[0].domain_id} label={value[0].domain_name} {...getTagProps({ index: 0 })} />,
                <Box key="more" sx={{ marginLeft: '5px' }}>+{value.length - 1} more</Box>
              ]
              : value.map((option, index) => (
                <Chip sx={{ width: '8em' }} key={option.domain_id} label={option.domain_name} {...getTagProps({ index })} />
              ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Domains" placeholder="Domains" sx={{ width: '50x' }} />
          )}
          sx={{ width: '300px' }}
          size="small"
        />

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {userDetails && sprints.length != 0 && userDetails.global_privileges['sprint'] != 'none' ? (
            <div style={{ height: "3em" }} className='d-flex justify-content-center align-items-center'>
              <div className={classes['create-sprint-btn']}
                onClick={handleCreateSprint}
              >
                <ControlPointIcon
                  style={{
                    color: 'black', marginRight: '0.25em',



                  }}

                />
                Create Sprint
              </div>
            </div>
          ) : (
            <>
              <div></div>
            </>
          )
          }

          <InputText value={globalFilterValue} size="small" type="search" onChange={onGlobalFilterChange} placeholder="Search"
            style={{ width: '9em', height: '2em', borderRadius: '10px' }}

          />
        </div>
        {/* <Button  style={{ textAlign: 'center', width: '5em', height: '2em', borderRadius: '10px' }} size="small" type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} /> */}

      </div>
    );
  };

  const [sprintTable, setSprintTable] = useState(false)

  const [step, setStep] = useState(0)
  const [sprintDetails, setSprintDetails] = useState([])
  const [domainList, setDomainList] = useState([])

  const populateSprint = async (rowData) => {
    setSprintDetails(rowData)
    try {
      handleNavigateToSprintView(rowData, rowData.sprint_id)



    } catch (error) {
      console.error('Error creating task', error);
    }
  }


  const viewBodyTemplate = (rowData) => {
    return <SprintView style={{ width: '1.5em', cursor: 'pointer' }} icon="pi pi-file" onClick={() => { populateSprint(rowData) }} />;
  };

  const dateBodyTemplate = (rowData) => {
    return rowData.created_at
    // return formatDate(rowData.created_at);
  };

  const header = renderHeader();

  const [dashboard, setDashboard] = useState({
    "Dashboard": "Sprint Overview",
    "Info": "The Sprint Overview provides a comprehensive view of all tasks and subtasks assigned within a particular sprint."
  })
  const [sprintMode, setSprintMode] = useState(true)

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };


  const [selectedValue, setSelectedValue] = useState(null);
  const [textValue, setTextValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false)

  const options = ['Option 1', 'Option 2', 'Option 3'];
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');


  const handleSnackClose = () => {
    setSnackbarOpen(false);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [editSprintId, setEditSprintId] = useState(null)

  const handleEditSave = async () => {
    const domainIds = editDomainSprintList.map(domain => domain.domain_id);
    const domainIdsString = domainIds.join(',');

    const taskIds = selectedTasks.map(task => task.task_id).join(',');
    let data = {
      sprint_name: editSprintName,
      domain_id_list: domainIdsString,
      start_date: editSprintStartDate,
      end_date: editSprintEndDate,
      sprint_id: editSprintId,
      status: 1,
      extra_task_list: taskIds,


    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.post(`/backend/edit_sprint`, data, config);
      setSnackbarOpen(true)
      setMessage('Sprint details edited successfully')
      setSeverity('success')


      fetchSprintList()

    } catch (error) {
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
      // console.error('Error creating task', error);
    }
    handleClose();

  }

  const validateEndDate = (start, end) => {
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);
    const maxAllowedEndDate = new Date(startDateObj);
    maxAllowedEndDate.setDate(startDateObj.getDate() + 15);

    // Check if the selected end date is greater than the max allowed end date
    if (endDateObj > maxAllowedEndDate) {
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    // Handle save logic here
    const domainIds = selectedDomains.map(domain => domain.domain_id);
    const domainIdsString = domainIds.join(',');
    if (textValue == "") {
      setSnackbarOpen(true)
      setMessage('Please enter sprint name')
      setSeverity('error')
      return
    }
    if (domainIdsString == "") {
      setSnackbarOpen(true)
      setMessage('Please select domain')
      setSeverity('error')
      return
    }

    if (startDate == "" || startDate == null) {
      setSnackbarOpen(true)
      setMessage('Please enter a start date')
      setSeverity('error')
      return
    }
    if (endDate == "" || endDate == null) {
      setSnackbarOpen(true)
      setMessage('Please enter a end date')
      setSeverity('error')
      return
    }
    let validate_date = validateEndDate(startDate, endDate)
    if (!validate_date) {
      setSnackbarOpen(true)
      setMessage('Sprint Cannot exceed 15 days')
      setSeverity('error')
      return
    }

    let data = {
      sprint_name: textValue.trim(),
      domain_id_list: domainIdsString,
      start_date: startDate,
      end_date: endDate

    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.post(`/backend/create_sprint`, data, config);

      fetchSprintList()
      // console.log('sprint createdsuccessfully')

    } catch (error) {
      console.error('Error creating task', error);
    }
    handleClose();
  };


  const handleClose = () => {
    setOpen(false)
    setTextValue('')
    setStartDate(null)
    setEndDate(null)
    setSelectedDomains([])
  }


  const [selectedTasks, setSelectedTasks] = useState([]);

  const onTaskSelectionChange = (e) => {
    setSelectedTasks(e.value);
  };


  const getDomainNames = (rowData) => {
    const domainNames = rowData.domain_id_list.map(domainId => rowData.all_domain_summary[domainId]?.name).filter(name => name);
    return domainNames.join(', ');
  };

  const [editSprintName, setEditSprintName] = useState('')
  const [editSprintStartDate, setEditSprintStartDate] = useState(null)
  const [editSprintEndDate, setEditSprintEndDate] = useState(null)
  const [editDomainSprintList, setEditDomainSprintList] = useState([])
  const [editedDomains, setEditedDomains] = useState([])
  const [taskDetails, setTaskDetails] = useState([])

  const populateSprintData = async (rowData) => {

    setEditSprintName(rowData.sprint_name)
    setEditSprintStartDate(rowData.start_date)
    setEditSprintEndDate(rowData.end_date)
    setEditSprintId(rowData.sprint_id)

    const filteredDomains = domains.filter(domain => rowData.domain_id_list.includes(domain.domain_id));
    setEditDomainSprintList(filteredDomains)

    setSelectedTasks([])
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let sprintResponse = await axios.get(`/backend/get_overdue_tasks_list/${rowData.sprint_id}`, config);
      // let domainResponse = await axios.get(`/backend/get_all_tasks_list/DD-10/PD-14/1`, config);


      const filteredTasks = sprintResponse.data.filter(task => rowData.extra_task_list.includes(task.task_id));
      setSelectedTasks(filteredTasks)
      setTaskDetails(sprintResponse.data)

    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }


    // Output the filtered list
    setStep(2)
    setOpen(true)
  }


  const [sprintActiveStatus, setSprintActiveStatus] = useState(null)

  const handleSprintDisable = (rowData, rowIndex) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${rowData.is_active ? 'disable' : 'enable'} this  ${rowData.sprint_name} sprint?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        let status = !rowData.is_active
        sprintStatus(rowData, status);
      }
    });
  };


  const sprintStatus = async (rowData, sprintActiveStatus) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const data = {
        sprint_id: rowData.sprint_id,
        status: sprintActiveStatus,
      }
      const response = await axios.post(`/backend/sprint_status_update`, data, config);

    }
    catch (error) {
      // setProjectSnackBar(true)
      // setMessage(error.response.data.details)
      // setSeverity('success')
    }

  }

  const actionBodyTemplate = (rowData, actionType, rowIndex) => {
    const currentDate = new Date();
    console.log(rowData,'this is rowData')
    const endDate = new Date(rowData.end_date);
    let icon = '';
    let actionClass = '';
    console.log(endDate < currentDate)
    switch (actionType) {
      case 'edit':
        icon = 'pi pi-pencil';
        actionClass = 'icon-size';
        return (
          <EditIcon disabled={endDate<currentDate ? true:false} style={{ width: '1.5em', cursor: endDate < currentDate ? 'not-allowed':'pointer' }} onClick={() => {endDate<currentDate ? console.log('reached here'): populateSprintData(rowData) }} className={`${classes['icon-size']} p-button-rounded p-button-text`} />
        );

      case 'sprint_status':
        icon = 'pi pi-ban';
        actionClass = 'pi-ban';
        return (
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={sprintActiveStatus === 1} onChange={() => { handleSprintDisable(rowData) }}
              />
            }
          />
        );
      default:
        break;
    }
  }

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);

  const onPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };


  const customFilter = (value, filter) => {
    if (!value) return false;
    return value.toLowerCase().includes(filter.toLowerCase());
  };

  const filteredSprint = sprints.filter((user) => {

    return (
      (user.sprint_name && customFilter(user.sprint_name, globalFilterValue)) ||
      (user.created_at && customFilter(user.created_at, globalFilterValue)) ||
      (user.created_by && customFilter(user.created_by, globalFilterValue)) ||
      (user.domain_name && customFilter(user.domain_name, globalFilterValue))
    );
  });

  const navigate = useNavigate();

  const handleNavigateToSprintView = (sprintDetails, sprint_id) => {
    let state = {
      sprintDetails: sprintDetails,
      sprint_id: sprint_id
    }
    navigate('/dash/dashboard/sprint/sprintView', {
      state
    });
  };


  const dateStyles = {
    height: '2.5em',
    width: '8em',
    border: '1px solid gainsboro', // Corrected spelling of 'gainsboro'
    borderRadius: '2px', // Uncomment and set borderRadius if needed
  };
  return (
    <>

      {step == 1 &&
        <>
          <Dialog open={open} onClose={handleClose}

          >
            <DialogTitle style={{ padding: 0 }}>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ borderBottom: '1px solid black', padding: '9px 0' }}
              >
                <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>
                  Create a new Sprint
                </Typography>
                <IconButton onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Box>

            </DialogTitle>
            <DialogContent>
              <TextField
                label="Sprint Name"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <input
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ ...dateStyles, background: 'rgba(217, 217, 217, 0.22)', marginRight: '1em' }}
                  fullWidth
                  margin="normal"
                  size="small"
                  min={getCurrentDate()}
                // style={{ marginRight: '10px' }}
                />
                <input
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  margin="normal"
                  min={getCurrentDate()}
                  size="small"
                  // style={{ marginLeft: '10px' }}
                  style={{ ...dateStyles, background: 'rgba(217, 217, 217, 0.22)' }}
                />
              </div>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={domains}
                getOptionLabel={(option) => option.domain_name}
                value={selectedDomains}
                onChange={onDomainFilterChange}
                limitTags={2}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Domains" placeholder="Domains" fullWidth />
                )}
                sx={{ width: '100%', marginTop: '16px' }}
                size="small"
              />

            </DialogContent>

            <DialogActions style={{ justifyContent: 'center' }}>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              <Button onClick={handleSave} style={{ borderRadius: '10px', backgroundColor: 'rgb(9, 94, 173)' }}>Save</Button>
            </DialogActions>
          </Dialog>
        </>
      }

      {step == 2 &&
        <>
          <Dialog open={open} onClose={handleClose}
            PaperProps={{
              sx: {
                width: '60em',
                height: '50em',
                maxWidth: '50em ',

              },
            }}
          >
            <DialogTitle style={{ padding: 0 }}>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ borderBottom: '1px solid black', padding: '9px 0' }}
              >
                <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>
                  Edit the Sprint
                </Typography>
                <IconButton onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Box>

            </DialogTitle>
            <DialogContent

            >
              <TextField
                label="Sprint Name"
                value={editSprintName}
                onChange={(e) => setEditSprintName(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={editSprintStartDate}
                  onChange={(e) => setEditSprintStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  margin="normal"
                  size="small"
                  style={{ marginRight: '10px' }}
                  disabled
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={editSprintEndDate}
                  onChange={(e) => setEditSprintEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  margin="normal"
                  size="small"
                  style={{ marginLeft: '10px' }}
                  disabled
                />
              </div>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={domains}
                getOptionLabel={(option) => option.domain_name}
                value={editDomainSprintList}
                onChange={onDomainSprintEdit}
                limitTags={2}
                disabled
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Domains" placeholder="Domains" fullWidth />
                )}
                sx={{ width: '100%', marginTop: '16px' }}
                size="small"
              />

              <div className="mt-2">Task List : </div>
              <div className="datatable-selection-demo mt-3 task-list-datatable">
                <DataTable
                  value={taskDetails}
                  selection={selectedTasks}
                  onSelectionChange={onTaskSelectionChange}
                  dataKey="task_id"
                  selectionMode="multiple"
                  scrollable
                  scrollHeight="40vh"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  totalRecords={taskDetails.length}
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                >
                  <Column selectionMode="multiple" style={{ width: '3em' }} />
                  <Column field="task_name" header="Task Name" sortable />
                  <Column field="status" header="Status" sortable />
                  <Column field="domain_name" header="Domain Name" sortable />
                  <Column field="project_name" header="Project Name" sortable />
                  <Column field="task_description" header="Task Description" sortable />
                </DataTable>
              </div>

            </DialogContent>

            <DialogActions style={{ justifyContent: 'center' }}>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              <Button onClick={handleEditSave} style={{ borderRadius: '10px', backgroundColor: 'rgb(9, 94, 173)' }}>Save</Button>
            </DialogActions>
          </Dialog>
        </>
      }


      <div className={classes['content']}>
        <Navbar dashboard={dashboard} setIsLoaded={setIsLoaded} isLoaded={isLoaded} userDetails={userDetails} />


        {isLoaded ? (<>
          <DashboardTitle sprintMode={sprintMode} isLoaded={isLoaded} userDetails={userDetails} />

          {!sprintTable &&
            <div className={classes['card']} >
              <div className='d-flex justify-content-end align-items-center'>


              </div>


              {sprints.length != 0 ? (
                <>
                  <div className={classes['data-card']}>
                    <DataTable
                      // value={sprints}
                      scrollable
                      scrollHeight="50vh"
                      value={filteredSprint}
                      paginator
                      showGridlines
                      rows={10}
                      loading={loading}
                      dataKey="id"
                      // filters={filters}
                      globalFilterFields={['sprint_name', 'created_by', 'created_at', 'domain']}
                      header={header}
                      rowsPerPageOptions={[5, 10, 20, 50]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      totalRecords={totalRecords}
                      emptyMessage="No sprints found."
                    >
                      <Column
                        field="sprint_name"
                        header="Sprint Name"
                        sortable
                        filterPlaceholder="Search by sprint"
                        style={{ minWidth: '12rem', borderBottom: '1px solid #ccc' }}
                        bodyClassName={classes['custom-datatable-body']}
                        headerClassName={'header-align-sprint'}
                      />
                      <Column
                        bodyClassName={classes['custom-datatable-body']}
                        headerClassName={'header-align-sprint'}
                        field="created_at"
                        header="Created On"
                        dataType="date"
                        style={{ minWidth: '10rem', borderBottom: '1px solid #ccc' }}
                        body={dateBodyTemplate}
                        sortable
                        filterElement={(options) => (
                          <Calendar
                            value={options.value}
                            onChange={(e) => options.filterCallback(e.value)}
                            dateFormat="mm/dd/yy"
                            placeholder="mm/dd/yyyy"
                            mask="99/99/9999"
                          />
                        )}
                      />
                      <Column
                        field="created_by"
                        header="Created By"
                        sortable
                        filterPlaceholder="Search by creator"
                        style={{ minWidth: '12rem', borderBottom: '1px solid #ccc' }}
                        bodyClassName={classes['custom-datatable-body']}
                        headerClassName={'header-align-sprint'}
                      />
                      <Column
                        field="domain"
                        header="Domain"
                        body={getDomainNames}
                        style={{ minWidth: '12rem', borderBottom: '1px solid #ccc' }}
                        bodyClassName={classes['custom-datatable-body']}
                        headerClassName={'header-align-sprint'}
                      />
                      <Column
                        header="View"
                        body={viewBodyTemplate}
                        style={{ minWidth: '6rem', borderBottom: '1px solid #ccc' }}
                        bodyClassName={classes['custom-datatable-body']}
                        headerClassName={'header-align-sprint'}
                      />
                      {(userDetails && (userDetails.global_privileges['domain_creation'] != 'none' || userDetails.global_privileges['sprint'] != 'none')) &&

                        <Column header="Edit" body={(rowData, options) => actionBodyTemplate(rowData, 'edit', options.rowIndex + 1)}
                          bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align-sprint'}
                          style={{ width: '6rem', textAlign: 'center', borderBottom: '1px solid #ccc' }} />

                      }
                      {/* {(userDetails && (userDetails.global_privileges['domain_creation'] != 'none' || userDetails.global_privileges['sprint'] != 'none')) && 

                    <Column header="Sprint Status" body={(rowData, options) => actionBodyTemplate(rowData, 'sprint_status', options.rowIndex + 1)}
                    bodyClassName={classes['custom-datatable-body']} headerClassName={'header-align-sprint'}
                    style={{ width: '6rem', textAlign: 'center', borderBottom: '1px solid #ccc' }} />

                     } */}



                    </DataTable>

                  </div>



                </>
              ) : (
                <>
                  {userDetails && sprints.length === 0 && userDetails.global_privileges['sprint'] !== 'none' ? (
                    <div className={`${classes.dataCard} d-flex justify-content-center align-items-center flex-column`} style={{ width: '100%',height:'100%' }}>
                     <CreateSprint />
                      <div className={classes.noSprintsText}>No Sprints Here</div>
                      {/* <div
                        className={classes.createSprintButton}
                        onClick={handleCreateSprint}
                      >
                        <ControlPointIcon className={classes.icon} />
                        Create Sprint
                      </div> */}
                    {/* </div>  */}
                      <div className={classes['create-sprint-btn']}
                        onClick={handleCreateSprint}
                      >
                        <ControlPointIcon
                          style={{
                            color: 'black', marginRight: '0.25em',



                          }}

                        />
                        Create Sprint
                      </div>
                    </div>

                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      No sprints to displayed
                    </div>
                  )}

                </>
              )}

            </div>
          }
        </>
        ) :
          (
            <>
              <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            </>
          )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleSnackClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

      </div>


    </>
  );
}
