import React, { useEffect, useState } from 'react'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import classes from "./Navbar.module.css"
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';
import Badge from '@mui/material/Badge';

import { styled } from '@mui/material/styles';
import useStore from '../Common/State'






const Navbar = ({ dashboard, userDetails, setIsLoaded ,isLoaded}) => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 3,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      backgroundColor:'rgb(9, 94, 173)'
    },
  }));

  useEffect(() => {
    notificationCountFetch()
  }, []);
  const navigate = useNavigate();
  const handleNavigateToNotification = () => {
    navigate('/dash/notification')
  }

  const [notificationcount,setNotificationCount] = useState(0)
  const notificationCountFetch = async () => {
    try {

        const response = await axios.get(`/logs/get_notifications_count`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getCookie("dash_access_token")}`,
                },
            }
        );
        if (response) {
          useStore.getState().setCount(response.data)
        }
    } catch (error) {
        console.error("Error Fetching Count", error);
        return 0;
    }
};
const count = useStore(state => state.count);

  return (
    <>
      {isLoaded &&
        <>
          <div className={classes['navbar']}>
            <div></div>
            {dashboard && (
              <>
                <div className={classes['dash-title']}>
                  <div style={{ color: 'white' }}>{dashboard.Dashboard}</div>
                  <Tooltip
                    title={dashboard.Info}
                    classes={classes.customTooltip}
                  >
                    <IconButton className={classes.customIconButton}>
                      <InfoIcon className={classes.infoIcon} />
                    </IconButton>
                  </Tooltip>
                </div>

              </>
            )}

            <div className={classes['nav-container']}>
              <div
              >{userDetails && userDetails.name}</div>
              <div style={{ margin: '0.5em 0.5em' }}>
                <IconButton aria-label="cart"
                  onClick={handleNavigateToNotification}
                >
                  <StyledBadge badgeContent={count} color="secondary" max={999}>
                    <NotificationsNoneIcon style={{ color: 'white' }} />
                  </StyledBadge>
                </IconButton>
              </div>

            </div>
          </div>
        </>
      }
    </>
  )
}

export default Navbar

