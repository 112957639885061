import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classes from "./Verify.module.css";
import axios from "axios";
import CancelIcon from '@mui/icons-material/Cancel';


const Verify = () => {

    const location = window.location.pathname
    const user_params = location.split('/');
    const username = user_params[user_params.length - 1];
    const [block,setBlock]  = useState(true)
     useEffect( async () => {
      try{
        const response = await axios.get(`/backend/dh-login/activate/account/${username}`)
      }
    catch (err) {
      const response_status = err.response.status;
      if (response_status === 401 || response_status === 422 || response_status === 500) {
        setBlock(false)
      }
    }
    }, [])
    
    return (
      <>
      {block ?  (
                <div className={classes['container']}>
                <div className={`${classes['card']} ${classes['card-login']} mx-auto mt-5`}>
                  <div className={`${classes['card-body']}`}>
                    <CheckCircleIcon style = {{color:'green'}}/>
                    <div className="message">
                      Email Verification Successfully Done<br />
                      Your Account is now Unblocked
                    </div>
                  </div>
                </div>
              </div>

      ):(
        <div className={classes['container']}>
          <div className={`${classes['card']} ${classes['card-login']} mx-auto mt-5`}>
            <div className={`${classes['card-body']}`}>
              <CancelIcon style = {{color:'red'}}/>
              <div className="message">
                URL Doesn't Exist
              </div>
            </div>
          </div>
        </div>
      )
      }




      </>

      );
    };

export default Verify