import classes from "./Login.module.css";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import logImage from "../../Assets/login-main-img.svg";
import DashLogo from "../../Assets/Dash-logo.svg";

import { createTheme, ThemeProvider } from '@mui/material/styles';


import { useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import CssBaseline from '@mui/joy/CssBaseline';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Link from '@mui/joy/Link';
import { useState, useEffect } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@mui/icons-material/Error";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ReCAPTCHA from '../../Components/Common/ReCAPTCHA';
import ForgotPasswordModal from "./ForgotPasswordModal";
import sha256 from 'crypto-js/sha256';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';

import DOMPurify from 'dompurify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import { Close} from '@mui/icons-material'
import isValidEmail from '../../Services/Validators/emailValidator';





const Login = ({ setIsRoot ,handleLoginSuccess,setUserData}) => {
  const [errorsnackBar, setErrorSnackBar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [modalOpen, setModalOpen] = useState(false);

  const [userName, setUserName] = useState()
  const [password, setPassword] = useState()
  const [captcha, setCaptcha] = useState('');
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [useremail,setUserEmail] = useState('')
  const [isOTPSent, setIsOTPSent] = useState(false);
	const [sendOTPLoading, setSendOTPLoading] = useState(false);
	const [reSendOTPLoading, setReSendOTPLoading] = useState(false);
	const [confirmOTPLoading, setConfirmOTPLoading] = useState(false);
  const [OTPErrorText, setOTPErrorText] = useState(false);
  const [emailError, setEmailError] = useState(false);
	const [helperText, setHelperText] = useState('');
  const [email2, setEmail2] = useState('');
  const [step, setStep] = useState(1);

  const[otpOpen,setOtpOpen] = useState(false)


  const [msg, setMsg] = useState('');

  
  const [snackbarOpen,setSnackbarOpen]=useState(false)
  const [message,setMessage] = useState('')
  const [severity,setSeverity] =useState('')

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOtpOpen = () => setOtpOpen(true);
  const handleOtpClose = () => {
    setOtpOpen(true)
    setOtp('')
  };

  useEffect(() => {
    setIsRoot(true);
  }, []);


  var inputStyle = {
    marginTop: '1em'
  }
  var fontWeight = {
    fontWeight: '500'
  }
  let hoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };
  const navigate = useNavigate()
  const handleLogin = async (event) => {
    if (!userName) {
      // toast.error('Please enter your username.', {
      //   position: "bottom-right",
      //   autoClose: 2500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: false,
      //   theme: "colored",
      // });
      setSnackbarOpen(true)
      setMessage('Please enter your username')
      setSeverity('error')
      return;
    }
    if (!password) {
      // toast.error('Please enter your password.', {
      //   position: "bottom-right",
      //   autoClose: 2500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: false,
      //   theme: "colored",
      // });
      setSnackbarOpen(true)
      setMessage('Please enter your password')
      setSeverity('error')
      return;
    }

    if (!captcha) {
      // toast.error('Please verify the ReCAPTCHA to login!', {
      //   position: "bottom-right",
      //   autoClose: 2500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: false,
      //   theme: "colored",
      // });
      // return;
      setSnackbarOpen(true)
      setMessage('Please Verify the RECAPTCHA to Login!')
      setSeverity('error')
      return
    }


    try {
      const data = {
        "username": btoa(userName.trim()),
        "password": btoa(password.trim()),
        "captcha": captcha
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post("/backend/dh-login", data, config)
      if (response) {
        if (response.data.detail == "otp send to email"){
          handleOtpOpen()
          setIsOTPSent(false)
          setRemainingTime(response.data.time_rem)
          setInvalidOTP(false)
        }
        else{
          // let global ={
          //   "domain_creation": "edit",
          //   "user_control": "edit",
          //   "gantt_chart": "full",
          //   "sprint": "full",
          //   "perf_score": "edit",
          //   "notes": "edit"
          // }
          // handleLoginSuccess(response.data.globalPrivileges)
          // handleLoginSuccess(global)
          setIsOTPSent(false)
          navigate(`/dash/dashboard`)
          setIsRoot(false)
          setCookie("dash_access_token", response.data.access_token, 7200)

        }

      }
    }
    catch (err) {
      window.grecaptcha.reset();
      setCaptcha('');
      // console.error("Error in logging in", error)
      // setErrorSnackBar(true)
      setError(true)
      setInvalidOTP(true)
      const response_status = err.response.status;
      if (response_status === 401 || response_status === 422 || response_status === 403) {

        // toast.error(err.response.data.detail, {
        //   position: "bottom-right",
        //   autoClose: 2500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: false,
        //   theme: "colored",
        // });
        setSnackbarOpen(true)
        setMessage(err.response.data.detail)
        setSeverity('error')
      }
      else if (response_status === 500) {
        // toast.error("Something went wrong", {
        //   position: "bottom-right",
        //   autoClose: 2500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: false,
        //   theme: "colored",
        // });
        setSnackbarOpen(true)
        setMessage(err.response.data.detail)
        setSeverity('error')
      }
      else{
        // toast.error(err.response.data.detail, {
        //   position: "bottom-right",
        //   autoClose: 2500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: false,
        //   theme: "colored",
        // });
        setSnackbarOpen(true)
        setMessage(err.response.data.detail)
        setSeverity('error')

      }
    }
  };
  const setCookie = (name, value, expiresInSeconds) => {
    const d = new Date();
    d.setTime(d.getTime() + (expiresInSeconds * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }



  const handleResendOTP = async () => {
    const sanitizedEmail = DOMPurify.sanitize(userName);
    setReSendOTPLoading(true);
    try {
      const response = await axios.post(`/backend/dh-login/resend-otp`, { username: btoa(sanitizedEmail),password:btoa(password)}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response) {
        setReSendOTPLoading(false);
        if (response.status === 200) {
          setInvalidOTP(false)
          // toast.success('OTP sent successfully!', {
          //   position: "bottom-right",
          //   autoClose: 2500,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: false,
          //   theme: "colored",
          // });
          setSnackbarOpen(true)
          setMessage('OTP sent successfully!')
          setSeverity('success')
        }
        setRemainingTime(response.data.time_rem)
      }
    }
    catch (err) {
      setReSendOTPLoading(false);
      setInvalidOTP(true);
      if (err.response.status === 422) {
        setOTPErrorText("Unprocessable Entity");
      }
      else if (err.response.status === 403) {
        setOTPErrorText("Account/IP is blocked");
      }
      else if (err.response.status === 500) {
        setOTPErrorText("Something went wrong");
      }
      else {
        setOTPErrorText("Something went wrong");
      }
    }
  }
  
  const handleSendOTP = async () => {
    setSendOTPLoading(true);
    const sanitizedEmail = DOMPurify.sanitize(userName);
    if(!sanitizedEmail) {
      setEmailError(true);
      setHelperText('Please enter email ID');
      setSendOTPLoading(false);
      return;
    }
    if (!isValidEmail(sanitizedEmail)) {
      setEmailError(true);
      setHelperText('Invalid Email ID');
      setSendOTPLoading(false);
      return;
    }

    setEmailError(false);
    setHelperText('');
    try {
      setUserEmail(sanitizedEmail)
      const response = await axios.post(`/backend/forgot-password/check-email`, { email: sanitizedEmail }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response) {
        setSendOTPLoading(false);
        if (response.status === 200) {
          setIsOTPSent(true);
          setStep(step + 1);
        }
        else {
          setEmailError(true);
          setHelperText("Something went wrong");
        }
      }
  
    } catch (err) {
      setSendOTPLoading(false);
      setEmailError(true);
      if (err.response.status === 422) {
        setHelperText("Unprocessable Entity");
      }
      else if (err.response.status === 403) {
        setHelperText("Account/IP is blocked");
      }
      else if (err.response.status === 500) {
        setHelperText("Something went wrong");
      }
      else {
        setHelperText("Something went wrong");
      }
    }
  }
  
  const handleConfirmOTP = async () => {
    setConfirmOTPLoading(true);
    const sanitizedEmail = DOMPurify.sanitize(userName);
    if (otp.length !== 6) {
      setInvalidOTP(true);
      setOTPErrorText('Enter 6 digit OTP');
      setConfirmOTPLoading(false);
      return;
    }
    console.log(email2)
    try {
      setConfirmOTPLoading(true);
      const response = await axios.post(`/backend/dh-login/verify-otp`, {username:btoa(sanitizedEmail),password:btoa(password), otp: btoa(otp) }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response) {
        setConfirmOTPLoading(false);
        if (response.status === 200) {
          setIsRoot(false)
          setUserData(true)
          navigate(`/dash/dashboard`)
      
        }
      }
    }
    catch (err) {
      setConfirmOTPLoading(false);
      setInvalidOTP(true);
      if (err.response.status === 422) {
        setOTPErrorText("Unprocessable Entity");
      }
      else if (err.response.status === 500) {
        setOTPErrorText("Something went wrong");
      }
      else if (err.response.status === 403) {
        setOTPErrorText("Account/IP is blocked");
      }
      else if (err.response.status === 401) {
        setOTPErrorText("Invalid OTP");
      }
      else {
        setOTPErrorText("Something went wrong");
      }
    }
  
  }


  const otpbuttonStyles = {
    border: "1px solid black",
    backgroundColor: "transparent",
    borderRadius: "0.75rem",
    color: "black",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    
  }
  const confirmbuttonStyles ={
    border: "1px solid black",
    backgroundColor: "rgba(28, 28, 28, 1)",
    borderRadius: "0.75rem",
    color: "rgba(238, 225, 213, 1)",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft : "1em"

  }
  const confirmHoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  }
  const otpHoverStyles = {
    backgroundColor: "rgba(28, 28, 28, 1)",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "white",
  }




  const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
        return;
    }
    setOtpOpen(false)
    setOtp('')


}

const [remainingTime, setRemainingTime] = useState(0);

const [isDisabled, setIsDisabled] = useState(true);

useEffect(() => {
  if (remainingTime > 0) {
    setIsDisabled(true);
    const interval = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  } else {
    setIsDisabled(false);
  }
}, [remainingTime]);

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

  return (
    <>
      <Dialog
      open={otpOpen}
      // onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      sx={{ borderRadius: '1rem' }}
      style={{ top: '-10%' }}
      classes={{
          paper: classes.dialogPaper,
      }}
  
      onClose={handleOtpClose}
  >
    <DialogTitle sx={{ paddingBottom: '0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className={classes['title-container']} style={{margin:'0 auto',color:'black',fontWeight:'bold'}}>
              Verify OTP 
          </div>
          <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
              <Close />
          </IconButton>
      </DialogTitle>
                  <DialogContent>
                      <div style={{ textAlign: 'center' }}><h6 className='bold mt-4 text-align-center'>A 6 digit code has been sent to your email address: {userName} </h6></div>
                      <div className='mt-3 w-100 row'>
                          <div className='col-12 d-flex flex-column justify-content-center '>
                              <div className={`${classes['otp-input']} d-flex flex-column justify-content-center align-items-center  rounded`}>
                                  <p className='text-muted small m-0' style={{ fontWeight: 'bold' }}>Enter OTP</p>
                                  <OtpInput
                                      disabled={true}
                                      value={otp}
                                      onChange={setOtp}
                                      numInputs={6}
                                      inputType='number'
                                      renderInput={(props) => <input {...props}
                                          className='d-flex justify-content-center'
                                      />}
                                  />
                                  {invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null}
                                  <div style={{ display: 'flex', 'justifyContent': 'center', alignItems: 'center', marginTop: '1em' }}>
                                      {/* {reSendOTPLoading ? <CircularProgress /> : <Button disabled={!isOTPSent} onClick={handleResendOTP} sx={{ ...otpbuttonStyles, "&:hover": otpHoverStyles }}>{isDisabled ? `Resend OTP (${formatTime(remainingTime)})` : 'Resend OTP'}</Button>} */}
                                      <Button           disabled={isDisabled || reSendOTPLoading}
      onClick={handleResendOTP}
      sx={{ ...otpbuttonStyles, "&:hover": otpHoverStyles }}
    >
      {reSendOTPLoading ? <CircularProgress /> : isDisabled ? `Resend OTP (${formatTime(remainingTime)})` : 'Resend OTP'}
    </Button>
                                      {confirmOTPLoading ? <CircularProgress /> : <Button  variant='contained' sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }} onClick={handleConfirmOTP} >Confirm OTP</Button>}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </DialogContent>
                  
      </Dialog>
      

      <div className={`${classes['login-container']} conatiner-fluid`}>
        <Grow in={true} timeout={1000}>
          <div className={classes['login-inner-container']}>
            <div className={classes['leftContainer']}>
              <div className={classes["image-container"]}>
                <img src={logImage} className={classes['tp-image']} alt="" />
              </div>
            </div>
            <div className={classes['rightContainer']}>
              <div className={classes['user-login-container']}>
                <div className={classes['logo-container']}>
                  <img src={DashLogo} className={classes['tp-image-2']} alt="" />
                </div>

                <div className={classes['user-input-container']}>
                  <FormControl sx={{ marginBottom: '1em', width: '100%' }}>
                    <FormLabel sx={{ ...fontWeight }} >Username or Email</FormLabel>
                    {/* <Input
           sx={{padding:'0.5em'}}
            name="email"
            type="email"
            placeholder="johndoe@email.com"
            on
          /> */}


                    <TextField
                      error={error}
                      placeholder="Username"
                      className={classes["input"]}
                      onChange={(event) => {
                        setError(false)
                        setUserName(event.target.value)
                      }}
                      onKeyDown={handleKeyDown}
                    />

                  </FormControl>
                  <FormControl sx={{ marginBottom: '1em', width: '100%' }}>
                    <FormLabel sx={{ ...fontWeight }}>Password</FormLabel>
                    {/* <Input
            sx={{padding:'0.5em'}}
            name="password"
            type="password"
            placeholder="password"
          /> */}
                    <TextField
                      InputProps={{

                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={error}
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      className={classes["input"]}
                      onInput={(event) => {
                        setError(false)
                        setPassword(event.target.value)
                      }}
                      onKeyDown={handleKeyDown}

                    />
                  </FormControl>
                  <div style={{display:'flex',justifyContent:'end'}}>
                  <Typography
                    fontSize="sm"
                    sx={{
                      ...inputStyle, alignSelf: 'end',
                      textAlign: 'end', ...fontWeight, marginBottom: '1em', cursor: 'pointer',width:'10em',color:'blue',margin:0
                    }} id="forgot-password"
                    onClick={handleOpen} //my code 
                  // onClick={() => setModalOpen(true)} //sciative code
                  >
                    Forgot Password ?
                  </Typography>
                  </div>
                  <div>
                    <ReCAPTCHA setCaptcha={setCaptcha} />
                  </div>
                  <Button
                    type="submit"
                    sx={{
                      width: '100%',
                      color: 'white',
                      backgroundColor: 'black',
                      padding: '0.8em',
                      ...inputStyle,
                      "&:hover": hoverStyles
                    }} id="user-login"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Log in</Button>

                </div>

              </div>
            </div>

          </div>
        </Grow>
        <div className={classes["modal-container"]}>
          <ForgotPasswordModal isOpen={open} onClose={handleClose} />
        </div>
      </div>








      <Snackbar
        autoHideDuration={5000}
        variant="soft"
        color="danger"
        open={errorsnackBar}
        onClose={() => setErrorSnackBar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<ErrorIcon />}
        endDecorator={
          <Button
            onClick={() => setErrorSnackBar(false)}
            size="sm"
            variant="soft"
            color="danger"
          >
            Dismiss
          </Button>
        }
      >
        <Typography>Invalid Credentials</Typography>
      </Snackbar>

      <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar> 

      <ToastContainer style={{width:'25em'}} />
    </>
  )
}

export default Login




{/* <ThemeProvider theme={theme}> */ }