import React, { useState, useEffect } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Paper, Collapse, IconButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, AccountBox as AccountBoxIcon, PowerSettingsNew as PowerSettingsNewIcon, Menu as MenuIcon } from '@mui/icons-material';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { getCookie } from "../../Services/Cookie/Cookie";
import { ReactComponent as Chart } from "../../Assets/Chart.svg";
import { ReactComponent as SciativeLogo } from "../../Assets/SciativeLogo.svg";
import { ReactComponent as Usermanagement } from "../../Assets/Usermanagement.svg";
import { ReactComponent as Timeline } from "../../Assets/Timeline.svg";
import { ReactComponent as Document } from "../../Assets/Document.svg";
import classes from "./Sidebar.module.css";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ShortcutIcon from '@mui/icons-material/Shortcut';

const FireNav = styled(List)(({ theme }) => ({
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 12,
    marginTop: '4px'
  },
}));

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchUserDetails();
    fetchDomain();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config);
      setUserData(response.data.global_privileges);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    document.cookie = `dash_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    navigate(`/dash/`);
  };

  const isLinkActive = (link) => {
    return location.pathname === link;
  };

  const activeStyles = {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  };

  const [domainList, setDomainList] = useState([]);

  const fetchDomain = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let domainResponse = await axios.get(
        `/backend/get_user_task_domain_access`,
        config
      );
      let domainData = domainResponse.data;
      domainData = Object.entries(domainData).map(([key, value]) => ({
        label: value,
        value: key
      }));
      setDomainList(domainData);
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };

  const handleToggleSidebar = (val) => {
    setCollapsed(!collapsed);
    setOpen(val)
  };

  return (
    <div className={classes.sidebar} style={{ width: collapsed ? '5%' : '14%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
          <ThemeProvider
            theme={createTheme({
              components: {
                MuiListItemButton: {
                  defaultProps: {
                    disableTouchRipple: true,
                  },
                },
              },
              palette: {
                mode: 'dark',
                primary: { main: 'rgb(102, 157, 246)' },
                background: { paper: 'black' },
              },
            })}
          >
            <Paper elevation={0} sx={{ width: '100%' }}>
              <FireNav component="nav" disablePadding>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                  {!collapsed && <SciativeLogo style={{ width: '100px' }} />}
                  <IconButton
                    onClick={()=>{handleToggleSidebar(true)}}
                    sx={{
                      margin: collapsed ? '0 auto' : '',
                      color: 'rgba(255, 255, 255, 0.5)',
                      transition: 'color 0.3s ease',
                      '&:hover': {
                        color: 'white',
                      },
                    }}
                  >
                    <MenuIcon style={{ fontSize: '24px' }} />
                  </IconButton>
                </Box>
                <Divider />


                <div className={`${classes['side-inner-container']} ${collapsed ? classes['collapsed'] : ''}`}>
                  <ListItemButton
                    component={Link} to={'/dash/dashboard'}
                    sx={{
                      // backgroundColor: isLinkActive('/dash/dashboard') ? activeStyles.backgroundColor : 'transparent',
                      color: isLinkActive('/dash/dashboard') ? 'white' : 'rgba(255, 255, 255, 0.5)',
                      paddingLeft: collapsed ? '1em' : '1em',
                      paddingRight: '10px',
                      '&:hover .MuiListItemText-root': {
                        color: 'white',
                        transition: 'color 0.3s ease',
                      },

                    }}
                  >
                    <ListItemIcon sx={{ fontSize: 20 }}>
                      <Chart style={{ width: collapsed ? '24px' : '24px' }} />
                    </ListItemIcon>
                    {!collapsed && (
                      <ListItemText
                        primary={
                          <Typography variant="body1"
                            // style={{ fontSize: 15, marginTop: 5, color: isLinkActive('/dash/dashboard') ? 'white' : 'rgba(255, 255, 255, 0.5)'}}>
                            style={{
                              fontSize: 15,
                              marginTop: 5,
                              color: isLinkActive('/dash/dashboard') ? 'white' : 'rgba(255, 255, 255, 0.5)',
                              transition: 'color 0.3s ease', // Add smooth transition for text color
                            }}
                            sx={{
                              // Hover effect directly on Typography
                              '&:hover': {
                                color: 'white', // Ensure the color changes on hover
                              },
                            }}>

                            Planning Interface
                          </Typography>
                        }
                      />
                    )}
                  </ListItemButton>

                  <Divider />
                  {domainList && domainList.length !== 0 && (
                    <ListItemButton
                      component={Link} to={"/dash/task"}
                      sx={{
                        // backgroundColor: isLinkActive('/dash/task') ? activeStyles.backgroundColor : 'transparent',
                        color: isLinkActive('/dash/task') ? 'white' : 'rgba(255, 255, 255, 0.5)',
                        paddingLeft: collapsed ? '1em' : '0.8em',
                        paddingRight: '10px',
                      }}
                    >
                      <ListItemIcon sx={{ fontSize: 10 ,marginRight:'7px'}}>
                        <Document style={{ width: collapsed ? '31px' : '31px',height:collapsed?'31px':'31px' }} />
                      </ListItemIcon>
                      {!collapsed && (
                        <ListItemText primary={
                          <Typography variant="body1" style={{ fontSize: 15, marginTop: 5, color: isLinkActive('/dash/task') ? 'white' : 'rgba(255, 255, 255, 0.5)' }}>
                            Create Task
                          </Typography>
                        } />
                      )}
                    </ListItemButton>
                  )}
                  <Divider />
                  <div style={{ position: 'relative' }}>
                    {userData && (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') &&
                      <>
                        <ListItemButton onClick={() => setOpen(!open)}
                          sx={{
                            backgroundColor: (isLinkActive('/dash/admin/domainManagement') || isLinkActive('/dash/admin/manageUser')) ? activeStyles.backgroundColor : 'transparent',
                            color: (isLinkActive('/dash/admin/domainManagement') || isLinkActive('/dash/admin/manageUser')) ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
                            paddingLeft: collapsed ? '1em' : '1em',
                            paddingRight: '10px',
                          }}
                        >
                          {!collapsed &&
                            <ListItemIcon>
                              <AccountBoxIcon style={{ width:'30px',height:'30px' }} />
                            </ListItemIcon>
                          }
                          {collapsed &&
                            <ListItemIcon onClick={()=>{handleToggleSidebar(false)}}>
                              <AccountBoxIcon style={{ width:'30px',height:'30px' }} />
                            </ListItemIcon>
                          }
                          {!collapsed && (
                            <ListItemText primary="Admin"
                              primaryTypographyProps={{ style: { color: (isLinkActive('/dash/admin/domainManagement') || isLinkActive('/dash/admin/manageUser')) ? 'inherit' : 'rgba(255, 255, 255, 0.5)' } }}
                            />
                          )}
                          {!collapsed &&
                            (
                              <>
                                {open ? <ExpandLess /> : <ExpandMore />}
                              </>
                            )}

                        </ListItemButton>
                      </>
                    }
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <div style={{ position: 'relative' }}>
                        <List component="div" disablePadding>
                          {userData['user_management'] !== 'none' &&
                            <ListItemButton
                              component={Link}
                              to={'/dash/admin/manageUser'}
                              sx={{
                                paddingLeft: collapsed ? '2em' : '3em',
                                backgroundColor: isLinkActive('/dash/admin/manageUser') ? activeStyles.backgroundColor : 'transparent',
                                color: isLinkActive('/dash/admin/manageUser') ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
                                paddingRight: '10px',
                              }}
                            >
                              {!collapsed && (
                                <>
                                  <ListItemIcon>
                                    <Usermanagement
                                      sx={{ width: collapsed ? '20px' : '20px', height: '20px', marginRight: '5px' }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText primary={<Typography variant="body1" style={{ fontSize: '0.7em', color: isLinkActive('/dash/admin/manageUser') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' }}>Manage Users</Typography>} />
                                </>
                              )}
                            </ListItemButton>
                          }
                          {userData['domain_management'] !== 'none' &&
                            <ListItemButton
                              component={Link}
                              to={'/dash/admin/domainManagement'}
                              sx={{
                                paddingLeft: collapsed ? '2em' : '3em',
                                backgroundColor: isLinkActive('/dash/admin/domainManagement') ? activeStyles.backgroundColor : 'transparent',
                                color: isLinkActive('/dash/admin/domainManagement') ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
                                paddingRight: '10px',
                              }}
                            >
                              {!collapsed && (
                                <>
                                  <ListItemIcon>
                                    <Timeline
                                      sx={{ width: collapsed ? '16px' : '20px', height: '20px', marginRight: '5px' }}
                                    />
                                  </ListItemIcon>

                                  <ListItemText primary={<Typography variant="body1" style={{ fontSize: '0.7em', color: isLinkActive('/dash/admin/domainManagement') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' }}>Manage Domains</Typography>} />
                                </>
                              )}
                            </ListItemButton>
                          }
                        </List>
                        {!collapsed && (
                          <>
                            {open && (
                              <>
                                <div className="arrow-icon" style={{ position: 'absolute', top: (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') ? '10px' : '52px', left: '23px', visibility: (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') ? 'visible' : 'hidden' }}>
                                  {/* <SubdirectoryArrowRightIcon style={{ width: '0.8em', color: '#FFC601' }} /> */}
                                  <ShortcutIcon style={{ width: '0.8em', color: '#FFC601', transform: 'scaleY(-1)' }} />
                                </div>
                                <div className="arrow-icon" style={{ position: 'absolute', top: (userData['user_management'] !== 'none' && userData['domain_management'] !== 'none') ? '52px' : '', left: '23px', visibility: (userData['user_management'] !== 'none' && userData['domain_management'] !== 'none') ? 'visible' : 'hidden' }}>
                                  <ShortcutIcon style={{ width: '0.8em', color: '#FFC601', transform: 'scaleY(-1)' }} />
                                </div>
                              </>
                            )}
                            {open && (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') && (
                              <div
                                className="connector-line"
                                style={{
                                  position: 'absolute',
                                  top: '2px',
                                  left: '25.8px',
                                  height: userData['user_management'] !== 'none' && userData['domain_management'] !== 'none' ? '60px' : '18px',
                                  borderLeft: '2px solid #FFC601',
                                  color: '#FFC601',
                                }}
                              ></div>
                            )}
                          </>
                        )
                        }

                      </div>
                    </Collapse>
                  </div>
                </div>
                <Divider />
              </FireNav>
            </Paper>
          </ThemeProvider>
        </Box>
        <div onClick={handleLogout} style={{
          color: 'white', cursor: 'pointer', bottom: '10%',
          padding: '4px'
        }}>
          <IconButton onClick={handleLogout} color="inherit">
            <PowerSettingsNewIcon />
            {!collapsed &&
              <Typography variant="body1" sx={{ marginLeft: 1 }}>
                Logout
              </Typography>
            }

          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;




// Old code 

// import React, { useState, useEffect } from 'react';
// import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
// import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Paper, Collapse, IconButton, Typography } from '@mui/material';
// import { ExpandLess, ExpandMore, AccountBox as AccountBoxIcon, PowerSettingsNew as PowerSettingsNewIcon } from '@mui/icons-material';
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import { getCookie } from "../../Services/Cookie/Cookie";
// import { ReactComponent as Chart } from "../../Assets/Chart.svg";
// import { ReactComponent as SciativeLogo } from "../../Assets/SciativeLogo.svg";
// import { ReactComponent as Usermanagement } from "../../Assets/Usermanagement.svg";
// import { ReactComponent as Timeline } from "../../Assets/Timeline.svg";
// import { ReactComponent as Document } from "../../Assets/Document.svg";
// import classes from "./Sidebar.module.css";
// import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
// import ShortcutIcon from '@mui/icons-material/Shortcut';

// const FireNav = styled(List)(({ theme }) => ({
//   '& .MuiListItemIcon-root': {
//     minWidth: 0,
//     marginRight: 12,
//     marginTop: '4px'
//   },
// }));

// const Sidebar = () => {
//   const [open, setOpen] = useState(false);
//   const [userData, setUserData] = useState({});
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     fetchUserDetails();
//     fetchDomain()
//   }, []);

//   const fetchUserDetails = async () => {
//     try {
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${getCookie("dash_access_token")}`,
//         },
//       };
//       const response = await axios.get("/backend/get_privileges", config);
//       setUserData(response.data.global_privileges);
//     } catch (error) {
//       console.error(error)
//     }
//   };

//   const handleLogout = () => {
//     document.cookie = `dash_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
//     navigate(`/dash/`);
//   };

//   const isLinkActive = (link) => {
//     return location.pathname === link;
//   };

//   const activeStyles = {
//     backgroundColor: 'rgba(255, 255, 255, 0.08)',
//   };
//   const [domainList, setDomainList] = useState([]);

//   const fetchDomain = async () => {
//     let is_active = 'All';
//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getCookie('dash_access_token')}`,
//       },
//     };
//     try {
//       let domainResponse = await axios.get(
//         `/backend/get_user_task_domain_access`,
//         config
//       );

//       let domainData = domainResponse.data;
//       domainData = Object.entries(domainData).map(([key, value]) => ({
//         label: value,
//         value: key
//       }));
//       setDomainList(domainData)
//     } catch (error) {
//       console.error('There was an error fetching the domain data!', error);
//     }
//   };


// return (
//   <div className={classes.sidebar}>
//     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
//       <Box sx={{ display: 'flex', width: '100%' }}>
//         <ThemeProvider
//           theme={createTheme({
//             components: {
//               MuiListItemButton: {
//                 defaultProps: {
//                   disableTouchRipple: true,
//                 },
//               },
//             },
//             palette: {
//               mode: 'dark',
//               primary: { main: 'rgb(102, 157, 246)' },
//               background: { paper: 'black' },
//             },
//           })}
//         >
//           <Paper elevation={0} sx={{ width: '100%' }}>
//             <FireNav component="nav" disablePadding>
//               <SciativeLogo />
//               <Divider />
//               <div className={classes['side-inner-container']}>
//                 <ListItemButton
//                   component={Link} to={'/dash/dashboard'}
//                   sx={{
//                     backgroundColor: isLinkActive('/dash/dashboard') ? activeStyles.backgroundColor : 'transparent',
//                     color: isLinkActive('/dash/dashboard') ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
//                   }}
//                 >
//                   <ListItemIcon sx={{ fontSize: 20 }}>
//                     <Chart />
//                   </ListItemIcon>
//                   <ListItemText
//                     primary={
//                       <Typography variant="body1" style={{ fontSize: 15, marginTop: 5, color: isLinkActive('/dash/dashboard') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' }}>
//                         Dashboard
//                       </Typography>
//                     }
//                   />
//                 </ListItemButton>
//                 <Divider />
//                 {domainList && domainList.length != 0 && (
//                   <ListItemButton
//                     component={Link} to={"/dash/task"}
//                     // sx={{ paddingLeft: '24px' }}
//                     sx={{
//                       backgroundColor: isLinkActive('/dash/task') ? activeStyles.backgroundColor : 'transparent',
//                       color: isLinkActive('/dash/task') ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
//                     }}

//                   >
//                     <ListItemIcon sx={{ fontSize: 10 }}>
//                       <Document />
//                     </ListItemIcon>
//                     <ListItemText primary={
//                       <Typography variant="body1" style={{ fontSize: 15, marginTop: 5, color: isLinkActive('/dash/task') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' }}>
//                         Create Task
//                       </Typography>
//                     } />
//                   </ListItemButton>
//                 )}
//                 <Divider />

//                 <div style={{ position: 'relative' }}>
//                   {userData && (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') &&
//                     <>
//                       <ListItemButton onClick={() => setOpen(!open)}
//                         sx={{
//                           backgroundColor: (isLinkActive('/dash/admin/domainManagement') || isLinkActive('/dash/admin/manageUser')) ? activeStyles.backgroundColor : 'transparent',
//                           color: (isLinkActive('/dash/admin/domainManagement') || isLinkActive('/dash/admin/manageUser')) ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
//                         }}
//                       >
//                         <ListItemIcon>
//                           <AccountBoxIcon style={{ width: '1em' }} />
//                         </ListItemIcon>
//                         <ListItemText primary="Admin"
//                           primaryTypographyProps={{ style: { color: (isLinkActive('/dash/admin/domainManagement') || isLinkActive('/dash/admin/manageUser')) ? 'inherit' : 'rgba(255, 255, 255, 0.5)' } }}
//                         />
//                         {open ? <ExpandLess /> : <ExpandMore />}
//                       </ListItemButton>
//                     </>
//                   }

//                   <Collapse in={open} timeout="auto" unmountOnExit>
//                     <div style={{ position: 'relative' }}>
//                       <List component="div" disablePadding>
//                         {userData['user_management'] !== 'none' &&
//                           <ListItemButton
//                             component={Link}
//                             to={'/dash/admin/manageUser'}
//                             sx={{
//                               paddingLeft: '3em',
//                               backgroundColor: isLinkActive('/dash/admin/manageUser') ? activeStyles.backgroundColor : 'transparent',
//                               color: isLinkActive('/dash/admin/manageUser') ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
//                             }}
//                           >
//                             <ListItemIcon>
//                               <Usermanagement
//                                 sx={{ color: isLinkActive('/dash/admin/manageUser') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' }}
//                               />
//                             </ListItemIcon>
//                             <ListItemText
//                               primary="Manage User"
//                               primaryTypographyProps={{ style: { fontSize: '0.8em', color: isLinkActive('/dash/admin/manageUser') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' } }}
//                             />
//                           </ListItemButton>

//                         }
//                         {userData['domain_management'] !== 'none' &&
//                           <ListItemButton
//                             component={Link} to={'/dash/admin/domainManagement'}
//                             sx={{
//                               paddingLeft: '3em', backgroundColor: isLinkActive('/dash/admin/domainManagement') ? activeStyles.backgroundColor : 'transparent',
//                               color: isLinkActive('/dash/admin/domainManagement') ? 'inherit' : 'rgba(255, 255, 255, 0.5)',
//                             }}

//                           >
//                             <ListItemIcon>
//                               <Timeline />
//                             </ListItemIcon>
//                             <ListItemText primary="Manage Domain"
//                               primaryTypographyProps={{ style: { fontSize: '0.8em', color: isLinkActive('/dash/admin/domainManagement') ? 'inherit' : 'rgba(255, 255, 255, 0.5)' } }}
//                             />
//                           </ListItemButton>
//                         }
//                       </List>
//                       {open && (
//                         <>
//                           <div className="arrow-icon" style={{ position: 'absolute', top: (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') ? '10px' : '52px', left: '23px', visibility: (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') ? 'visible' : 'hidden' }}>
//                             {/* <SubdirectoryArrowRightIcon style={{ width: '0.8em', color: '#FFC601' }} /> */}
//                             <ShortcutIcon style={{ width: '0.8em', color: '#FFC601', transform: 'scaleY(-1)' }} />
//                           </div>
//                           <div className="arrow-icon" style={{ position: 'absolute', top: (userData['user_management'] !== 'none' && userData['domain_management'] !== 'none') ? '52px' : '', left: '23px', visibility: (userData['user_management'] !== 'none' && userData['domain_management'] !== 'none') ? 'visible' : 'hidden' }}>
//                             <ShortcutIcon style={{ width: '0.8em', color: '#FFC601', transform: 'scaleY(-1)' }} />
//                           </div>
//                         </>
//                       )}
//                       {open && (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') && (
//                         <div
//                           className="connector-line"
//                           style={{
//                             position: 'absolute',
//                             top: '2px',
//                             left: '25.8px',
//                             height: userData['user_management'] !== 'none' && userData['domain_management'] !== 'none' ? '60px' : '18px',
//                             borderLeft: '2px solid #FFC601',
//                             color: '#FFC601',
//                           }}
//                         ></div>
//                       )}

//                     </div>
//                   </Collapse>
//                 </div>

//               </div>
//             </FireNav>
//           </Paper>
//         </ThemeProvider>
//       </Box>
//       <div onClick={handleLogout} style={{
//         color: 'white', cursor: 'pointer', bottom: '10%',
//         padding: '4px'
//       }}>
//         <IconButton onClick={handleLogout} color="inherit">
//           <PowerSettingsNewIcon />
//           <Typography variant="body1" sx={{ marginLeft: 1 }}>
//             Logout
//           </Typography>
//         </IconButton>
//       </div>
//     </div>
//     <ToastContainer />
//   </div>
// );
// };

// export default Sidebar;





// Side bar logic 
{/* {userData && (userData['user_management'] != 'none' || userData['domain_management'] != 'none')
                    &&
                    <ListItemButton onClick={() => setOpen(!open)}>
                      <ListItemIcon>
                        <AccountBoxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  }

                  <Collapse in={open} timeout="auto" unmountOnExit>

                    <List component="div" disablePadding>
                      {userData['user_management'] != 'none' &&
                        <ListItemButton
                          component={Link} to={'/dash/admin/manageUser'}
                          sx={{ pl: 4, backgroundColor: isLinkActive('/dash/admin/manageUser') ? activeStyles.backgroundColor : 'transparent' }}
                        >
                          <ListItemIcon>
                            <Usermanagement />
                          </ListItemIcon>
                          <ListItemText primary="Manage User"
                            primaryTypographyProps={{ style: { fontSize: '0.9em' } }}
                          />
                        </ListItemButton>
                      }
                      {userData['domain_management'] != 'none' &&
                        <ListItemButton
                          component={Link} to={'/dash/admin/domainManagement'}
                          sx={{ pl: 4, backgroundColor: isLinkActive('/dash/admin/domainManagement') ? activeStyles.backgroundColor : 'transparent' }}
                        >
                          <ListItemIcon>
                            <Timeline />
                          </ListItemIcon>
                          <ListItemText primary="Manage Domain"
                            primaryTypographyProps={{ style: { fontSize: '0.9em' } }}
                          />
                        </ListItemButton>
                      }

                    </List>


                  </Collapse> */}


{/* <div style={{ position: 'relative' }}>
                    {userData && (userData['user_management'] !== 'none' || userData['domain_management'] !== 'none') &&
                      <>
                        <ListItemButton onClick={() => setOpen(!open)}>
                          <ListItemIcon>
                            <AccountBoxIcon />
                          </ListItemIcon>
                          <ListItemText primary="Admin" />
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        {open && (
                            <>
                              {userData['user_management'] !== 'none' && (
                                <div className="arrow-icon" style={{ top: 'calc(100% + 8px)', left: '16px' }}>
                                  <SubdirectoryArrowRightIcon />
                                </div>
                              )}
                              {userData['domain_management'] !== 'none' && (
                                <div className="arrow-icon" style={{ top: 'calc(100% + 56px)', left: '16px' }}>
                                  <SubdirectoryArrowRightIcon />
                                </div>
                              )}
                            </>
                          )}
                      </>
                    }

                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {userData['user_management'] !== 'none' &&
                          <ListItemButton
                            component={Link} to={'/dash/admin/manageUser'}
                            sx={{ pl: 4, backgroundColor: isLinkActive('/dash/admin/manageUser') ? activeStyles.backgroundColor : 'transparent' }}
                          >
                            <ListItemIcon>
                              <Usermanagement />
                            </ListItemIcon>
                            <ListItemText primary="Manage User"
                              primaryTypographyProps={{ style: { fontSize: '0.9em' } }}
                            />
                          </ListItemButton>
                        }
                        {userData['domain_management'] !== 'none' &&
                          <ListItemButton
                            component={Link} to={'/dash/admin/domainManagement'}
                            sx={{ pl: 4, backgroundColor: isLinkActive('/dash/admin/domainManagement') ? activeStyles.backgroundColor : 'transparent' }}
                          >
                            <ListItemIcon>
                              <Timeline />
                            </ListItemIcon>
                            <ListItemText primary="Manage Domain"
                              primaryTypographyProps={{ style: { fontSize: '0.9em' } }}
                            />
                          </ListItemButton>
                        }
                      </List>
                    </Collapse>
                  </div> */}


