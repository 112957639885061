export const getCookie = (cookieNameInput) => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie
            .split("=")
            .map((part) => part.trim());
        if (cookieName === cookieNameInput) {
            return cookieValue;
        }
    }
    return null;
};