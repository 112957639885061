//  New code 
import Task from '../Task/Task';
import React, { useState, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import VisibilityIcon from '@mui/icons-material/Visibility';
import classes from "./ProjectListView.module.css";
import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';
import { useLocation } from 'react-router-dom';
import DashboardTitle from './DashboardTitle';
import Navbar from '../Navbar/Navbar';
import { Dropdown } from 'primereact/dropdown';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { node } from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Snackbar, Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { ReactComponent as ListView } from '../../Assets/ListView.svg';
import { ReactComponent as GantView } from '../../Assets/GantView.svg';
import { ReactComponent as Collapse } from '../../Assets/Collapse.svg';
import { ReactComponent as Expand } from '../../Assets/Expand.svg';
// import { ReactComponent as AddTask } from '../../Assets/AddTask.svg';
import AddTask from '../../Assets/add_task.png';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as Attachement } from '../../Assets/Attachment.svg';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { InputText } from 'primereact/inputtext';
import { FilterList } from '@mui/icons-material';
import { Menu } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CriticalIcon from "../../Assets/warning.png";
import RejectIcon from "../../Assets/decline.png";
import PendingIcon from "../../Assets/pending.png";
import RecurringTask from "../../Assets/refresh.png";

import ShortcutIcon from '@mui/icons-material/Shortcut';
import Swal from 'sweetalert2';

import Tooltip from '@mui/material/Tooltip';


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'rgba(0,0,0,1)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 15,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 1)' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

}));



const ProjectListView = (props) => {
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const location = useLocation();
  // const { domainId, projectId, dashboard, dashboardActivated, domainName, userDetails, projectName, project_progress, user_progress, toggleActive } = location.state;
  const { tabValue, project_owner_approved, projectDueDate, domainOwner, project_owner, setFullView, fullView, domainId, projectId, dashboard, dashboardActivated, domainName, projectName, project_progress, user_progress, toggleActive, setIsLoaded, isLoaded, setListViewActive, projectUserList
  } = props;

  const [userDetails, setUserDetails] = useState(null)
  const [nodes, setNodes] = useState([]);
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [dueDate, setDueDate] = useState(getCurrentDate());
  const [reviewDescription, setReviewDescription] = useState('')
  const [listView, setListView] = useState(true)
  const [userProgress, setUserProgress] = useState(0)
  const [projectProgress, setProjectProgress] = useState(0)
  // const [isLoaded, setIsLoaded] = useState(false)


  const [searchText, setSearchText] = useState('');
  const [filteredNodes, setFilteredNodes] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [progressPercentage, setProgressPercentage] = useState(0)


  const navigate = useNavigate()

  const [projectSnackBar, setProjectSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [reviewEstimation, setReviewEstimation] = useState(null);


  const [isDisable, setIsDisable] = useState(false)

  const handleProjectSnackbar = () => {
    setProjectSnackBar(false)
  }




  const [notesAccess, setNotesAccess] = useState(false)
  const [perfScoreAccess, setPerfScoreAccess] = useState(false)


  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
    }
    catch (error) {

      if (error.response.status == 401) {
        const message = error.response.data.details || 'An error occurred';
      }
    }
  }

  let viewType;
  useEffect(() => {
    fetchUserDetails();
  }, [fullView]);

  useEffect(() => {
    if (userDetails) {
      // Set access controls based on userDetails
      const noteAccess = userDetails.global_privileges.notes !== 'none';
      const perfScore = userDetails.global_privileges.perf_score !== 'none';

      setNotesAccess(noteAccess);
      setPerfScoreAccess(perfScore);

      // Call functions that depend on userDetails here
      fetchAllUser();
      fetchDomain();
      fetchProjects();
      fetchOptionList();

      // Determine view type and set progress percentage
      if (fullView) {
        viewType = true
        setProgressPercentage(userProgress);
      } else {
        viewType = false
        setProgressPercentage(projectProgress);
      }

      // Fetch tasks based on view type
      fetchAllTask();
    }
  }, [userDetails]);


  // Treetable


  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };





  const onRowToggle = (event) => {
    let keys = { ...expandedKeys };
    if (event.node.children) {
      if (keys[event.node.key]) {
        delete keys[event.node.key];
      } else {
        keys[event.node.key] = true;
      }
    }

    setExpandedKeys(keys);
  };

  let expandedSubtask = false
  const handleRowClick = (e) => {
    if (!expandedSubtask) {
      onRowToggle(e);
    }
    else {
      // setExpandedSubtask(false)
      expandedSubtask = false
    }
  };


  const expandAll = () => {
    const allKeys = filteredNodes.reduce((acc, node) => {
      const addKeys = (node) => {
        acc[node.key] = true;
        if (node.children) {
          node.children.forEach(child => addKeys(child));
        }
      };
      addKeys(node);
      return acc;
    }, {});
    setExpandedKeys(allKeys);
    setCollapseState(false)
  };


  const [collapseState, setCollapseState] = useState(true)

  const handleCollapseAll = () => {
    setExpandedKeys({});
    setCollapseState(true)
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children) {
      _expandedKeys[node.key] = true;
      node.children.forEach(child => {
        expandNode(child, _expandedKeys);
      });
    }
  };









  const [activeView, setActiveView] = useState('list'); // 'list' or 'gantt'

  const handleToggle = (view) => {
    setActiveView(view);
    if (view == 'gantt') {
      let state = {
        task_id: 'TD-01'
      }
      setListViewActive(false)
      // navigate('/dash/dashboard/project/ganttView', {
      //   state
      // });

    }
    else {
      return
    }

  };


  const [originalNodes, setOriginalNodes] = useState([])
  const [progressBarColors, setProgressBarColors] = useState([])
  const [statusColors, setStatusColors] = useState([])


  const fetchOptionList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_all_options", config)
      let data = response.data
      setProgressBarColors(data.progress_bar_color)
      setStatusColors(data.status)
      setStatus(data.status_sequence)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };

  const fetchAllTask = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };

      const is_active = 1;

      const response = await axios.get(`/backend/get_all_tasks_list/${domainId}/${projectId}/${is_active}`, config);
      const taskDetailsList = response.data.tasks;


      const userDetailsList = taskDetailsList.filter(task => {
        // Check if task.task_users_list is defined
        return task.task_users_list && task.task_users_list.includes(userDetails.username);
      });
      // if (fullView) {
      if (viewType) {
        setFilteredNodes([])
        setNodes([])
        const transformedNodes = userDetailsList.map(transformTaskDetailsToNodes);
        setOriginalNodes(transformedNodes)
        setNodes(transformedNodes);
        setFilteredNodes(transformedNodes);
        // setProgressPercentage(user_progress)
        setProgressPercentage(response.data.projects[0].user_overall_progress)
      }
      else {
        setFilteredNodes([])
        setNodes([])
        const transformedNodes = taskDetailsList.map(transformTaskDetailsToNodes);
        setNodes(transformedNodes);
        setOriginalNodes(transformedNodes)
        setFilteredNodes(transformedNodes);
        setProgressPercentage(response.data.projects[0].overall_progress)
      }
      setIsLoaded(true)

    } catch (error) {
      console.error('Error fetching task details:', error);
    }
  };


  const [newTask, setNewTask] = useState(false)
  const createTaskModal = () => {
    setNewTask(true)
    setTaskActivated(true)
  }

  const dateStyles = {
    height: '2.5em',
    border: '1px solid gainsboro', // Corrected spelling of 'gainsboro'
    borderRadius: '2px', // Uncomment and set borderRadius if needed
    width: '100%'
  };

  const handleRowExpansion = (rowData) => {
    // Logic to expand row or perform any action
    // Example: Expand row by setting expandedRows state
    // setExpandedRows([...expandedRows, rowData.key]);
  };

  // Custom body function for each column
  const rowExpansion = (rowData, column) => {

    const handleClick = () => {
      handleRowExpansion(rowData);
    };

    return (
      <div onClick={handleClick}>
        {rowData[column.field]}
      </div>
    );
  };



  // const transformTaskDetailsToNodes = (taskDetails) => {
  //   // Initialize variables for calculating average performance score
  //   let totalScore = 0;
  //   let validScoresCount = 0;

  //   const subTasks = Object.keys(taskDetails.sub_task).map((subTaskKey, index, array) => {
  //     const subTask = taskDetails.sub_task[subTaskKey];

  //     // Only consider valid numeric scores for the average calculation if is_approved is 1
  //     if (subTask.is_approved === 1 && typeof subTask.performance_score === 'number' && !isNaN(subTask.performance_score)) {
  //       totalScore += subTask.performance_score;
  //       validScoresCount++;
  //     }

  //     return {
  //       key: subTaskKey,
  //       task_id: taskDetails.task_id,
  //       data: {
  //         sub_task_name: subTask.sub_task_name,
  //         created_at: subTask.start_datetime,
  //         deadline: subTask.end_datetime,
  //         effort_hours: subTask.effort_hours,
  //         remarks: subTask.remarks,
  //         assign_to: subTask.sub_task_assign_to_name,
  //         is_file_attached: subTask.is_file_attached,
  //         file_name: subTask.file_name,
  //         s3_file_name: subTask.s3_file_name,
  //         completion_date: subTask.completion_date,
  //         status: subTask.sub_task_status,
  //         notes: subTask.remarks,
  //         performance_score: subTask.performance_score,
  //         username: subTask.sub_task_assign_to,
  //         approved: subTask.is_approved,
  //         last_child: index === array.length - 1,
  //         task_status: taskDetails.status
  //       }

  //     };
  //   });

  //   // Calculate the average performance score if there are valid scores
  //   const averagePerformanceScore = validScoresCount > 0 ? (totalScore / validScoresCount) : null;

  //   return {
  //     key: taskDetails.task_id,
  //     data: {
  //       assigned_by: taskDetails.assigned_by_name,
  //       task_name: taskDetails.task_name,
  //       assign_to: taskDetails.task_owner_name,
  //       created_at: taskDetails.start_date,
  //       deadline: taskDetails.deadline,
  //       completion_date: taskDetails.task_completion_date,
  //       effort_hours: taskDetails.total_task_effort_hours[0],
  //       status: taskDetails.status,
  //       task_id: taskDetails.task_id,
  //       notes: taskDetails.note,
  //       performance_score: averagePerformanceScore,
  //       critical: taskDetails.is_critical,
  //       approved: taskDetails.approval_status,
  //       is_recurring: taskDetails.is_recurring,
  //       task_status: taskDetails.status,
  //       sub_task_name: (
  //         <>
  //         {tabValue ==0 ?
  //         (
  //           <div onClick={() => expandedSubtask = true}
  //           className='d-flex flex-column justify-content-center align-items-center'>
  //             <EditIcon style={{ cursor: 'pointer' }} title="(Add/Edit) Task/Subtask" size="small" onClick={() => populateTaskData(taskDetails.task_id)} className={`${classes['icon-size']} p-button-rounded p-button-text`} />
  //           </div>
  //         ):(
  //           <div onClick={() => expandedSubtask = true} className='d-flex flex-column justify-content-center align-items-center'>
  //             <VisibilityIcon style={{ cursor: 'pointer' }} title="(Add/Edit) Task/Subtask" size="small" onClick={() => populateTaskData(taskDetails.task_id)} className={`${classes['icon-size']} p-button-rounded p-button-text`} />
  //           </div>
  //         )
  //       }

  //         </>
  //       )
  //     },
  //     children: subTasks
  //   };
  // };

  const transformTaskDetailsToNodes = (taskDetails) => {
    // Initialize variables for calculating average performance score
    let totalScore = 0;
    let validScoresCount = 0;

    const subTasks = Object.keys(taskDetails.sub_task).map((subTaskKey, index, array) => {
      const subTask = taskDetails.sub_task[subTaskKey];

      // Only consider valid numeric scores for the average calculation if is_approved is 1
      if (subTask.is_approved === 1 && typeof subTask.performance_score === 'number' && !isNaN(subTask.performance_score)) {
        totalScore += subTask.performance_score;
        validScoresCount++;
      }

      return {
        key: subTaskKey,
        task_id: taskDetails.task_id,
        data: {
          sub_task_name: (
            <Tooltip
              title={subTask.sub_task_name || ''}
              arrow
              enterTouchDelay={0}
              sx={{ maxWidth: 125, whiteSpace: 'pre-wrap' }}
            >
              <div onClick={() => expandedSubtask = true} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer' }}>
                {subTask.sub_task_name}
              </div>
            </Tooltip>
          ),
          created_at: subTask.start_datetime,
          deadline: subTask.end_datetime,
          effort_hours: subTask.effort_hours,
          remarks: subTask.remarks,
          assign_to: subTask.sub_task_assign_to_name,
          is_file_attached: subTask.is_file_attached,
          file_name: subTask.file_name,
          s3_file_name: subTask.s3_file_name,
          completion_date: subTask.completion_date,
          status: subTask.sub_task_status,
          notes: subTask.remarks,
          performance_score: subTask.performance_score,
          username: subTask.sub_task_assign_to,
          approved: subTask.is_approved,
          last_child: index === array.length - 1,
          task_status: taskDetails.status
        }

      };
    });

    // Calculate the average performance score if there are valid scores
    const averagePerformanceScore = validScoresCount > 0 ? (totalScore / validScoresCount) : null;

    return {
      key: taskDetails.task_id,
      data: {
        assigned_by: taskDetails.assigned_by_name,
        task_name: taskDetails.task_name,
        assign_to: taskDetails.task_owner_name,
        created_at: taskDetails.start_date,
        deadline: taskDetails.deadline,
        completion_date: taskDetails.task_completion_date,
        effort_hours: taskDetails.total_task_effort_hours[0],
        status: taskDetails.status,
        task_id: taskDetails.task_id,
        notes: taskDetails.note,
        performance_score: averagePerformanceScore,
        critical: taskDetails.is_critical,
        approved: taskDetails.approval_status,
        is_recurring: taskDetails.is_recurring,
        task_status: taskDetails.status,
        sub_task_name: (
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <Tooltip
              title={tabValue === 0 ? "(Add/Edit) Task/Subtask" : "(View) Task/Subtask"}
              arrow
            >
              {tabValue === 0 ? (
                <EditIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    expandedSubtask = true;
                    populateTaskData(taskDetails.task_id);
                  }}
                  className={`${classes['icon-size']} p-button-rounded p-button-text`}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    expandedSubtask = true; // Set expandedSubtask to true
                    populateTaskData(taskDetails.task_id);
                  }}
                  className={`${classes['icon-size']} p-button-rounded p-button-text`}
                />
              )}
            </Tooltip>
          </div>
        ),

      },
      children: subTasks
    };
  };




  const [taskActivated, setTaskActivated] = useState(false)
  const [taskDetails, setTaskDetails] = useState([])
  const [userTaskDetails, setUserTaskDetails] = useState([])
  const populateTaskData = async (data) => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get(`/backend/get_tasks_details/${data}`, config);
      let taskData = response.data;
      setTaskDetails(taskData);
      setModal(true);
      setTaskActivated(true);
      setNewTask(false)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const message = error.response.data.details || 'An error occurred';
        // Handle the error accordingly, maybe redirect to login or show a notification
      } else {
        console.error("An unexpected error occurred");
      }
    }
  };





  const fetchAllUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/1`, config);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchProjects = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let is_active_ = 1;
      let projectResponse = await axios.get(`/backend/get_all_projects_list/${domainId}/${is_active_}`, config);
      setProjectList(projectResponse.data);
    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }
  };

  const fetchDomain = async () => {
    let is_active = 'All';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let domainResponse = await axios.get(`/backend/get_all_domain_list/${is_active}`, config);
      setDomainList(domainResponse.data);
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };

  const actionBodyTemplate = (rowData, actionType, rowIndex) => {
    switch (actionType) {
      case 'edit':
        return (
          <EditIcon onClick={() => populateTaskData(rowData.task_id)} className={`${classes['icon-size']} p-button-rounded p-button-text`} />
        );
      default:
        return null;
    }
  };

  const getRowClassName = (node) => {
    if (node.children && expandedKeys[node.key]) {
      return { style: { backgroundColor: 'gainsboro' } };
    }
    return {};
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);


  // const generateStatusOptions = () => {
  //   return statusColors.map((statusObj) => {
  //     const status = Object.keys(statusObj)[0];
  //     const color = statusObj[status];
  //     return (
  //       <MenuItem key={status} value={status} style={{ backgroundColor: color, color: 'white' }}>
  //         <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <div style={{ width: '10px', height: '10px', backgroundColor: color, marginRight: '5px', borderRadius: '50%' }}></div>
  //           <span>{status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}</span>
  //         </div>
  //       </MenuItem>
  //     );
  //   });
  // };

  // const statusTemplate = (rowData) => {
  //   const selectedColor = statusColors.find(item => Object.keys(item)[0] === rowData.data.status);
  //   const backgroundColor = selectedColor ? Object.values(selectedColor)[0] : '#FFFFFF';

  //   return (
  //     <Select
  //       size="small"
  //       value={rowData.data.status}
  //       onChange={(e) => handleStatusChange(e, rowData)}
  //       onOpen={() => setDropdownOpen(true)}
  //       onClose={() => setDropdownOpen(false)}
  //       style={{ backgroundColor: backgroundColor, color: 'white', width: 'fit-content' }}
  //       sx={{ fontSize: '10px' }}
  //     >
  //       {generateStatusOptions()}
  //     </Select>
  //   );
  // };

  const statusTemplate = (rowData) => {
    const selectedColor = getStatusColor(rowData.data.status);
    const backgroundColor = selectedColor ? selectedColor : '#FFFFFF';
    const isParent = rowData.children && rowData.children.length >= 0;
    const isApproved = rowData.data.approved === 1;
    const statusOptions = generateStatusOptions(rowData.data.status);
    const onhold = rowData.data.task_status === 'on hold';
    return (
      <>
        {isParent || rowData.data.username !== userDetails.username || statusOptions.length <= 1 || isApproved != 1 || onhold ? (
          <>
            <div style={{ width: '100%' }} className='d-flex justify-content-center align-items-center '>
              <div style={{ backgroundColor: backgroundColor, color: 'black', fontWeight: 'bold', minWidth: '5em', width: '85%', padding: '8px', borderRadius: '50px' }} className='d-flex justify-content-center align-items-center'>
                {/* <span>{rowData.data.status}</span> */}
                <span style={{ fontSize: '10px' }}>{rowData.data.status.charAt(0).toUpperCase() + rowData.data.status.slice(1).toLowerCase()}</span>
              </div>
            </div>
          </>
        ) :
          (isApproved && !onhold) ? (
            <Select
              size="small"
              value={rowData.data.status}
              onChange={(e) => handleStatusChange(e, rowData)}
              onOpen={() => setDropdownOpen(true)}
              onClose={() => setDropdownOpen(false)}
              style={{ backgroundColor: backgroundColor, color: 'black', width: '85%', borderRadius: '50px', fontWeight: 'bold' }}
              sx={{ fontSize: '10px' }}
            >
              {generateStatusOptions(rowData.data.status)}
            </Select>
          ) : (
            <div style={{ width: '100%' }} className='d-flex justify-content-center align-items-center '>
              <div style={{ backgroundColor: backgroundColor, color: 'black', minWidth: '5em', width: '85%', padding: '8px', borderRadius: '50px', fontWeight: 'bold' }} className='d-flex justify-content-center align-items-center'>
                <span style={{ fontSize: '10px' }}>{rowData.data.status.charAt(0).toUpperCase() + rowData.data.status.slice(1).toLowerCase()}</span>
              </div>
            </div>
          )
        }
      </>
    );
  };

  const getStatusColor = (status) => {
    for (const [key, value] of Object.entries(statusColors)) {
      if (status in value) {
        return value[status];
      }
    }
    return null;
  };

  const generateStatusOptions = (currentStatus) => {
    const statusOptions = [];

    const relevantStatusColors = Object.entries(statusColors).find(([key]) => key === currentStatus);

    if (relevantStatusColors) {
      const [, innerStatuses] = relevantStatusColors;

      for (const [status, color] of Object.entries(innerStatuses)) {
        statusOptions.push(
          <MenuItem key={status} value={status} style={{ backgroundColor: color, color: 'black' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: '10px', height: '10px', backgroundColor: color, marginRight: '5px', borderRadius: '50%' }}></div>
              <span style={{ color: 'black' }}>{status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}</span>
            </div>
          </MenuItem>
        );
      }
    }

    return statusOptions;
  };

  const rowClassName = (rowData) => {
    if (!rowData.children) {
      return 'custom-expanded-row-treetable';
    }
    return '';
  };






  const [reviewData, setReviewData] = useState(null)

  const handleStatusChange = async (event, rowData) => {
    const newStatus = event.target.value;
    if (rowData.data.created_at === "" && rowData.data.deadline === "") {
      setProjectSnackBar(true)
      setMessage('SubTask Missing Start Date or End Date')
      setSeverity('error')
      return
    }
    if (newStatus == "completed review pending" || newStatus == "completed review pending (overdue)") {
      setSelectedUser(null)
      setOpen(true)
      setReviewData(rowData)
      return
    }
    else {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("dash_access_token")}`,
          },
        };
        let review_status = ['review failed', 'review passed', 'review failed (overdue)', 'review passed (overdue)']
        let is_sub_task_status_review;
        if (review_status.includes(newStatus)) {
          is_sub_task_status_review = 1
        } else {
          is_sub_task_status_review = 0
        }

        function cleanStatus(status) {
          return status.replace(" (overdue)", "");
        }

        let new_status = cleanStatus(newStatus)
        const data = {
          task_id: rowData.task_id,
          project_id: projectId,
          domain_id: domainId,
          sub_task_status: new_status,
          sub_task_id: rowData.key,
          is_sub_task_review: is_sub_task_status_review,
        }
        const response = await axios.post(`/backend/edit_task_status`, data, config);
        fetchAllTask()
        setDueDate(null)
        setReviewDescription('')
        setReviewEstimation(null)
        setProjectSnackBar(true)
        setMessage('SubTask Status Updated Successfully')
        setSeverity('success')

      }
      catch (error) {
        setProjectSnackBar(true)
        setMessage(error.response.data.detail)
        setSeverity('error')
      }
    }
    const updateChildStatus = (nodes) => {
      return nodes.map(node => {
        if (node.key === rowData.key) {
          return { ...node, data: { ...node.data, status: newStatus } };
        }
        if (node.children) {
          return { ...node, children: updateChildStatus(node.children) };
        }
        return node;
      });
    };

    const updatedNodes = updateChildStatus(nodes);
    setNodes(updatedNodes);
  };
  const confirmbuttonStyles = {
    border: "1px solid black",
    backgroundColor: "rgba(28, 28, 28, 1)",
    color: "rgba(238, 225, 213, 1)",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '10em',
    borderRadius: "28px",
  }
  const confirmHoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  }
  const buttonStyles2 = {
    border: "1px solid black",
    backgroundColor: "#EBEBEB",
    color: "black",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '10em',
    borderRadius: "28px",
  };

  const hoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  };






  const handleApprove = async (rowData) => {
    setIsDisable(true)
    let new_status = "completed review pending";
    if (selectedUser == null) {
      setProjectSnackBar(true)
      setMessage('Please Select a User to Review the Sub Task')
      setSeverity('error')
      return
    }
    else if ((reviewDescription == '' || reviewDescription == null)) {
      setProjectSnackBar(true)
      setMessage('Enter Review Description Name')
      setSeverity('error')
      return
    }

    else if ((dueDate == null || dueDate == '')) {
      setProjectSnackBar(true)
      setMessage('Please Select a Review Date to assign ')
      setSeverity('error')
      return
    }

    else if (dueDate < getCurrentDate()) {
      setProjectSnackBar(true)
      setMessage('Review date cannot be less than current date')
      setSeverity('error')
      return
    }

    else if (dueDate > projectDueDate) {
      setProjectSnackBar(true)
      setMessage('Review date cannot be greater than project due date')
      setSeverity('error')
      return
    }
    else if (reviewEstimation == null) {
      setProjectSnackBar(true)
      setMessage('Please Select a Estimation Time ')
      setSeverity('error')
      return
    }
    else if (reviewEstimation > 10) {
      setProjectSnackBar(true)
      setMessage('Estimation time should not be greater than 10 hours')
      setSeverity('error')
      return
    }
    else if (reviewEstimation == 0) {
      setProjectSnackBar(true)
      setMessage('Estimation time cannot be zero')
      setSeverity('error')
      return
    }


    let review_status = ['review failed', 'review passed', 'review failed (overdue)', 'review passed (overdue)']
    let is_sub_task_status_review;
    if (review_status.includes(new_status)) {
      is_sub_task_status_review = 1
    } else {
      is_sub_task_status_review = 0
    }

    function cleanStatus(status) {
      return status.replaceAll(" (overdue)", "");
    }

    let updated_status = cleanStatus(new_status)

    // Create the data object
    const data = {
      domain_id: domainId,
      project_id: projectId,
      reviewer: selectedUser.username,
      effort_hours: reviewEstimation,
      date: dueDate,
      sub_task_description: reviewDescription,
      task_id: reviewData.task_id,
      sub_task_id: reviewData.key,
      is_sub_task_review: is_sub_task_status_review,
    };



    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`
        }
      };


      const response = await axios.post(`/backend/send_task_review`, data, config);



      if (response) {
        let data = {
          task_id: reviewData.task_id,
          project_id: projectId,
          domain_id: domainId,
          sub_task_status: updated_status,
          sub_task_id: rowData.key,
          is_sub_task_review: is_sub_task_status_review,
        }
        const edit_status = await axios.post('/backend/edit_task_status', data, config)
      }

      fetchAllTask();
      setDueDate(null)
      setReviewDescription('')
      setReviewEstimation(null)

      setProjectSnackBar(true);
      setMessage('Approval sent for review');
      setSeverity('success');
      setOpen(false);
      setIsDisable(false)
      const updateChildStatus = (nodes) => {
        return nodes.map(node => {
          if (node.key === rowData.key) {
            return { ...node, data: { ...node.data, status: new_status } };
          }
          if (node.children) {
            return { ...node, children: updateChildStatus(node.children) };
          }
          return node;
        });
      };

      const updatedNodes = updateChildStatus(nodes);
      setNodes(updatedNodes);
    } catch (error) {
      setIsDisable(false)
      setProjectSnackBar(true);
      setMessage(error.response ? error.response.data.details : 'An error occurred');
      setSeverity('error');
    }



  };


  const handleClickOpen = () => {
    setOpen(true)
  }


  const handleReviewUser = (value) => {

    setSelectedUser(value)
  }

  const reviewUser = Array.isArray(users) ? users
    .filter(user => user.username !== userDetails.username) // Remove user with username 'hitesh'
    .slice() // Create a copy of the filtered array
    .sort((a, b) => {
      if (domainId == null) {
        return 0; // Return 0 if domainId is null, indicating no sorting
      }
      if (a.core_domain === domainId) return -1;
      if (b.core_domain === domainId) return 1;
      return a.core_domain.localeCompare(b.core_domain);
    }) : null;



  const handleReviewCancel = async () => {

    if (swalActive) {
      const title = 'Unsaved Changes';
      const confirmButtonText = 'OK';
      const cancelButtonText = 'Cancel';

      const result = await Swal.fire({
        title,
        text: 'Do you want to exit?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        customClass: {
          container: 'custom-swal-container'
        }
      });

      if (!result.isConfirmed) {
        return;
      }

      setOpen(false)
      setReviewDescription('')
      setDueDate('')
      setReviewEstimation('')
      setSelectedUser(null)



    }
    else {
      setOpen(false)
      setReviewDescription('')
      setDueDate('')
      setReviewEstimation('')
      setSelectedUser(null)
    }

  }
  const onClose = () => {
    setOpen(false)
    setDueDate(null)
    setReviewDescription('')
    setReviewEstimation(null)

  }
  const useStyles = makeStyles({
    progressBarContainer: {
      position: 'relative',
      width: '100%',
      height: '1.5em',
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      // cursor: 'pointer'
    },
    progressBar: {
      height: '100%',
      borderRadius: '4px',
      transition: 'width 0.2s ease-in-out',
    },
    progressText: {
      width: '1px',
      height: '1px',
      position: 'absolute',
      top: '2px',
      display: 'flex'
    },
    projectLabel: {
      fontSize: '1.25em',
    },

    fileItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1em',
      borderBottom: `1px solid black`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    fileName: {
      flexGrow: 1,
    },
    downloadIcon: {
      marginLeft: '1em',
      color: 'blue',
    },
    dialogActions: {
      padding: '1em',
    },
  });
  const styles = useStyles();
  const getColorByPercentage = (percentage) => {
    if (percentage >= 0 && percentage <= 100) {
      const index = Math.floor(percentage / 10);
      return progressBarColors[index - 1];
    }
    return null;
  };


  // const [fullView, setFullView] = useState(false)

  const handleViewToggle = (event) => {
    if (event.target.checked) {
      viewType = true
    }
    else {
      viewType = false
    }
    setFullView(event.target.checked)
    fetchAllTask()

  }




  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [subtaskDetails, setSubtaskDetails] = useState('')

  const handleOpenAttachment = (files, node) => {
    setSelectedFiles(files);
    setAttachmentOpen(true);
    let subtaskName = String(node.data.sub_task_name.props.title)
    setSubtaskDetails(subtaskName)
  };

  const handleCloseAttachment = () => {
    setAttachmentOpen(false);
    setSelectedFiles([]);
    setSubtaskDetails('')
  };

  const [swalActive, setSwalActive] = useState(false)


  const attachmentTemplate = (node) => {
    const isParent = node.children;

    if (isParent) {
      return '-';
    } else {
      const files = node.data.file_name.map((fileName, index) => ({
        name: fileName,
        s3FileName: node.data.s3_file_name[index],
      }));

      return (
        <IconButton onClick={() => handleOpenAttachment(files, node)}>
          <Attachement sx={{ height: '25px' }} />
        </IconButton>
        // <div></div>
      );
    }
  };
  const handleS3FileDownload = async (file_id, fileNames) => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const data = new FormData();
      data.append('file_id', file_id);
      let firstHyphenIndex = fileNames.indexOf('-');
      let trimmedFilename = fileNames.substring(firstHyphenIndex + 3); // Result: "Product_Details_All_2024-06-29_09_00.csv"

      // Step 2: Remove the extra digits at the end
      let lastUnderscoreIndex = trimmedFilename.lastIndexOf('||');
      let finalFilename = trimmedFilename.substring(0, lastUnderscoreIndex) + '.csv';

      const response = await axios.post(`/backend/get_task_resource_s3`, data, config);


      const url = response.data.presigned_url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", finalFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error creating task', error);
    }
  }

  const formatFileName = (fileNames) => {
    let firstHyphenIndex = fileNames.indexOf('-');
    let trimmedFilename = fileNames.substring(firstHyphenIndex + 3);

    let lastUnderscoreIndex = trimmedFilename.lastIndexOf('||');
    let finalFilename = trimmedFilename.substring(0, lastUnderscoreIndex) + '.csv';
    return finalFilename
  };


  const searchNodes = (nodes, term, status) => {
    if (!term.trim() && !status.trim()) {
      return nodes;
    }

    return nodes.filter(node => {
      const dataValues = Object.values(node.data);
      const matchesSearch = dataValues.some(value =>
        typeof value === 'string' && value.toLowerCase().includes(term.toLowerCase())
      );
      // Check if the node matches the status filter
      const matchesStatus = !status || node.data.status.toLowerCase() === status.toLowerCase();

      if (matchesSearch && matchesStatus) {
        return true; // Found a match in parent node data and status filter
      }

      // Recursively search through children
      if (node.children) {
        node.children = searchNodes(node.children, term, status); // Update children with filtered results
        return node.children.length > 0; // Return parent node if any child matches
      }

      return false; // No match found in parent or children
    });
  };


  const [selectedStatus, setSelectedStatus] = useState('');


  const handleStatusFilterChange = (rowData) => {
    setSelectedStatus(rowData);
    if (rowData == '') {
      setFilteredNodes(originalNodes)
    }
    else {
      const foundTask = originalNodes.filter(task =>
        task.data.status === rowData ||
        task.children.some(child => child.data.status === rowData)
      );
      setFilteredNodes(foundTask)
    }

  };

  const filterNodes = (term, status) => {
    if (!term.trim() && !status.trim()) {

      // const filtered = searchNodes(nodes, term, status);
      // setFilteredNodes(originalNodes);
      if (fullView) {
        viewType = true
      }
      else {
        viewType = false
      }
      fetchAllTask()
    } else {
      const filtered = searchNodes(nodes, term, status);
      setFilteredNodes(filtered);
    }
  };





  const handleSearchChange = (e) => {
    const term = e.target.value || ''; // Ensure e.target.value is not undefined
    setSearchText(term);
    filterNodes(term, statusFilter); // Apply filter immediately on search text change
  };


  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const [status, setStatus] = useState([])


  const customStatusHeader = () => {
    return (
      <div style={{ alignItems: 'center' }}>
        <span>Status</span>
        <IconButton size="small" onClick={handleFilterIconClick}>
          <FilterList />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            style: { 
              fontSize: '0.875rem', 
            },
          }}
        >
          <MenuItem
            key="clear"
            onClick={() => {
              handleStatusFilterChange('');
              handleFilterClose();
            }}
            style={{
              fontWeight: selectedStatus === '' ? 'bold' : 'normal',
              backgroundColor: selectedStatus === '' ? '#e0e0e0' : 'transparent'
            }}
          >
            Clear
          </MenuItem>
          {status.map(status => (
            <MenuItem
              key={status}
              onClick={() => {
                handleStatusFilterChange(status);
                handleFilterClose();
              }}
              style={{
                fontWeight: selectedStatus === status ? 'bold' : 'normal',
                backgroundColor: selectedStatus === status ? '#e0e0e0' : 'transparent'
              }}
            >
              {status.toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  };






  const handleReviewEstimation = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setReviewEstimation(value)
    }
  };



  const taskNameTemplate = (rowData) => {
    const isParent = rowData.children && rowData.children.length >= 0;
    const isCritical = rowData.data && rowData.data.critical === 1;
    const isRecurring = rowData.data && rowData.data.is_recurring === 1;
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%", width: "100%", position: 'relative' }}>
        {isParent && (
          <>
            {(isCritical || isRecurring) ? (
              <div className='d-flex flex-column justify-content-evenly align-items-center' style={{ height: '100%', position: 'relative' }}>
                {isCritical && (
                  <img title="Critical Task" src={CriticalIcon} alt="Critical Task Icon" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                )}
                {isRecurring && (
                  <img title="Recurring Task" src={RecurringTask} alt="Recurring Task Icon" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                )}
              </div>
            ) : (
              <>
                <div style={{ width: '15px' }}></div>
              </>
            )}
            <Tooltip title={rowData.data.task_name} arrow>
              <span
                style={{
                  textAlign: 'start',
                  width: '60%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {rowData.data.task_name}
              </span>
            </Tooltip>
          </>
        )}
        {!isParent && (
          <>
            {rowData && (
              <>
                <div
                  className="connector-line"
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: 'calc(50% - 2px)',
                    height: rowData.data.last_child ? '50%' : '100%',
                    borderLeft: '0.15em solid grey',
                  }}
                />

                <div
                  className="arrow-icon"
                  style={{
                    position: 'absolute',
                    top: `18px`,
                    left: '4.45em',
                  }}
                >
                  <ShortcutIcon style={{ fontSize: '1.45em', color: 'grey', transform: 'scaleY(-1)' }} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const [isCompleteDate, setIsCompleteDate] = useState(false);

  const handleDateChange = (e) => {
    const value = e.target.value;
    setDueDate(value);

    if (value.length === 10) { // Check if the input length is 10 characters (YYYY-MM-DD)
      setIsCompleteDate(true);
    } else {
      setIsCompleteDate(false);
    }
  };

  const handleDateBlur = () => {
    if (isCompleteDate && dueDate < getCurrentDate()) {
      setProjectSnackBar(true);
      setMessage(`Task due date cannot be less than current date`);
      setSeverity('error');
      setDueDate('');
      setSwalActive(true);
      return
    } else if (isCompleteDate) {
      setSwalActive(true);
    }
  };


  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);



  const onSort = (event) => {
    const { sortField, sortOrder } = event;

    // Set the sort state for the TreeTable UI
    setSortField(sortField);
    setSortOrder(sortOrder);

    // Create a copy of the filteredNodes to avoid direct mutation
    const sortedNodes = [...filteredNodes];

    sortedNodes.sort((a, b) => {
      let result = 0;
      if (a.data[sortField] < b.data[sortField]) {
        result = -1;
      } else if (a.data[sortField] > b.data[sortField]) {
        result = 1;
      }
      return sortOrder * result;
    });

    const childNodes = sortedNodes.map((task) => {
      if (task.children && task.children.length > 0) {

        const updatedChildren = task.children.map((child, index) => ({
          ...child,
          data: {
            ...child.data,
            last_child: index === task.children.length - 1
          }
        }));

        return {
          ...task,
          children: updatedChildren
        };
      }

      // Return the task unchanged if it has no children
      return task;
    });
    setFilteredNodes(childNodes);
  };










  const assignToTemplate = (rowData) => {
    const isParent = rowData.children ? true : false;
    const isRejected = rowData.data && rowData.data.approved === 0;
    const isPending = rowData.data && rowData.data.approved === -1;

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isParent && isRejected && (
          <img title={"Approval rejected"} src={RejectIcon} alt="Reject Icon" style={{ width: '20px', height: '20px', marginRight: '8px', cursor: 'pointer' }} />
        )}
        {isParent && isPending && (
          <img title={"Approval pending"} src={PendingIcon} alt="Pending Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        {!isParent && isRejected && (
          <img title={"Approval rejected"} src={RejectIcon} alt="Reject Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        {!isParent && isPending && (
          <img title={"Approval pending"} src={PendingIcon} alt="Pending Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        {!((isParent && isRejected) || (isParent && isPending) || (!isParent && isRejected) || (!isParent && isPending)) && (
          <div style={{ width: '20%' }}></div>
        )}
        <Tooltip title={rowData.data.assign_to} arrow>
          <span
            style={{
              width: '70%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'start'
            }}
          >
            {rowData.data.assign_to}
          </span>
        </Tooltip>
      </div>
    );
  };

  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}
        PaperProps={{
          sx: {
            width: '29em',
            maxWidth: '35em',
          },
        }}
      >
        <DialogTitle className={classes['dialogTitle']} >
          <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid black', width: '100%' }}>
            <div style={{ marginLeft: '1em' }}>Subtask Review</div>
            <IconButton onClick={handleReviewCancel} className={classes['closeButton']}>
              <CloseIcon />
            </IconButton>
          </div>

        </DialogTitle>
        <DialogContent style={{ paddingBottom: '0' }} className='mt-3'>
          {/* <div className='d-flex justify-content-between align-items-center'> */}
          <div className={classes['dialogItem']}>
            Project Name: <span
            // className={classes['highlight']}
            >{projectName}</span>
          </div>
          <div className={classes['dialogItem']}>
            Sub Task Name: <span
            // className={classes['highlight']}
            >
              {reviewData && reviewData.data.sub_task_name}
            </span>
          </div>
          {/* </div> */}

          <Typography className={`${classes['task-label']}`} variant="subtitle1">Select User for Review <span style={{ color: 'red' }}>*</span></Typography>
          <Autocomplete
            id="user-domain"
            options={reviewUser}
            groupBy={(option) => option.domain_name}
            getOptionLabel={(option) => option.name}
            value={selectedUser}
            onChange={(event, newValue) => handleReviewUser(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />

          <div className='d-flex justify-content-between align-items-center'>
            <div className={'form-group d-flex flex-column mt-2'} style={{ width: '40%' }}>
              <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Review Date<span style={{ color: 'red' }}>*</span></label>
              <input
                type="date"
                id="dateInput"
                value={dueDate}
                max={projectDueDate}
                // onChange={(e) => setDueDate(e.target.value)}
                onChange={handleDateChange}

                onBlur={handleDateBlur}
                InputLabelProps={{ shrink: true }}
                style={{ ...dateStyles }}
                min={getCurrentDate()}

              />
            </div>

            <div style={{ width: '40%' }}>
              <Typography variant="subtitle1" className={`${classes['task-label']} mb-1`} >Estimation Time (Hrs)<span style={{ color: 'red' }}>*</span></Typography>
              <TextField
                // label="Estimation Time (Hrs)"
                type="number"
                value={reviewEstimation}
                onChange={(e) => { handleReviewEstimation(e) }}
                onKeyDown={(e) => {
                  if (e.key === '-' || e.key === 'e') {
                    e.preventDefault();
                  }
                }}
                sx={{ width: '100%' }}
                size="small"
                inputProps={{ min: 0 }}

              />
            </div>
          </div>
          <Typography className={`${classes['task-label']}`} variant="subtitle1">Review Description<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            // label="Review Description"
            multiline
            rows={4}
            value={reviewDescription}
            onChange={(e) => {
              const originalValue = e.target.value;
              const value = toSentenceCase(originalValue);
              setReviewDescription(originalValue);
            }}
            fullWidth
          />


        </DialogContent>
        <DialogActions className={classes['dialogActions']}>
          <Button onClick={() => handleApprove(reviewData)}

            sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}
            disabeld={isDisable}>Send Request</Button>
          <Button onClick={() => handleReviewCancel()}
            sx={{ ...buttonStyles2, "&:hover": hoverStyles }}
          >Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={attachmentOpen} onClose={handleCloseAttachment}
        PaperProps={{
          sx: {
            width: '35em',
            maxWidth: '70em',
          },
        }}
      >
        <DialogTitle className={`${classes['dialogTitle']} d-flex justify-content-between align-items-center`}>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ borderBottom: '1px solid black', padding: '9px 0', width: '100%'}}
          >
            <Tooltip
              title={`Attached files for ${subtaskDetails}  Subtask`}
              arrow
            >
              <Typography
                style={{
                  display:'block',
                  fontWeight: 'bold',
                  fontSize: '1em',
                  marginLeft: '1em',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '80%'
                }}
                
              >
                Attached files for {subtaskDetails} Subtask
              </Typography>
            </Tooltip>
            <IconButton onClick={handleCloseAttachment} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {selectedFiles.length > 0 ? (
            <table className={classes.fileTable}>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {selectedFiles.map((file, index) => (
                  <tr key={index} className={classes.tableRow}>
                    <td>{formatFileName(file.name)}</td>
                    <td style={{ textAlign: 'center' }}>
                      <IconButton
                        href={file.s3FileName}
                        download
                        size="small"
                        onClick={() => handleS3FileDownload(file.s3FileName, file.name)}
                        className={classes.downloadIcon}
                        title="Download File"
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className={classes.noFilesMessage}>
              No files uploaded for this subtask.
            </div>
          )}

        </DialogContent>
      </Dialog>


      <div className={classes['content']}>
        {/* <Navbar dashboard={dashboard} userDetails={userDetails} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
        <DashboardTitle dashboardActivated={dashboardActivated} listView={listView} projectName={projectName} isLoaded={isLoaded} setIsLoaded={setIsLoaded} /> */}
        {isLoaded ?
          (
            <>
              <Box sx={{ width: '100%' }} className={classes['tab-container']}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '0em 1em' }} className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex justify-content-between align-items-center' style={{ width: '100%' }}>

                    <div style={{ marginLeft: '1em' }}>

                      <div>
                        <Button
                          onClick={() => handleToggle('list')}
                          sx={{
                            backgroundColor: activeView === 'list' ? 'rgb(172, 169, 231)' : 'white',
                            color: activeView === 'list' ? 'white' : 'black',
                            borderRadius: '20px',
                            '&:hover': {
                              color: activeView === 'list' ? 'white' : 'white',
                              backgroundColor: activeView === 'list' ? 'rgb(120, 117, 179)' : 'rgb(120, 117, 179)',
                            },
                            fontSize: '0.7em',
                            marginRight: '1em'
                          }}
                          variant="contained"
                          startIcon={
                            <FormatListBulletedIcon
                              style={{
                                color: activeView === 'list' ? 'white' : 'black',
                              }}
                            />}
                        >
                          ListView
                        </Button>
                        <Button
                          onClick={() => handleToggle('gantt')}
                          sx={{
                            backgroundColor: activeView === 'gantt' ? 'rgb(172, 169, 231)' : 'white',
                            color: activeView === 'gantt' ? 'white' : 'black',
                            borderRadius: '20px',
                            border: 'none',
                            '&:hover': {
                              color: activeView === 'gantt' ? 'black' : 'white',
                              backgroundColor: activeView === 'gantt' ? 'white' : 'rgb(172, 169, 231)',
                            },
                            fontSize: '0.7em',
                            marginRight: '1em'
                          }}
                          variant="contained"
                          startIcon={<GantView />}
                        >
                          Gantt Chart View
                        </Button>

                        {userDetails && (userDetails.global_privileges.domain_creation != 'none' || (userDetails.personal_privileges?.[domainId]?.project[projectId] != 'view' && userDetails.personal_privileges?.[domainId]?.project[projectId] != 'view|a') || (userDetails.personal_privileges?.[domainId]?.['access'] != 'view' && userDetails.personal_privileges?.[domainId]?.['access'] != 'view|a')) && tabValue == 0 &&

                          <Button
                            onClick={() => createTaskModal()}
                            sx={{
                              backgroundColor: 'white',
                              color: 'black',
                              borderRadius: '20px',
                              '&:hover': {
                                backgroundColor: 'rgb(20, 100,200)',
                              },
                              fontSize: '0.7em',

                            }}
                            variant="contained"

                            startIcon={<img src={AddTask} alt="icon" style={{ width: 23, height: 22 }} />}

                          >
                            Create Tasks
                          </Button>
                        }
                      </div>
                      <div className='d-flex justify-content-between align-items-center ' >
                        <div>
                          <span>Full View</span>
                          <FormControlLabel
                            style={{ margin: 0 }}
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={fullView}
                                onChange={handleViewToggle}
                              />

                            }
                          />
                          <span>My View</span>
                        </div>
                      </div>

                    </div>
                    <div style={{ width: '35%' }} className='d-flex justify-content-between align-items-center'>
                      <div style={{ width: '60%' }}><strong>Project Progress is at {Math.round(progressPercentage)} %</strong></div>
                      <div style={{ padding: '0.5em', width: '60%', borderRadius: '40px' }}>
                        <Box className={styles.progressBarContainer}>
                          <div
                            className={styles.progressBar}
                            style={{
                              width: `${Math.round(progressPercentage)}%`,
                              backgroundColor: getColorByPercentage(progressPercentage),
                            }}
                          >
                          </div>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
              <div className={`${classes['card']} ${classes['scrollable-card']}`}>
                <div className='d-flex justify-content-between m-2'>
                  <div>
                    Project Due Date : {projectDueDate}
                  </div>
                  <div className='d-flex align-items-center'>
                    <div>
                      {collapseState ?
                        (
                          <>
                            <IconButton onClick={expandAll} aria-label="expand all">
                              <Expand title="Expand All Task" />
                            </IconButton>
                          </>
                        ) :
                        (
                          <>
                            <IconButton onClick={handleCollapseAll} aria-label="collapse all">
                              <Collapse title="Collapse All Task" />
                            </IconButton>
                          </>
                        )
                      }



                    </div>
                    <div className="p-input-icon-left" style={{ textAlign: 'end' }}>
                      <InputText size="small" type="search"
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                        style={{ width: '9em', height: '2em', borderRadius: '10px' }}
                      />
                    </div>

                  </div>

                </div>


                <div >
                  <TreeTable
                    value={filteredNodes}
                    expandedKeys={expandedKeys}
                    onToggle={(e) => setExpandedKeys(e.value)}
                    // onRowClick={onRowToggle}
                    onRowClick={handleRowClick}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25]}
                    scrollable
                    frozenWidth="150px"
                    scrollHeight="50vh"
                    scrollBarWidth='thins'
                    className="custom-tree-table"
                    rowClassName={rowClassName}
                    removableSort
                    onSort={onSort}
                    sortField={sortField}
                    sortOrder={sortOrder}

                  >
                    <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="assigned_by" header="Assign By" expander frozen style={{ height: '57px', padding: 0, cursor: 'pointer' }} />
                    <Column sortable body={taskNameTemplate} bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="task_name" header="Task" style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }} />
                    <Column sortable body={assignToTemplate} field="assign_to" header="Assignee"
                      style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }}
                      bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'}
                    />
                    <Column
                      sortable
                      bodyClassName={classes['custom-treetable-body']}
                      headerClassName={'header-align-list'}
                      field="sub_task_name"
                      header="Subtask"
                      style={{ width: '100px', height: '57px', padding: 0, cursor: 'pointer', marginLeft: '1em' }}
                      body={(rowData) => (
                        <div
                          onClick={() => expandedSubtask = true}
                          style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', textWrap: 'nowrap', textAlign: 'start' }}
                        >
                          {rowData.data.sub_task_name}
                        </div>
                      )}
                    />


                    {/* <Column onClick={(e) => e.stopPropagation()} bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="sub_task_name" header="Subtask" style={{ width: '100px', height: '57px', padding: 0, cursor: 'pointer' }} /> */}
                    <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="created_at" header="Start Date" style={{ width: '120px', height: '57px', padding: 0, cursor: 'pointer' }} />
                    <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="deadline" header="Due Date" style={{ width: '120px', height: '57px', padding: 0, cursor: 'pointer' }} />
                    <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="completion_date" header="Completion Date" style={{ width: '180px', height: '57px', padding: 0, cursor: 'pointer' }} />
                    <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="effort_hours" header="Effort Hours" style={{ width: '150px', height: '57px', cursor: 'pointer' }} />
                    <Column bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list-status'} header={customStatusHeader} body={statusTemplate} style={{ width: '200px', height: '57px', padding: 0, cursor: 'pointer' }} />
                    {notesAccess && (
                      <Column
                        sortable
                        bodyClassName={classes['custom-treetable-body']}
                        headerClassName={'header-align-list'}
                        field="notes"
                        header="Notes"
                        style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }}
                        body={(rowData) => (
                          <Tooltip
                            title={rowData.data.notes || ''}
                            arrow
                            enterTouchDelay={0}
                            sx={{
                              maxWidth: 200,
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {rowData.data.notes}
                            </div>
                          </Tooltip>
                        )}
                      />
                    )}


                    {perfScoreAccess && <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="performance_score" header="Performance(5/5)" style={{ width: '180px', height: '57px', padding: 0, cursor: 'pointer' }} />}
                    <Column
                      bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} body={attachmentTemplate} header="Attachment" style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }} />
                  </TreeTable>

                </div>

              </div>

            </>
          )
          :
          (
            <>
              <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            </>
          )
        }

      </div>
      {
        taskActivated &&
        <Task tabValue={tabValue} project_owner_approved={project_owner_approved} setFullView={setFullView} fullView={fullView} setProjectSnackBar={setProjectSnackBar} setProjectMessage={setMessage} setProjectSeverity={setSeverity} projectDueDate={projectDueDate} projectOwner={project_owner} domainOwner={domainOwner} fetchAllTask={fetchAllTask} newTask={newTask} setTaskActivated={setTaskActivated} taskData={taskDetails} modal={modal} domainId={domainId} projectId={projectId} userList={users} domainsLists={domainList} projectLists={projectList} statusColors={statusColors} userDetails={userDetails} projectUserList={projectUserList} />
      }
      <Snackbar
        open={projectSnackBar}
        autoHideDuration={3000}
        onClose={handleProjectSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleProjectSnackbar} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

    </>
  );
};

export default ProjectListView;



