import React, { useState, useEffect, useRef,useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import classes from './DomainManagement.module.css'
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';
import { getCookie } from '../../Services/Cookie/Cookie';
import axios from "axios"
import { ReactComponent as DomainHead } from "../../Assets/DomainHead.svg";
import IconButton from "@mui/material/IconButton";
import { Close } from '@mui/icons-material';

import { ReactComponent as CheckIcon } from "../../Assets/CheckIcon.svg";
import { ReactComponent as CrossIcon } from "../../Assets/CrossIcon.svg";
import { InputText } from 'primereact/inputtext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './hideScrollbar.css';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import DOMPurify from 'dompurify';
import RejectIcon from "../../Assets/decline.png";
import PendingIcon from "../../Assets/pending.png";



import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Box,
  Typography,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Navbar from '../Navbar/Navbar';


const getRandomColor = (index) => {
  const colors = ["rgb(227, 245, 255)", "rgb(249, 236, 217)", "rgb(247, 206, 226)", "rgb(204, 202, 240)", "rgb(202, 240, 204)"
  ];
  return colors[index % colors.length];
};


function CustomTabPanel(props) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function DomainManagement() {
  const [projects, setProjects] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [domains, setDomains] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const toast = useRef(null);


  const [accessState, setAccessState] = useState('')
  const [projectAccess, setProjectAccess] = useState('')
  const [userId, setUserID] = useState('')
  const [userStatus, setEditUserStatus] = useState('')

  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [domainActivated, setDomainActivated] = useState(false)
  const [userAccess, setUserAccess] = useState()


  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const [domainType, setDomainType] = useState(0);

  const [domainData, setDomainData] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);


  const domainToDisplay = searchTerm ? filteredProjects : domains;



  const handleChange = (event, newValue) => {
    setDomainGlobalFilter('')
    setGlobalFilter('')
    setSubGlobalFilter('')

    setCurrentPage(0)
    setRows(5)
    setExpandedRows(null)
    setTabValue(newValue);

  };

  const domainTypeChange = (event, newValue) => {
    setDomainGlobalFilter('')
    setGlobalFilter('')
    setSubGlobalFilter('')

    setCurrentPage(0)
    setRows(5)
    setDomainType(newValue)
  }



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleProjectClose = () => {
    setProjectOpen(false);
    setSnackbarOpen(false);

  };


  const buttonHoverStyle = {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black'
  };


  const [dashboard, setDashboard] = useState({
    "Dashboard": "Domain Management",
    "Info": "Domain management privilege enables you to manage project-level access assigned to users."
  })

  const [allDomains, setAllDomains] = useState([])
  const fetchDomain = async () => {
    let is_active = "ALL";
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let domainResponse = await axios.get(`/backend/get_user_edit_domain_list/${is_active}`, config);
      let domainData = domainResponse.data;
      const domainSorted = domainData.sort((a, b) => {
        return a.domain_name.localeCompare(b.domain_name);
      });

      setDomains(domainData);

      let allDomainResponse = await axios.get(`/backend/get_all_domain_list/ALL`, config);
      let allDomainList = allDomainResponse.data;
      setAllDomains(allDomainList);

    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }
  };


  const [updateTable, setUpdateTable] = useState(true)


  const [userPrivileges, setUserPrivileges] = useState({})

  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserPrivileges(data)
      setIsLoaded(true)
    }
    catch (error) {

      if (error.response.status == 401) {
        const message = error.response.data.details || 'An error occurred';
      }
    }
  }

  const handleProjectAccess = async () => {
    let user_id = userId
    let access_state = userAccess
    let domain_id = domainId
    let project_id = projectName
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        }
      };
      const data = {
        domain_id: domain_id,
        project_id: project_id,
        access: access_state,
        username: user_id
      };
      const response = await axios.post('/backend/edit_user_project_access', data, config);
      setSnackbarOpen(true)
      setMessage('User Status Changed Successfully')
      setSeverity('success')
      handleUpdateTable(domain_id, false)
      setOpen(false)

    }
    catch (err) {
      const message = err.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)
    }
  }

  const handleDomainAccess = async () => {
    let user_id = userId
    let access_state = userAccess
    let domain_id = domainId
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        }
      };
      const data = {
        domain_id: domain_id,
        access: access_state,
        username: user_id
      };
      const response = await axios.post('/backend/edit_user_domain_access', data, config);
      setSnackbarOpen(true)
      setMessage('User Status Changed Successfully')
      setSeverity('success')
      fetchDomain()
      handleUpdateTable(domain_id, false)
      setOpen(false)

    }
    catch (err) {
      const message = err.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)
    }
  }
  const closeModal = (event, reason) => {
    setOpen(false)
  }

  const handleRadioChange = (project_name, user_id, status) => {
    let data = {
      [project_name]: status
    }

    setEditUserStatus(status)
    setUserAccess(status)
  };
  const [projectName, setProjectName] = useState('')
  const [pname, setPname] = useState('')
  const [domainId, setDomainId] = useState('')
  const [projectOpen, setProjectOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [previousProjectName, setPreviousProjectName] = useState('')
  const [prevUserName, setPrevUserName] = useState('')


  const handleEdit = (rowData) => {

    let project = rowData.project
    let project_name = rowData.pr_name
    setProjectName(rowData.pr_id)
    setPreviousProjectName(project_name)
    setPname(project_name)
    let result = []
    setUserID(rowData.username)
    setPrevUserName(rowData.username)
    for (const key in rowData) {
      if (key === "is_owner" || key == "is_superuser" || key == "is_view_a" || key == "is_domain_owner") {
        continue
      }
      if (typeof rowData[key] === 'boolean') {
        result.push(key);
        if (typeof rowData[key] === 'boolean' && rowData[key]) {
          setEditUserStatus(key)

          let data = {
            [project]: key
          }
          setUserAccess(key)
        }
      }

    }
    setAccessState([rowData])
    setProjectAccess(result)
    setStep(1)
    setOpen(true)

  };

  const domainAccessEdit = (rowData) => {

    let project = rowData.project
    let project_name = rowData.pr_name
    setProjectName(rowData.pr_id)
    setPname(project_name)
    let result = []
    setUserID(rowData.username)
    for (const key in rowData) {
      if (key === "is_owner" || key == "is_external" || key == "is_superuser") {
        continue
      }
      if (typeof rowData[key] === 'boolean') {
        result.push(key);
        if (typeof rowData[key] === 'boolean' && rowData[key]) {
          setEditUserStatus(key)

          let data = {
            [project]: key
          }
          setUserAccess(key)
        }
      }

    }
    setAccessState([rowData])
    setProjectAccess(result)
    setStep(2)
    setOpen(true)

  };


  const fetchAllUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/ALL`, config);
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  // Need this
  useEffect(() => {
    fetchDomain();
    fetchAllUser();
    fetchUserDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const expandAll = () => {
    let _expandedRows = {};

    projects.forEach((p) => (_expandedRows[`${p.project_id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getProjectSeverity(rowData)}></Tag>;
  };

  const getProjectSeverity = (product) => {
    switch (product.status) {
      case 'open':
        return 'info';

      case 'success':
        return 'success';

      case 'on hold':
        return 'warning';

      default:
        return null;
    }
  };


  const allowExpansion = (rowData) => {
    return rowData.order.length > 0;
  };
  const [projectId, setProjectId] = useState('')

  function getUserByUsername(users, username) {
    return users.find(user => user.username === username);
  }

  const handleProjectEdit = async (rowData) => {
    try {
      let project_owner_name = getUserByUsername(users, rowData.project_owner)

      setSelectedUser(project_owner_name)
      setPreviousProjectName(rowData.pr_name)
      setPrevUserName(rowData.project_owner)
      setProjectOpen(true)
      setNewProjectName(rowData.project_name)
      setProjectId(rowData.project_id)
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  const actionBodyTemplate = (rowData, actionType, disabled, data) => {
    const handleClick = (actionFunc) => {
      if (!disabled) {
        actionFunc(rowData);
      }
    };

    switch (actionType) {
      case 'edit':
        return (
          tabValue === 0 ? (
            <Tooltip
              title={
                (disabled && rowData.is_owner)
                  ? 'Transfer the ownership of the project to change the access level'
                  : disabled
                    ? 'User has higher level access'
                    : ''
              }
            >
              <EditIcon
                className={`${classes['icon-size']} ${classes['pi-pencil']} }`}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                onClick={() => handleClick(handleEdit)}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                disabled && rowData.is_owner
                  ? 'Transfer the ownership of the domain to change the access level'
                  : disabled
                    ? 'User has higher access'
                    : ''
              }
            >
              <EditIcon
                className={`${classes['icon-size']} ${classes['pi-pencil']} }`}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                onClick={() => handleClick(domainAccessEdit)}
              />
            </Tooltip>
          )
        );
      case 'delete':
        return (

          tabValue === 0 ? (
            <Tooltip
              title={
                (disabled && rowData.is_owner)
                  ? 'Transfer the ownership of the project to change the access level'
                  : disabled
                    ? 'User has higher level access'
                    : ''
              }
            >
              <DeleteForeverIcon
                className={`${classes.iconSize} p-button-rounded p-button-text}`}
                sx={{ color: 'red', cursor: disabled ? 'not-allowed' : 'pointer' }}
                onClick={() => !disabled && handleClick(handleDisableUser)}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={
                disabled && rowData.is_owner
                  ? 'Transfer the ownership of the domain to change the access level'
                  : disabled
                    ? 'User has higher level access'
                    : ''
              }>
              <DeleteForeverIcon
                sx={{ color: 'red', cursor: disabled ? 'not-allowed' : 'pointer' }}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                onClick={() => handleClick(domainAccessRemoved)}
              />
            </Tooltip>
          )



        );
      case 'projectEdit':
        return (
          <EditIcon className={`${classes['icon-size']} ${classes['pi-pencil']}`} style={{ cursor: 'pointer' }} onClick={() => handleProjectEdit(rowData)} />
        );
      default:
        return null;
    }
  };


  const [globalFilter, setGlobalFilter] = useState(null);
  const [subGlobalFilter, setSubGlobalFilter] = useState({});
  const [domainGlobalFilter, setDomainGlobalFilter] = useState(null);
  const [domainNames, setDomainNames] = useState(null);

  const [newProjectName, setNewProjectName] = useState('');
  const [newDomainName, setNewDomainName] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)

  const handleDomainStatus = async (id, status, domain) => {
    setDomainNames(domain.domain_name)
    handleDomain(id, status)
  }

  const handleUpdateTable = async (id, status) => {
    handleDomain(id, status);

  };


  const [externalDomainUsers, setExternalDomainUsers] = useState([])
  const [internalDomainUsers, setInternalDomainUsers] = useState([])
  const [domainOwner, setDomainOwner] = useState('')

  const handleDomain = async (id, status) => {
    setDomainId(id)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let is_active_ = "All"
      let projectResponse = await axios.get(`/backend/get_all_projects_list/${id}/${is_active_}`, config);
      let domainUserResponse = await axios.get(`/backend/get_domain_access_user_list/${id}`, config);

      let domainUsers = domainUserResponse.data.order
      setDomainOwner(domainUserResponse.data.owner)
      function moveOwnerToFront(users) {
        const ownerIndex = users.findIndex(user => user.is_owner === true);
        if (ownerIndex > -1) {
          const [owner] = users.splice(ownerIndex, 1);
          users.unshift(owner);
        }
        return users;
      }

      const reorderedUsers = moveOwnerToFront(domainUsers);
      const externalUsers = reorderedUsers.filter(user => user.is_external);
      const internalUsers = reorderedUsers.filter(user => !user.is_external);
      setExternalDomainUsers(externalUsers)
      setInternalDomainUsers(internalUsers)
      // setDomainData(domainUserResponse.data.order)
      setDomainData(reorderedUsers)
      setDomainActivated(true)
      // fetchDomain()

      setProjects(projectResponse.data)
      status && setExpandedRows(null);
    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }

  }
  const backHoverStyles = {
    color: 'white',
  };

  // Updated one owner at first 
  const rowExpansionTemplate = (data, index) => {
    // Find the matching user in the order array
    const matchingUserIndex = data.order.findIndex(user => user.username === data.project_owner);

    // Clone the order array to avoid mutating the original data
    const sortedOrder = [...data.order];

    // Move the matching user to the front of the array if found
    if (matchingUserIndex > -1) {
      const [matchingUser] = sortedOrder.splice(matchingUserIndex, 1);
      sortedOrder.unshift(matchingUser);
    }

    return (
      <div className="project-child-table">
        <DataTable
          value={sortedOrder}
          header={subheaders(data, index.index)}
          rowClassName={(rowData) => rowData.is_owner ? 'highlighted-row table-row-border-bottom' : 'table-row-border-bottom'}
          globalFilter={subGlobalFilter[index.index]}
          scrollable
          scrollHeight="300px"
          removableSort
        >
          <Column field="name" header="User" sortable headerClassName="sub-header-align" bodyClassName={classes['body-align']} style={{ width: '20%' }} />
          <Column field="view" header="View" headerClassName="sub-header-align" body={(rowData) => iconBodyTemplate(rowData, 'view')} bodyClassName={classes['body-align']} style={{ width: '20%' }} />
          <Column field="edit" header="Edit" headerClassName="sub-header-align" body={(rowData) => iconBodyTemplate(rowData, 'edit')} bodyClassName={classes['body-align']} style={{ width: '20%' }} />
          <Column field="full" header="Full" headerClassName="sub-header-align" body={(rowData) => iconBodyTemplate(rowData, 'full')} bodyClassName={classes['body-align']} style={{ width: '20%' }} />
          <Column header="Edit" body={(rowData) => actionBodyTemplate(rowData, 'edit', (rowData.username == userPrivileges.username || rowData.is_owner || rowData.is_superuser || (rowData.view && rowData.is_view_a)), data)} headerClassName="sub-header-align" bodyClassName={classes['body-align']} style={{ width: '20%' }} />
          {(userPrivileges.global_privileges.domain_creation === "full" || userPrivileges.personal_privileges?.[domainId]?.access === 'full') && (
            <Column header="Delete" body={(rowData) => actionBodyTemplate(rowData, 'delete', (rowData.username == userPrivileges.username || rowData.is_owner || rowData.is_superuser || (rowData.view && rowData.is_view_a)), data)} headerClassName="sub-header-align" bodyClassName={classes['body-align']} style={{ width: '20%' }} />
          )}
        </DataTable>
      </div>
    );
  };


  const domainAccessTable = (domainData) => {
    const matchingUser = domainData.find(user => user.is_owner == true);
    return (
      <>
        <div className={`${classes['domain-table']} `} >
          <DataTable
            value={domainData}
            header={domainSubheaders()}
            rowClassName={(rowData) => rowData.username === matchingUser?.username ? 'highlighted-row table-row-border-bottom' : 'table-row-border-bottom'}
            globalFilter={domainGlobalFilter}
            scrollable
            scrollHeight='300px'
            removableSort
            tableClassName={'domain-table'}
          >
            <Column field="name" header="User" sortable headerClassName={'sub-header-align'} bodyClassName={`${classes['body-align']}`} style={{ width: '20%' }} />
            <Column field="view" header="View" headerClassName={'sub-header-align'} body={(rowData) => iconBodyTemplate(rowData, 'view')} bodyClassName={`${classes['body-align']}`} style={{ width: '20%' }} />
            <Column field="edit" header="Edit" headerClassName={'sub-header-align'} body={(rowData) => iconBodyTemplate(rowData, 'edit')} bodyClassName={`${classes['body-align']}`} style={{ width: '20%' }} />
            <Column field="full" header="Full" headerClassName={'sub-header-align'} body={(rowData) => iconBodyTemplate(rowData, 'full')} bodyClassName={`${classes['body-align']}`} style={{ width: '20%' }} />
            {(userPrivileges.global_privileges.domain_creation != "none" || userPrivileges.username == domainOwner) &&
              <Column header="Edit" body={(rowData) => actionBodyTemplate(rowData, 'edit', ((rowData.username == userPrivileges.username) || (rowData.username === matchingUser?.username)))} headerClassName={'sub-header-align'} bodyClassName={`${classes['body-align']}`} style={{ width: '20%' }} />
            }
            {(userPrivileges.global_privileges.domain_creation == "full") &&
              <Column header="Delete" body={(rowData) => actionBodyTemplate(rowData, 'delete', (rowData.username == userPrivileges.username || rowData.is_owner || rowData.is_superuser || (rowData.view && rowData.is_view_a)), '')} headerClassName={'sub-header-align'} bodyClassName={`${classes['body-align']}`} style={{ width: '20%' }} />
            }
          </DataTable>
        </div>
      </>
    );
  };
  const header = (
    <>
    </>
  );
  const debouncedSetSubGlobalFilter = debounce((value, index, setSubGlobalFilter) => {
    setSubGlobalFilter(prevFilters => ({
      ...prevFilters,
      [index]: value
    }));
  }, 100);
  
  const handleSubGlobalFilter = useCallback((value, index) => {
    debouncedSetSubGlobalFilter(value, index, setSubGlobalFilter);
  }, []); 

  const subheaders = (data, index) => {
    return (
      <>
        <div className="table-header d-flex justify-content-end">
          {/* <h6>{data.project_name} -  {data.project_owner} </h6>   */}
          <div className="p-input-icon-left">
            {/* <i className="pi pi-search" style={{marginLeft:'1em'}} /> */}
            <InputText
              type="search"
              value={Object.keys(subGlobalFilter).length !== 0 && subGlobalFilter[index] ? subGlobalFilter[index] : ''}
              onChange={(e) => handleSubGlobalFilter(e.target.value, index)}
              placeholder="Search"
              style={{ textAlign: 'center', width: '8em', height: '1.5em', borderRadius: '5px' }}
            />
          </div>
        </div>
      </>
    )
  };

  const debouncedSetGlobalDomainFilter = useCallback(
    debounce((value) => {
      setDomainGlobalFilter(value);
    },100), 
    []
  );

  const debouncedSetGlobalFilter = useCallback(
    debounce((value) => {
      setGlobalFilter(value);
    },100), 
    []
  );

  function debounce(func, delay) {
    let timerId;
    return function(...args) {
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const handleDomainGlobalFilter = (e) =>{

    debouncedSetGlobalDomainFilter(e.target.value)
  }
  const handleGlobalFilter = (e) =>{
    debouncedSetGlobalFilter(e.target.value)
  }

  const domainSubheaders = (data) => {
    return (
      <>
        <div className="table-header d-flex justify-content-between align-items-center">
          <Box sx={{ width: '100%' }} className={classes['tab-container']}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='d-flex justify-content-between align-items-center'>
              <Tabs value={domainType} onChange={domainTypeChange} aria-label="basic tabs example">
                <Tab label="Core Domain User" {...a11yProps(0)} />
                <Tab label="External Domain User" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Box>
          {/* <h6>{data.project_name} -  {data.project_owner} </h6>   */}
          <div className="p-input-icon-left">
            {/* <i className="pi pi-search" style={{marginLeft:'1em'}} /> */}
            <InputText
              type="search"
              value={domainGlobalFilter || ''}
              onChange={handleDomainGlobalFilter}
              // onChange={(e) => setDomainGlobalFilter(e.target.value)}
              placeholder="Search"
              style={{ textAlign: 'center', width: '8em', height: '1.5em', borderRadius: '5px' }}
            />
          </div>


        </div>


      </>
    )
  };

  const handleAllDomain = () => {
    setDomainActivated(false)
    setDomainGlobalFilter('')
    setGlobalFilter('')
    setSubGlobalFilter('')
    setSearchTerm('')
    setTabValue(0)
    setCurrentPage(0)
    setRows(5)


  }



  const handleDisableUser = (userDetails) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to  Delete this  ${userDetails.username}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        disableUser(userDetails);
      }
    });
  };


  const domainAccessRemoved = (userDetails) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to  Remove   ${userDetails.username} from  the ${domainNames} Domain ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, do it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        removeDomainUser(userDetails);
      }
    });
  };

  const removeDomainUser = async (userDetails) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    const data = {
      domain_id: userDetails.domain_id,
      username: userDetails.username
    };
    try {
      const response = await axios.post('/backend/remove_user_domain_access', data, config);
      setSnackbarOpen(true)
      setMessage('User Removed Successfully')
      setSeverity('success')
      fetchDomain()
      setOpen(false)
      handleDomain(userDetails.domain_id, false)
    } catch (error) {
      const message = error.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)

    }
  }

  const iconBodyTemplate = (rowData, field) => {
    return rowData[field] ? <CheckIcon className={`${classes['icon-size']} text-success`} /> : <CrossIcon className={`${classes['icon-size']} text-danger`} />;
  };

  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('')

  const disableUser = async (userDetails) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    const data = {
      domain_id: userDetails.domain_id,
      project_id: userDetails.pr_id,
      username: userDetails.username
    };
    try {
      const response = await axios.post('/backend/remove_user_project_access', data, config);
      setSnackbarOpen(true)
      setMessage('User Removed Successfully')
      setSeverity('success')
      handleUpdateTable(userDetails.domain_id, false, userDetails)
      setOpen(false)
    } catch (error) {
      const message = error.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)

    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState(5);

  const onPageChange = (event) => {
    setCurrentPage(event.page);
    setRows(event.rows);
  };

  const handleRowToggle = (e) => {
    setExpandedRows(e.data);
  };

  const handleProject = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };

    if (newProjectName == previousProjectName && selectedUser === prevUserName) {
      setMessage('No Changes Detected to Update')
      setSnackbarOpen(true)
      setSeverity('info')
      return
    }

    if (!newProjectName) {
      setSnackbarOpen(true)
      setMessage('Please enter Project Name')
      setSeverity('error')
      return
    }
    if (!selectedUser) {
      setSnackbarOpen(true)
      setMessage('Please Select Project Owner')
      setSeverity('error')
      return
    }


    const data = {
      project_id: DOMPurify.sanitize(projectId),
      project_name: DOMPurify.sanitize(newProjectName.trim()),
      owner: selectedUser.username,
      domain_id: DOMPurify.sanitize(domainId)
    };
    try {
      const response = await axios.post('/backend/edit_owner_project', data, config);
      const user = users.find(user => user.username === selectedUser.username);
      let userDomainOwner = allDomains.filter(item => item.owner == userPrivileges.username)
      let doesUsernameExist = userDomainOwner.some(item => item.domain_id === selectedUser.core_domain);
      if (response.data.detail == "no changes to update!") {
        setMessage(response.data.detail)
      }
      else if (user.core_domain == domainId || selectedUser.username == prevUserName) {
        setMessage('Project Details Changed Successfully')
      }
      else if (user.core_domain != domainId && selectedUser.username !== prevUserName && !doesUsernameExist) {
        setMessage('Approval sent to Transfer the Project Ownership')
      }
      else {
        setMessage('Project Details Changed Successfully')
      }
      setSnackbarOpen(true)
      setSeverity('success')
      handleUpdateTable(domainId, false)
      setProjectOpen(false)

    } catch (error) {
      const message = error.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)
    }

  }

  const editDomainDetails = async () => {
    if (!newDomainName) {
      setSnackbarOpen(true);
      setMessage('Please Enter Domain Name');
      setSeverity('warning');
      return;
    }
    if (!selectedUser) {
      setSnackbarOpen(true);
      setMessage('Please Select the Domain Owner');
      setSeverity('warning');
      return;
    }
    const data = {
      domain_name: newDomainName,
      owner: selectedUser,
      domain_id: domainId,
    };

    try {
      const response = await axios.post(`/backend/edit_domain`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('dash_access_token')}`,
        },
      });
      if (response.status === 200) {
        setOpen(false);
        setDomainNames(newDomainName)
        setStep(0)
        Swal.fire({
          title: 'Domain Details',
          text: 'Domain Details Updated Successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      setOpen(false);
      Swal.fire({
        title: 'Domain Details',
        text: `${err.response.data.detail || 'Unknown error'}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };


  const handleDomainEdit = async () => {
    try {
      let domainOwner = domainData.find(user => user.is_owner === true);
      setSelectedUser(domainOwner.username)
      setNewDomainName(domainNames)

      setProjectOpen(true)
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  const handleDomainDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    const data = {
      domainId: DOMPurify.sanitize(newDomainName.trim()),
      owner: DOMPurify.sanitize(selectedUser),
      domain_id: DOMPurify.sanitize(domainId)
    };

    try {
      try {
        const response = await axios.post(`/backend/edit_domain`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie('dash_access_token')}`,
          },
        });
        if (response.status === 200) {
          setOpen(false);
          setDomainNames(newDomainName)

          Swal.fire({
            title: 'Domain Details',
            text: 'Domain Details Updated Successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        }
      } catch (err) {
        setOpen(false);
        Swal.fire({
          title: 'Domain Details',
          text: `${err.response.data.detail || 'Unknown error'}`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      const message = error.response.data.detail || 'An error occurred';
      setSnackbarOpen(true)
      setSeverity('error')
      setSnackbarOpen(true)
      setMessage(message)
    }

  }


  const sortedUsers = Array.isArray(users) ? users.slice().sort((a, b) => {
    if (domainId == null) {
      return []
    }
    if (a.core_domain === domainId) return -1;
    if (b.core_domain === domainId) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;


  const [projectCard, setProjectCard] = useState(true)



  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);
    const filtered = domains.filter((domain) => {
      const searchTermLower = searchTerm.toLowerCase();
      if ((domain.domain_name.toLowerCase().includes(searchTermLower) || domain.owner_name.toLowerCase().includes(searchTermLower))) {
        return true;
      }


      return false;
    });



    setFilteredProjects(filtered);
  };


  const assignToTemplate = (rowData) => {
    const isRejected = rowData && rowData.is_owner_approved === 0;
    const isPending = rowData && rowData.is_owner_approved === -1;

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* {isRejected && (
          <img title={"Approval rejected"} src={RejectIcon} alt="Reject Icon" style={{ width: '20px', height: '20px', marginRight: '8px', cursor: 'pointer' }} />
        )}
        {isPending && (
          <img title={"Approval pending"} src={PendingIcon} alt="Pending Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        {!isPending && !isRejected && (
          <div style={{ width: '20px', height: '20px', marginRight: '8px' }} ></div>
        )} */}
        <span style={{ width: '80%', textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap', textAlign: 'start' }}>{rowData.project_owner_name}</span>
      </div>
    );
  };



  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        maxWidth="sm"
        fullWidth

        sx={{ borderRadius: '1rem', padding: 0 }}

        classes={{
          paper: classes.dialogPaper,
        }}
      >
        {step == 1 &&
          (
            <>
              <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', padding: '0.25em', paddingBottom: '20px' }}>
                <div className="d-flex align-items-center">
                  <div style={{ width: '100%' }} className={`${classes['custom-input-container']} ${classes['global-privilege-container']} d-flex  flex-column justify-content-between align-items-center`}>
                    <div style={{ width: '96%' }} className='d-flex justify-content-between align-items-center mr-1 mt-2'>
                      <div style={{ fontWeight: 'bold' }}>Change the Access Level for the {pname} Project</div>
                      <div>
                        <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close" style={{ textAlign: 'end' }}>
                          <Close />
                        </IconButton>
                      </div>

                    </div>
                    <div className={`d-flex flex-wrap mt-2 ${classes['custom-table']}`} style={{ width: '100%' }}>

                      <table >
                        <thead>
                          <tr style={{ textAlign: 'center' }}>
                            <th>username</th>
                            {projectAccess && projectAccess.map((value, index) =>
                              <th
                                key={index}
                              >{value}</th>
                            )}

                          </tr>
                        </thead>
                        <tbody>

                          {accessState && accessState.map((privilegeObj, index) => {
                            let project_name = privilegeObj['project']
                            let user_id = privilegeObj['id']
                            // let username = privilegeObj['username']
                            let username = privilegeObj['name']
                            return (
                              <tr key={index} className={`${classes['access-name']}`} >
                                <td className={`${classes['function-name']}`}>{username}</td>
                                <td><input type="radio" name={project_name} checked={userStatus === 'view'} onChange={() => handleRadioChange(project_name, user_id, 'view')} /></td>
                                <td><input type="radio" name={project_name} checked={userStatus === 'edit'} onChange={() => handleRadioChange(project_name, user_id, 'edit')} /></td>
                                <td><input type="radio" name={project_name} checked={userStatus === 'full'} onChange={() => handleRadioChange(project_name, user_id, 'full')} /></td>
                              </tr>
                            );
                          })}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }} className='mt-3'>
                  <Button variant="contained" onClick={handleProjectAccess} style={{ borderRadius: '5px', backgroundColor: 'rgb(9, 94, 173)' }}>
                    Save
                  </Button>
                </div>


              </DialogContent>
            </>
          )}
        {step == 2 &&
          <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%', padding: '0.25em', paddingBottom: '20px' }}>
            <div className="d-flex align-items-center">
              <div style={{ width: '100%' }} className={`${classes['custom-input-container']} ${classes['global-privilege-container']} d-flex  flex-column justify-content-between align-items-center`}>
                <div style={{ width: '96%' }} className='d-flex justify-content-between align-items-center mr-1 mt-2'>
                  <div style={{ fontWeight: 'bold' }}>Change the Access Level for the {domainNames} Domain Users</div>
                  <div>
                    <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close" style={{ textAlign: 'end' }}>
                      <Close />
                    </IconButton>
                  </div>

                </div>
                <div className={`d-flex flex-wrap mt-2 ${classes['custom-table']}`} style={{ width: '100%' }}>

                  <table >
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        <th>username</th>
                        {projectAccess && projectAccess.map((value, index) =>
                          <th>{value}</th>
                        )}

                      </tr>
                    </thead>
                    <tbody>

                      {accessState && accessState.map((privilegeObj, index) => {
                        let project_name = privilegeObj['project']
                        let user_id = privilegeObj['id']
                        let username = privilegeObj['username']
                        return (
                          <tr key={index} className={`${classes['access-name']}`} >
                            <td className={`${classes['function-name']}`}>{username}</td>
                            <td><input type="radio" name={project_name} checked={userStatus === 'view'} onClick={() => handleRadioChange(project_name, user_id, 'view')} /></td>
                            <td><input type="radio" name={project_name} checked={userStatus === 'edit'} onClick={() => handleRadioChange(project_name, user_id, 'edit')} /></td>
                            <td><input type="radio" name={project_name} checked={userStatus === 'full'} onClick={() => handleRadioChange(project_name, user_id, 'full')} /></td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'center' }} className='mt-3'>
              <Button variant="contained" onClick={handleDomainAccess} style={{ borderRadius: '5px', backgroundColor: 'rgb(9, 94, 173)' }}>
                Save
              </Button>
            </div>


          </DialogContent>
        }

      </Dialog>


      <Dialog open={projectOpen} onClose={handleProjectClose}>

        {tabValue == 0 ? (
          <>
            <DialogTitle style={{ padding: 0, margin: '0 0.65em' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid black', padding: '9px 0' }}>
                <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>Edit Project</Typography>
                <IconButton onClick={handleProjectClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent style={{ width: '35em', height: '15em', paddingBottom: 0 }} className={'d-flex flex-column justify-content-evenly '}>
              <Box className='d-flex justify-content-evenly align-items-center'>
                <Box style={{ width: '45%' }}>
                  <Typography variant="subtitle1"> Input Project Name<span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    style={{ width: '100%', }}
                    variant="outlined"
                    // label="Domain Name"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    margin="normal"
                    required
                    size="small"
                  >{newProjectName} </TextField>
                </Box>
                <Box style={{ width: '45%' }}>
                  <Typography variant="subtitle1">Transfer Ownership <span style={{ color: 'red' }}>*</span></Typography>
                  <Autocomplete
                    options={sortedUsers}
                    groupBy={(option) => option.domain_name}
                    // getOptionLabel={(option) => option.username}
                    getOptionLabel={(option) => option.name}
                    value={selectedUser}
                    onChange={(event, newValue) => setSelectedUser(newValue)}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select User"
                        variant="outlined"
                        margin="normal"
                        required
                        size="small"
                        style={{ width: '100%' }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" style={{ borderRadius: '5px', backgroundColor: 'rgb(9, 94, 173)', "&:hover": buttonHoverStyle }} onClick={handleProject}>
                  Save
                </Button>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle style={{ padding: 0, margin: '0 0.65em' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid black', padding: '9px 0' }}>
                <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>Edit Domain Details</Typography>
                <IconButton onClick={handleProjectClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent style={{ width: '35em', height: '15em', paddingBottom: 0 }} className={'d-flex flex-column justify-content-evenly '}>
              <Box className='d-flex justify-content-evenly align-items-center'>
                <Box style={{ width: '45%' }}>
                  <Typography variant="subtitle1"> Input Domain Name<span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    style={{ width: '100%', }}
                    variant="outlined"
                    // label="Domain Name"
                    value={newDomainName}
                    onChange={(e) => setNewDomainName(e.target.value)}
                    margin="normal"
                    required
                    size="small"
                  >{newProjectName} </TextField>
                </Box>
                <Box style={{ width: '45%' }}>
                  <Typography variant="subtitle1">Transfer Ownership <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    style={{ width: '100%', }}
                    select
                    variant="outlined"
                    label="Select User"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    margin="normal"
                    size="small"
                    required
                  >
                    {users && users.map((user) => (
                      <MenuItem key={user.user_id} value={user.username}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" sx={{ backgroundColor: '#FFC50B', color: 'black', fontWeight: 'bold', "&:hover": buttonHoverStyle }} onClick={handleDomainDetails}>
                  Save
                </Button>
              </Box>
            </DialogContent>
          </>

        )}

      </Dialog>




      <div className={classes['content']}>
        <Navbar dashboard={dashboard} setIsLoaded={setIsLoaded} isLoaded={isLoaded} userDetails={userPrivileges} />
        {isLoaded ? (
          <>
            {!domainActivated && (
              <>
                <div style={{ paddingRight: '3em', textAlign: 'end', paddingTop: '1em' }}>
                  <TextField
                    style={{ width: '12em' }}
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearch}
                    size='small'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className={classes['domain-container']}>



                  <div className="container d-flex flex-wrap ">
                    {/* {domains.map((domain, index) => ( */}
                    {domainToDisplay.map((domain, index) => (
                      <div key={domain.domain_id} className={`card m-3`} style={{ width: '14em', height: '15em', border: '0.5px solid grey', cursor: 'pointer' }} onClick={() => { handleDomainStatus(domain.domain_id, true, domain) }}>
                        <div
                          className="card-header text-black d-flex justify-content-center align-items-center"
                          style={{ backgroundColor: getRandomColor(index), cursor: 'pointer', borderBottom: '0.5px solid grey', fontWeight: '600', width: '100%' }}
                        >
                          <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "100%" }}>
                            {domain.domain_name}
                          </div>



                        </div>
                        <div>

                          <div className='d-flex align-items-center' style={{ height: '3em' }}>
                            <div style={{ fontSize: '1.25em', width: '30%', fontWeight: 'bold' }}>
                              {domain.on_going_count}
                            </div>
                            <div>
                              On Going Project
                            </div>
                          </div>

                          <div className='d-flex align-items-center' style={{ height: '3em' }}>
                            <div style={{ fontSize: '1.25em', width: '30%', fontWeight: 'bold' }}>
                              {domain.on_hold_count}
                            </div>
                            <div>
                              On Hold Project
                            </div>
                          </div>

                          <div className='d-flex align-items-center' style={{ height: '3em' }}>
                            <div style={{ fontSize: '1.25em', width: '30%', fontWeight: 'bold' }}>
                              {domain.success_count}
                            </div>
                            <div>
                              Successful Project
                            </div>
                          </div>
                        </div>
                        <div className="card-body d-flex  align-items-center p-0">
                          {/* <p className="card-text"> */}
                          <DomainHead style={{ width: '30%', height: '1.5em', marginLeft: '0.1em' }} />
                          <div>
                            {domain.owner_name}
                          </div>

                          {/* </p> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {domainActivated && (
              <>
                <div >
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}
                  >
                    <Tab label="Project Access Mangement" {...a11yProps(0)}
                      sx={{
                        backgroundColor: tabValue === 0 ? 'rgb(227, 242, 253)' : '',
                        boxShadow: tabValue === 0 ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '',
                      }}
                    />
                    <Tab label="Domain Access Management" {...a11yProps(1)}
                      sx={{
                        backgroundColor: tabValue === 1 ? 'rgb(227, 242, 253)' : '',
                        boxShadow: tabValue === 1 ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '',
                      }}
                    />
                  </Tabs>
                </div>
                <div className={`${classes['domain-back-container']} mt-2 d-flex justify-content-between align-items-center`} >
                  <div className={`${classes['domain-back']}`} onClick={handleAllDomain} >
                    <ArrowCircleLeftIcon style={{ color: 'black', "&:hover": backHoverStyles }} />
                  </div>
                  <div style={{ fontSize: '1.25em', fontWeight: 'bold' }}>{domainNames} Domain</div>
                  <div>

                    {tabValue == 0 ? (
                      <>
                        <div className="table-header d-flex justify-content-end" style={{ zIndex: '10000' }}>
                          <div className="flex flex-wrap justify-content-end gap-2 mr-2" style={{ height: '1.5em' }}>
                            <Button style={{ height: '100%' }} title="Expand All Projects" icon="pi pi-plus" onClick={expandAll} text />
                            <Button style={{ height: '100%' }} title="Collapse All Projects" icon="pi pi-minus" onClick={collapseAll} text />
                          </div>
                          <div className="p-input-icon-left" style={{ marginLeft: '0.25em', marginRight: '0.5em' }}>
                            {/* <i className="pi pi-search" style={{marginLeft:'0em'}} /> */}
                            <InputText
                              type="search"
                              value={globalFilter || ''}
                              // onChange={(e) => setGlobalFilter(e.target.value)}
                              onChange={handleGlobalFilter}
                              placeholder="Search"
                              style={{ textAlign: 'center', width: '6em', height: '1.5em', borderRadius: '10px' }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}




                  </div>
                </div>



                {tabValue == 0 ? (
                  <>
                    <div className={classes['project-card']}>
                      {/* <Toast ref={toast} /> */}
                      <DataTable
                        scrollable
                        scrollHeight="31em"
                        value={projects} expandedRows={expandedRows}
                        onRowToggle={handleRowToggle}
                        rowExpansionTemplate={rowExpansionTemplate}
                        rowClassName={() => 'table-row-border-bottom'}
                        dataKey="project_id" header={header} tableStyle={{ minWidth: '4%' }} headerClassName={'header-align parent-dataTable-header'} bodyClassName={`${classes['body-align']}`}
                        globalFilter={globalFilter}
                        paginator
                        className={'parent-dataTable'}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown "
                        rows={rows}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        tableClassName={'parent-table'}
                        removableSort

                      >
                        <Column expander={allowExpansion} headerClassName={'header-align'} />
                        <Column field="project_name" header="Project Name" sortable tableStyle={{ width: '19%' }} headerClassName={'header-align'} bodyClassName={`${classes['body-align']}`} />
                        <Column field="project_owner_name" body={assignToTemplate} header="Project Owner" sortable tableStyle={{ width: '19%' }} headerClassName={'header-align'} bodyClassName={`${classes['body-align']}`} />
                        <Column field="created_by" header="Created By" sortable tableStyle={{ width: '19%' }} headerClassName={'header-align'} bodyClassName={`${classes['body-align']}`} />
                        <Column field="total_user" header="Total User" sortable tableStyle={{ width: '19%' }} headerClassName={'header-align'} bodyClassName={`${classes['body-align']}`} />
                        <Column header="Edit" body={(rowData) => actionBodyTemplate(rowData, 'projectEdit')} headerClassName={'header-align'} bodyClassName={`${classes['body-align']}`} style={{ width: '10%' }} />
                        <Column field="status" header="Project Status" sortable tableStyle={{ width: '19%' }} body={statusBodyTemplate} headerClassName={'header-align'} bodyClassName={`${classes['body-align']}`} />
                      </DataTable>
                    </div>
                  </>

                ) : (
                  <>
                    {domainType == 0 ? (
                      domainAccessTable(internalDomainUsers)
                    ) : (
                      domainAccessTable(externalDomainUsers)
                    )}
                  </>
                )}

              </>


            )}
          </>
        ) : (
          <>
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </div>
          </>
        )
        }
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>

  );
}
