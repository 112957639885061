import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import styles from './RejectionReasonDialog.module.css';
import { getCookie } from '../../Services/Cookie/Cookie';
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const RejectionReasonDialog = ({ open, onClose ,projectData ,soloClose,setMessage,setSeverity,setNotificationSnackbar}) => {
  const [reason, setReason] = useState('');
  const [rejectOpen,setRejectOpen] = useState(open)

//   useEffect(() => {
// setRejectOpen(open)
//   }, [])
  
  const handleSave = async(projectData) => {

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        }
      };
      const data={
        approval_id:projectData.approval_id,
        approval_status:0,
        remark:reason
      }
      try {
        const response = await axios.post(`/backend/update_approval`, data,config);
        onClose();
        setNotificationSnackbar(true)
        setMessage('Approval Status Updated Successfully')
        setSeverity('success')
      } catch (error) {
      }
  
  
  };

  const rejectClose = () =>{
    setRejectOpen(false)
  }

  return (
    <Dialog
      // open={rejectOpen}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          minWidth: '500px',
          maxWidth: '500px',
        },
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        <div style={{borderBottom:'1px solid black'}}>Rejection Reason </div>
        {/* <Button onClick={onClose}>Cancel</Button> */}
        <IconButton onClick={soloClose} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>

        </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <div >
        <TextField
          className={styles.dialogTextField}
          autoFocus
          margin="dense"
          id="reason"
          label="Reason for Rejection"
          type="text"
          fullWidth
          multiline
          rows={4} // Adjust the number of rows as needed
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        </div>

      </DialogContent>
      <DialogActions className={`${styles['dialogActions']} mb-2`}>
        {/* <Button onClick={onClose}>Cancel</Button> */}
        <div>
        <Button  sx={{backgroundColor: 'rgb(9, 94, 173) !important',
                  color: 'white', fontWeight: '400', '&:hover': { backgroundColor: 'rgb(9,99,175)'}}} onClick={()=>{handleSave(projectData)}} >Send</Button>

{/* className={styles.buttonSave} */}
        </div>

      </DialogActions>
    </Dialog>
  );
};

export default RejectionReasonDialog;

