import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import classes from './EditUser.module.css';

import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";

import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { getCookie } from "../../Services/Cookie/Cookie"
import Swal from 'sweetalert2';
import DOMPurify, { removeHooks } from 'dompurify';

import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';




const EditUser = (props) => {
    const [editClicked, setEditClicked] = useState({
        skillSet: false,
        coreDomain: false,
        globalPrivilege: false
    });

    const [containerEnabled, setContainerEnabled] = useState({
        skillSet: false,
        coreDomain: false,
        globalPrivilege: false,
    });

    const [arrowDirections, setArrowDirections] = useState({
        skillset: 'down',
        coreDomain: 'down',
        globalPrivilege: 'down'
    });

    const [selectedValues, setSelectedValues] = useState({});
    const [skills, setSkills] = useState({});
    const [globalPrivileges, setGlobalPrivileges] = useState([]);
    const [coreDomain, setCoreDomain] = useState([]);
    const [activeSection, setActiveSection] = useState(["coreDomain", "skillset", "globalPrivilege"]);
    const [skillLevel, setSkillLevel] = useState([]);
    const [open, setOpen] = useState(false);
    const [abbrevationSkill, setAbbrevationSkill] = useState(false);
    const [finalSkill, setFinalSkill] = useState({});
    const [domain, setDomain] = useState();
    const [finalDomain, setFinalDomain] = useState({});
    const [modalOpenIndex, setModalOpenIndex] = useState(null);
    const [abbrivation, setAbbrevation] = useState({});
    const [skillCheck, setSkillCheck] = useState({});
    const [skillLevelCheck, setSkillLevelCheck] = useState({});
    const [defaultOptionSet, setDefaultOptionSet] = useState(false);
    const [globalAccess, setGlobalAccess] = useState();

    const [level, setLevel] = useState({})






    // useEffect(() => {
    //     fetchData()
    // }, [props.editingUser.username,props.editingUser.email,props.editingUser.skill_set, props.editingUser.core_domain, props.editingUser.globalprivileges]);

    useEffect(() => {
        fetchData();
    }, []);
    const [userName, setUserName] = useState()
    const [email, setEmail] = useState()
    const [createdBy, setCreatedBy] = useState('')
    const [lastEditedBy, setLastEditedBy] = useState('')

    const handleUserDetails = async (event) => {
        setUserName(props.editingUser.username);

        if (!finalSkill || Object.keys(finalSkill).length === 0) {
            props.setSnackbarOpen(true);
            props.setMessage('Please select the Skill Set of the User');
            props.setSeverity('error');
            return;
        }

        if (!finalDomain || Object.keys(finalDomain).length === 0) {
            props.setSnackbarOpen(true);
            props.setMessage('Please Select a Core Domain');
            props.setSeverity('error');
            return;
        }

        const data = {
            username: DOMPurify.sanitize(props.editingUser.username),
            is_active: "1",
            core_domain: DOMPurify.sanitize(Object.keys(finalDomain)[0]),
            skill_set: finalSkill,
            global_privileges: selectedValues,
            password: '',
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie("dash_access_token")}`,
            }
        };

        try {
            let response = await axios.post('/backend/dh-edit-user', data, config);
            props.setSnackbarOpen(true);
            props.setMessage('Details Saved Successfully');
            props.setSeverity('success');
            props.setShowModal(false);
            props.setUpdated(prev => prev + 1);
        } catch (error) {
            props.setSnackbarOpen(true);
            props.setMessage('An error occurred while saving the details');
            props.setSeverity('error');
        }
    };


    const transformData = (input) => {
        const [obj] = input; // Extract the single object from the array
        return Object.keys(obj).map(key => ({ [key]: obj[key] }));
    };


    const [editModal, setEditModal] = useState(false)
    const fetchData = async () => {

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("dash_access_token")}`,

            },
        };
        let is_active = 1
        const response = await axios.get("/backend/get_all_options", config)
        const domainResponse = await axios.get(`/backend/get_all_domain_list/${is_active}`, config)
        let domainData = domainResponse.data
        const modifyDomain = domainData.map(item => ({ [item.domain_id]: item.domain_name }));
        let data = response.data
        const skillsResponse = transformData([data['skill-set']]);

        const privilegesResponse = transformData([data['global_privileges']]);
        // const coreDomain = [{ 50: 'Retail' }, { 20: 'BRIO' }, { 35: 'Viaje' }];
        const skillLevel = transformData([data['skill-levels']]);
        const globalAccess = data['access-level']


        setGlobalAccess(globalAccess)
        setSkills(skillsResponse);
        setGlobalPrivileges(privilegesResponse);
        setCoreDomain(modifyDomain);
        setSkillLevel(skillLevel);
        // const userResponse = await axios.get(`/backend/get_privileges/`,config)
        const userResponse = await axios.get(`/backend/get_privileges_for_user/${props.editingUser.user_id}`, config)
        let userData = userResponse.data
        // setFinalSkill(props.editingUser.skill_set);
        // setFinalDomain(props.editingUser.core_domain);
        // setSelectedValues(props.editingUser.global_privileges);
        setFinalSkill(userData.skill_set);
        setFinalDomain(userData.core_domain);
        setSelectedValues(userData.global_privileges);

        setCreatedBy(`${userData.created_by} - ${userData.created_at}`)
        setLastEditedBy(`${userData.last_edited_by} - ${userData.last_edited_timestamp}`)


        let a = skillsResponse;
        let b = userData.skill_set;
        let c = skillLevel
        let d = {}


        a.forEach((item, index) => {
            const [key, value] = Object.entries(item)[0];
            if (b.hasOwnProperty(key)) {
                const bValue = b[key];
                c.forEach((level, levelIndex) => {
                    if (Object.keys(level)[0] === bValue) {
                        d[index.toString()] = { [bValue]: levelIndex };
                    }
                });
            }
        });
        // setDomain()
        setAbbrevation(d)
        setAbbrevationSkill(true)
        // const index = modifyDomain.findIndex(obj => Object.keys(obj)[0] === props.editingUser.core_domain);
        const index = modifyDomain.findIndex(obj => Object.keys(obj)[0] === userData.core_domain);
        if (index !== -1) {
            let mDomain = { [userData.core_domain]: modifyDomain[index][userData.core_domain] };
            setFinalDomain(mDomain)
            setDomain(index + 1)
        }

        const result = {};

        Object.keys(b).forEach(key => {
            // Find the index of the key in the skillsList array
            const index = a.findIndex(item => item[key] !== undefined);
            if (index !== -1) {
                // Add the index as the key and the corresponding value from data as the value
                result[index] = b[key];
            }
        });
        setSkillLevelCheck(result)
        setLevel(result)


        setEditModal(true)
        props.setEditLoaded(false)
    };

    const handleEditClick = (section) => {
        setEditClicked(prevState => ({
            ...prevState,
            [section]: true
        }));

        setContainerEnabled(prevState => ({
            ...prevState,
            [section]: true
        }));

    };

    const handleCloseClick = (section) => {
        setEditClicked(prevState => ({
            ...prevState,
            [section]: false
        }));

        setContainerEnabled(prevState => ({
            ...prevState,
            [section]: false
        }));
    };

    const handleEditUser = () => {
        props.setShowModal(true);
    };

    const [swalActive,setSwalActive] = useState(false)

    const handleCloseModal = async() => {

            if (swalActive) {
              const title = 'Unsaved Changes';
              const confirmButtonText = 'OK';
              const cancelButtonText = 'Cancel';
          
              const result = await Swal.fire({
                title,
                text: 'Do you want to exit ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText,
                cancelButtonText,
                customClass: {
                  container: 'custom-swal-container'
                }
              });
          
              if (!result.isConfirmed) {
                return;
              }
              else{

                  props.setShowModal(false);
                  setSwalActive(false)
              }
            }
            else{
                props.setShowModal(false);
                setSwalActive(false)
            }   
    };

    const handleArrowClick = (section) => {
        if (activeSection.length === 1) {
            setActiveSection(["coreDomain", "skillset", "globalPrivilege"]);
        } else {
            setActiveSection([section]);
        }

        setArrowDirections(prevDirections => {
            // Create a copy of the previous state
            const newDirections = { ...prevDirections };

            // Toggle the direction of the specific section
            newDirections[section] = prevDirections[section] === 'down' ? 'up' : 'down';


            // Return the updated state
            return newDirections;
        });


    };

    const handleRadioChange = (functionName, value) => {
        setSwalActive(true)
        setSelectedValues(prevValues => ({
            ...prevValues,
            [functionName]: value
        }));
    };

    const chipStyles = {
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        padding: '0.2em',
        margin: '0.5em',
        cursor: 'pointer',
        border: '1px solid black',
        borderRadius: '20px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    };

    const chipHoverStyle = {
        backgroundColor: '#EEEEEE'
    };

    const closeModal = (event, reason) => {

        setSkillLevelCheck(level)
        setOpen(false);
        setAbbrevationSkill(true);
        setSkillButton(true)
        // setEditModal(false)
    };

    const handleEditModal = () => {
        props.setShowModal(true)
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const [skillName, setSkillName] = useState('')

    const handleSkillLevelClick = (index, value, key) => {
        setSkillName(value)
        setOpen(true)
        setModalOpenIndex(index)

        const filteredState1 = Object.keys(skillLevelCheck)
            .filter(key => key in level)
            .reduce((obj, key) => {
                obj[key] = skillLevelCheck[key];
                return obj;
            }, {});
        setSkillLevelCheck(filteredState1);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleSkillClick = (key, skill, skillLevelIndex) => {
        setSkillCheck({});
        setSkillCheck((prevSkillCheck) => ({
            ...prevSkillCheck,
            [key]: skillLevelIndex
        }));
        setSkillLevelCheck((prevState) => ({
            ...prevState,
            [modalOpenIndex]: key
        }));
        setSkillButton(false)
    };

    const handleSkill = (keyToRemove, index, indexKey, skillIndex, skillLabel, skillName) => {
        setSwalActive(true)
        setAbbrevation(prevState => {
            const newState = { ...prevState };
            if (newState[keyToRemove]) {
                delete newState[keyToRemove];
            }
            return newState;
        });
        setSkillLevelCheck(prevState => {
            const newState = { ...prevState };
            delete newState[keyToRemove]
            return newState
        })
        setLevel(prevState => {
            const newState = { ...prevState };
            delete newState[keyToRemove]
            return newState
        })
        setFinalSkill(prevState => {
            const newState = { ...prevState };
            delete newState[indexKey]
            return newState
        })

    }


    const addSkill = () => {
        setAbbrevation((prevState) => ({
            ...prevState,
            [modalOpenIndex]: skillCheck
        }));

        setLevel((prevState) => ({
            ...prevState,
            [modalOpenIndex]: Object.keys(skillCheck)[0]
        }));

        setAbbrevationSkill(true);

        setFinalSkill((prevValues) => ({
            ...prevValues,
            [Object.keys(skills[modalOpenIndex])[0]]: Object.keys(skillCheck)[0]
        }));

        setOpen(false)
        setSwalActive(true)
        // closeModal();
    };

    const handleDomain = (value) => {
        setSwalActive(true)
        setDomain()
        setFinalDomain({})
    };




    const handleCoreDomain = (index) => {
        setSwalActive(true)
        setDomain(index + 1)
        setFinalDomain(coreDomain[index])
    }




    const [skillButton, setSkillButton] = useState(true)

    const [globalInfo, setGlobalInfo] = useState({
        "domain_management": "Manage Domain",
        "user_management": "Manage User",
        "sprint": "create Sprint",
        "notes": "create Notes",
        "domain_creation": "Create Domain",
        "perf_score": "performance score"
    })
    return (
        <>
            <Dialog
                open={open}
                onClose={closeModal}
                maxWidth="sm"
                fullWidth
                sx={{ borderRadius: '1rem' }}
                style={{ top: '-10%' }}
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '20em' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>Select the Skillset Level for  <strong> {skillName} </strong> Skill</div>
                        <div>
                            <IconButton edge="end" color="inherit" onClick={() => closeModal()} aria-label="close">
                                <Close />
                            </IconButton>
                        </div>

                    </div>
                    <div className="d-flex ">
                        <div className={`${classes['custom-input-container-enabled']} ${classes['chip-container']} d-flex flex-column justify-content-around`}>
                            <div className='d-flex justify-content-between  flex-column flex-wrap' style={{ width: '100%' }}>
                                {skillLevel && skillLevel.map((skill, index) => {
                                    const key = Object.keys(skill)[0];
                                    const value = skill[key];
                                    const isActive = skillLevelCheck.hasOwnProperty(modalOpenIndex) && skillLevelCheck[modalOpenIndex] === key;
                                    const activeStyle = { cursor: 'pointer', borderBottom: '1px solid black', color: 'black', fontWeight: 'bold', backgroundColor: '#EEEEEE' };
                                    const defaultStyle = { cursor: 'pointer', borderBottom: '1px solid black', color: '#898383' };

                                    return (
                                        <div
                                            key={index}
                                            style={isActive ? activeStyle : defaultStyle}
                                            onClick={() => handleSkillClick(key, value, index)}
                                            className={classes['skill-level']}
                                        >
                                            {value}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained" onClick={addSkill} disabled={skillButton}>
                            <AddIcon sx={{ color: 'white', fontWeight: 'bold' }} /> Add
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {/* <div> */}
            <Dialog
                // open={props.showModal}
                open={editModal}
                onClose={handleEditModal}

            >
                <DialogContent>
                    {/* {props.showModal && ( */}
                    <div className={`${classes['edit-modal']} modal fade show`} style={{ display: 'block' }} tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content" style={{ height: '90vh' }}>
                                <div class="modal-header p-0 d-flex justify-content-between px-3 align-items-center">
                                    <h5 class="modal-title">Edit User Details </h5>
                                    <div style={{ textAlign: 'end' }}><button type="button" className="btn-close m-3" onClick={handleCloseModal}></button></div>
                                </div>
                                <div className={` modal-body  ${classes['user-modal']}`}>
                                    <div className={`${classes['user-info']}`}>
                                        <TextField
                                            size="small"
                                            id="user-name"
                                            label="Name"
                                            value={props.editingUser.name}
                                            sx={{ backgroundColor: 'white' }}
                                            disabled
                                        />
                                        <TextField

                                            size="small"
                                            id="user-email"
                                            label="Email"
                                            value={props.editingUser.username}
                                            sx={{ backgroundColor: 'white' }}
                                            disabled
                                        />
                                        <TextField

                                            size="small"
                                            id="user-name"
                                            label="Created By - Date"
                                            // value={`${props.editingUser.created_by} - ${props.editingUser.created_at}`}
                                            value={createdBy}
                                            sx={{ backgroundColor: 'white' }}
                                            disabled
                                        />
                                        <TextField

                                            size="small"
                                            id="user-email"
                                            label="Last Edited By - Date"
                                            // value={`${props.editingUser.last_edited_by} - ${props.editingUser.last_edited_timestamp}`}
                                            value={lastEditedBy}
                                            sx={{ backgroundColor: 'white' }}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <div className={`d-flex justify-content-between mt-4`}>
                                            <div>Skillset<span style={{ color: 'red' }}>*</span></div>
                                            <div>
                                                {!editClicked.skillSet && (<EditIcon sx={{ cursor: 'pointer' }} onClick={() => { handleEditClick('skillSet'); handleArrowClick('skillset'); }} />)}
                                            </div>
                                        </div>
                                        {(arrowDirections.skillset === 'down' || arrowDirections.skillset === 'up') && (
                                            <div className="d-flex flex-column align-items-center mt-1">
                                                <div className={`${containerEnabled.skillSet ? classes['custom-input-container-enabled'] : classes['custom-input-container']} d-flex justify-content-between align-items-center`}>
                                                    {Object.keys(abbrivation).length > 0 && abbrevationSkill ? (
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                <div className={`d-flex flex-start flex-wrap`}>
                                                                    {Object.entries(abbrivation).map(([key, value]) => {
                                                                        const index = key;
                                                                        const indexKey = Object.keys(skills[key])[0];
                                                                        const skillIndex = Object.values(value)[0];
                                                                        const skillLabel = skillLevel[skillIndex] ? Object.values(skillLevel[skillIndex])[0] : '';
                                                                        const skillName = skills[key] ? Object.values(skills[key])[0] : '';

                                                                        return (
                                                                            <div className={classes['skill-container']} style={{ ...chipStyles, "&:hover": chipHoverStyle }}>
                                                                                <Chip
                                                                                    key={key}
                                                                                    label={
                                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                            <Box
                                                                                                sx={{
                                                                                                    width: 24,
                                                                                                    height: 24,
                                                                                                    borderRadius: '50%',
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'center',
                                                                                                    backgroundColor: '#f0f0f0',
                                                                                                    marginRight: 1,
                                                                                                    fontSize: 14,
                                                                                                }}
                                                                                            >
                                                                                                {skillLabel[0]}
                                                                                            </Box>
                                                                                            {skillName}
                                                                                        </Box>
                                                                                    }
                                                                                    variant="outlined"
                                                                                    sx={{ margin: 0, padding: 0, border: 'none' }}
                                                                                    aria-hidden="false"
                                                                                />
                                                                                {containerEnabled.skillSet && (
                                                                                    <CancelIcon onClick={() => { handleSkill(key, index, indexKey, skillIndex, skillLabel, skillName); }} />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className='d-flex justify-content-between align-item-center' style={{ width: '100%' }}>
                                                                No Skillset Selected
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {arrowDirections.skillset === 'up' && (
                                            <div className="d-flex mt-1">
                                                <div className={`${classes['custom-input-container-enabled']} ${classes['chip-container']} d-flex flex-column justify-content-around`}>
                                                    <div className='right' style={{ alignSelf: 'flex-end' }}>
                                                        <IconButton edge="end" color="inherit" onClick={() => { handleArrowClick('skillset'); handleCloseClick('skillSet'); }} aria-label="close">
                                                            <Close />
                                                        </IconButton>
                                                    </div>
                                                    <div className='d-flex j flex-wrap' style={{ width: '100%' }}>
                                                        {skills.map((skill, index) => {
                                                            const [key, value] = Object.entries(skill)[0];
                                                            const isAbbreviation = index in abbrivation;
                                                            const Icon = isAbbreviation ? CheckIcon : AddIcon;

                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    label={value}
                                                                    value={key}
                                                                    avatar={<Icon sx={{ color: 'black', fontWeight: 'bold' }} aria-hidden="false" />}
                                                                    variant="outlined"
                                                                    className={classes.chip}
                                                                    // sx={isAbbreviation ? { backgroundColor: '#EEEEEE' } : {}}
                                                                    sx={{ ...chipStyles, "&:hover": chipHoverStyle, ...(isAbbreviation && { backgroundColor: '#EEEEEE' }) }}
                                                                    onClick={() => handleSkillLevelClick(index, value, key)}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className={`d-flex justify-content-between mt-4`}>
                                            <div>Core Domain<span style={{ color: 'red' }}>*</span></div>
                                            <div>{!editClicked.coreDomain && (<EditIcon sx={{ cursor: 'pointer' }} onClick={() => { handleArrowClick('coreDomain'); handleEditClick('coreDomain'); }} />)}</div>
                                        </div>
                                        <div>
                                            {(arrowDirections.coreDomain === 'down' || arrowDirections.coreDomain === 'up') && (
                                                <div className="d-flex flex-column align-items-center mt-1">
                                                    <div className={`${containerEnabled.coreDomain ? classes['custom-input-container-enabled'] : classes['custom-input-container']} d-flex justify-content-between align-items-center`} >
                                                        {domain ? (
                                                            <div style={{ width: '100%' }} >
                                                                <div className="d-flex justify-content-center align-items-center" style={{ ...chipStyles, "&:hover": chipHoverStyle, width: 'fit-content' }}>
                                                                    <Chip
                                                                        key={domain}
                                                                        label={Object.values(coreDomain[domain - 1])[0]}
                                                                        variant="outlined"
                                                                        sx={{ minWidth: '6em', maxWidth: '20em', margin: 0, padding: 0, border: 'none' }}
                                                                        aria-hidden="false"
                                                                    />
                                                                    {containerEnabled.coreDomain && (
                                                                        <CancelIcon onClick={handleDomain} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className='d-flex justify-content-between align-items-center' style={{ width: '100%' }}>
                                                                    No Core Domain Selected
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {arrowDirections.coreDomain === 'up' && (
                                                <div className="d-flex align-items-center mt-1">
                                                    <div className={`${classes['custom-input-container-enabled']} ${classes['chip-container']} d-flex flex-column justify-content-around`}>
                                                        <div className='right' style={{ alignSelf: 'flex-end' }}>
                                                            <IconButton edge="end" color="inherit" onClick={() => { handleArrowClick('coreDomain'); handleCloseClick('coreDomain'); }} aria-label="close">
                                                                <Close />
                                                            </IconButton>
                                                        </div>
                                                        <div className='d-flex  flex-wrap' style={{ width: '100%' }}>
                                                            {coreDomain.map((cdomain, index) => {
                                                                const [key, value] = Object.entries(cdomain)[0];
                                                                const isLastDomain = index === domain - 1;
                                                                const Icon = isLastDomain ? CheckIcon : AddIcon;

                                                                return (
                                                                    <Chip
                                                                        key={index}
                                                                        label={value}
                                                                        avatar={<Icon sx={{ color: 'black', fontWeight: 'bold' }} aria-hidden="false"/>}
                                                                        variant="outlined"
                                                                        sx={{ ...chipStyles, "&:hover": chipHoverStyle, ...(isLastDomain && { backgroundColor: '#EEEEEE' }) }}
                                                                        onClick={!isLastDomain ? () => handleCoreDomain(index) : undefined}
                                                                        aria-hidden="false"
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {(arrowDirections.globalPrivilege === 'down' || arrowDirections.globalPrivilege === 'up') && (
                                            <div className="d-flex flex-column align-items-center mt-4">
                                                <div className={`d-flex justify-content-between align-items-center`} style={{ width: '100%' }} >
                                                    <span style={{ color: 'black' }}>Global Privilege</span>
                                                    {!editClicked.globalPrivilege && (
                                                        <EditIcon sx={{ cursor: 'pointer' }} onClick={() => { handleEditClick('globalPrivilege'); }} />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="d-flex align-items-center mt-1">
                                            <div className={`${containerEnabled.globalPrivilege ? classes['custom-input-container-enabled'] : classes['custom-input-container']} ${classes['global-privilege-container']} d-flex  flex-column justify-content-between align-items-center`}>
                                                <div className='d-flex flex-wrap' style={{ width: '100%' }}>
                                                    {editClicked.globalPrivilege && (
                                                        <div style={{ width: '100%', textAlign: 'end' }}>
                                                            <IconButton edge="end" color="inherit" onClick={() => { handleArrowClick('global'); handleCloseClick('globalPrivilege'); }} aria-label="close">
                                                                <Close />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <table className={`${classes['edit-table']}`}>
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>Privileges Name</th>
                                                                    {globalAccess && globalAccess.map((value, index) =>
                                                                        <th
                                                                         key={index}
                                                                        >{value.toUpperCase()}</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {globalPrivileges && globalPrivileges.map((privilegeObj, index) => {
                                                                    let privilege = Object.values(privilegeObj)[0];
                                                                    let key = Object.keys(privilegeObj)[0];
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className={`${classes['function-name']}`}>{privilege}
                                                                                <Tooltip
                                                                                    title={globalInfo[key]}
                                                                                    classes={classes.customTooltip}
                                                                                >
                                                                                    <IconButton className={classes.customIconButton} sx={{ padding: '0.25em' }}>
                                                                                        <InfoIcon sx={{ fontSize: '0.55em' }} className={classes.infoIcon} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    className={`${containerEnabled.globalPrivilege ? classes['radio-pointer'] : ''}`}
                                                                                    type="radio"
                                                                                    name={privilege}
                                                                                    checked={!defaultOptionSet && selectedValues[key] === 'none'}
                                                                                    onChange={() => handleRadioChange(key, 'none')}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    className={`${containerEnabled.globalPrivilege ? classes['radio-pointer'] : ''}`}
                                                                                    type="radio"
                                                                                    name={privilege}
                                                                                    checked={!defaultOptionSet && selectedValues[key] === 'edit'}
                                                                                    onChange={() => handleRadioChange(key, 'edit')}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    className={`${containerEnabled.globalPrivilege ? classes['radio-pointer'] : ''}`}
                                                                                    type="radio"
                                                                                    name={privilege}
                                                                                    checked={!defaultOptionSet && selectedValues[key] === 'full'}
                                                                                    onChange={() => handleRadioChange(key, 'full')}
                                                                                />
                                                                            </td>

                                                                        </tr>

                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-3 mb-3' style={{ textAlign: 'center' }}>
                                        <Button variant="contained" style={{ backgroundColor: 'rgb(9, 94, 173)' }} onClick={() => { handleUserDetails() }}>Save</Button>
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* )} */}


                </DialogContent>
            </Dialog>
        </>
    );
};

export default EditUser;


