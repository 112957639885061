import React, { useEffect, useState } from 'react';
import { Button, Snackbar, Alert } from '@mui/material';
import ProjectApprovalDialog from './ProjectApprovalDialog';
import RejectionReasonDialog from './RejectionReasonDialog';
import Navbar from '../Navbar/Navbar';
import classes from "./Notification.module.css"
import Collapse from "@mui/material/Collapse";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';
import Pagination from '@mui/material/Pagination';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import Fade from "@mui/material/Fade";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TextField from "@mui/material/TextField";
import useStore from "../Common/State";
import CircularProgress from '@mui/material/CircularProgress';
import RejectIcon from "../../Assets/decline.png";
import PendingIcon from "../../Assets/pending.png";
import approvalAccept from "../../Assets/mark.png";
import Autocomplete from '@mui/material/Autocomplete';

function CustomTabPanel(props) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabValue: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Notification = () => {
  const [open, setOpen] = useState(false);
  const [openRejectReason, setOpenRejectReason] = useState(false);

  const [dropMap, setDropMap] = useState({});
  const [notificationRead, setNotificationRead] = useState();
  const [approvalList, setApprovalList] = useState([])

  const [notifications, setNotifications] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [noNotification, setNoNotification] = useState('')
  const [noApproval, setNoApproval] = useState('')

  const [isLoaded, setIsLoaded] = useState(false)

  //   const handleDropdown = (index) => {
  //     console.log(index,'this is the index')
  //     setDropMap((prevState) => ({
  //         ...prevState,
  //         [index]: !prevState[index],
  //     }));

  // };

  const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectReasonOpen = (approvalData) => {
    setOpenRejectReason(true);
  };

  const handleRejectReasonClose = () => {
    setOpenRejectReason(false);
    setOpen(false)
    fetchApproval()
  };

  const handleRejectOnlyClose = () => {
    setOpenRejectReason(false)
  }

  const projectData = {
    projectName: 'Project Alpha',
    username: 'johndoe',
    requestedBy: 'Jane Smith',
    description: 'This is a description of Project Alpha.',
  };
  const [dashboard, setDashboard] = useState({
    "Dashboard": "Notification",
    "Info": "Approval and Status"
  })
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {

    if (newValue == 0) {
      setSearchInput('')
      setFilteredNotifications([])
      fetchApproval()
      setTabValue(newValue);
      setPage(1)
    }
    else {
      setSearchInput('')
      setFilteredNotifications([])
      fetchNotifications()
      setTabValue(newValue);
      setPage(1)
    }
  };




  const [searchInput, setSearchInput] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  useEffect(() => {
    setFilteredNotifications(notifications);
  }, [notifications]);

  
  const filterNotifications = (searchInput, type) => {
    if (type == 'approval') {
      const filtered = notifications.filter((notification) => {
        for (let key in notification) {
          if (typeof notification[key] === 'string' && notification[key].toLowerCase().includes(searchInput.toLowerCase())) {
            return true;
          }
          if (typeof notification[key] === 'object' && notification[key] !== null) {
            for (let subKey in notification[key]) {
              if (typeof notification[key][subKey] === 'string' && notification[key][subKey].toLowerCase().includes(searchInput.toLowerCase())) {
                return true;
              }
            }
          }
        }
        return false;
      });
      setFilteredNotifications(filtered);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      setPage(1);
    }
    else {
      const filtered = notifications.filter((notification) => {
        for (let key in notification) {
          if (typeof notification[key] === 'string' && notification[key].toLowerCase().includes(searchInput.toLowerCase())) {
            return true;
          }
          if (typeof notification[key] === 'object' && notification[key] !== null) {
            for (let subKey in notification[key]) {
              if (typeof notification[key][subKey] === 'string' && notification[key][subKey].toLowerCase().includes(searchInput.toLowerCase())) {
                return true;
              }
            }
          }
        }
        return false;
      });
      setFilteredNotifications(filtered);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
      setPage(1);

    }

  };



  const handleSearchInputChange = (event, type) => {
    setSearchInput(event.target.value);
    filterNotifications(event.target.value, type);
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const weekday = weekdays[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const paddedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedDate = `${weekday} ${month} ${day} ${year} ${hours}:${paddedMinutes}`;
    return formattedDate;
  }


  const handleSearchInputCancel = () => {
    setSearchInput('');
    setFilteredNotifications(notifications);
    setTotalPages(Math.ceil(notifications.length / itemsPerPage));
    setPage(1);
  };

  const [userDetails, setUserDetails] = useState({})
  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
    }
    catch (error) {

      if (error.response.status == 401) {
        const message = error.response.data.details || 'An error occurred';
      }
    }
  }


  const fetchApproval = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_pending_approvals`, config);
      setApprovalList(response.data)
      if (response.data.length == 0) {
        setNoApproval('No Approval Found')
      }
      setNotifications(response.data);
      setFilteredNotifications(response.data);
      setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      const readStatus = response.data.reduce((acc, notification) => {
        acc[notification.approval_id] = notification.is_read_by_user;
        return acc;
      }, {});
      setNotificationRead(readStatus);
      setIsLoaded(true)
    } catch (error) {
    }

  }

  const fetchNotifications = async () => {
    setFilteredNotifications([])
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/logs/get_notifications`, config);
      setNotifications(response.data);
      if (response.data.length == 0) {
        setNoNotification('No Notification Found')
      }
      // setFilteredNotifications([])
      setFilteredNotifications(response.data);
      setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      const readStatus = response.data.reduce((acc, notification) => {
        acc[notification.notification_id] = notification.is_read_by_user;
        return acc;
      }, {});
      setNotificationRead(readStatus);
    } catch (error) {
    }

  }


  const [expandedNotification, setExpandedNotification] = useState(null);

  const handleDropdown = (key) => {
    setExpandedNotification(expandedNotification === key ? null : key);
  };



  const [approvals, setApprovals] = useState(1)
  const [step, setStep] = useState(0)
  useEffect(() => {
    fetchApproval()
    fetchUserDetails()
  }, [approvals])

  const getTimeElapsed = (notificationTimestamp) => {

    const parseDate = (timestamp) => {
      const [datePart, timePart] = timestamp.split(' ');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
      return new Date(year, month - 1, day, hours, minutes, seconds);
    };

    const notificationTime = parseDate(notificationTimestamp);
    const currentTime = new Date();

    const timeDifference = Math.abs(currentTime - notificationTime);

    const oneMinuteInMilliseconds = 60 * 1000;
    const oneHourInMilliseconds = 60 * oneMinuteInMilliseconds;
    const oneDayInMilliseconds = 24 * oneHourInMilliseconds;

    const minutes = Math.floor(timeDifference / oneMinuteInMilliseconds);
    const hours = Math.floor(timeDifference / oneHourInMilliseconds);
    const days = Math.floor(timeDifference / oneDayInMilliseconds);

    if (days > 7) {
      return notificationTime.toLocaleDateString();
    } else if (days === 7) {
      return "1 week ago";
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }

  };



  // const getTimeElapsed = (notificationTimestamp) => {

  //   const parseDate = (timestamp) => {
  //     const [datePart, timePart] = timestamp.split(' ');
  //     const [year, month, day] = datePart.split('-').map(Number);
  //     const [hours, minutes, seconds] = timePart.split(':').map(Number);
  //     return new Date(year, month - 1, day, hours, minutes, seconds);
  //   };

  //   const notificationTime = parseDate(notificationTimestamp);
  //   const currentTime = new Date();

  //   const timeDifference = Math.abs(currentTime - notificationTime);

  //   const oneMinuteInMilliseconds = 60 * 1000;
  //   const oneHourInMilliseconds = 60 * oneMinuteInMilliseconds;
  //   const oneDayInMilliseconds = 24 * oneHourInMilliseconds;
  //   const oneMonthInMilliseconds = 30 * oneDayInMilliseconds; // Approximation of 30 days per month
  //   const oneYearInMilliseconds = 365 * oneDayInMilliseconds; // Approximation of 365 days per year

  //   const minutes = Math.floor(timeDifference / oneMinuteInMilliseconds);
  //   const hours = Math.floor(timeDifference / oneHourInMilliseconds);
  //   const days = Math.floor(timeDifference / oneDayInMilliseconds);
  //   const months = Math.floor(timeDifference / oneMonthInMilliseconds);
  //   const years = Math.floor(timeDifference / oneYearInMilliseconds);

  //   if (years > 0) {
  //     return `${years} year${years > 1 ? "s" : ""} ago`;
  //   } else if (months > 0) {
  //     return `${months} month${months > 1 ? "s" : ""} ago`;
  //   } else if (days > 0) {
  //     return `${days} day${days > 1 ? "s" : ""} ago`;
  //   } else if (hours > 0) {
  //     return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  //   } else if (minutes > 0) {
  //     return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  //   } else {
  //     return "Just now";
  //   }
  // };



  const handlePageChange = (event, value) => {
    setExpandedNotification({})
    setPage(value);
  };

  const [approvalData, setApprovalData] = useState({})
  const [actionType, setActionType] = useState(null)
  const handleApproval = (data, action_type) => {
    if (action_type == 201 || action_type == 202 || action_type == 203) {
      setStep(1)
      setActionType(action_type)
    }
    else {
      setStep(2)
    }
    setApprovalData(data)
    setOpen(true)
  }

  const handleRead = async (approval_id) => {
    let data = approval_id
    try {
      if (!notificationRead[data]) {
        let payload = {
          approval_id: data
        }
        const response = await axios.post(`/backend/update_approval_read_status`,
          payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("dash_access_token")}`,
          },
        },
        )

        if (response) {
          setNotificationRead((prevState) => ({
            ...prevState,
            [data]: true
          }))

          useStore.getState().getCount()


        }
      }

    } catch (error) {
      console.error("Error updating notification read status", error)
    }
    setNotificationRead((prevState) => ({
      ...prevState,
      [data.approval_id]: true
    }))
  }

  const handleNotificationRead = async (approval_id) => {
    let data = approval_id
    try {
      if (!notificationRead[data]) {
        let payload = {
          notification_id: data
        }
        const response = await axios.post(`/logs/notification_read_status`,
          payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("dash_access_token")}`,
          },
        },
        )
        if (response) {
          setNotificationRead((prevState) => ({
            ...prevState,
            [data]: true
          }))
          useStore.getState().getCount()

        }
      }

    } catch (error) {
      console.error("Error updating notification read status", error)
    }

  }
  const [notificationSnackbar, setNotificationSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handleNotificationSnackbar = () => {
    setNotificationSnackbar(false)
  }

  const approvalDropDown = [
    { label: 'Approval Pending', value: -1 },
    { label: 'Rejected', value: 0 },
    { label: 'Approval Accepted', value: 1 }
  ];

  const notificationDropdown = [
    { label: 'Unread Notification', value: -1 },
    { label: 'Read Notification', value: 0 }
  ];



  return (
    <>
      <Fade in={true} timeout={1000}>
        <div className={classes['content']}>
          <Navbar dashboard={dashboard} isLoaded={true} userDetails={userDetails} />
          {isLoaded ?
            (
              <>
                <div>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={tabValue} onChange={handleChange} aria-label="To view Notification and tab" style={{ padding: '1em' }}>
                        <Tab label="Approval" {...a11yProps(0)} />
                        <Tab label="Notifications" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                    </CustomTabPanel>
                  </Box>

                </div>


                <div style={{ padding: '1em' }}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div></div>
                    {/* <div>
                      {tabValue == 0 &&
                        <Autocomplete
                          options={approvalDropDown}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Approval Status" />}
                          onChange={(event, newValue) => {
                            
                          }}
                        />

                      }
                      {tabValue == 1 &&
                        <Autocomplete
                          options={notificationDropdown}
                          getOptionLabel={(option) => option.label} // Display the label in the dropdown
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Notification Status" />}
                          onChange={(event, newValue) => {
                            console.log(newValue); // Logs the selected option object
                          }}
                        />

                      }
                    </div> */}
                    <div className={classes["search-bar"]}>
                      <TextField
                        id="standard-basic"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleSearchInputCancel}>
                              <CancelOutlinedIcon />
                            </InputAdornment>
                          )
                        }}
                        sx={{
                          padding: "0",
                          width: "10rem",
                          "& input": {
                            padding: "0.5rem",
                          },
                        }}
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        value={searchInput}
                        onChange={(e) => { handleSearchInputChange(e, 'approval') }}
                      />
                    </div>
                  </div>
                  {tabValue == 0 ? (
                    <>


                      <div className={classes["notifications-container"]}>
                        <div className={classes["inner-notifications-container"]}>
                          {filteredNotifications && filteredNotifications.length ? (
                            filteredNotifications
                              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                              .map((notification, key, index) => (

                                <React.Fragment key={notification.approval_id || index}>
                                  <div
                                    className={classes["notification"]}
                                    onClick={() => {
                                      handleApproval(notification, notification.action_type)
                                      handleRead(notification.approval_id)
                                    }}
                                    style={{
                                      backgroundColor: notificationRead && notificationRead[notification.approval_id] ? '#f2f2f2' : ''
                                    }}


                                  >

                                    <div className={classes["styler"]}></div>
                                    <div className={classes["notification-content"]}>
                                      <div className={classes["inner-content"]}>
                                        <div className={classes['icon-holders']}>
                                          {/* {notification.is_read_by_user == 1 ? <MarkAsUnreadIcon /> : <MarkunreadIcon />} */}
                                          {notificationRead && notificationRead[notification.approval_id] ? <MarkAsUnreadIcon /> : <MarkunreadIcon />}
                                        </div>

                                        <div className={classes["title-content"]}>

                                          {notification.dashboard && <span style={{ textAlign: 'start', fontWeight: "bold" }}>{notification.action} </span>}
                                          <div style={{ textAlign: 'start' }}>
                                            Project Name: <span>{notification.to_update_settings?.project_name ?? 'None'}</span>
                                          </div>

                                          <div style={{ textAlign: 'start' }}>Requested By : <span>{notification.by_username}</span> </div>
                                          <div style={{ textAlign: 'start' }}>For Username : <span>{notification.for_username}</span> </div>
                                        </div>
                                        <div className={`${classes["day"]} d-flex`}>
                                          {(notification && notification.is_approved == -1) && (
                                            <img title={"Approval pending"} src={PendingIcon} alt="Pending Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                          )}
                                          {
                                            (notification && notification.is_approved == 0) && (
                                              <img title={"Approval rejected"} src={RejectIcon} alt="Reject Icon" style={{ width: '25px', height: '25px', marginRight: '8px' }} />
                                            )
                                          }
                                          {
                                            (notification && notification.is_approved == 1) && (
                                              <img title={"Approval accepted"} src={approvalAccept} alt="Approved Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                            )
                                          }
                                          {getTimeElapsed(notification.timestamp)}
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                </React.Fragment>
                              ))
                          ) : (
                            <h1>{noApproval}</h1>
                          )}
                        </div>
                      </div>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                        sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                      />
                    </>
                  ) : (
                    <>
                      <div>
                        <div className={classes["notifications-container"]}>
                          <div className={classes["inner-notifications-container"]}>
                            {filteredNotifications && filteredNotifications.length ? (
                              filteredNotifications
                                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                .map((notification, key, index) => (
                                  <React.Fragment key={notification.approval_id}>
                                    <div
                                      className={classes["notification"]}

                                      onClick={() => {
                                        handleDropdown(key)
                                        handleNotificationRead(notification.notification_id)
                                      }}

                                      style={{
                                        backgroundColor: notificationRead && notificationRead[notification.notification_id] ? '#f2f2f2' : ''
                                      }}

                                    >
                                      <div className={classes["styler"]}></div>
                                      <div className={classes["notification-content"]}>
                                        <div className={classes["inner-content"]}>
                                          <div className={classes['icon-holders']}>
                                            {notificationRead && notificationRead[notification.notification_id] ? <MarkAsUnreadIcon /> : <MarkunreadIcon />}
                                          </div>

                                          {notification.action_type === 111 &&
                                            <div className={classes["title-content"]}>
                                              {notification.action &&
                                                <div className='d-flex flex-column justify-content-between'>
                                                  <div style={{ textAlign: 'start', fontWeight: 'bold' }}>
                                                    Domain Name: {notification?.resource_info?.domain_name ?? 'None'}
                                                  </div>
                                                  <div style={{ textAlign: 'start', fontWeight: 'bold' }}>
                                                    Project Name: {notification?.resource_info?.project_name ?? 'None'}
                                                  </div>

                                                  {/* <div style={{ textAlign: 'start', fontWeight: "bold" }}>{notification.action}</div> */}
                                                  <div style={{ textAlign: 'start', fontWeight: "bold" }}>Status: {notification?.resource_info?.status}</div>

                                                </div>
                                              }
                                            </div>
                                          }

                                          {([105, 106, 107, 108, 103, 104, 102].includes(notification.action_type)) &&
                                            <div className={classes["title-content"]}>
                                              {notification.action &&
                                                <div className='d-flex flex-column justify-content-between'>
                                                  <div style={{ textAlign: 'start', fontWeight: 'bold' }}>
                                                    Domain Name: {notification?.new_settings?.domain_name ?? 'None'}
                                                  </div>
                                                  <div style={{ textAlign: 'start', fontWeight: 'bold' }}>
                                                    Project Name: {notification?.new_settings?.project_name ?? 'None'}
                                                  </div>

                                                  <div style={{ textAlign: 'start', fontWeight: "bold" }}>User Name: {notification?.by_username}</div>
                                                  {[107].includes(notification.action_type) && (
                                                    <>
                                                      <div style={{ textAlign: 'start', fontWeight: "bold" }}>
                                                        Action: {notification.action.replace(/for\s.*$/, `of ${notification?.new_settings?.project_name ?? 'None'}`)}
                                                      </div>
                                                    </>
                                                  )}
                                                  {![107].includes(notification.action_type) && (

                                                    <>
                                                      <div style={{ textAlign: 'start', fontWeight: "bold" }}>
                                                        Action: {notification.action.replace(/for\s.*$/, `of ${notification?.new_settings?.project_name ?? 'None'}`)}

                                                      </div>
                                                    </>
                                                  )}


                                                  <div style={{ textAlign: 'start', fontWeight: "bold" }}>Remarks: {notification?.new_settings?.remark}</div>

                                                </div>
                                              }
                                            </div>
                                          }

                                          {([101].includes(notification.action_type)) &&
                                            <div className={classes["title-content"]}>
                                              {notification?.dashboard &&
                                                <span style={{ textAlign: 'start', fontWeight: "bold" }}>
                                                  {notification.action.split(' |')[0]}
                                                </span>
                                              }
                                            </div>
                                          }
                                          <div className={classes["day"]}>
                                            {getTimeElapsed(notification.timestamp)}
                                          </div>
                                        </div>
                                      </div>
                                      {notification?.resource_info && (
                                        <div className={classes['tooltip']}>
                                          {notification?.resource_info?.sub_task
                                            ? Object.keys(notification?.resource_info?.sub_task).length
                                            : 0}
                                        </div>
                                      )}

                                      {notification?.resource_info && !notification?.resource_info?.sub_task && (
                                        <div className={classes['tooltip']}>
                                          1
                                        </div>
                                      )}


                                    </div>
                                    {/* {notification} */}




                                    {notification?.resource_info && notification?.resource_info?.sub_task && Object.keys(notification?.resource_info?.sub_task).length >= 1 && (
                                      <Collapse in={expandedNotification === key} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                                        <div className={classes["dropdown"]}>
                                          {Object.keys(notification.resource_info.sub_task)
                                            .slice()
                                            .reverse()
                                            .map((subtaskKey, ind_key) => {
                                              const subtask = notification.resource_info.sub_task[subtaskKey];
                                              return (
                                                <div className={classes["subtask"]} key={ind_key}>
                                                  <p className={classes["subtaskName"]} style={{ margin: 0 }}>{capitalizeEachWord(subtask.sub_task_name)}</p>
                                                  <p className={classes["subtaskStatus"]} style={{ margin: 0 }} >{capitalizeEachWord(subtask.sub_task_status)}</p>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </Collapse>
                                    )}
                                  </React.Fragment>
                                ))
                            ) : (
                              <h1>{noNotification}</h1>
                            )}
                          </div>
                        </div>
                        <Pagination
                          count={totalPages}
                          page={page}
                          onChange={handlePageChange}
                          color="primary"
                          size="large"
                          sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {open &&
                    (
                      <>
                        <ProjectApprovalDialog
                          open={open}
                          onClose={handleClose}
                          onReject={() => { handleRejectReasonOpen(approvalData) }}
                          projectData={approvalData}
                          setApprovals={setApprovals}
                          step={step}
                          setStep={setStep}
                          actionType={actionType}
                          setNotificationSnackbar={setNotificationSnackbar}
                          setMessage={setMessage}
                          setSeverity={setSeverity}
                        />
                        <RejectionReasonDialog
                          open={openRejectReason}
                          onClose={handleRejectReasonClose}
                          soloClose={handleRejectOnlyClose}
                          projectData={approvalData}
                          setNotificationSnackbar={setNotificationSnackbar}
                          setMessage={setMessage}
                          setSeverity={setSeverity}
                        />
                      </>
                    )}

                </div>
              </>
            ) : (
              <>
                <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                </div>
              </>
            )
          }

        </div>


      </Fade >
      <Snackbar
        open={notificationSnackbar}
        autoHideDuration={3000}
        onClose={handleNotificationSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleNotificationSnackbar} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notification;


