import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import logImage from "../../Assets/login-main-img.svg";
import DashLogo from "../../Assets/Dash-logo.svg";

import { createTheme, ThemeProvider } from '@mui/material/styles';


import { useColorScheme } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import CssBaseline from '@mui/joy/CssBaseline';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/material/Input';
import Link from '@mui/joy/Link';
import { useState, useEffect } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@mui/icons-material/Error";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ReCAPTCHA from '../../Components/Common/ReCAPTCHA';
import sha256 from 'crypto-js/sha256';
import DialogActions from '@mui/material/DialogActions';


import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel'; 

import DOMPurify from 'dompurify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


import classes from "./ForgotPasswordModal.module.css"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import { Close} from '@mui/icons-material'

import isValidEmail from '../../Services/Validators/emailValidator';

const ForgotPasswordModal = (props) => {

    const [modalOpen, setModalOpen] = useState(false);

	const [step, setStep] = useState(1);
	const [email2, setEmail2] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [helperText, setHelperText] = useState('');
	const [otp, setOtp] = useState('');
	const [password1, setPassword1] = useState("");
	const [showPassword1, setShowPassword1] = useState(false);
	const [password2, setPassword2] = useState("");
	const [showPassword2, setShowPassword2] = useState(false);
	const [invalidOTP, setInvalidOTP] = useState(false);
	const [OTPErrorText, setOTPErrorText] = useState(false);
	const [pswdError, setPswdError] = useState(false);
	const [pswdValidations, setPswdValidations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOTPSent, setIsOTPSent] = useState(false);
	const [sendOTPLoading, setSendOTPLoading] = useState(false);
	const [reSendOTPLoading, setReSendOTPLoading] = useState(false);
	const [confirmOTPLoading, setConfirmOTPLoading] = useState(false);
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [useremail,setUserEmail] = useState('')





const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
        return;
    }
    setModalOpen(false);
    setStep(1);
    setEmail2('');
    setEmailError(false);
    setHelperText('');
    setOtp('');
    setPassword1('')
    setPassword2('')
    setShowPassword1(false)
    setShowPassword2(false);
    setInvalidOTP(false);
    setPswdError(false);
    setIsOTPSent(false);
    props.onClose()
}

    const resetInputStyles={
      // border:"2px solid grey",
    }

    const buttonStyles = {
        borderColor: "rgba(192, 192, 192, 1)",
        backgroundColor: "rgba(28, 28, 28, 1)",
        borderRadius: "0.75rem",
        color: "rgba(238, 225, 213, 1)",
        fontWeight: 700,
        textTransform: "none",
        marginTop: "1rem",
        padding: "0.75rem",
        width: "100%",
        height:"3em"
    };
    const buttonStyles2 = {
        border: "1px solid black",
        backgroundColor: "#EBEBEB",
        borderRadius: "0.75rem",
        color: "black",
        fontWeight: 700,
        textTransform: "none",
        marginTop: "1rem",
        padding: "0.75rem",
        width: "100%",
        height:"3em"
    };
    const otpbuttonStyles = {
      border: "1px solid black",
      backgroundColor: "transparent",
      borderRadius: "0.75rem",
      color: "black",
      fontWeight: 700,
      textTransform: "none",
      marginTop: "1rem",
      padding: "0.75rem",
      
    }
    const confirmbuttonStyles ={
      border: "1px solid black",
      backgroundColor: "rgba(28, 28, 28, 1)",
      borderRadius: "0.75rem",
      color: "rgba(238, 225, 213, 1)",
      fontWeight: 700,
      textTransform: "none",
      marginTop: "1rem",
      padding: "0.75rem",
      marginLeft : "1em"
    }
    
    const confirmHoverStyles = {
      backgroundColor: "transparent",
      border: "1px solid black",
      transition: "background-color 0.1s ease-in-out",
      color: "black",
    }
    const otpHoverStyles = {
      backgroundColor: "rgba(28, 28, 28, 1)",
      border: "1px solid black",
      transition: "background-color 0.1s ease-in-out",
      color: "white",
    }

    const hoverStyles = {
        backgroundColor: "transparent",
        border: "1px solid black",
        transition: "background-color 0.1s ease-in-out",
        color: "black",
    };


    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

const handleMouseDownPassword1 = (event) => {
  event.preventDefault();
};

const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

const handleMouseDownPassword2 = (event) => {
  event.preventDefault();
};

const handleResendOTP = async () => {
  const sanitizedEmail = DOMPurify.sanitize(email2);
  setReSendOTPLoading(true);
  try {
    const response = await axios.post(`/backend/forgot-password/check-email`, { email: sanitizedEmail }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response) {
      setReSendOTPLoading(false);
      if (response.status === 200) {
        toast.success('OTP sent successfully!', {
          position: "bottom-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
        });
      }
    }
  }
  catch (err) {
    setReSendOTPLoading(false);
    setInvalidOTP(true);
    if (err.response.status === 422) {
      setOTPErrorText("Unprocessable Entity");
    }
    else if (err.response.status === 403) {
      setOTPErrorText("Account/IP is blocked");
    }
    else if (err.response.status === 500) {
      setOTPErrorText("Something went wrong");
    }
    else {
      setOTPErrorText("Something went wrong");
    }
  }
}

const handleSendOTP = async () => {
  setSendOTPLoading(true);
  const sanitizedEmail = DOMPurify.sanitize(email2);
  if(!sanitizedEmail) {
    setEmailError(true);
    setHelperText('Please enter email ID');
    setSendOTPLoading(false);
    return;
  }
  if (!isValidEmail(sanitizedEmail)) {
    setEmailError(true);
    setHelperText('Invalid Email ID');
    setSendOTPLoading(false);
    return;
  }
  setEmailError(false);
  setHelperText('');
  try {
    setUserEmail(sanitizedEmail)
    const response = await axios.post(`/backend/forgot-password/check-email`, { email: sanitizedEmail }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response) {
      setSendOTPLoading(false);
      if (response.status === 200) {
        setIsOTPSent(true);
        setStep(step + 1);
      }
      else {
        setEmailError(true);
        setHelperText("Something went wrong");
      }
    }

  } catch (err) {
    setSendOTPLoading(false);
    setEmailError(true);
    if (err.response.status === 422) {
      setHelperText("Unprocessable Entity");
    }
    else if (err.response.status === 403) {
      setHelperText("Account/IP is blocked");
    }
    else if (err.response.status === 500) {
      setHelperText("Something went wrong");
    }
    else {
      setHelperText("Something went wrong");
    }
  }
}

const handleConfirmOTP = async () => {
  setConfirmOTPLoading(true);
  const sanitizedEmail = DOMPurify.sanitize(email2);
  if (otp.length !== 6) {
    setInvalidOTP(true);
    setOTPErrorText('Enter 6 digit OTP');
    setConfirmOTPLoading(false);
    return;
  }
  try {
    setConfirmOTPLoading(true);
    const response = await axios.post(`/backend/forgot-password/verify-otp`, { email: sanitizedEmail, otp: otp }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response) {
      setConfirmOTPLoading(false);
      if (response.status === 200) {
        if(atob(response.data) === otp){
          setStep(step + 1);

        }else{
          setInvalidOTP(true);
          setOTPErrorText("Something went wrong");
        }
      }
      else {
        setInvalidOTP(true);
        setOTPErrorText("Something went wrong");
      }
    }
  }
  catch (err) {
    setConfirmOTPLoading(false);
    setInvalidOTP(true);
    if (err.response.status === 422) {
      setOTPErrorText("Unprocessable Entity");
    }
    else if (err.response.status === 500) {
      setOTPErrorText("Something went wrong");
    }
    else if (err.response.status === 403) {
      setOTPErrorText("Account/IP is blocked");
    }
    else if (err.response.status === 401) {
      setOTPErrorText("Invalid OTP");
    }
    else {
      setOTPErrorText("Something went wrong");
    }
  }

}

const handleResetPassword = async () => {
  setResetPasswordLoading(true);
  if(!password1 || !password2) {
    setPswdValidations(["Please enter password"]);
    setResetPasswordLoading(false);
    return;
  }
  const sanitizedEmail = DOMPurify.sanitize(email2);
  const sanitizedPassword1 = DOMPurify.sanitize(password1);
  const sanitizedPassword2 = DOMPurify.sanitize(password2);
  setPswdValidations([]);
  if (sanitizedPassword1 !== password1 || sanitizedPassword2 !== password2) {
    setPswdValidations(['Character not allowed']);
    setResetPasswordLoading(false);
    return;
  }
  if(password1.length < 8) {
    setPswdValidations(["Password must be atleast 8 characters long"]);
    setResetPasswordLoading(false);
    return;
  }
  if (password1 !== password2) {
    setPswdError(true);
    setPswdValidations([]);
    setResetPasswordLoading(false);
  }
  else {
    setPswdError(false);
    let errors_arr = [];
    !(/[a-z]/.test(password1)) && errors_arr.push("Password must contain atleast one lowercase letter");
    !(/[A-Z]/.test(password1)) && errors_arr.push("Password must contain atleast one uppercase letter");
    !(/[0-9]/.test(password1)) && errors_arr.push("Password must contain atleast one number");
    !(/[!@#$%^&*]/.test(password1)) && errors_arr.push("Password must contain atleast one special character");
    !(password1.length >= 8) && errors_arr.push("Password must be atleast 8 characters long");
    setPswdValidations(errors_arr);

    if (errors_arr.length === 0) {
      try {
        const response = await axios.post(`/backend/forgot-password/reset-password`, { email: sanitizedEmail, password: password1,otp : otp }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response) {
          setResetPasswordLoading(false);
          if (response.status === 200) {
            closeModal();
            toast.success('Password reset successfully!', {
              position: "bottom-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              theme: "colored",
            });
          }
          else {
            setPswdValidations(["Something went wrong"]);
          }
        }
      }
      catch (err) {
        setResetPasswordLoading(false);
        if (err.response.status === 422) {
          setPswdValidations(["Unprocessable Entity"]);
        }
        else if (err.response.status === 500) {
          setPswdValidations(["Something went wrong"]);
        }
        else {
          setPswdValidations(["Something went wrong"]);
        }
      }
    }
    else {
      setResetPasswordLoading(false);
    }
  }

}

const handleClose = (event, reason) => {
  if (reason !== 'backdropClick') {
      props.onClose();
  }
};


    return (
      //   <Dialog
      //       open={props.isOpen}
      //       onClose={props.onClose}
      //       maxWidth="sm"
      //       fullWidth
      //       sx={{ borderRadius: '1rem' }}
      //       style={{ top: '-10%' }}
      //       classes={{
      //           paper: classes.dialogPaper,
      //       }}
      //   >


      //       {
			// step === 1 ?
			// <>
      //       <DialogTitle sx={{ paddingBottom: '0' }}>
      //           <div className={classes['title-container']}>
      //               Forgot Your Password?
      //           </div>
      //       </DialogTitle>
      //       <DialogContent>
      //           <div className={classes['container']}>
      //               <div className={classes['text-content']}>
      //                   <h6>Enter the email associated with your account and we’ll email you a link to reset your password</h6>
      //               </div>

      //               <div className={classes['input-container']}>
      //                   <TextField
      //                       placeholder="Email"
      //                       sx={{
      //                           // backgroundColor: "rgba(242, 242, 242, 1)",
      //                           borderRadius: "3rem !important",
      //                           marginTop: '1rem',
      //                           // "& .MuiOutlinedInput-notchedOutline": {
      //                           //     borderColor: "#E0E3E7",
      //                           // },
      //                           // "&:hover .MuiOutlinedInput-notchedOutline": {
      //                           //     borderColor: "#B2BAC2",
      //                           // },
      //                           // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      //                           //     borderColor: "#6F7E8C",
      //                           // },
      //                           // "& label.Mui-focused": {
      //                           //     color: "#6F7E8C",
      //                           // }
      //                       }}
      //                       error={emailError} helperText={emailError ? helperText : ""}
      //                       id="email-1" 
      //                       label="Email ID" 
      //                       variant="standard" 
      //                       value={email2} 
      //                       onChange={(evt) => setEmail2(evt.target.value)} disabled={isOTPSent}
      //                   />
      //               </div>
      //           </div>
      //           <div className={classes['button-container']}>
      //               <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
      //                   {/* <Button
      //                       type="submit"
      //                       sx={{ ...buttonStyles, "&:hover": hoverStyles }}
      //                   >
      //                       Send OTP
      //                   </Button> */}
      //                   {
			// 											sendOTPLoading ? <CircularProgress /> : <Button sx={{ ...buttonStyles, "&:hover": hoverStyles }} onClick={handleSendOTP} variant='contained' disabled={isOTPSent}>Send OTP</Button>
			// 									}
                        
      //                   <Button
      //                       type="submit"
      //                       sx={{ ...buttonStyles2, "&:hover": hoverStyles }}
      //                       onClick={props.onClose}
      //                   >
      //                       Cancel
      //                   </Button>
      //               </Box>
      //           </div>
      //       </DialogContent>
			// 				</>
			// 				:
			// 				null
			// 		}
      //   {
      //       step === 2 ?
      //           <>
      //       <DialogTitle sx={{ paddingBottom: '0' }}>
      //           <div className={classes['title-container']}>
      //               Forgot Your Password?
      //           </div>
                
      //       </DialogTitle>

      //               <DialogContent style={{paddingBottom:'0'}}>
      //                 <div style={{textAlign:'center'}}><h5 className='bold mt-4 text-align-center'>A 6 digit code has been sent to your {useremail} email address</h5></div>
                        
      //                   <div className='mt-3 w-100 row'>
      //                       <div className='col-12 d-flex flex-column justify-content-center '>
      //                           <div className={`${classes['otp-input']} d-flex flex-column justify-content-center align-items-center  rounded`}>
      //                               <p className='text-muted small m-0' style={{fontWeight:'bold'}}>Enter OTP</p>
      //                               {/* <div className="d-flex justify-content-center"> */}
      //                               <OtpInput
      //                               disabled={true}
      //                                   value={otp}
      //                                   onChange={setOtp}
      //                                   numInputs={6}
      //                                   inputType='number'
      //                                   renderInput={(props) => <input {...props} 
      //                                   className='d-flex justify-content-center'
      //                               />
      //                             }
                                    
      //                               />
      //                               {/* </div> */}
      //                               {
      //                                   invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null
      //                               }
      //                               <div style={{display:'flex','justifyContent':'center',alignItems:'center',marginTop:'1em'}}>
      //                               {
      //                                   reSendOTPLoading ? <CircularProgress /> : <Button onClick={handleResendOTP}  sx={{...otpbuttonStyles,"&:hover":otpHoverStyles}}>Resend OTP</Button>
      //                               }
      //                               {
      //                                   confirmOTPLoading ? <CircularProgress /> : <Button variant='contained' sx={{...confirmbuttonStyles,"&:hover":confirmHoverStyles}}  onClick={handleConfirmOTP} >Confirm OTP</Button>
      //                                   // disabled={!isOTPSent}
      //                               }
      //                               </div>
      //                           </div>
      //                       </div>
      //                   </div>
      //                   <DialogActions>
      //                   <div className='w-100  d-flex justify-content-end'>
      //                       <Button onClick={closeModal} sx={{backgroundColor:'grey',color:'white',"&:hover":confirmHoverStyles,marginRight:'1em'}}>Cancel</Button>
      //                   </div>
      //               </DialogActions>
      //               </DialogContent>

      //           </>
      //           :
      //           null
      //   },
      //               {
			// 			step === 3 ?
      //       <>
      //       <DialogTitle sx={{ paddingBottom: '0' }}>
      //           <div className={classes['title-container']}>
      //               Reset Password
      //           </div>
      //       </DialogTitle>

			// 				<DialogContent>
			// 					{/* <h4 className='bold'>Reset Password</h4> */}
			// 					<div className='mt-3 row'>
			// 						<div className='col-12 d-flex flex-column justify-content-center'>
			// 							<p className='text-muted small ' style={{fontWeight:'bold'}}>Create a New Password (Minimum 8 characters, including uppercase and lowercase letters, numbers, and symbols)</p>
			// 							<FormControl  variant="standard">
			// 								<InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
			// 								<Input
			// 									id="standard-adornment-password"
			// 									type={showPassword1 ? 'text' : 'password'}
			// 									onChange={(evt) => setPassword1(evt.target.value)}
			// 									endAdornment={
			// 										<InputAdornment position="end">
			// 											<IconButton
			// 												aria-label="toggle password visibility"
			// 												onClick={handleClickShowPassword1}
			// 												onMouseDown={handleMouseDownPassword1}
			// 											>
			// 												{showPassword1 ? <Visibility /> : <VisibilityOff />}
			// 											</IconButton>
			// 										</InputAdornment>
			// 									}
			// 								/>
			// 							</FormControl>
			// 							<FormControl className='mt-4' sx={{ marginTop:'1.5em' }} variant="standard">
			// 								<InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
			// 								<Input
      //                  sx={{...resetInputStyles}}
			// 									id="standard-adornment-password"
			// 									type={showPassword2 ? 'text' : 'password'}
			// 									onChange={(evt) => setPassword2(evt.target.value)}
			// 									endAdornment={
			// 										<InputAdornment position="end">
			// 											<IconButton
			// 												aria-label="toggle password visibility"
			// 												onClick={handleClickShowPassword2}
			// 												onMouseDown={handleMouseDownPassword2}
			// 											>
			// 												{showPassword2 ? <Visibility /> : <VisibilityOff />}
			// 											</IconButton>
			// 										</InputAdornment>
			// 									}
			// 								/>
			// 							</FormControl>
			// 							{
			// 								pswdError &&
			// 								<p className='text-danger small'>
			// 									Passwords do not match!<br />
			// 								</p>
			// 							}
			// 							{
			// 								pswdValidations.length > 0 &&
			// 								<p className='text-danger small'>
			// 									<ul>
			// 										{
			// 											pswdValidations.map((error, index) => {
			// 												return <li key={index}>{error}</li>
			// 											})
			// 										}
			// 									</ul>
			// 								</p>
			// 							}
			// 							<Button onClick={handleResetPassword} variant='contained' className='mt-4' sx={{...otpbuttonStyles,"&:hover":otpHoverStyles}}>Reset Password</Button>
			// 						</div>
			// 					</div>
			// 				</DialogContent>
      //         </>
			// 				:
			// 				null
  
			// 		}


      //   </Dialog>
      
      <Dialog
      open={props.isOpen}
      // onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      sx={{ borderRadius: '1rem' }}
      style={{ top: '-10%' }}
      classes={{
          paper: classes.dialogPaper,
      }}
  
      onClose={handleClose}
  >

      {
          step === 1 ?
              <>
          <DialogTitle sx={{ paddingBottom: '0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className={classes['title-container']} style={{margin:'0 auto'}}>
              Forgot Your Password?
          </div>
          <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
              <Close />
          </IconButton>
      </DialogTitle>
                  <DialogContent>
                      <div className={classes['container']}>
                          <div className={classes['text-content']}>
                              <h6>Enter the email associated with your account and we’ll email you a OTP to reset your password</h6>
                          </div>
                          <div className={classes['input-container']}>
                              <TextField
                                  placeholder="Email"
                                  sx={{ borderRadius: "3rem !important", marginTop: '1rem' }}
                                  error={emailError}
                                  helperText={emailError ? helperText : ""}
                                  id="email-1"
                                  label="Email ID"
                                  variant="standard"
                                  value={email2}
                                  onChange={(evt) => setEmail2(evt.target.value)}
                                  disabled={isOTPSent}
                              />
                          </div>
                      </div>
                      <div className={classes['button-container']}>
                          <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center" width="80%">
                              {sendOTPLoading ? <CircularProgress /> : <Button sx={{ ...buttonStyles, "&:hover": hoverStyles }} onClick={handleSendOTP} variant='contained' disabled={isOTPSent}>Send OTP</Button>}
                              <Button
                                  type="submit"
                                  sx={{ ...buttonStyles2, "&:hover": hoverStyles }}
                                  onClick={closeModal}
                              >
                                  Cancel
                              </Button>
                          </Box>
                      </div>
                  </DialogContent>
              </>
              : null
      }
      {
          step === 2 ?
              <>
                    <DialogTitle sx={{ paddingBottom: '0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className={classes['title-container']} style={{margin:'0 auto'}}>
              Forgot Your Password?
          </div>
          <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
              <Close />
          </IconButton>
      </DialogTitle>
                  <DialogContent>
                      <div style={{ textAlign: 'center' }}><h6 className='bold mt-4 text-align-center'>A 6 digit code has been sent to your email address: {useremail} </h6></div>
                      <div className='mt-3 w-100 row'>
                          <div className='col-12 d-flex flex-column justify-content-center '>
                              <div className={`${classes['otp-input']} d-flex flex-column justify-content-center align-items-center  rounded`}>
                                  <p className='text-muted small m-0' style={{ fontWeight: 'bold' }}>Enter OTP</p>
                                  <OtpInput
                                      disabled={true}
                                      value={otp}
                                      onChange={setOtp}
                                      numInputs={6}
                                      inputType='number'
                                      renderInput={(props) => <input {...props}
                                          className='d-flex justify-content-center'
                                      />}
                                  />
                                  {invalidOTP ? <p className='text-danger small'>{OTPErrorText}</p> : null}
                                  <div style={{ display: 'flex', 'justifyContent': 'center', alignItems: 'center', marginTop: '1em' }}>
                                      {reSendOTPLoading ? <CircularProgress /> : <Button disabled={!isOTPSent} onClick={handleResendOTP} sx={{ ...otpbuttonStyles, "&:hover": otpHoverStyles }}>Resend OTP</Button>}
                                      {confirmOTPLoading ? <CircularProgress /> : <Button disabled={!isOTPSent} variant='contained' sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }} onClick={handleConfirmOTP} >Confirm OTP</Button>}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </DialogContent>
              </>
              : null
      }
      {
          step === 3 ?
              <>
                  <DialogTitle sx={{ paddingBottom: '0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div className={classes['title-container']}>
                          Reset Password
                      </div>
                      <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
              <Close />
          </IconButton>
                  </DialogTitle>
                  <DialogContent>
                      <div className='mt-3 row'>
                          <div className='col-12 d-flex flex-column justify-content-center'>
                              <p className='text-muted small ' style={{ fontWeight: 'bold' }}>Create a New Password (Minimum 8 characters, including uppercase and lowercase letters, numbers, and symbols)</p>
                              <FormControl variant="standard">
                                  <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                                  <Input
                                      id="standard-adornment-password"
                                      type={showPassword1 ? 'text' : 'password'}
                                      onChange={(evt) => setPassword1(evt.target.value)}
                                      endAdornment={
                                          <InputAdornment position="end">
                                              <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword1}
                                                  onMouseDown={handleMouseDownPassword1}
                                              >
                                                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                          </InputAdornment>
                                      }
                                  />
                              </FormControl>
                              <FormControl className='mt-4' sx={{ marginTop: '1.5em' }} variant="standard">
                                  <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                                  <Input
                                      sx={{ ...resetInputStyles }}
                                      id="standard-adornment-password"
                                      type={showPassword2 ? 'text' : 'password'}
                                      onChange={(evt) => setPassword2(evt.target.value)}
                                      endAdornment={
                                          <InputAdornment position="end">
                                              <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword2}
                                                  onMouseDown={handleMouseDownPassword2}
                                              >
                                                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                          </InputAdornment>
                                      }
                                  />
                              </FormControl>
                              {pswdError && <p className='text-danger small'>Passwords do not match!<br /></p>}
                              {pswdValidations.length > 0 && <p className='text-danger small'>
                                  <ul>
                                      {pswdValidations.map((error, index) => <li key={index}>{error}</li>)}
                                  </ul>
                              </p>}
                              <Button onClick={handleResetPassword} variant='contained' className='mt-4' sx={{ ...otpbuttonStyles, "&:hover": otpHoverStyles }}>Reset Password</Button>
                          </div>
                      </div>
                  </DialogContent>
              </>
              : null
      }
  </Dialog>
        
    )
}

export default ForgotPasswordModal
