import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Button,
  Box,
  IconButton,
  Typography,
  Snackbar,
  LinearProgress,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Radio,
  TableBody,
  Popover,
  Tooltip, DialogActions,
  Grid,
} from '@mui/material';
import { FormControlLabel, FormGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { ReactComponent as DomainHead } from "../../Assets/DomainHead.svg";
import { getCookie } from '../../Services/Cookie/Cookie';
import Navbar from '../Navbar/Navbar';
import { TableVirtuoso } from 'react-virtuoso';
import Swal from 'sweetalert2';
import { Card, CardContent } from '@mui/material';
import classes from "./Task.module.css"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';

import { InputText } from 'primereact/inputtext'
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import "../Admin/hideScrollbar.css"

import dayjs from 'dayjs';
import UserTitle from '../Admin/UserTitle'
import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, Select, ListItemText } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./Task.module.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Search, Edit, PauseCircleFilled, CheckCircle, } from '@mui/icons-material';

import RejectIcon from "../../Assets/decline.png";
import PendingIcon from "../../Assets/pending.png";
import ReassignIcon from "../../Assets/Reassign.png";


const Task = (props) => {
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const {tabValue,project_owner_approved, setFullView, fullView, statusColors, projectDueDate, projectUserList, projectOwner, domainOwner, domainId, projectId, userList, domainsLists, projectLists, modal, setTaskActivated, taskData, userDetails, newTask, fetchAllTask } = props

  const [taskName, setTaskName] = useState('');
  const [taskUserList, setTaskUserList] = useState([])
  const [editTask, setEditTask] = useState(false)
  const [taskDescription, setTaskDescription] = useState('');
  const [domainList, setDomainList] = useState(domainsLists || []);
  const [projectList, setProjectList] = useState(projectLists || [])
  const [selectedDomain, setSelectedDomain] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [repeat, setRepeat] = useState('');
  // const [dueDate, setDueDate] = useState(getCurrentDate());
  const [dueDate, setDueDate] = useState(getCurrentDate());
  const [assignedOn, setAssignedOn] = useState('');
  const [status, setStatus] = useState([]);

  const [isPaused, setIsPaused] = useState(false);

  const [isSuperUser, setSuperUser] = useState(false)
  const [projectEditAccess, setProjectEditAccess] = useState(false)

  const [date, setDate] = useState('')
  const [users, setUsers] = useState(userList || [])



  const [interval, setInterval] = useState(1);
  const [timeUnit, setTimeUnit] = useState('days');
  const [endOption, setEndOption] = useState('never');
  const [endDate, setEndDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  const [assignTypeUser, setAssignTypeUser] = useState([]);

  const [assignList, setAssignList] = useState([])
  const [assingType, setAssignType] = useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [notes, setNotes] = useState('')
  const [taskActive, setTaskActive] = useState(0)

  // const [projectDueDate,setProjectDueDate] = useState(null);

  const handleSnackClose = () => {
    setSnackbarOpen(false);
  };

  const formatFileName = (fileNames) => {
    let firstHyphenIndex = fileNames.indexOf('-');
    let trimmedFilename = fileNames.substring(firstHyphenIndex + 3);

    let lastUnderscoreIndex = trimmedFilename.lastIndexOf('||');
    let finalFilename = trimmedFilename.substring(0, lastUnderscoreIndex) + '.csv';
    return finalFilename
  }


  const handleTaskActiveToggle = () => {
    setIsPaused(!isPaused);
    setSwalActive(true)
    const response = !isPaused ? 1 : 0;
    setTaskActive(response)

  };



  const handleS3FileDownload = async (file_id, fileNames) => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const data = new FormData();
      data.append('file_id', file_id);

      let firstHyphenIndex = fileNames.indexOf('-');
      let trimmedFilename = fileNames.substring(firstHyphenIndex + 3); // Result: "Product_Details_All_2024-06-29_09_00.csv"

      // Step 2: Remove the extra digits at the end
      let lastUnderscoreIndex = trimmedFilename.lastIndexOf('||');
      let finalFilename = trimmedFilename.substring(0, lastUnderscoreIndex) + '.csv';
      const response = await axios.post(`/backend/get_task_resource_s3`, data, config);
      // const url = response.data.presigned_url;
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", finalFilename);
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // URL.revokeObjectURL(url);

      const url = response.data.presigned_url;
      const fileResponse = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([fileResponse.data]);

      // Create a link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", finalFilename);

      // Append the link to the body, trigger the click, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL to free up memory
      URL.revokeObjectURL(link.href);


    } catch (error) {
      console.error('Error creating task', error);
    }
  }
  const handleTimeUnitChange = (e) => {
    setTimeUnit(e.target.value);
  };

  // const handleEndOptionChange = (e) => {
  //   setEndOption(e.target.value);
  // };

  const handleDayChange = (e) => {
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(e)) {
        return prevSelectedDays.filter((day) => day !== e);
      } else {
        return [...prevSelectedDays, e];
      }
    });
  };


  const fetchAssignTypeUser = async (assing_id) => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get(`/backend/get_all_skill_user/${assing_id}`, config);

      // setAssignUser(response.data)
      setAssignTypeUser(response.data)

    } catch (error) {
      console.error('Error creating task', error);
    }
  }

  const fetchAssignType = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let response = await axios.get(
        `/backend/get_skill_set_list`,
        config
      );

      let assingType = response.data;
      setAssignList(assingType)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  }


  const assingSortedUser = Array.isArray(assignTypeUser) ? assignTypeUser.slice().sort((a, b) => {
    if (selectedDomain == null) {
      return []
    }
    if (a.core_domain === selectedDomain.domain_id) return -1;
    if (b.core_domain === selectedDomain.domain_id) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;



  const dateStyles = {
    height: '2.5em',
    border: '1px solid gainsboro', // Corrected spelling of 'gainsboro'
    borderRadius: '2px', // Uncomment and set borderRadius if needed
  };

  const [subtasks, setSubtasks] = useState([])


  const subTaskContainerStyle = {
    marginBottom: '20px',
    padding: '2px',
    backgroundColor: 'rgba(217, 217, 217, 0.22)',
    borderRadius: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '99%',
    marginLeft: '0.20em',
    paddingBottom: '1em'
  }

  const sortedUsers = Array.isArray(users) ? users.slice().sort((a, b) => {
    if (domainId == null) {
      return []
    }
    if (a.core_domain === domainId) return -1;
    if (b.core_domain === domainId) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;

  const [deletePrivileges, setDeletePrivileges] = useState(false)
  const [userName, setUserName] = useState('')


  useEffect(() => {
    setUsers(userList)
    // setDomainList(domainsLists)
    // setProjectList(projectLists)
    // taskDetails(props.taskDetails)

    if (newTask) {

      setEditTask(false)
      setOpen(true)
      setRepeat(0)

      let selectedDomains = domainList.find(domain => domain.domain_id === domainId);
      let selectedProject = projectList.find(project => project.project_id === projectId);
      setSelectedDomain(selectedDomains)
      setSelectedProject(selectedProject)
    }
    else {
      taskDetails(taskData)
    }

    fetchAssignType()
    let superUser = userDetails.global_privileges.domain_creation != 'none' ? true : false;
    // let projectEditAccess = (userDetails.global_privileges.domain_creation != 'none' ? true : false || userDetails.personal_privileges[domainId]['project'][projectId] != 'view' ? true : false || userDetails.personal_privileges[domainId]['access'] != 'view') ? true : false;
    // let deleteAccess = (userDetails.global_privileges.domain_creation === "full" || userDetails.personal_privileges[domainId]['project'][projectId] === "full" || userDetails.personal_privileges[domainId]['access'] == 'full') ? true : false;


    let projectEditAccess = (
      (userDetails.username === taskOwnerDetails && taskApproved == 1) ||
      // userDetails.username === projectOwner ||
      userDetails.global_privileges.domain_creation !== 'none' ||
      (userDetails.personal_privileges[domainId]?.project?.[projectId] !== 'view' && userDetails.personal_privileges[domainId]?.project?.[projectId] !== 'view|a') ||
      (userDetails.personal_privileges[domainId]?.access !== 'view' && userDetails.personal_privileges[domainId]?.access !== 'view|a')
    ) ? true : false;
    let deleteAccess = (
      userDetails.global_privileges.domain_creation === 'full' ||
      (userDetails.personal_privileges[domainId]?.project?.[projectId] === 'full') ||
      (userDetails.personal_privileges[domainId]?.access === 'full')
    ) ? true : false;
    setSuperUser(superUser)
    setProjectEditAccess(projectEditAccess)
    setDeletePrivileges(deleteAccess)
    setUserName(userDetails.username)



    // fetchDomain()
  }, [modal])


  const [domainsList, setDomainsList] = useState([])
  const fetchProjects = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let is_active_ = 1
      let projectResponse = await axios.get(`/backend/get_all_projects_list/${domainId}/${is_active_}`, config);
      setProjectList(projectResponse.data)
    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }

  }

  const getStatusColorAndText = (taskStatus) => {
    for (let key in statusColors) {
      if (statusColors[key][taskStatus]) {
        return {
          color: statusColors[key][taskStatus],
          text: taskStatus.charAt(0).toUpperCase() + taskStatus.slice(1)
        };
      }
    }
    return {
      color: 'black',
      text: taskStatus.charAt(0).toUpperCase() + taskStatus.slice(1)
    };
  };

  const [taskStatus, setTaskStatus] = useState('')
  const [taskId, setTaskId] = useState('')
  const [subTaskOwnerDetails, setSubTaskOwnerDetails] = useState([])
  const [taskOwnerDetails, setTaskOwnerDetails] = useState('')
  const [taskApproved, setTaskApproved] = useState(null)

  const [taskInfo, setTaskInfo] = useState({})
  const taskDetails = async (taskDetailsList) => {
    let selectedUsers = users.find(user => user.username === taskDetailsList.task_owner);
    let selectedDomains = domainList.find(domain => domain.domain_id === taskDetailsList.domain_id);
    let projectLists = projectList.find(project => project.project_id === taskDetailsList.project_id)



    const names = [];

    if (taskDetailsList.task_owner) {
      names.push(taskDetailsList.task_owner);
    }

    // Add subtask assigned names
    if (taskDetailsList.sub_task) {
      for (const key in taskDetailsList.sub_task) {
        if (taskDetailsList.sub_task.hasOwnProperty(key)) {
          const subTask = taskDetailsList.sub_task[key];
          if (subTask.sub_task_assign_to) {
            names.push(subTask.sub_task_assign_to);
          }
        }
      }
    }

    let taskInfoDetails = {
      "task_owner_backup": selectedUsers,
      "task_reassign_status": 0
    }
    setEditTask(true)
    setSubTaskOwnerDetails(names)
    setTaskOwnerDetails(taskDetailsList['task_owner'])
    setTaskInfo(taskInfoDetails)

    setTaskName(taskDetailsList['task_name'])
    setTaskUserList(taskDetailsList['task_users_list'])
    setSelectedUser(selectedUsers)
    setSelectedDomain(selectedDomains)
    setSelectedProject(projectLists)
    setTaskDescription(taskDetailsList['task_description'])
    setTaskStatus(taskDetailsList['status'])
    setTaskId(taskDetailsList['task_id'])
    setAssignedOn(taskDetailsList['assigned_on'])
    setTaskApproved(taskDetailsList['approval_status'])
    setCritical(taskDetailsList['is_critical'])

    // setSelectedUser(taskDetailsList['assign_to'])
    setIsRecurring(taskDetailsList['is_recurring'])
    // setRecurringType(taskDetailsList['recurring_type'])
    setStatus(taskDetailsList['open'])
    setDueDate(taskDetailsList['deadline'])

    for (let subtaskId in taskDetailsList['sub_task']) {
      if (taskDetailsList['sub_task'][subtaskId].is_approved === 0) {
        taskDetailsList['sub_task'][subtaskId].sub_task_assign_type_backup = taskDetailsList['sub_task'][subtaskId].sub_task_assign_type
        taskDetailsList['sub_task'][subtaskId].sub_task_assign_type = null
        taskDetailsList['sub_task'][subtaskId].sub_task_assign_to_backup = taskDetailsList['sub_task'][subtaskId].sub_task_assign_to
        taskDetailsList['sub_task'][subtaskId].sub_task_reassign_status = 0
        taskDetailsList['sub_task'][subtaskId].is_reapproved = null
      }
      taskDetailsList['sub_task'][subtaskId].start_datetime_bkp = taskDetailsList['sub_task'][subtaskId].start_datetime
      taskDetailsList['sub_task'][subtaskId].end_datetime_bkp = taskDetailsList['sub_task'][subtaskId].end_datetime
    }

    setSubtasks(taskDetailsList['sub_task']);
    setRepeat(taskDetailsList['is_recurring'])
    setInterval(taskDetailsList['recurring_data']['repeat_every'])
    setTimeUnit(taskDetailsList['recurring_data']['type'])
    setSelectedDays(taskDetailsList['recurring_data']['repeat_on'])
    setEndDate(taskDetailsList['recurring_data']['end'])

    setNotes(taskDetailsList['note'])

    if (taskDetailsList['status'] == 'on hold') {
      setIsPaused(1)
      setTaskActive(1)

    }
    else {
      setIsPaused(0)
      setTaskActive(0)

    }
    let newOptionText = ''
    if (taskDetailsList['is_recurring'] == 1) {


      let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      let weeklyDays = '';

      if (taskDetailsList['recurring_data']['type'] == "weeks" || taskDetailsList['recurring_data']['type'] == "week") {
        taskDetailsList['recurring_data']['repeat_on'].forEach((index) => {

          weeklyDays += days[index - 1] + ',';

        });
      }

      if (weeklyDays.endsWith(',')) {
        weeklyDays = weeklyDays.slice(0, -1);
      }


      if ((taskDetailsList['recurring_data']['type'] == "days" || taskDetailsList['recurring_data']['type'] == "day") && (taskDetailsList['recurring_data']['end'] != undefined && taskDetailsList['recurring_data']['end'] != null)) {
        if (taskDetailsList['recurring_data']['repeat_every'] == '1') {
          newOptionText = `Daily ${taskDetailsList['recurring_data']['end'].includes('9999') ? '' : ` until ${taskDetailsList['recurring_data']['end']}`}`
        }
        else {
          newOptionText = `Every ${taskDetailsList['recurring_data']['repeat_every']} days ${taskDetailsList['recurring_data']['end'].includes('9999') ? '' : ` until ${taskDetailsList['recurring_data']['end']}`}`
        }
      }
      else if ((taskDetailsList['recurring_data']['type'] == "days" || taskDetailsList['recurring_data']['type'] == "day") && (taskDetailsList['recurring_data']['end'] == undefined || taskDetailsList['recurring_data']['end'] == null)) {
        if (taskDetailsList['recurring_data']['repeat_every'] == '1') {
          newOptionText = `Daily `
        }
        else {
          newOptionText = `Every ${taskDetailsList['recurring_data']['repeat_every']} days `
        }
      }
      else if ((taskDetailsList['recurring_data']['type'] == "weeks" || taskDetailsList['recurring_data']['type'] == "week") && (taskDetailsList['recurring_data']['end'] != undefined && taskDetailsList['recurring_data']['end'] != null)) {
        if (taskDetailsList['recurring_data']['repeat_every'] == '1') {
          newOptionText = `Every weeks on ${weeklyDays} ${taskDetailsList['recurring_data']['end'].includes('9999') ? '' : ` until ${taskDetailsList['recurring_data']['end']}`}`
        }
        else {
          newOptionText = `Every ${taskDetailsList['recurring_data']['repeat_every']} weeks on ${weeklyDays} ${taskDetailsList['recurring_data']['end'].includes('9999') ? '' : ` until ${taskDetailsList['recurring_data']['end']}`}`
        }
      }
      else if ((taskDetailsList['recurring_data']['type'] == "weeks" || taskDetailsList['recurring_data']['type'] == "week") && (taskDetailsList['recurring_data']['end'] == undefined || taskDetailsList['recurring_data']['end'] == null)) {
        if (taskDetailsList['recurring_data']['repeat_every'] == 1) {
          newOptionText = `Every weeks on ${weeklyDays} `
        }
        else {
          newOptionText = `Every ${taskDetailsList['recurring_data']['repeat_every']} weeks on ${weeklyDays} `
        }
      }
      setRepeat(2)
    }
    else {
      setRecurring(taskDetailsList['is_recurring'])
      setRepeat(taskDetailsList['is_recurring'])

    }
    const newOption = { value: 2, label: newOptionText };


    const updatedOptions = options.filter(option => option.value !== 2);

    // Add the new dynamic option
    updatedOptions.push(newOption);

    setOptions(updatedOptions);
    // setRepeat(2)



    setOpen(true)

    let subtask_length = taskDetailsList['sub_task']
    let length = Object.keys(subtask_length).length
    const keys = Object.keys(subtask_length);
    let newKey;
    if (keys.length === 0) {
      newKey = 1;
    } else {
      const numericKeys = keys.map(key => parseInt(key, 10));
      const maxKey = Math.max(...numericKeys);
      newKey = maxKey + 1;
    }
    setSubtaskLength(newKey)

  }


  const getStatusOptions = (currentStatus) => {
    return statusColors[currentStatus] ? Object.keys(statusColors[currentStatus]) : [];
  };

  const setDomainId = async (event, value) => {
    setSelectedDomain(value)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    try {
      let is_active_ = 1
      let projectResponse = await axios.get(`/backend/get_all_projects_list/${value.domain_id}/${is_active_}`, config);
      setProjectList(projectResponse.data)
    } catch (error) {
      console.error("There was an error fetching the domain data!", error);
    }

  }
  const setProjectId = (event, value) => {
    setSelectedProject(value)
  }

  const fetchAllUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/ALL`, config);

      setUsers(response.data)
      // fetchProjectDetails()
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }


  const [allDomainList, setAllDomainList] = useState([])

  const [swalActive, setSwalActive] = useState(false)


  const fetchDomain = async () => {
    let is_active = 'All';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let allDomainResponse = await axios.get(
        `/backend/get_all_domain_list/${is_active}`,
        config
      );

      let domainResponse = await axios.get(
        `/backend/get_user_domain_list/${is_active}`,
        config
      );

      let domainData = domainResponse.data;
      setDomainList(domainData)
      setAllDomainList(allDomainResponse.data)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };


  const handleClickOpen = () => {
    setOpen(true);
    setEditTask(true)
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClose = async () => {

    if (swalActive) {
      const title = 'Unsaved Changes';
      const confirmButtonText = 'OK';
      const cancelButtonText = 'Cancel';

      const result = await Swal.fire({
        title,
        text: 'Do you want to exit?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        customClass: {
          container: 'custom-swal-container'
        }
      });

      if (!result.isConfirmed) {
        return;
      }
      setOpen(false);
      setTaskName('');
      setEditTask(false);
      setSwalActive(false)

      setTaskDescription('');
      // setDomainList([]);
      setProjectList([]);
      setSelectedDomain(null);
      setSelectedProject(null);
      setSelectedUser(null);
      setRepeat('');
      setDueDate('');
      setAssignedOn([]);
      setStatus([]);
      setDate('');
      setStatus('')
      // setUsers([]);
      setSubtasks([]);
      setStatus('')
      props.setTaskActivated(false)

    }
    else {
      setSwalActive(false)
      setOpen(false);
      setTaskName('');
      setEditTask(false);

      setTaskDescription('');
      // setDomainList([]);
      setProjectList([]);
      setSelectedDomain(null);
      setSelectedProject(null);
      setSelectedUser(null);
      setRepeat('');
      setDueDate('');
      setAssignedOn([]);
      setStatus([]);
      setDate('');
      setStatus('')
      // setUsers([]);
      setSubtasks([]);
      setStatus('')
      props.setTaskActivated(false)
    }
  };

  const [open, setOpen] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [isRecurring, setIsRecurring] = useState(0)
  const [recurringType, setRecurringType] = useState()
  const [recurryingDialog, setRecurringDialog] = useState(false)
  const [finalRecurringStatus, setFinalRecurringStatus] = useState({})


  const [projectUser, setProjectUser] = useState()
  const [assignOwner, setAssignOwner] = useState([])



  const handleSelectUser = (value) => {

    setSelectedUser(value)
    setAssignOwner([])


    if (value) {
      if (editTask) {
        if (value.username === taskInfo['task_owner_backup'].username) {
          return
        }
      }

      let project_user = false


      let userDomainOwner = domainList.filter((item) => {
        return item.owner.trim().toLowerCase() === userDetails.username.trim().toLowerCase();
      });

      let doesUsernameExist = userDomainOwner.some(item => item.domain_id === value.core_domain);


      // let domainAccess = value.personal_privileges?.[domainId]?.access;
      // if ((domainAccess != 'view' && domainAccess != 'view|a' && domainAccess != undefined)) {
      //   domainAccess = true
      // }
      // else {
      //   domainAccess = false
      // }
      if ((projectUserList.includes(value.username) || taskUserList.includes(value.username) ||
        doesUsernameExist))
      {
        project_user = true
      }
      const result = project_user;
      if (!result) {
        setAssignOwner(prevExtendedList => {
          const newDomain = {
            user_domain_id: value.core_domain,
            domain_name: value.domain_name,
            head: domainsLists.find(domainItem => domainItem.domain_id === value.core_domain)?.owner || '',
            total_users: 1,
            project_owner: 1,
            user_list: [value.username]

          };
          return [...prevExtendedList, newDomain];

        });
      }
    }

  }





  const [extendedDomainUser, setExtendedDomainUser] = useState([])

  const handleTaskUser = (value) => {
    if (value.core_domain != domainId) {
      setExtendedDomainUser(prevExtendedDomainUser => {
        const updatedExtendedDomainList = [...prevExtendedDomainUser];
        const existingDomainIndex = updatedExtendedDomainList.findIndex(domain => domain.domain_id === value.core_domain);

        if (existingDomainIndex !== -1) {
          if (!updatedExtendedDomainList[existingDomainIndex].user_list.includes(value.username)) {
            updatedExtendedDomainList[existingDomainIndex].user_list.push(value.username);
          }
        } else {
          const newDomain = {
            domain_id: value.core_domain,
            domain_name: domainsLists.find(domainItem => domainItem.domain_id === value.core_domain)?.domain_name || '',
            user_list: [value.username],
            head: domainsLists.find(domainItem => domainItem.domain_id === value.core_domain)?.owner || '',
          };
          updatedExtendedDomainList.push(newDomain);
        }

        return updatedExtendedDomainList;
      });
    }

  }





  const handleSubtaskChange = (index, field, value) => {

    setSwalActive(true)
    if (field == "subtask_performance_score") {
      const numericValue = Number(value);
      if (numericValue < 0 && numericValue > 5 && value.length != 1) {
        return
      }
    }
    const updatedSubtasks = { ...subtasks };
    // updatedSubtasks[index][field] = value;
    updatedSubtasks[index] = {
      ...updatedSubtasks[index],
      [field]: value,
    };
    setSubtasks(updatedSubtasks);
    setSubtask(false)

    if (editTask) {
      if (field == "sub_task_reassign_status") {
        if (!value) {
          setAssignTypeUser([])

          updatedSubtasks[index] = {
            ...updatedSubtasks[index],
            ['sub_task_assign_to']: null,
            ['sub_task_assign_type']: null,
          };
          setSubtasks(updatedSubtasks);
          setSubtask(false)
        }
        else {
          setAssignTypeUser([])

          updatedSubtasks[index] = {
            ...updatedSubtasks[index],
            ['sub_task_assign_to']: updatedSubtasks[index]['sub_task_assign_to_backup'],
            ['sub_task_assign_type']: updatedSubtasks[index]['sub_task_assign_type_backup'],
          };
          setSubtasks(updatedSubtasks);
          setSubtask(false)
        }

      }
    }

    if (field === 'sub_task_assign_to') {

      if (value) {
        if (editTask) {
          if (value.username === updatedSubtasks[index]['sub_task_assign_to_backup']) {
            return
          }
        }

        let domainAccess = value.personal_privileges?.[domainId]?.access;
        // if (value.core_domain != domainId && !(taskUserList.includes(value.username) || domainAccess || (projectUserList.includes(value.username)))) {
        // if ((domainAccess != 'view' && domainAccess != 'view|a' && domainAccess != undefined)) {
        //   domainAccess = true
        // }
        // else {
        //   domainAccess = false
        // }
        let userDomainOwner = domainList.filter((item) => {
          return item.owner.trim().toLowerCase() === userDetails.username.trim().toLowerCase();
        });
        let doesUsernameExist = userDomainOwner.some(item => item.domain_id === value.core_domain);


        if (value.core_domain != domainId && !(taskUserList.includes(value.username) || doesUsernameExist || (projectUserList.includes(value.username)))) {
          setExtendedDomainUser(prevExtendedDomainUser => {
            const updatedExtendedDomainList = [...prevExtendedDomainUser];
            const existingDomainIndex = updatedExtendedDomainList.findIndex(domain => domain.domain_id === value.core_domain);

            if (existingDomainIndex !== -1) {
              if (!updatedExtendedDomainList[existingDomainIndex].user_list.includes(value.username)) {
                updatedExtendedDomainList[existingDomainIndex].user_list.push(value.username);
              }
            } else {
              const newDomain = {
                domain_id: value.core_domain,
                domain_name: domainsLists.find(domainItem => domainItem.domain_id === value.core_domain)?.domain_name || '',
                user_list: [value.username],
                head: domainsLists.find(domainItem => domainItem.domain_id === value.core_domain)?.owner || '',
              };
              updatedExtendedDomainList.push(newDomain);
            }

            return updatedExtendedDomainList;
          });
        }
      }
    }

    if (field === "sub_task_assign_type") {

      if (value == null) {
        setAssignType(null)
      }
      if (value) {
        setAssignType(value.skill_id)
        fetchAssignTypeUser(value.skill_id)
        updatedSubtasks[index] = {
          ...updatedSubtasks[index],
          ['sub_task_assign_to']: null,
        };
        setSubtasks(updatedSubtasks);

      }
      else {
        setAssignTypeUser([])
        updatedSubtasks[index] = {
          ...updatedSubtasks[index],
          ['sub_task_assign_to']: null,
        };
        setSubtasks(updatedSubtasks);
        setSubtask(false)
      }
    }




  };

  const handleDeleteSubtask = (index) => {
    const updatedSubtasks = { ...subtasks };
    delete updatedSubtasks[index];
    setSubtasks(updatedSubtasks);
    const updatedUploadedFiles = uploadedFiles.filter(file => !file.name.startsWith(`${index}-`));
    setUploadedFiles(updatedUploadedFiles);
    setSubtask(false);
    setSwalActive(true)
  };




  // New one
  const handleAddSubTaskEdit = () => {
    setSwalActive(true)

    let newTimeUnit
    if (timeUnit === "days") {
      newTimeUnit = 'day'
    } else if (timeUnit === "weeks") {
      newTimeUnit = 'week'
    }
    else {
      newTimeUnit = timeUnit
    }
    let recurringDict = {
      "type": newTimeUnit,
      "repeat_every": interval,
      "repeat_on": selectedDays,
      "end": endDate
    }
    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }

    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }



    const newSubtask = {
      sub_task_name: '',
      sub_task_description: '',
      start_datetime: '',
      effort_hours: 0,
      end_datetime: '',
      remarks: '',
      sub_task_assign_to: null,
      is_file_attached: 0,
      file_name: [],
      isEditable: true,
      performance_score: '',
      sub_task_status: 'not picked',
    };

    setSubtaskLength(prevState => prevState + 1);
    let newLength = subtaskLength + 1
    // const newSubtaskKey = (Object.keys(subtasks).length + 1).toString();

    const keys = Object.keys(subtasks);
    let newKey;
    if (keys.length === 0) {
      newKey = 1;
    } else {
      const numericKeys = keys.map(key => parseInt(key, 10));
      const maxKey = Math.max(...numericKeys);
      newKey = maxKey + 1;
    }
    setSubtasks({
      ...subtasks,
      [subtaskLength]: newSubtask
    });
    setNewSubtaskKey(newKey);
    setTimeout(() => {
      if (newSubtaskRef.current) {
        newSubtaskRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);


  };

  const [newSubtaskKey, setNewSubtaskKey] = useState(null);
  const newSubtaskRef = useRef(null);


  const handleAddSubTaskNew = () => {

    setSwalActive(true)

    let newTimeUnit
    if (timeUnit === "days") {
      newTimeUnit = 'day'
    } else if (timeUnit === "weeks") {
      newTimeUnit = 'week'
    }
    else {
      newTimeUnit = timeUnit
    }
    let recurringDict = {
      "type": newTimeUnit,
      "repeat_every": interval,
      "repeat_on": selectedDays,
      "end": endDate
    }
    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }

    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }



    const newSubtask = {
      sub_task_name: '',
      sub_task_description: '',
      start_datetime: '',
      effort_hours: 0,
      end_datetime: '',
      remarks: '',
      sub_task_assign_to: null,
      is_file_attached: 0,
      file_name: [],
      performance_score: '',
      sub_task_status: 'not picked',

    };



    setSubtaskLength(prevLength => prevLength + 1);
    setSubtasks(prevSubtasks => ({
      ...prevSubtasks,
      [subtaskLength]: newSubtask,
    }));

    setNewSubtaskKey(subtaskLength);


    setTimeout(() => {
      if (newSubtaskRef.current) {
        newSubtaskRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);



  };



  useEffect(() => {
    if (newSubtaskKey) {
      const element = document.getElementById(newSubtaskKey);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [newSubtaskKey]);





  const [subtaskLength, setSubtaskLength] = useState(1)
  const [innerSubFiles, setInnerSubFiles] = useState({})



  const handleFileUpload = (index, files) => {
    const maxSizeInBytes = 512 * 1024; 

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSizeInBytes) {
        setSnackbarOpen(true);
        setMessage(`The file "${file.name}" exceeds the maximum size of 512 KB.`);
        setSeverity('warning');
      
        return;
      }
    }

    const updatedSubtasks = { ...subtasks };
    const subtask = updatedSubtasks[index];
    let value;

    // Update innerSubFiles state
    if (!innerSubFiles.hasOwnProperty(index)) {
      let initialvalue = 0;
      setInnerSubFiles(prevState => ({
        ...prevState,
        [index]: initialvalue
      }));
      value = 0;
    } else {
      value = innerSubFiles[index] + 1;
      setInnerSubFiles(prevState => ({
        ...prevState,
        [index]: value
      }));
    }

    const existingFileCount = value;

    // Generate new file names with unique identifiers
    const newFileNames = Array.from(files).map((file, i) => {
      const unixTime = Math.floor(Date.now() / 1000);
      const fileNameWithoutExtension = file.name.replace(/\s+/g, '_').replace(/(.*)\.(.*)/, `$1||${unixTime}.$2`);
      return `${index}-${existingFileCount + i + 1}-${fileNameWithoutExtension}`;
    });

    // Update subtask file names
    subtask.file_name = subtask.file_name.filter(name => {
      const [fileIndex, , ...fileNameParts] = name.split('-');
      const baseFileName = fileNameParts.join('-').split('||')[0]; // Extract the base file name
      return !(fileIndex == index && newFileNames.some(newName => {
        const [newIndex, , ...newFileNameParts] = newName.split('-');
        const newBaseFileName = newFileNameParts.join('-').split('||')[0]; // Extract the new base file name
        return fileIndex == newIndex && baseFileName === newBaseFileName;
      }));
    });

    subtask.file_name = subtask.file_name.concat(newFileNames);
    subtask.is_file_attached = subtask.file_name.length;

    setSubtasks(updatedSubtasks);

    // Create updated File objects with new file names
    const updatedFiles = Array.from(files).map((file, i) => {
      const newFileName = newFileNames[i];
      return new File([file], newFileName, { type: file.type });
    });

    // Update the uploadedFiles state
    const updatedUploadedFiles = uploadedFiles.filter(file => {
      const [fileIndex, , ...fileNameParts] = file.name.split('-');
      const baseFileName = fileNameParts.join('-').split('||')[0]; // Extract the base file name
      return !(fileIndex == index && updatedFiles.some(newFile => {
        const [newIndex, , ...newFileNameParts] = newFile.name.split('-');
        const newBaseFileName = newFileNameParts.join('-').split('||')[0]; // Extract the new base file name
        return fileIndex == newIndex && baseFileName === newBaseFileName;
      }));
    }).concat(updatedFiles);

    setUploadedFiles(updatedUploadedFiles);
  };






  const handleFileDelete = (subtaskIndex, fileIndex) => {
    const updatedSubtasks = { ...subtasks };
    const subtask = updatedSubtasks[subtaskIndex];

    subtask.file_name = subtask.file_name.filter((_, idx) => idx !== fileIndex);
    subtask.is_file_attached = subtask.file_name.length;

    setSubtasks(updatedSubtasks);

    const updatedFiles = uploadedFiles.filter((_, idx) => idx !== fileIndex);
    setUploadedFiles(updatedFiles);
  };

  const [subtask, setSubtask] = useState(false)
  const [recurrenceType, setRecurrenceType] = useState('');
  const [weeklyDays, setWeeklyDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const handleRecurrenceChange = (event) => {
    setRecurrenceType(event.target.value);
  };

  const handleWeeklyDaysChange = (event) => {
    setWeeklyDays({ ...weeklyDays, [event.target.name]: event.target.checked });
  };


  const [approval, setApproval] = useState(false)
  const DomainList = ({ domainList }) => {
    return (
      <div>
        {domainList.map((domain, index) => (
          <div key={index} className="domain-container m-2 p-2" style={{ border: '1px solid black' }}>
            <div className="domain-item p-1">Domain: <strong>{domain.domain_name}</strong></div>
            <div className="domain-item p-1"><strong>Head: {domain.head}</strong></div>
            <div className="domain-item p-1">
              <strong>UserList: </strong>
              {domain.user_list && domain.user_list.length > 0 ? (
                <div className="user-list">
                  {domain.user_list.map((user, userIndex) => (
                    <span key={userIndex} className="user-item">
                      {user}{userIndex < domain.user_list.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </div>
              ) : (
                <span>No users</span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };



  const handleApprovalClose = () => {
    setApproval(false); // Close dialog on click outside or ESC key
  };

  const handleApprovalConfirm = () => {
    setApproval(false);
    taskEdit()
  };

  const handleCreateTaskApprovalClose = () => {
    setApproval(false); // Close dialog on click outside or ESC key
  };

  const handleCreateTaskApproval = () => {
    setApproval(false);
    createTask()
  };

  const confirmbuttonStyles = {
    border: "1px solid black",
    backgroundColor: "rgba(28, 28, 28, 1)",
    borderRadius: "0.75rem",
    color: "rgba(238, 225, 213, 1)",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '9em'
  }
  const confirmHoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  }
  const buttonStyles2 = {
    border: "1px solid black",
    backgroundColor: "#EBEBEB",
    borderRadius: "0.75rem",
    color: "black",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '9em'
  };

  const hoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  };


  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }


  const taskEdit = async () => {
    let recurringDict = {
      "type": timeUnit,
      "repeat_every": interval,
      "repeat_on": selectedDays,
      "end": endDate
    }
    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }
    let is_approved;
    let is_reapproved;
    if (taskInfo.task_reassign_status == 0) {
      is_approved = taskApproved
      is_reapproved = null
    }
    else {
      is_reapproved = -1
    }

    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }
    function cleanStatus(status) {
      return status.replaceAll(" (overdue)", "");
    }
    Object.keys(subtasks).forEach(key => {
      if (subtasks[key].hasOwnProperty('isEditable')) {
        delete subtasks[key].isEditable;
      }
      if (subtasks[key].hasOwnProperty('sub_task_status')) {
        subtasks[key].sub_task_status = cleanStatus(subtasks[key].sub_task_status);
      }
      if (subtasks[key] && subtasks[key].hasOwnProperty('sub_task_reassign_status')) {
        if (subtasks[key].sub_task_reassign_status == 1) {
          subtasks[key].is_reapproved = -1;
        }
        else {
          subtasks[key].is_reapproved = null;
        }
        delete subtasks[key].sub_task_reassign_status;
        delete subtasks[key].sub_task_assign_to_backup;
        delete subtasks[key].sub_task_assign_type_backup;
      }
    });


    if (Object.keys(subtasks).length == 0) {
      setSnackbarOpen(true)
      setMessage('Minimum one subtask is needed')
      setSeverity('error')
      return
    }


    for (let key in subtasks) {
      if (subtasks.hasOwnProperty(key)) {
        let subTask = subtasks[key];

        if (subTask.hasOwnProperty('sub_task_name')) {
          subTask.sub_task_name = toSentenceCase(subTask.sub_task_name);
        }

        if (subTask.hasOwnProperty('sub_task_description')) {
          subTask.sub_task_description = toSentenceCase(subTask.sub_task_description);
        }
      }

    }

    if (isRecurring == 1) {
      for (let key in subtasks) {
        if (subtasks.hasOwnProperty(key)) {
          let subTask = subtasks[key];

          if (subTask.hasOwnProperty('start_datetime')) {
            subTask.start_datetime = dueDate;
          }

          if (subTask.hasOwnProperty('end_datetime')) {
            subTask.end_datetime = dueDate;
          }
        }

      }
    }

    const data = {
      task_id: taskId,
      task_name: selectedUser,
      domain_id: selectedDomain.domain_id,
      project_id: selectedProject.project_id,
      task_name: taskName,
      task_description: taskDescription,
      note: notes,
      task_owner: selectedUser['username'],
      is_recurring: isRecurring,
      recurring_type: recurringDict,
      status: "not picked",
      deadline: dueDate,
      sub_task: subtasks,
      is_critical: critical,
      is_task_on_hold: taskActive,
      is_approved: is_approved,
      is_reapproved: is_reapproved,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    uploadedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,

      },
    };

    
    try {
      const response = await axios.post('/backend/edit_task', formData, config);

      setSnackbarOpen(true)
      setMessage('Task Updated successfully')
      setSeverity('success')
      fetchAllTask()
      props.setTaskActivated(false)
      props.setProjectSnackBar(true)
      props.setProjectMessage('Task Updated successfully')
      props.setProjectSeverity('success')
      props.setFullView(false)
      setOpen(false)
      setSwalActive(false)
    } catch (error) {
      console.error('Error creating task', error);
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  }

  const [mergedApproval, setMergedApproval] = useState([])
  const mergeAndCleanDomains = (domains, domainsWithUsers) => {


    // Initialize the merged array with the domains array
    const merged = domains.map(domain => {
      const matchingDomainWithUsers = domainsWithUsers.find(domainWithUsers => domainWithUsers.domain_id === domain.domain_id);
      if (matchingDomainWithUsers) {
        // let updatedUserList = matchingDomainWithUsers.user_list.filter(user => user !== domain.head);
        let updatedUserList = matchingDomainWithUsers.user_list.filter(user => user !== domain.head);
        domains.forEach(domain => {
          // Extract usernames from the user_list
          let usernames = domain.user_list;
          // Alternatively, you can use the spread operator:
          updatedUserList = [...updatedUserList, ...usernames];
        });
        updatedUserList = [...new Set(updatedUserList)];
        return {
          ...matchingDomainWithUsers,
          user_list: updatedUserList
        };
      } else {
        return {
          ...domain,
          user_list: domain.user_list ? [...new Set([...domain.user_list])] : [domain.head]
        };
      }
    });

    // Adding the domains from domainsWithUsers which are not present in domains
    domainsWithUsers.forEach(domainWithUsers => {
      const matchingDomain = domains.find(domain => domain.domain_id === domainWithUsers.domain_id);
      if (!matchingDomain) {
        // If domainsWithUsers is empty or the domain is not present in domains
        const userListWithHead = domainWithUsers.user_list && domainWithUsers.user_list.length > 0
          ? domainWithUsers.user_list
          : [domainWithUsers.head];

        // Remove duplicates using a Set
        const uniqueUserList = [...new Set(userListWithHead)];

        merged.push({
          ...domainWithUsers,
          user_list: uniqueUserList
        });
      }
    });

    return merged;
  };





  const handleEditTask = async () => {
    // const subTasksArray = Object.values(subtasks);
    // let usernames = [];
    // Object.keys(subtasks).forEach(key => {
    //   const subtask = subtasks[key];
    //   if (subtask.sub_task_assign_to && typeof subtask.sub_task_assign_to === 'object') {
    //     const username = subtask.sub_task_assign_to.username;
    //     usernames.push(username);
    //   }
    // });
    // const filteredExternalDomainUser = extendedDomainUser.filter(domain => {
    //   const hasMatchingUser = domain.user_list.some(ele => usernames.includes(ele));
    //   return hasMatchingUser;
    // });

    // setMergedApproval(filteredExternalDomainUser)
    // if (filteredExternalDomainUser.length !== 0) {
    //   setApproval(true);
    //   return
    // }

    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }


    let usernames = [];
    let newMergedApproval = []
    Object.keys(subtasks).forEach(key => {
      const subtask = subtasks[key];
      if (subtask.sub_task_assign_to && typeof subtask.sub_task_assign_to === 'object') {
        const username = subtask.sub_task_assign_to.username;
        usernames.push(username);
      }
    });

    let filteredExternalDomainUser = extendedDomainUser.filter(domain => {
      const hasMatchingUser = domain.user_list.some(ele => usernames.includes(ele));
      return hasMatchingUser;
    });

    const shouldMergeAssignOwner = assignOwner.some(owner =>
      owner.user_domain_id !== selectedDomain.domain_id &&
      owner.user_list &&
      !(owner.user_list.some(user => taskUserList.includes(user)) ||
        owner.user_list.some(user => projectUserList.includes(user)))
    );
    // const shouldMergeAssignOwner = assignOwner.some(owner => owner.domain_id !== selectedDomain );

    if (shouldMergeAssignOwner) {
      filteredExternalDomainUser = mergeAndCleanDomains(assignOwner, filteredExternalDomainUser);
      newMergedApproval = [...filteredExternalDomainUser];
    }
    else {
      newMergedApproval = filteredExternalDomainUser
    }
    const getUsernames = (subtasks) => {
      return Object.values(subtasks).map(subtask => subtask?.sub_task_assign_to?.username);
    };

    const userNames = getUsernames(subtasks);
    userNames.push(selectedUser['username'])


    const filterUserLists = (domains, allowedUsers) => {
      return domains.map(domain => {
        return {
          ...domain,
          user_list: domain.user_list.filter(user => allowedUsers.includes(user))
        };
      });
    };

    // Get the filtered domains
    const filteredDomains = filterUserLists(newMergedApproval, userNames);
    setMergedApproval(filteredDomains)
    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }
    
    if (newMergedApproval.length !== 0) {
      const subtaskError = validateSubtasks(subtasks);
      if (subtaskError) {
        setSnackbarOpen(true);
        setMessage(subtaskError);
        setSeverity('error');
        return;
      }

      setApproval(true);
      return
    }
    taskEdit()
  };

  const validateSubtasks = (subtasks) => {
    let index = 1;
    for (const subtask of Object.values(subtasks)) {
      if (!subtask.sub_task_name || subtask.sub_task_name.trim() === '') {
        return `Subtask ${index} is missing the Subtask Name`;
      }
      if (!subtask.sub_task_description || subtask.sub_task_description.trim() === '') {
        return `Subtask ${index} is missing the Subtask Description`;
      }
      if (subtask.sub_task_assign_to === null || Object.keys(subtask.sub_task_assign_to).length === 0 || subtask.sub_task_assign_type == '') {
        return `Subtask ${index} is missing the Assignee`;
      }
      if (subtask.sub_task_assign_type === null || Object.keys(subtask.sub_task_assign_type).length === 0 || subtask.sub_task_assign_type == '') {
        return `Subtask ${index} is missing the Assign type`;
      }
      if (subtask.effort_hours > 20 || subtask.effort_hours === null || subtask.effort_hours === 0 || isNaN(subtask.effort_hours) || subtask.effort_hours == '') {
        return `The maximum permitted efforts hours for subtask ${index} is 20 hours`;
      }
      if ((subtask.start_datetime && !subtask.end_datetime) || (!subtask.start_datetime && subtask.end_datetime)) {
        return `Subtask ${index} must have both Start Date and End Date if one is provided`;
      }
      if ((subtask.performance_score > 5)) {
        return `Subtask ${index} performance score is not valid`;
      }


      if (!(editTask)) {
        if (subtask.start_datetime && ((subtask.start_datetime < getCurrentDate() || subtask.start_datetime > dueDate))) {
          return `Subtask ${index} start date is not valid`;
        }
        if (subtask.end_datetime && ((subtask.end_datetime < getCurrentDate() || subtask.end_datetime > dueDate))) {
          return `Subtask ${index} end date is not valid`;
        }
        if ((subtask.start_datetime && subtask.end_datetime) && subtask.start_datetime > subtask.end_datetime) {
          return `Subtask ${index} date is not valid`;
        }
      }

      if (editTask && subtask.isEditable) {
        if (subtask.start_datetime && ((subtask.start_datetime < getCurrentDate() || subtask.start_datetime > dueDate))) {
          return `Subtask ${index} start date is not valid`;
        }
        if (subtask.end_datetime && ((subtask.end_datetime < getCurrentDate() || subtask.end_datetime > dueDate))) {
          return `Subtask ${index} end date is not valid`;
        }
        if ((subtask.start_datetime && subtask.end_datetime) && subtask.start_datetime > subtask.end_datetime) {
          return `Subtask ${index} date is not valid`;
        }

      }
      if (editTask && subtask.start_datetime_bkp == "") {
        if (subtask.start_datetime && ((subtask.start_datetime < getCurrentDate() || subtask.start_datetime > dueDate))) {
          return `Subtask ${index} start date is not valid`;
        }
        if (subtask.end_datetime && ((subtask.end_datetime < getCurrentDate() || subtask.end_datetime > dueDate))) {
          return `Subtask ${index} end date is not valid`;
        }
        if ((subtask.start_datetime && subtask.end_datetime) && subtask.start_datetime > subtask.end_datetime) {
          return `Subtask ${index} date is not valid`;
        }
      }


      if (editTask && (projectOwner === userDetails.username && project_owner_approved == 1)) {
        if (subtask.start_datetime && subtask.start_datetime != subtask.start_datetime_bkp && (subtask.start_datetime < subtask.start_datetime_bkp || subtask.start_datetime > dueDate)) {
          return `Subtask ${index} start date is not valid`;
        }
        if (subtask.start_datetime && subtask.end_datetime != subtask.end_datetime_bkp && (subtask.end_datetime > dueDate || subtask.end_datetime < getCurrentDate())) {
          return `Subtask ${index} end date is not valid`;
        }
        if ((subtask.start_datetime && subtask.end_datetime) && subtask.start_datetime > subtask.end_datetime) {
          return `Subtask ${index} date is not valid`;
        }
      }

      index++;
    }
    return null;
  };


  const createTask = async () => {
    // const subTasksArray = Object.values(subtasks);
    let newTimeUnit
    if (timeUnit === "days") {
      newTimeUnit = 'day'
    } else if (timeUnit === "weeks") {
      newTimeUnit = 'week'
    }
    else {
      newTimeUnit = timeUnit
    }
    if (endOption == 'on') {

      if (((endDate < getCurrentDate()) && isRecurring && endDate < projectDueDate)) {
        setSnackbarOpen(true)
        setMessage('Invalid recurring date')
        setSeverity('error')
        return
      }
    }

    let recurringDict = {
      "type": newTimeUnit,
      "repeat_every": interval,
      "repeat_on": selectedDays,
      "end": endDate
    }
    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }
    // else if (dueDate < getCurrentDate()) {
    //   setSnackbarOpen(true)
    //   setMessage('Due Date cannot be less than current date')
    //   setSeverity('error')
    //   return
    // }






    function removeIsEditableKey(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          removeIsEditableKey(obj[key]);
        }
      }
      delete obj.isEditable;
    }


    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }

    if (isRecurring == 1) {
      for (let key in subtasks) {
        if (subtasks.hasOwnProperty(key)) {
          let subTask = subtasks[key];

          if (subTask.hasOwnProperty('start_datetime')) {
            subTask.start_datetime = getCurrentDate();
          }

          if (subTask.hasOwnProperty('end_datetime')) {
            subTask.end_datetime = getCurrentDate();
          }
        }

      }
    }

    for (let key in subtasks) {
      if (subtasks.hasOwnProperty(key)) {
        let subTask = subtasks[key];

        if (subTask.hasOwnProperty('sub_task_name')) {
          subTask.sub_task_name = toSentenceCase(subTask.sub_task_name);
        }

        if (subTask.hasOwnProperty('sub_task_description')) {
          subTask.sub_task_description = toSentenceCase(subTask.sub_task_description);
        }
      }

    }



    const data = {
      domain_id: selectedDomain.domain_id,
      project_id: selectedProject.project_id,
      task_name: toSentenceCase(taskName),
      task_description: toSentenceCase(taskDescription),
      note: notes,
      task_owner: selectedUser['username'],
      is_recurring: isRecurring,
      recurring_type: recurringDict,
      status: "not picked",
      deadline: isRecurring ? getCurrentDate() : dueDate,
      sub_task: subtasks,
      is_critical: critical,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    uploadedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });


    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,

      },
    };

    try {
      const response = await axios.post('/backend/create_task', formData, config);
      setSnackbarOpen(true)
      setSwalActive(false)
      setMessage('Task Created Successfully')
      setSeverity('success')
      setOpen(false)
      setTaskName('')
      setTaskDescription('')
      setSelectedDomain(null)
      setSelectedProject(null)
      setSelectedUser(null)
      setRepeat(0)
      setDueDate('')
      setNotes('')
      setInterval(1)
      setTimeUnit('day')
      setEndOption('never')
      setEndDate(null)
      setSubtasks([])
      fetchAllTask()
      setFinalRecurringStatus({})
      props.setTaskActivated(false)
      props.setFullView(false)

    } catch (error) {
      console.error('Error creating task', error);
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  };


  const handleCreateTask = async () => {

    // const subTasksArray = Object.values(subtasks);
    let usernames = [];
    let newMergedApproval = []

    // if ((endDate < getCurrentDate() && isRecurring)) {
    //   setSnackbarOpen(true)
    //   setMessage('Recurring enddate cannot be less than today')
    //   setSeverity('error')
    //   return
    // }

    if (selectedDomain == null) {
      setSnackbarOpen(true)
      setMessage('Select a Domain')
      setSeverity('error')
      return
    }
    else if (selectedProject == null) {
      setSnackbarOpen(true)
      setMessage('Select a Project')
      setSeverity('error')
      return
    }
    else if ((taskName == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Name')
      setSeverity('error')
      return
    }
    else if ((taskDescription == '' || taskName == null)) {
      setSnackbarOpen(true)
      setMessage('Enter Task Description')
      setSeverity('error')
      return
    }
    else if (selectedUser == null) {
      setSnackbarOpen(true)
      setMessage('Please Select a User to Assign Task')
      setSeverity('error')
      return
    }
    else if ((dueDate == null || dueDate == '') && repeat == 0) {
      setSnackbarOpen(true)
      setMessage('Please Select a due Date to assign ')
      setSeverity('error')
      return
    }


    Object.keys(subtasks).forEach(key => {
      const subtask = subtasks[key];
      if (subtask.sub_task_assign_to && typeof subtask.sub_task_assign_to === 'object') {
        const username = subtask.sub_task_assign_to.username;
        usernames.push(username);
      }
    });

    let filteredExternalDomainUser = extendedDomainUser.filter(domain => {
      const hasMatchingUser = domain.user_list.some(ele => usernames.includes(ele));
      return hasMatchingUser;
    });
    const shouldMergeAssignOwner = assignOwner.some(owner => owner.domain_id !== selectedDomain.domain_id);
    if (shouldMergeAssignOwner) {
      filteredExternalDomainUser = mergeAndCleanDomains(assignOwner, filteredExternalDomainUser);
      newMergedApproval = [...filteredExternalDomainUser];
    }
    else {
      newMergedApproval = filteredExternalDomainUser
    }
    setMergedApproval(newMergedApproval)


    const getUsernames = (subtasks) => {
      return Object.values(subtasks).map(subtask => subtask?.sub_task_assign_to?.username);
    };

    const userNames = getUsernames(subtasks);
    userNames.push(selectedUser['username']);


    const filterUserLists = (domains, allowedUsers) => {
      return domains.map(domain => {
        return {
          ...domain,
          user_list: domain.user_list.filter(user => allowedUsers.includes(user))
        };
      });
    };

    // Get the filtered domains
    const filteredDomains = filterUserLists(newMergedApproval, userNames);


    setMergedApproval(filteredDomains)

    const subtaskError = validateSubtasks(subtasks);
    if (subtaskError) {
      setSnackbarOpen(true);
      setMessage(subtaskError);
      setSeverity('error');
      return;
    }

    if (newMergedApproval.length !== 0) {

      const subtaskError = validateSubtasks(subtasks);
      if (subtaskError) {
        setSnackbarOpen(true);
        setMessage(subtaskError);
        setSeverity('error');
        return;
      }

      setApproval(true);
      return
    }

    createTask()
  };

  const [recurring, setRecurring] = useState(true)
  const [critical, setCritical] = useState(0)
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 60,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(31px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    }
  }));

  const handleRecurring = (e) => {

    if (e.target.value) {
      setRecurringDialog(true)
      setIsRecurring(e.target.value)
      setRepeat(e.target.value)
      setRecurring(false)
      setReoccured(true)
      // swalActive(true)
    } else {
      setIsRecurring(e.target.value)
      setRepeat(e.target.value)
      setRecurring(true)
      setReoccured(false)
      // swalActive(true)
    }
  }

  const handleCritical = (event) => {
    setCritical(event.target.checked ? 1 : 0);
    setSwalActive(true)
  };

  const [options, setOptions] = useState([
    { value: 0, label: "Don't Repeat" },
    { value: 1, label: 'Custom' }
  ]);

  const addPerformanceScore = (e, key) => {
    const value = e.target.value;
    // Check if the input is empty
    if (value === '') {
      handleSubtaskChange(key, 'performance_score', '');
      return;
    }


    // Use a regular expression to check for valid numbers between 1 and 5 with up to one decimal place
    const isValid = /^(?:[0-4](?:\.\d{0,1})?|5(?:\.0)?)$/.test(value);

    if (isValid) {
      if (value > 5) {
        setSnackbarOpen(true)
        setMessage('Invalid Performance Score')
        setSeverity('error')
        handleSubtaskChange(key, 'performance_score', null);
        return
      }
      else {
        handleSubtaskChange(key, 'performance_score', parseFloat(value));
      }
    } else {
      setSnackbarOpen(true)
      setMessage('Invalid Performance Score')
      setSeverity('error')
      handleSubtaskChange(key, 'performance_score', '');
      return
    }
  };

  // const handlePositiveNumberChange = (e, key) => {
  //   const value = parseFloat(e.target.value);
  //   if (value < 0 || value == '-') {
  //     return
  //   }
  //   if (value >= 0) {
  //     handleSubtaskChange(key, 'effort_hours', value);
  //   }
  //   else {
  //     handleSubtaskChange(key, 'effort_hours', value)
  //   }
  // };

  const handlePositiveNumberChange = (e, key) => {
    const value = e.target.value;

    // Allow empty input for clearing
    if (value === '') {
      handleSubtaskChange(key, 'effort_hours', '');
      return;
    }

    const parsedValue = parseFloat(value);

    // Check if the parsed value is a valid positive number
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      handleSubtaskChange(key, 'effort_hours', parsedValue);
    }
  };




  const isRecurringValid = (recurringType, selectedDays) => {
    const today = new Date();
    const endDate = new Date(recurringType.end);
    const currentWeekList = [];

    // Calculate dates for the current week
    for (let dts = today.getDay() + 1; dts < 8; dts++) {
      const curDate = new Date(today.getTime() + (dts - today.getDay()) * 24 * 60 * 60 * 1000);
      const curDateStr = curDate.toISOString().split('T')[0];
      if (curDateStr < recurringType.end) {
        currentWeekList.push(curDateStr);
      }
    }


    // Check if the current week includes any of the selected days
    let isRepeatInWeek = false;
    for (const dts of selectedDays) {
      const dateDayOfWeek = new Date(today.getTime() - (today.getDay() - dts) * 24 * 60 * 60 * 1000);
      const dateDayOfWeekStr = dateDayOfWeek.toISOString().split('T')[0];

      if (currentWeekList.includes(dateDayOfWeekStr)) {
        isRepeatInWeek = true;
        break;
      }
    }

    if (!isRepeatInWeek) {
      // Calculate next week’s potential run date
      try {
        const nextWeekStart = new Date(today.getTime() + (7 - today.getDay()) * 24 * 60 * 60 * 1000);
        const nextWeekRunDate = new Date(nextWeekStart.getTime() + (recurringType.repeatEvery) * 7 * 24 * 60 * 60 * 1000);
        const minRepeatDay = Math.min(...selectedDays);
        nextWeekRunDate.setDate(nextWeekRunDate.getDate() + minRepeatDay);
        const nextWeekRunDateStr = nextWeekRunDate.toISOString().split('T')[0];

        if (endDate > new Date(nextWeekRunDateStr) || endDate > projectDueDate) {
          return false; // Invalid recurring end date
        }
      } catch (error) {
        console.error(error);
        return false; // Error in calculation
      }
    }

    return true; // Valid recurring event
  };


  const [reoccured, setReoccured] = useState(false)
  const handleSaveReoccurence = () => {


    if (timeUnit == "days" || timeUnit == 'day') {
      if (endOption == "on" && (endDate == null || endDate == undefined)) {

        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }

    else if (timeUnit == 'week' || timeUnit == 'weeks') {
      if ((endOption == "on" && (endDate == null || endDate == undefined)) || (selectedDays.length == 0)) {
        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }

    }

    if (timeUnit == "days" || timeUnit == 'day') {
      if (interval == "" || interval == null || interval == undefined) {

        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }

    else if (timeUnit == 'week' || timeUnit == 'weeks') {
      if (interval == "" || interval == null || interval == undefined) {
        setMessage("Please fill all the details")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }


    if (endDate != null && endDate != undefined && endOption == "on" && (timeUnit == 'days' || timeUnit == "day")) {

      const parsedEndDate = new Date(endDate);
      const repeatEvery = interval;
      const today = new Date();
      const futureDate = new Date(today.getTime() + repeatEvery * 24 * 60 * 60 * 1000);
      const projectEndDate = new Date(projectDueDate)
      if ((endDate < getCurrentDate())) {
        setMessage("Please select a valid recurring date")

        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }

      if ((parsedEndDate < futureDate || parsedEndDate > projectEndDate)) {
        setMessage("Please select a valid recurring date")

        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }
    }

    if (endDate != null && endDate != undefined && endOption == "on" && (timeUnit == 'weeks' || timeUnit == "week")) {
      let recurringType = {
        end: endDate,
        repeatEvery: interval,
        repeatOn: selectedDays
      }
      if(endDate > projectDueDate){
        setMessage("Please select a valid recurring date")
        setSeverity("warning")
        setSnackbarOpen(true)
        return
      }

      if (!(isRecurringValid(recurringType, selectedDays))) {
        setMessage("Please select a valid recurring date")
        setSeverity("warning")
        setSnackbarOpen(true)
        return

      }
    }




    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    let weeklyDays = '';
    if (timeUnit == "weeks" || timeUnit == "week") {
      selectedDays.forEach((index) => {

        weeklyDays += days[index - 1] + ',';

      });
    }

    if (weeklyDays.endsWith(',')) {
      weeklyDays = weeklyDays.slice(0, -1);
    }

    let newOptionText = ''
    if ((timeUnit == "days" || timeUnit == "day") && (!(endDate == undefined || endDate == null) && !(endDate == ''))) {
      if (interval == '1') {
        newOptionText = `Daily Until ${endDate}`
      }
      else {
        newOptionText = `Every ${interval} days Until ${endDate}`
      }
    }
    else if ((timeUnit == "days" || timeUnit == "day") && (endDate == undefined || endDate == null || endDate == '')) {
      if (interval == '1') {
        newOptionText = `Daily `
      }
      else {
        newOptionText = `Every ${interval} days `
      }
    }
    else if ((timeUnit == "weeks" || timeUnit == "week") && (!(endDate == undefined || endDate == null) && !(endDate == ''))) {
      if (interval == '1') {
        newOptionText = `Every weeks on ${weeklyDays} until ${endDate}`
      }
      else {
        newOptionText = `Every ${interval} weeks on ${weeklyDays} until ${endDate}`
      }
    }
    else if ((timeUnit == "weeks" || timeUnit == "week") && (endDate == undefined || endDate == null || endDate == '')) {
      if (interval == 1) {
        newOptionText = `Every weeks on ${weeklyDays} `
      }
      else {
        newOptionText = `Every ${interval} weeks on ${weeklyDays} `
      }
    }
    const newOption = { value: 2, label: newOptionText };

    const updatedOptions = options.filter(option => option.value !== 2);

    // Add the new dynamic option
    updatedOptions.push(newOption);

    setOptions(updatedOptions);
    setRepeat(2)
    setRecurringDialog(false);
    setFinalRecurringStatus({
      type: timeUnit,
      repeat_every: interval,
      repeat_on: selectedDays,
      end: endDate,
      endOption: endOption,
    });
    setReoccured(true)

  }


  const handleEndOptionChange = (e) => {
    setEndOption(e.target.value);
    if (e.target.value === 'never') {
      setEndDate(''); // Reset the endDate when 'Never' is selected
    }
  };




  const handleReoccuringDialogClose = () => {
    setRecurringDialog(false)
    setTimeUnit(finalRecurringStatus['type'])
    setInterval(finalRecurringStatus['repeat_every'])
    setSelectedDays(finalRecurringStatus['repeat_on'])
    setEndDate(finalRecurringStatus['end'])
    setEndOption(finalRecurringStatus['endOption'])

    setTimeUnit('day')
    setEndOption('never')
    setInterval(1)
    setEndDate(null)
    setSelectedDays([])


    if (Object.keys(finalRecurringStatus).length > 0) {
      if (isRecurring) {
        setRepeat(2);
      }
      else {
        setRepeat(0);
        setReoccured(false)
      }
    } else {
      setRepeat(0);
      setRecurring(0);
      setIsRecurring(0);
      setReoccured(false)
    }
  }

  const [isCompleteDate, setIsCompleteDate] = useState(false);
  const [subtaskIScomplete, setSubtaskIscomplete] = useState(false);

  const handleDateChange = (e) => {
    const value = e.target.value;
    setDueDate(value);

    if (value.length === 10) {
      setIsCompleteDate(true);
    } else {
      setIsCompleteDate(false);
    }
  };


  const handleDateChangeSubtask = (key, type, date) => {
    setSubtaskIscomplete(false)
    const value = date;




    if (value.length === 10) {
      setSubtaskIscomplete(true);
    } else {
      setSubtaskIscomplete(false);
    }
  };

  const handleBlur = (index, field, value) => {
    if ((field === "start_datetime" || field === "end_datetime") && subtaskIScomplete) {
      if (value < getCurrentDate() || (value > dueDate)) {
        const fieldName = field === "start_datetime" ? 'start date' : 'end date';
        setSnackbarOpen(true);
        setMessage(`Subtask ${index} ${fieldName} cannot be less than the current date`);
        setSeverity('error');
        value = ''
        handleSubtaskChange(index, field, value);
        return;
      }
    }

    handleSubtaskChange(index, field, value);
  };

  const handleDateBlur = () => {

    if (isCompleteDate && dueDate < getCurrentDate()) {
      setSnackbarOpen(true);
      setMessage(`Task due date cannot be less than current date`);
      setSeverity('error');
      setDueDate('');
      return
    } else if (isCompleteDate && dueDate > projectDueDate) {

      setSnackbarOpen(true);
      setMessage(`Task due date cannot be greater than project date`);
      setSeverity('error');
      setDueDate('');
      return
    }
    else if (isCompleteDate) {
      setSwalActive(true);
    };
  };

  const handleTaskInfoChanges = (value) => {
    setSwalActive(true)
    setTaskInfo({
      ...taskInfo,
      task_reassign_status: value,
    });
    if (!value) {
      setSelectedUser(null)
      setAssignOwner([])
    }
    else {
      setSelectedUser(taskInfo.task_owner_backup)
      // setAssignOwner(taskInfo.task_owner_backup)
    }
  }



  return (

    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            width: '60em',
            maxWidth: '80em',
          },
        }}
      >
        <DialogTitle style={{ padding: 0, margin: '0 0.25em' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ borderBottom: '1px solid black', padding: '9px 0' }}
          >
            <div className='d-flex justify-content-between align-items-between w-100'>
              <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>
                {editTask ? 'Edit The Task' : 'Create A New Task'}
              </Typography>
              <div></div>
              <div>
                {editTask && (
                  <div style={{ backgroundColor: getStatusColorAndText(taskStatus).color, borderRadius: '10px', padding: '0.25em', marginRight: '1em', color: 'black' }}>
                    Status: {getStatusColorAndText(taskStatus).text}
                  </div>
                )}
              </div>
            </div>

            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className="mt-4" style={{ scrollbarWidth: 'none' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography className={`${classes['task-label']}`} variant="subtitle1">Select Domain<span className={`${classes['required-asterisk']}`}>*</span></Typography>
              <Autocomplete
                value={selectedDomain}
                onChange={setDomainId}
                options={domainList}
                getOptionLabel={(option) => option.domain_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                  />
                )}
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <Typography className={`${classes['task-label']}`} variant="subtitle1">Select Project<span className={`${classes['required-asterisk']}`}>*</span></Typography>
              <Autocomplete
                value={selectedProject}
                onChange={setProjectId}
                options={projectList}
                getOptionLabel={(option) => option.project_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Project"
                    variant="outlined"
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                  />
                )}
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ marginBottom: '1em' }} className={`${classes['task-label']}`} variant="subtitle1">Task Name<span className={`${classes['required-asterisk']}`}>*</span></Typography>
              <TextField
                // label="Task Name"
                value={taskName}
                onChange={(e) => {
                  const value = toSentenceCase(e.target.value);
                  // setTaskName(value);
                  setTaskName(e.target.value);
                  setSwalActive(true);
                }}
                fullWidth
                size="small"
                disabled={
                  tabValue == 0 ?(
                  !(
                    userDetails.username === selectedUser?.username ||
                    projectEditAccess
                  )):true
                }
              />
            </Grid>
            {editTask &&
              <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {
                  tabValue ==0 ?(
                  isPaused ? (
                    (userDetails.username === projectOwner && project_owner_approved == 1
                       || userDetails.username === domainOwner
                    ) ? (
                      <Tooltip title="Task On Hold">
                        <>
                          <Typography variant="body1" style={{ marginBottom: '0.5em', textAlign: 'center' }}>
                            Status: On Hold
                          </Typography>
                          <PlayCircleIcon
                            style={{ cursor: 'pointer', fontSize: '3em' }}
                            onClick={handleTaskActiveToggle}
                          />
                        </>
                      </Tooltip>
                    ) : null
                  ) : (
                    ((userDetails.username === projectOwner && project_owner_approved == 1   || userDetails.username === domainOwner )) ? (

                      <Tooltip title="Task Active">
                        <>
                          <Typography variant="body1" style={{ marginBottom: '0.5em', textAlign: 'center' }}>
                            Status: Active
                          </Typography>

                          <PauseCircleFilled
                            style={{ cursor: 'pointer', fontSize: '3em' }}
                            onClick={handleTaskActiveToggle}
                          />
                        </>
                      </Tooltip>
                    ) : null
                  )
                ):(<></>)
                }
              </Grid>
            }


            <Grid item xs={12}>
              <Typography className={`${classes['task-label']}`} variant="subtitle1">Task Description<span className={`${classes['required-asterisk']}`}>*</span></Typography>
              <TextField
                // label="Task Description"
                multiline
                rows={4}
                value={taskDescription}
                onChange={(e) => {
                  // let value = toSentenceCase(e.target.value);
                  setTaskDescription(e.target.value);
                  setSwalActive(true);
                }}

                fullWidth
                disabled={
                  tabValue ==0 ?(
                  !(
                    userDetails.username === selectedUser?.username ||
                    projectEditAccess
                  )
                ):true}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography className={`${classes['task-label']}`} variant="subtitle1">Select User<span className={`${classes['required-asterisk']}`}>*</span>
                {taskApproved == 0 && taskInfo?.task_reassign_status ? (
                  <>
                    <img
                      title="Reassign User"
                      src={ReassignIcon}
                      alt="Reassign Icon"
                      style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                    />
                  </>
                ) : taskApproved === 0 ? (
                  <img
                    title="Approval rejected"
                    src={RejectIcon}
                    alt="Reject Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '8px', cursor: 'pointer' }}
                  />
                ) : taskApproved === -1 ? (
                  <img
                    title="Approval pending"
                    src={PendingIcon}
                    alt="Pending Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                  />
                ) : null}


              </Typography>
              <Autocomplete
                id="user-domain"
                // options={users && users.length > 0 ? users.sort((a, b) => -b.core_domain.localeCompare(a.core_domain)) : []}
                options={sortedUsers}
                groupBy={(option) => option.domain_name}
                getOptionLabel={(option) => option.name}
                value={selectedUser}
                isOptionEqualToValue={(option, value) => {
                  if (value === null) return false; // Handle null value
                  return option.user_id === value.user_id;
                }}
                onChange={(event, value) => {
                  handleSelectUser(value);
                  setSwalActive(true);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                // disabled={editTask ? (!(taskApproved == 0 ? projectEditAccess || userDetails.username == taskOwnerDetails : false)) : false}
                disabled={
                  tabValue == 0 ? (
                  editTask
                    ? !(taskApproved === 0 && (projectEditAccess || (userDetails.username === taskOwnerDetails && taskApproved == 1)) && !(taskInfo?.task_reassign_status))
                    : false):true
                }


              />
            </Grid>

            {(taskApproved == 0 && projectEditAccess) &&
              <>
                <Grid item xs={2}>
                  <div className='d-flex flex-column'>
                    <Typography className={`${classes['task-label']}`} variant="subtitle1">
                      Reassign user
                    </Typography>
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={taskInfo.task_reassign_status} onChange={(e) => { handleTaskInfoChanges(e.target.checked) }}
                        disabled={
                          tabValue ==0 ?(
                          !(
                            userDetails.username === selectedUser?.username ||
                            projectEditAccess
                          )):true
                        }
                      />}
                    />
                  </div>
                </Grid>
              </>

            }

            <Grid item xs={2}>
              <Typography className={`${classes['task-label']}`} variant="subtitle1">
                Repeat<span className={`${classes['required-asterisk']}`}>*</span>
              </Typography>
              <FormControl fullWidth>
                {editTask ? (
                  <Typography
                    variant="body1"
                    className={classes.ellipsisText}
                    title={options.find(option => option.value === repeat)?.label || ''}
                  >
                    {options.find(option => option.value === repeat)?.label || ''}
                  </Typography>
                ) : (
                  <Select size="small" value={repeat} onChange={handleRecurring}>
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>


            {/* )} */}


            <Grid item xs={2} className='d-flex justify-content-center align-items-center flex-column' >
              <Typography className={`${classes['task-label']} text-center`} variant="subtitle1">
                Critical
              </Typography>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={<IOSSwitch sx={{ m: 1 }}
                  checked={critical === 1} onChange={handleCritical}
                  disabled={
                    tabValue ==0?(
                    !(
                      userDetails.username === selectedUser?.username ||
                      projectEditAccess
                    ) || editTask):true
                  }
                />}
              />
            </Grid>

            {/* <Grid item xs={4} className={recurring ? 'd-none' : ''} > */}
            <Grid item xs={4} className={`${(reoccured) ? 'd-none' : ''}`} >
              <div className={'form-group d-flex flex-column'}>
                <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Due Date<span className={`${classes['required-asterisk']}`}>*</span></label>
                <input
                  type="date"
                  id="dateInput"
                  value={dueDate}
                  onChange={handleDateChange}

                  onBlur={handleDateBlur}

                  inputlabelprops={{ shrink: true }}
                  style={{ ...dateStyles }}
                  min={getCurrentDate()}
                  max={projectDueDate}
                  disabled={
                    tabValue == 0?(
                    editTask ? true : false
                    ):true
                  }
                />
              </div>

            </Grid>

            {(userDetails.global_privileges.notes != 'none' && editTask) &&
              <Grid item xs={5}>
                <Typography className={`${classes['task-label']}`} variant="subtitle1">Remarks</Typography>
                <TextField
                  // label="Notes"
                  value={notes}
                  onChange={(e) => {
                    let value = toSentenceCase(e.target.value)
                    setNotes(value);
                    setSwalActive(true);
                  }}
                  disabled={tabValue==0 ? (!(taskApproved == 1)):true}
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                />
              </Grid>
            }


            {editTask &&
              (
                <>
                  <Grid item xs={3} >
                    <div className="form-group">
                      <label htmlFor="dateInput" className='mb-2'>Assigned on : </label>
                      <div>{assignedOn}</div>
                    </div>

                  </Grid>
                </>
              )
            }


          </Grid>
          {!subtask && Object.keys(subtasks).length === 0 && (
            <>
              <DialogActions className="d-flex justify-content-center align-items-center">
                {
                  tabValue ==0 ?(
                  editTask ? (
                    (projectEditAccess || taskUserList.includes(userDetails.username)) && (
                      <>
                        <Button
                          // variant="contained"
                          style={{ backgroundColor: 'grey', padding: '6px', width: '9em' }}
                          onClick={handleAddSubTaskEdit}
                          startIcon={
                            <AddCircleOutlineIcon style={{ color: 'white' }} />
                          }
                        >
                          <span style={{ fontSize: '1em', color: 'white' }}>SubTask</span>
                        </Button>

                      </>
                    )
                  ) : (
                    <>
                      <Button
                        // variant="contained"
                        style={{ backgroundColor: 'grey', padding: '6px', width: '9em' }}
                        onClick={handleAddSubTaskNew}
                        startIcon={
                          <AddCircleOutlineIcon style={{ color: 'white' }} />
                        }
                      >
                        <span style={{ fontSize: '1em', color: 'white' }}>SubTask</span>
                      </Button>
                    </>
                  )):(<></>)
                }


                { tabValue == 0 ?(
                editTask ? (
                 
                  <>
                    {(projectEditAccess || taskUserList.includes(userDetails.username)) && 
                      // {projectEditAccess && (taskUserList.includes(userDetails.username) || userDetails.username == projectOwner ) &&
                      <>
                        <Button title={"Update Subtask"} variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleEditTask()}>
                          Update Task
                        </Button>
                      </>}
                  </>
                ) : (
                  <Button title={"Save Subtask"} variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleCreateTask()}>
                    Create Task
                  </Button>
                )
              ):(<></>)
                }
              </DialogActions>
            </>
          )}

          {Object.keys(subtasks).map((key, index) => {

            const subtask = subtasks[key];


            if (typeof subtask.sub_task_assign_to === 'object') {

            } else {
              const selectedUser = users.find(user => user.username === subtask.sub_task_assign_to);
              subtask.sub_task_assign_to = selectedUser;
            }

            const statusOptions = getStatusOptions(subtask.sub_task_status);
            const isLastSubtask = index === Object.keys(subtasks).length - 1;
            return (
              <Grid container
                id={key} ref={key === newSubtaskKey ? newSubtaskRef : null}
                className="mt-3" spacing={2} key={key} style={{ ...subTaskContainerStyle }}>
                <Grid item xs={12}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Typography variant="h6">Subtask {index + 1} </Typography>

                    <div className='d-flex'>
                      {(subtask.is_approved == 0 && projectEditAccess) && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2" sx={{ marginLeft: '8px' }}>
                            Reassign Same User
                          </Typography>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={subtask.sub_task_reassign_status}
                                onChange={(e) =>
                                  handleSubtaskChange(key, 'sub_task_reassign_status', e.target.checked)
                                }
                              />
                            }
                            label=""
                          />

                        </div>
                      )}


                      {tabValue ==0 ?(
                      deletePrivileges ? (
                        <IconButton title="Delete SubTask" color="secondary" onClick={() => handleDeleteSubtask(key)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      ) : editTask && subtask?.isEditable ? (
                        <IconButton title="Delete SubTask" color="secondary" onClick={() => handleDeleteSubtask(key)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      ) : !editTask ? (
                        <IconButton title="Delete SubTask" color="secondary" onClick={() => handleDeleteSubtask(key)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      ) : null
                      ):(<></>)
                      }

                    </div>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`} >Subtask Name<span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    // label="Subtask Name"
                    value={subtask.sub_task_name}
                    onChange={(e) => handleSubtaskChange(key, 'sub_task_name', e.target.value)}
                    fullWidth
                    size="small"
                    disabled={
                      tabValue ==0 ?(
                      !(
                        userDetails.username === subtask.sub_task_assign_to?.username ||
                        userDetails.username === selectedUser?.username ||
                        projectEditAccess
                      )):true
                    }
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`} >Assign Type <span style={{ color: 'red' }}>*</span></Typography>
                  {/* <Autocomplete
                    value={subtask.sub_task_assign_type || null}
                    options={assignList}
                    getOptionLabel={(option) => option.skill_name}
                    onChange={(event, newValue) => handleSubtaskChange(key, 'sub_task_assign_type', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        required
                        size="small"
                        fullWidth
                        style={{ margin: 0 }}
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true, // Makes the input read-only
                        }}
                      />
                    )}
                    // disabled={editTask ? (subtask?.isEditable !== true && (!(subtask.is_approved == 0 ? projectEditAccess || userDetails.username == taskOwnerDetails : false))) : false}
                    disabled={
                      editTask
                        ? !subtask?.isEditable && (
                          subtask.is_approved !== 0 ||
                          subtask?.sub_task_reassign_status ||
                          !projectEditAccess && userDetails.username !== taskOwnerDetails
                        )
                        : false
                    }

                  /> */}
                  <Autocomplete
                    value={subtask.sub_task_assign_type || null}
                    options={assignList}
                    getOptionLabel={(option) => option.skill_name}
                    onChange={(event, newValue) => handleSubtaskChange(key, 'sub_task_assign_type', newValue)}
                    isOptionEqualToValue={(option, value) => {

                      if (value === null) return false;
                      
                      // Compare the option and value based on skill_id
                      return option.skill_id === value.skill_id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        required
                        size="small"
                        fullWidth
                        style={{ margin: 0 }}
                        InputProps={{
                          ...params.InputProps,
                          readOnly: true, // Makes the input read-only
                        }}
                      />
                    )}

                    disabled={
                      tabValue == 0 ?(
                      editTask
                        ? !subtask?.isEditable && (
                          subtask.is_approved !== 0 ||
                          subtask?.sub_task_reassign_status ||
                          !projectEditAccess && userDetails.username !== taskOwnerDetails
                        )
                        : false):true
                    }
                  />

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`} >Assign User<span style={{ color: 'red' }}>*</span>
                    {
                      (subtask.is_approved === 0 && subtask?.sub_task_reassign_status) ?
                        (
                          <>
                            <img
                              title="Reassign User"
                              src={ReassignIcon}
                              alt="Reassign Icon"
                              style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                            />
                          </>
                        ) :
                        subtask.is_approved === 0 ? (
                          <img
                            title="Approval rejected"
                            src={RejectIcon}
                            alt="Reject Icon"
                            style={{ width: '20px', height: '20px', marginLeft: '8px', cursor: 'pointer' }}
                          />
                        ) : subtask.is_approved === -1 ? (
                          <img
                            title="Approval pending"
                            src={PendingIcon}
                            alt="Pending Icon"
                            style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                          />
                        ) : null}

                  </Typography>
                  <Autocomplete
                    value={subtask.sub_task_assign_to || null}
                    options={assingSortedUser}
                    groupBy={(option) => option.domain_name}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => handleSubtaskChange(key, 'sub_task_assign_to', newValue)}
                    isOptionEqualToValue={(option, value) => {
                      if (value === null) return false; 
                      return option.user_id === value.user_id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        required
                        size="small"

                        style={{ margin: 0 }}
                      />
                    )}
                    disabled={
                      tabValue == 0 ? (
                      editTask
                        ? !subtask?.isEditable && (
                          subtask.is_approved !== 0 ||
                          subtask?.sub_task_reassign_status ||
                          !projectEditAccess && userDetails.username !== taskOwnerDetails
                        )
                        : false):true
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`}>Subtask Description<span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    // label="Subtask Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={subtask.sub_task_description}
                    onChange={(e) => handleSubtaskChange(key, 'sub_task_description', e.target.value)}
                    disabled={
                      tabValue == 0?(
                      !(
                        userDetails.username === subtask.sub_task_assign_to?.username ||
                        userDetails.username === selectedUser?.username ||
                        projectEditAccess
                      )):true
                    }
                  />
                </Grid>
                {/* <Grid item xs={3} className={!recurring ? 'd-none' : ''} > */}
                <Grid item xs={3} className={`${(reoccured) ? 'd-none' : ''}`}
                // className={!recurring ? 'd-none' : ''}
                >
                  {/* <div className={`${(recurring != 0) ? 'd-none' : ''} form-group d-flex flex-column`}> */}
                  <div className={`form-group d-flex flex-column`}>

                    {/* <div className={`form-group d-flex flex-column`}> */}
                    <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Start Date</label>
                    <input
                      type="date"
                      id="dateInput"
                      value={subtask.start_datetime}
                      // onChange={(e) => handleSubtaskChange(key, 'start_datetime', e.target.value)}
                      onChange={(e) => handleSubtaskChange(key, 'start_datetime', e.target.value)}
                      // onChange={(e)=>{handleDateChangeSubtask(key, 'start_datetime', e.target.value)}}
                      // onBlur={(e) => handleBlur(key, 'start_datetime', e.target.value)}
                      inputlabelprops={{ shrink: true }}
                      className="form-control"
                      style={{ ...dateStyles, background: 'rgba(217, 217, 217, 0.22)' }}
                      max={dueDate}
                      min={getCurrentDate()}
                      disabled={
                        tabValue == 0?(
                        editTask ? (
                          (projectOwner == userDetails.username && project_owner_approved == 1) ? false :
                            (!(
                              userDetails.username === subtask.sub_task_assign_to?.username ||
                              userDetails.username === selectedUser?.username ||
                              projectEditAccess
                            ) || (subtask.start_datetime && subtask.isEditable != true && subtask.start_datetime_bkp != ""))
                        ) : false):true
                      }

                    // || (subtask.start_datetime !== null && subtask.start_datetime !== undefined && subtask.start_datetime !== '' && projectOwner != userDetails.username )
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`} >Estimation Time (Hrs)<span style={{ color: 'red' }}>*</span></Typography>
                  {/* <TextField
                    type="number"
                    value={subtask.effort_hours}
                    onChange={(e) => { handlePositiveNumberChange(e, key) }}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                    size="small"
                    disabled={
                      editTask ? !(subtask.isEditable) : false
                    }
                  /> */}
                  <TextField
                    // label="Estimation Time (Hrs)"
                    type="number"
                    value={subtask.effort_hours || ''}
                    onChange={(e) => handlePositiveNumberChange(e, key)}
                    onKeyDown={(e) => {
                      // Allow backspace, delete, arrow keys, and tab
                      if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
                        return;
                      }
                      // Prevent entry of invalid characters
                      if (e.key === '-' || e.key === 'e') {
                        e.preventDefault();
                      }
                    }}
                    size="small"
                    disabled={
                      tabValue == 0?(
                      editTask ? !subtask.isEditable : false
                      ):true
                    }
                  />

                </Grid>
                <Grid item xs={3} className={`${(reoccured) ? 'd-none' : ''}`}>
                  <div className={` form-group d-flex flex-column`}>
                    <label htmlFor="dateInput" className={`${classes['task-label']} mb-1`}>Due Date</label>
                    <input
                      type="date"
                      id="dateInput"
                      value={subtask.end_datetime}
                      onChange={(e) => handleSubtaskChange(key, 'end_datetime', e.target.value)}
                      // onBlur={(e) => handleBlur(key, 'end_datetime', e.target.value)}
                      className="form-control"
                      style={{ ...dateStyles, background: 'rgba(217, 217, 217, 0.22)' }}
                      max={dueDate}
                      min={getCurrentDate()}

                      inputlabelprops={{ shrink: true }}
                      disabled={
                        tabValue == 0 ? (
                        editTask ? (
                          ((projectOwner == userDetails.username && project_owner_approved == 1) ? false :
                            !(
                              userDetails.username === subtask.sub_task_assign_to?.username ||
                              userDetails.username === selectedUser?.username ||
                              projectEditAccess
                            ) || (subtask.end_datetime && subtask.isEditable != true && subtask.end_datetime_bkp != "")
                          )
                        ) : false
                      ):true

                      }
                    // || (subtask.end_datetime !== null && subtask.end_datetime !== undefined && subtask.end_datetime !== '' && projectOwner != userDetails.username)
                    />
                  </div>

                </Grid>


                {!subtask.isEditable && editTask ? (
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Status</Typography>
                    <Select
                      value={subtask.sub_task_status}
                      onChange={(e) => handleSubtaskChange(key, 'sub_task_status', e.target.value)}

                      size="small"
                      style={{width:'8em'}}
                      disabled={true}
                    >
                      {statusOptions.map((statusOption, index) => (
                        <MenuItem key={index} value={statusOption}>
                          {statusOption
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                ) : (<>
                </>

                )}


                <Grid item xs={3}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`} >Upload Files</Typography>
                  <label htmlFor={`file-upload-${index}`} style={{ cursor: 'pointer' }}>
                    <input
                      id={`file-upload-${index}`}
                      type="file"
                      style={{ display: 'none' }}
                      // onInput={(e) => handleFileUpload(key, e.target.files)}
                      onInput={(e) => {
                        handleFileUpload(key, e.target.files);
                        // Reset the input value to allow the same file to be uploaded again
                        e.target.value = null;
                      }}


                      accept=".csv, image/*"
                      multiple
                      disabled={
                        tabValue == 0?(
                        !(
                          userDetails.username === subtask.sub_task_assign_to?.username ||
                          userDetails.username === selectedUser?.username ||
                          projectEditAccess
                        )):true
                      }
                    />
                    <IconButton component="span">
                      <CloudUploadIcon />
                    </IconButton>
                  </label>
                </Grid>


                <Grid item xs={3}>
                  <Typography variant="subtitle1" className={`${classes['task-label']}`} >Uploaded Files</Typography>
                  <div>
                    {subtask.file_name && subtask.file_name.length > 0 ? (
                      subtask.file_name.map((fileName, fileIndex) => (
                        <div key={fileIndex} style={{ display: 'flex', alignItems: 'center' }}>
                          <span
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '100%'
                            }}
                            onClick={() => { handleS3FileDownload(subtask.s3_file_name[fileIndex], fileName) }}
                          >
                            {formatFileName(fileName)}
                          </span>
                          <IconButton
                            disabled={
                              tabValue == 0?(
                              !(
                                userDetails.username === subtask.sub_task_assign_to?.username ||
                                userDetails.username === selectedUser?.username ||
                                projectEditAccess
                              )):true
                            }
                            onClick={() => handleFileDelete(key, fileIndex)}>
                            <CancelIcon />
                          </IconButton>
                        </div>
                      ))
                    ) : (
                      <span>No files uploaded</span>
                    )}
                  </div>
                </Grid>
                {(userDetails.global_privileges.perf_score != 'none' && editTask)
                  &&
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Performance Score (0-5)</Typography>
                    <TextField
                      // label="Performance Score"
                      type="number"
                      value={subtask.performance_score}
                      // onChange={(e) => handleSubtaskChange(key, 'subtask_performance_score', e.target.value)}
                      onChange={(e) => addPerformanceScore(e, key)}
                      onKeyDown={(e) => {
                        if (e.key === '-' || e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      disabled={
                        tabValue == 0 ?(
                        
                        !(subtask.is_approved == 1)
                        ):true
                      }
                      size="small"
                      inputProps={{ min: 0, max: 5 }}
                    />
                  </Grid>
                }
                {(userDetails.global_privileges.notes != 'none' && editTask) &&
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Remarks</Typography>
                    <TextField
                      // label="Reviews"
                      value={subtask.remarks}
                      onChange={(e) => handleSubtaskChange(key, 'remarks', e.target.value)}
                      fullWidth
                      size="small"
                      disabled={
                        tabValue ==0?(
                        !(subtask.is_approved == 1)
                        ):true
                      }
                      multiline
                      rows={4}
                    />
                  </Grid>
                }

                {/* <Grid item xs={3}>
                  <Typography variant="subtitle1">Status</Typography> */}
                {/* <TextField
                    label="Status"
                    value={subtask.status}
                    onChange={(e) => handleSubtaskChange(key, 'status', e.target.value)}
                    fullWidth
                    size="small"
                  /> */}
                {/* </Grid> */}





                {isLastSubtask && (
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '1em' }}>
                    {
                      tabValue == 0 ?(
                      editTask ? (
                        ((projectEditAccess) ||
                          // userDetails.username == taskOwnerDetails ||
                          (taskApproved == 1 && taskUserList.includes(userDetails.username) && userDetails.username == taskOwnerDetails) ||
                          (userDetails.personal_privileges[domainId]?.['project']?.[projectId] !== 'view' &&
                            userDetails.personal_privileges[domainId]?.['project']?.[projectId] !== 'view|a')) && (


                          <>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: 'grey', padding: 0, width: '9em' }}
                              onClick={handleAddSubTaskEdit}
                              startIcon={
                                <AddCircleOutlineIcon style={{ color: 'white' }} />
                              }
                            >
                              <span style={{ fontSize: '1em', color: 'white' }}>SubTask</span>
                            </Button>

                          </>


                        )
                      ) : (
                        <>
                       <Button
                            variant="contained"
                            style={{ backgroundColor: 'grey', padding: 0, width: '9em' }}
                            onClick={handleAddSubTaskNew}
                            startIcon={
                              <AddCircleOutlineIcon style={{ color: 'white' }} />
                            }
                          >
                            <span style={{ fontSize: '1em', color: 'white' }}>SubTask</span>
                          </Button>
                        </>
                      )
                    ):(
                      <></>
                    )
                    }
                    { tabValue == 0 ?(
                    editTask ? (
                      <>
                        {(projectEditAccess || taskUserList.includes(userDetails.username)) ? (
                          <Button title="Save Subtask" variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleEditTask()}>
                            Update Task
                          </Button>
                        ) : (subTaskOwnerDetails.includes(userDetails.username) && !projectEditAccess && taskUserList.includes(userDetails.username)) ? (
                          <Button title="Save Subtask" variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleEditTask()}>
                            Update Task
                          </Button>
                        ) : null}
                      </>
                    ) : (
                      <Button title="Save Subtask" variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleCreateTask()}>
                        Create Task
                      </Button>
                    )
                  
                    ):(<></>)
                  }


                  </Grid>
                )}
              </Grid>
            );
          })}
        </DialogContent>
      </Dialog>

      <Dialog open={approval} onClose={handleApprovalClose}
        PaperProps={{
          sx: {
            width: '30em',
            maxWidth: '30em',
          },
        }}
      >
        <DialogTitle>
          <strong>
            Proceed with the Approval Request ?
          </strong></DialogTitle>
        <DialogContent dividers style={{ height: '13em', overflowY: 'scroll' }}>
          <p>Approval required from the requested domain head:</p>
          <DomainList domainList={mergedApproval} />
        </DialogContent>
        <div className="modal-actions d-flex justify-content-center align-items-center mb-3">
          {editTask ?
            (
              <>
                <Button onClick={handleApprovalConfirm} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
                  Send Request
                </Button>
                <Button onClick={handleApprovalClose} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button onClick={handleCreateTaskApproval} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
                  Send Request
                </Button>
                <Button onClick={handleCreateTaskApprovalClose} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
                  Cancel
                </Button>
              </>
            )
          }


        </div>

      </Dialog>


      <Dialog open={recurryingDialog} onClose={handleReoccuringDialogClose}
        PaperProps={{
          sx: {
            width: '27em',
            // maxWidth: '30em',
            height: '27em'
          },
        }}
      >
        <DialogTitle>
          <strong>
            Custom recurrence
          </strong></DialogTitle>
        <DialogContent dividers style={{ height: '13em', overflowY: 'scroll' }}>
          <div className={classes['recurrence-input']}>
            <div className={`${classes['field']} d-flex  align-items-center`}>
              <div className='m-2'>Repeat Every : </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="number"
                  id="repeatInterval"
                  value={interval}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow clearing the input or enforce the minimum value
                    setInterval(value === '' ? '' : Math.max(1, Number(value)));
                  }}
                  min="1"
                  style={{ padding: '0.2em', width: '3em', backgroundColor: 'rgb(241, 243, 244)', border: 'none' }}
                />
                <select className='p-1 m-2' style={{ backgroundColor: 'rgb(241, 243, 244)', border: 'none' }} value={timeUnit} onChange={handleTimeUnitChange}>
                  <option value="day">
                    {interval > 1 ? "Days" : "Day"}
                  </option>
                  <option value="week">
                    {interval > 1 ? "Weeks" : "Week"}
                  </option>
                </select>
              </div>
            </div>

            {timeUnit === 'week' && (
              <div className={classes['field']}>
                <label className='mb-3'>Repeats On</label>
                <div className={classes['day-selector']}>
                  {['M', 'T', 'W', 'Th', 'F', 'S', 'Su'].map((day, index) => (
                    <div
                      key={index}
                      className={`${classes['day-circle']} ${selectedDays.includes(index + 1) ? `${classes['selected']}` : ''}`}
                      onClick={() => handleDayChange(index + 1)}
                    >
                      {day}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <FormControl component="fieldset">
              <FormLabel component="legend">Ends</FormLabel>
              <RadioGroup
                aria-label="end-options"
                name="end-options"
                value={endOption}
                onChange={handleEndOptionChange}
              >
                <div>
                  <FormControlLabel
                    value="never"
                    control={<Radio  />}
                    label="Never"
                  />
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '22em' }}>
                  <FormControlLabel
                    value="on"
                    control={<Radio />}
                    label="On"
                  />
                  {endOption === 'on' && (
                    <input
                      type="date"
                      value={endDate}
                      min={getCurrentDate()}
                      onChange={(e) => setEndDate(e.target.value)}
                      inputlabelprops={{
                        shrink: true,
                      }}
                      style={{ ...dateStyles }}

                    />
                  )}
                </div>
              </RadioGroup>
            </FormControl>

          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{ border: '1px solid black', padding: '0.35em' }} onClick={() => handleReoccuringDialogClose()}>Cancel</Button>
          <Button style={{ border: '1px solid black', padding: '0.35em' }} onClick={() => handleSaveReoccurence()}>Save</Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>

  )
}

export default Task
