import ReCAPTCHA from 'react-google-recaptcha';

const ReCAPTCHAComponent = ({setCaptcha}) => {

    const handleRecaptchaChange = async (token) => {
        if(
            token === undefined ||
            token === '' ||
            token === null
        ){
            console.log('CAPTCHA submit unsuccessfull.')
        }
        else{
            setCaptcha(token)
        }
    };

    const handleError = () => {
        alert('Something went wrong.')
    }

    return (
        <div className='mt-3 captcha-section' style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <ReCAPTCHA
                size="normal"
                render="explicit"
                theme="dark"
                sitekey="6LfLXIApAAAAAD0NWDn1l6mESuIdnbvGGUpofY-d"
                onChange={handleRecaptchaChange}
                onError={handleError}
            />
        </div>
    )
}

export default ReCAPTCHAComponent;