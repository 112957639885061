import React, { act } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography, FormControlLabel } from '@mui/material';
import styles from './ProjectApprovalDialog.module.css';
import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';



const ProjectApprovalDialog = ({ open, onClose, onReject, projectData, setApprovals, step, setStep, actionType, setNotificationSnackbar, setMessage, setSeverity }) => {
  // const { projectName, username, requestedBy, description } = projectData;
  const handleApprove = async (projectData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    const data = {
      approval_id: projectData.approval_id,
      approval_status: 1,
      remark: ''
    }
    try {
      const response = await axios.post(`/backend/update_approval`, data, config);

      setNotificationSnackbar(true)
      setMessage('Approval Status Updated Successfully')
      setSeverity('success')
      setApprovals((prevState) => prevState + 1);

      onClose();
    } catch (error) {
      setNotificationSnackbar(true)
      setMessage(error.response.data.detail)
      setSeverity('success')
    }

  };
  const [rescheduleDate, setRescheduleDate] = useState('')


  const onRejectReview = () => {
    setStep(3)
  }

  const handleReviewApprove = async (projectData) => {
    if (critical && rescheduleDate == '') {
      return

    }
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const data = {
        approval_id: projectData.approval_id,
        status: 1,
        is_reschedule: critical,
        reschedule_date: rescheduleDate,
        description: ''
      }
      const response = await axios.post(`/backend/approve_review_request`, data, config);

      setApprovals((prevState) => prevState + 1);
      setNotificationSnackbar(true)
      setMessage('Sub Task Review Status updated Successfully')
      setSeverity('success')
    }
    catch (error) {
      setNotificationSnackbar(true)
      setMessage(error.response.data.detail)
      setSeverity('success')
    }
    setStep(0)
    onClose()
  }
  const soloClose = () => {
    setStep(2)
  }

  const [reason, setReason] = useState('');
  const [rejectOpen, setRejectOpen] = useState(open)
  const [critical, setCritical] = useState(0)

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 60,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(31px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    }
  }));

  const handleCritical = (event) => {
    setCritical(event.target.checked ? 1 : 0);
  };

  const rejectReview = async (projectData) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const data = {
        approval_id: projectData.approval_id,
        status: 0,
        is_reschedule: critical,
        reschedule_date: rescheduleDate,
        description: reason,
      }
      // console.log(data)
      const response = await axios.post(`/backend/approve_review_request`, data, config);
      setApprovals((prevState) => prevState + 1);
      setNotificationSnackbar(true)
      setMessage('Approval Status Updated Successfully')
      setSeverity('success')
      

    }
    catch (error) {
      setNotificationSnackbar(true)
      setMessage(error.response.data.details)
      setSeverity('success')
    }
    setStep(0)
    onClose()
  }



  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };


  const [isCompleteDate, setIsCompleteDate] = useState(false);

  const handleDateChange = (e) => {
    const value = e.target.value;
    setRescheduleDate(value);

    if (value.length === 10) { 
      setIsCompleteDate(true);
    } else {
      setIsCompleteDate(false);
    }
  };

  const handleDateBlur = () => {
    if (isCompleteDate && rescheduleDate < getCurrentDate()) {
      setNotificationSnackbar(true);
      setMessage(`Review date cannot be less than current date`);
      setSeverity('error');
      setRescheduleDate('');
      // return
    } 
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth >
      {step == 1 && (
        <>
          <DialogTitle className={styles.dialogTitle}>
            <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid black', width: '100%' }}>
              {actionType === 201 && (
                <div style={{ marginLeft: '1em', fontSize: '1em' }} >{projectData.action}</div>
              )}

              {actionType === 202 && (
                <div style={{ marginLeft: '1em', fontSize: '1em' }} >{projectData.action}</div>
              )}

              {actionType === 203 && (
                <div style={{ marginLeft: '1em', fontSize: '0.8em' }} >{projectData.action}</div>
              )}


              {/* <Button onClick={onClose}>Cancel</Button> */}
              <IconButton onClick={onClose} className={styles.closeButton}>
                <CloseIcon />
              </IconButton>

            </div>
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <DialogContentText className={styles.dialogContentText}>
              <div className={styles.dialogItem}>
                Project Name: <span className={styles.highlight}>{projectData.to_update_settings.project_name}</span>
              </div>
              <div className={styles.dialogItem}>
                Username: <span className={styles.highlight}>{projectData.for_username}</span>
              </div>
              <div className={styles.dialogItem}>
                Requested By: <span className={styles.highlight}>{projectData.by_username}</span>
              </div>
              {actionType == 202 && (
                <div className={styles.dialogItem}>
                  Task Name: <span className={styles.highlight}>{projectData.to_update_settings.task_name}</span>
                </div>
              )}
              {/* {(actionType == 203) && (
                <div className={styles.dialogItem}>
                  Sub Task Name: <span className={styles.highlight}>{projectData.to_update_settings.sub_task_name}</span>
                </div>
              )} */}

              <div className={styles.dialogItem}>
                Status: {' '}
                {projectData.is_approved === -1 ? (
                  <span style={{ color: 'rgb(250, 176, 32)', fontWeight: 'bold', marginLeft: '1em' }}>Pending</span>
                ) : projectData.is_approved === 1 ? (
                  <span style={{ color: '#4caf50', fontWeight: 'bold', marginLeft: '1em' }}>Approved</span>
                ) : (
                  <span style={{ color: '#C70000', fontWeight: 'bold', marginLeft: '1em' }}>Rejected</span>
                )}
              </div>
            </DialogContentText>

            {actionType == 201 &&
              <TextField
                className={styles.dialogTextField}
                autoFocus
                margin="dense"
                id="description"
                label="Description"
                type="text"
                fullWidth
                value={projectData.description}
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            }


          </DialogContent>
          {projectData.is_approved === -1 && (
            <DialogActions className={styles.dialogActions}>
              <Button onClick={() => handleApprove(projectData)} className={styles.buttonApprove}>Approve</Button>
              <Button onClick={() => onReject(projectData)} className={styles.buttonReject}>Reject</Button>
            </DialogActions>
          )}
        </>
      )}

      {step == 2 && (
        <>
          <DialogTitle className={styles.dialogTitle}>
            <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: '1px solid black', width: '100%' }}>
              <div style={{ marginLeft: '1em', fontSize: '1em' }} >Subtask Review </div>
              {/* <Button onClick={onClose}>Cancel</Button> */}
              <IconButton onClick={onClose} className={styles.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <DialogContentText className={styles.dialogContentText}>
              <div className={styles.dialogItem}>
                Project Name : <span className={styles.highlight}>{projectData.to_update_settings.project_name}</span>
              </div>
              <div className={styles.dialogItem}>
                Username : <span className={styles.highlight}>{projectData.for_username}</span>
              </div>
              <div className={styles.dialogItem}>
                Requested By : <span className={styles.highlight}>{projectData.by_username}</span>
              </div>
              <div className={styles.dialogItem}>
                Requested Review  Date : <span className={styles.highlight}> {projectData.to_update_settings.review_date} </span>
              </div>
              <div className={styles.dialogItem}>
                Project Due Date : <span className={styles.highlight}> {projectData.to_update_settings.project_due_date} </span>
              </div>
              {projectData.action !== "project" && (
                <div className={styles.dialogItem}>
                  Sub Task  Name: <span className={styles.highlight}>{projectData.to_update_settings.sub_task_details.sub_task_name}</span>
                </div>
              )}
              <div className={styles.dialogItem}>
                Status: {' '}
                {projectData.is_approved === -1 ? (
                  <span style={{ color: 'rgb(250, 176, 32)', fontWeight: 'bold', marginLeft: '1em' }}>Pending</span>
                ) : projectData.is_approved === 1 ? (
                  <span style={{ color: '#4caf50', fontWeight: 'bold', marginLeft: '1em' }}>Approved</span>
                ) : (
                  <span style={{ color: '#C70000', fontWeight: 'bold', marginLeft: '1em' }}>Rejected</span>
                )}
              </div>
              {(projectData.is_approved === 1 && projectData.final_review_date) && (
                <>
                  <div className={styles.dialogItem}>
                    Accepted Review  Date : <span className={styles.highlight}> {projectData.final_review_date} </span>
                  </div>
                </>
              )
              }
            </DialogContentText>

            <TextField
              className={styles.dialogTextField}
              autoFocus
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              value={projectData.description}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
            {/* <div className='d-flex flex-column'> */}
            <div className='d-flex flex-column'>


              {projectData && projectData.is_approved == -1 && (
                <>              <Typography style={{}} variant="subtitle1">
                  Reschedule Review Date
                </Typography>
                  <div className='d-flex'>
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      control={<IOSSwitch sx={{ m: 1 }}
                        checked={critical === 1} onChange={handleCritical}
                      />}
                    />
                    {critical ? (
                      <input
                        type="date"
                        id="datePicker"
                        value={rescheduleDate}
                        // onChange={(e) => { setRescheduleDate(e.target.value) }}

                        onChange={handleDateChange}

                        onBlur={handleDateBlur}


                        style={{ width: '30%', padding: '0.5em', borderRadius: '10px' }}
                        min={getCurrentDate()}
                        disabled={projectData.is_approved != -1}
                      />
                    ) : (<></>)
                    }
                  </div>

                </>
              )

              }

            </div>



          </DialogContent>
          {projectData.is_approved === -1 && (
            <DialogActions className={styles.dialogActions}>
              <Button onClick={() => handleReviewApprove(projectData)} className={styles.buttonApprove}>Approve</Button>
              <Button onClick={() => onRejectReview(projectData)} className={styles.buttonReject}>Reject</Button>
            </DialogActions>
          )}
        </>
      )}


      {step === 3 && (
        <>
          <DialogTitle className={styles.dialogTitle}>
            <div style={{ borderBottom: '1px solid black' }}>Rejection Reason </div>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <IconButton onClick={soloClose} className={styles.closeButton}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <div >
              <TextField
                className={styles.dialogTextField}
                autoFocus
                margin="dense"
                id="reason"
                label="Reason for Rejection"
                type="text"
                fullWidth
                multiline
                rows={4} // Adjust the number of rows as needed
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>

          </DialogContent>
          <DialogActions className={`${styles['dialogActions']} mb-2`}>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <div>
              <Button style={{
                backgroundColor: 'rgb(9, 94, 173)',
                color: 'white', fontWeight: '400', '&:hover': { backgroundColor: 'rgb(9,99,175)' }
              }} onClick={() => { rejectReview(projectData) }} className={styles.buttonSave}>Send</Button>
            </div>

          </DialogActions>
        </>
      )}


    </Dialog>
  );
};

export default ProjectApprovalDialog;
