import React from 'react'
//  New code 
import Task from '../Task/Task';
import { useState, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import classes from "./SprintTable.module.css";
import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Snackbar, Alert } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Box, Chip } from '@mui/material';

import { ReactComponent as Collapse } from '../../Assets/Collapse.svg';
import { ReactComponent as Expand } from '../../Assets/Expand.svg';
import { InputText } from 'primereact/inputtext';
import { FilterList } from '@mui/icons-material';
import { Menu } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import DashboardTitle from '../Dashboard/DashboardTitle';
import Navbar from "../Navbar/Navbar";

import CriticalIcon from "../../Assets/warning.png";
import RejectIcon from "../../Assets/decline.png";
import PendingIcon from "../../Assets/pending.png";
import RecurringTask from "../../Assets/refresh.png";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';


const SprintTable = (props) => {
  let location = useLocation()
  let navigate = useNavigate()
  const { sprintDetails, sprint_id } = location.state

  const [searchText, setSearchText] = useState('');
  const [filteredNodes, setFilteredNodes] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [nodes, setNodes] = useState([]);

  const [expandedKeys, setExpandedKeys] = useState([]);

  const [status, setStatus] = useState([])

  const [domainList, setDomainList] = useState([])
  const [filterDomainList, setFilteredDmainLIst] = useState(null)
  const [originalTransformedList, setOriginalTransformedList] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  const [dashboard, setDashboard] = useState({
    "Dashboard": "Sprint Overview",
    "Info": "The Sprint Overview provides a comprehensive view of all tasks and subtasks assigned within a particular sprint."
  })
  const [sprintMode, setSprintMode] = useState(true)
  const [userDetails, setUserDetails] = useState(null)
  const [sprintProgress, setSprintProgress] = useState(0)
  const [sprintTable, setSprintTable] = useState(false)

  const [progressBarColors, setProgressBarColors] = useState([])
  const [statusColors, setStatusColors] = useState([])

  const fetchOptionList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_all_options", config)
      let data = response.data
      setProgressBarColors(data.progress_bar_color)
      setStatusColors(data.status)
      setStatus(data.status_sequence)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };





  // useEffect(() => {
  //     // if (props.domainList) {
  //     //     setDomainList(props.domainList)
  //     // }
  //     if (domainArray) {
  //         setDomainList(domainArray)
  //     }
  // }, [domainList])
  // }, [props.domainList])
  const fetchSprintDetails = async () => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const sprintResponse = await axios.get(`/backend/get_sprint_details/${sprint_id}`, config);
      let domainNames = sprintDetails.domain_name
      const domainArray = domainNames.split(',');
      setDomainList(domainArray)

      setSprintProgress(sprintResponse.data.progress)
      const transformedNodes = sprintResponse.data.all_task_list.map(transformTaskDetailsToNodes);
      setNodes(transformedNodes);
      setFilteredNodes(transformedNodes);
      setOriginalTransformedList(transformedNodes)
      setSprintTable(true)
      setIsLoaded(true)

    } catch (error) {
      console.error('Error creating task', error);
    }




  }

  const [collapseState, setCollapseState] = useState(true)
  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
    }
    catch (error) {

      if (error.response.status == 401) {
        const message = error.response.data.details || 'An error occurred';
      }
    }
  }

  useEffect(() => {

    fetchSprintDetails()
    fetchUserDetails()
    fetchOptionList()
  }, [])

  const transformTaskDetailsToNodes = (taskDetails) => {
    const subTasks = Object.keys(taskDetails.sprint_sub_task).map((subTaskKey, index, array) => {
      const subTask = taskDetails.sprint_sub_task[subTaskKey];
      return {
        key: subTaskKey,
        task_id: taskDetails.task_id,
        data: {
          sub_task_name: subTask.sub_task_name,
          created_at: subTask.start_datetime,
          deadline: subTask.end_datetime,
          effort_hours: subTask.effort_hours,
          remarks: subTask.remarks,
          assign_to: subTask.sub_task_assign_to_name,
          is_file_attached: subTask.is_file_attached,
          file_name: subTask.file_name,
          s3_file_name: subTask.s3_file_name,
          completion_date: subTask.completion_date,
          status: subTask.sub_task_status,
          notes: subTask.remarks,
          performance_score: subTask.performance_score,
          username: subTask.sub_task_assign_to,
          approved: subTask.is_approved,
          last_child: index === array.length - 1 // Set true for the last element
        }
      };
    });

    return {
      key: taskDetails.task_id,
      data: {
        assigned_by: taskDetails.assigned_by_name,
        task_name: taskDetails.task_name,
        assign_to: taskDetails.task_owner_name,
        created_at: taskDetails.start_date,
        deadline: taskDetails.deadline,
        completion_date: taskDetails.task_completion_date,
        effort_hours: taskDetails.total_task_effort_hours[0],
        status: taskDetails.status,
        task_id: taskDetails.task_id,
        notes: taskDetails.note,
        performance_score: taskDetails.performance_score,
        sub_task_name: `Total Subtask : ${Object.keys(taskDetails.sprint_sub_task).length}`,
        domain_name: taskDetails.domain_name,
        critical: taskDetails.is_critical,
        project_name: taskDetails.project_name  ,
        approved: taskDetails.approval_status,
        is_recurring: taskDetails.is_recurring,
      },
      children: subTasks
    };
  };


  const getStatusColor = (status) => {
    for (const [key, value] of Object.entries(statusColors)) {
      if (status in value) {
        return value[status];
      }
    }
    return null;
  };


  const domainName = (rowData) => {

  }

  const statusTemplate = (rowData) => {
    const selectedColor = getStatusColor(rowData.data.status);
    const backgroundColor = selectedColor ? selectedColor : '#FFFFFF';
    const isParent = rowData.children && rowData.children.length >= 0;
    return (
      <>
        <>
          <div style={{ width: '100%' }} className='d-flex justify-content-center align-items-center '>
            <div style={{ backgroundColor: backgroundColor, color: 'black', minWidth: '5em', width: '85%', padding: '8px', borderRadius: '50px' }} className='d-flex justify-content-center align-items-center'>
              {/* <span>{rowData.data.status}</span> */}
              <span style={{ fontSize: '10px', fontWeight: 'bold' }}>{rowData.data.status.charAt(0).toUpperCase() + rowData.data.status.slice(1).toLowerCase()}</span>
            </div>
          </div>
        </>
      </>
    );
  };


  const onRowToggle = (event) => {
    let keys = { ...expandedKeys };
    if (event.node.children) {
      if (keys[event.node.key]) {
        delete keys[event.node.key];
      } else {
        keys[event.node.key] = true;
      }
    }
    setExpandedKeys(keys);
  };

  let expandedSubtask = false
  const handleRowClick = (e) => {
    if (!expandedSubtask) {
      onRowToggle(e);
    }
    else {
      expandedSubtask = false
    }
  };


  const expandAll = () => {
    const allKeys = filteredNodes.reduce((acc, node) => {
      const addKeys = (node) => {
        acc[node.key] = true;
        if (node.children) {
          node.children.forEach(child => addKeys(child));
        }
      };
      addKeys(node);
      return acc;
    }, {});
    setExpandedKeys(allKeys);
    setCollapseState(false)
  };




  const handleCollapseAll = () => {
    setExpandedKeys({});
    setCollapseState(true)
  };




  const handleSearchChange = (e) => {
    const term = e.target.value || ''; // Ensure e.target.value is not undefined
    setSearchText(term);
    filterNodes(term, statusFilter); // Apply filter immediately on search text change
  };


  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusFilterChange = (e) => {
    setSelectedStatus(e);
    const status = e || ''; // Ensure e.target.value is not undefined
    setStatusFilter(status);
    filterNodes(searchText, status); // Apply filter immediately on status filter change
  };

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };


  const filterNodes = (term, status) => {

    if (!term.trim() && !status.trim()) {
      // const filtered = searchNodes(nodes, term, status);
      // setFilteredNodes(filtered);
      fetchSprintDetails()
    } else {
      term = term.trim()
      const filtered = searchNodes(nodes, term, status);
      setFilteredNodes(filtered);
    }
  };

  const rowClassName = (rowData) => {
    if (!rowData.children) {
      return 'custom-expanded-row-treetable';
    }
    return '';
    // return { 'custom-expanded-row-treetable': rowData.children && rowData.children.length > 0 };
  };

  const searchNodes = (nodes, term, status) => {
    if (!term.trim() && !status.trim()) {
      return nodes;
    }

    return nodes.filter(node => {
      const dataValues = Object.values(node.data);
      const matchesSearch = dataValues.some(value =>
        typeof value === 'string' && value.toLowerCase().includes(term.toLowerCase())
      );
      const matchesStatus = !status || node.data.status.toLowerCase() === status.toLowerCase();

      if (matchesSearch && matchesStatus) {
        return true;
      }
      if (node.children) {
        node.children = searchNodes(node.children, term, status); // Update children with filtered results
        return node.children.length > 0; // Return parent node if any child matches
      }

      return false; // No match found in parent or children
    });
  };

  const handleSprintBack = () => {
    // props.setSprintTable(false)
    navigate(-1);
  }


  const backHoverStyles = {
    color: 'black',
    cursor: 'pointer'
  };

  const handleDomainStatus = (event, rowData) => {
    if (rowData.length == 0 || rowData == '') {
      setFilteredNodes(originalTransformedList)
    }
    else {
      const trimmedDomainArray = rowData.map(domain => domain.trim());
      const filteredTasks = originalTransformedList.filter(task =>
        trimmedDomainArray.includes(task.data.domain_name)
      );
      setFilteredNodes(filteredTasks)
    }

  };




  const assignToTemplate = (rowData) => {
    const isParent = rowData.children ? true : false;
    const isRejected = rowData.data && rowData.data.approved === 0;
    const isPending = rowData.data && rowData.data.approved === -1;

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isParent && isRejected && (
          <img title={"Approval rejected"} src={RejectIcon} alt="Reject Icon" style={{ width: '20px', height: '20px', marginRight: '8px', cursor: 'pointer' }} />
        )}
        {isParent && isPending && (
          <img title={"Approval pending"} src={PendingIcon} alt="Pending Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        {!isParent && isRejected && (
          <img title={"Approval rejected"} src={RejectIcon} alt="Reject Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        {!isParent && isPending && (
          <img title={"Approval pending"} src={PendingIcon} alt="Pending Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        )}
        <Tooltip title={rowData.data.assign_to} arrow>
          <span
            style={{
              width: '70%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: 'start'
            }}
          >
            {rowData.data.assign_to}
          </span>
        </Tooltip>
      </div>
    );
  };




  const taskNameTemplate = (rowData) => {
    const isParent = rowData.children && rowData.children.length >= 0;
    const isCritical = rowData.data && rowData.data.critical === 1;
    const isRecurring = rowData.data && rowData.data.is_recurring === 1;

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%", width: "100%", position: 'relative' }}>
        {isParent && (
          <>
            {(isCritical || isRecurring) && (
              <div className='d-flex flex-column justify-content-evenly align-items-center' style={{ height: '100%', position: 'relative' }}>
                {isCritical && (
                  <img title="Critical Task" src={CriticalIcon} alt="Critical Task Icon" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                )}
                {isRecurring && (
                  <img title="Recurring Task" src={RecurringTask} alt="Recurring Task Icon" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                )}
              </div>
            )}
            <Tooltip title={rowData.data.task_name} arrow>
              <span
                style={{
                  textAlign: 'start',
                  width: '60%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {rowData.data.task_name}
              </span>
            </Tooltip>
          </>
        )}
        {!isParent && (
          <>
            {rowData && (
              <>
                <div
                  className="connector-line"
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: 'calc(50% - 2px)',
                    height: rowData.data.last_child ? '50%' : '100%',
                    borderLeft: '0.15em solid grey',
                  }}
                />

                <div
                  className="arrow-icon"
                  style={{
                    position: 'absolute',
                    top: `18px`,
                    left: '4.45em',
                  }}
                >
                  <ShortcutIcon style={{ fontSize: '1.45em', color: 'grey', transform: 'scaleY(-1)' }} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };


  // const customStatusHeader = () => {

  //   return (
  //     <div style={{ alignItems: 'center' }}>
  //       <span>Status</span>
  //       <IconButton size="small" onClick={handleFilterIconClick}>
  //         <FilterList />
  //       </IconButton>


  //       <Menu
  //         anchorEl={anchorEl}
  //         open={Boolean(anchorEl)}
  //         onClose={handleFilterClose}
  //         PaperProps={{
  //           style: { // Set the width of the Menu
  //             fontSize: '0.875rem', // Set font size for the Menu
  //           },
  //         }}
  //       >
  //         <MenuItem
  //           key="clear"
  //           onClick={() => {
  //             handleStatusFilterChange('');
  //             handleFilterClose();
  //           }}
  //         >
  //           Clear
  //         </MenuItem>
  //         {status.map(status => (
  //           <MenuItem
  //             key={status}
  //             onClick={() => {
  //               handleStatusFilterChange(status);
  //               handleFilterClose();
  //             }}
  //           >
  //             {status.toUpperCase()}
  //           </MenuItem>
  //         ))}
  //       </Menu>
  //     </div>
  //   )
  // };

  const customStatusHeader = () => {
    return (
      <div style={{ alignItems: 'center' }}>
        <span>Status</span>
        <IconButton size="small" onClick={handleFilterIconClick}>
          <FilterList />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            style: { 
              fontSize: '0.875rem', 
            },
          }}
        >
          <MenuItem
            key="clear"
            onClick={() => {
              handleStatusFilterChange('');
              handleFilterClose();
            }}
            style={{
              fontWeight: selectedStatus === '' ? 'bold' : 'normal',
              backgroundColor: selectedStatus === '' ? '#e0e0e0' : 'transparent'
            }}
          >
            Clear
          </MenuItem>
          {status.map(status => (
            <MenuItem
              key={status}
              onClick={() => {
                handleStatusFilterChange(status);
                handleFilterClose();
              }}
              style={{
                fontWeight: selectedStatus === status ? 'bold' : 'normal',
                backgroundColor: selectedStatus === status ? '#e0e0e0' : 'transparent'
              }}
            >
              {status.toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  };


  return (
    <>

      <div className={classes['content']}>
        <Navbar dashboard={dashboard} setIsLoaded={setIsLoaded} isLoaded={isLoaded} userDetails={userDetails} />
        <DashboardTitle sprintProgress={sprintProgress} isLoaded={isLoaded} tableView={sprintTable} />

        {isLoaded ? (
          <>
            <div className='d-flex flex-column' style={{ padding: '0.5em' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-center align-items-center'>
                  <div className={`${classes['sprint-back']}`} onClick={handleSprintBack} >
                    <ArrowCircleLeftIcon sx={{ color: 'black', "&:hover": backHoverStyles }} />
                  </div>
                  <div className="autocomplete-filter d-flex flex-column mb-2 mt-3" style={{ marginLeft: '1em' }}>
                    <Autocomplete
                      multiple
                      id="multiple-limit-tags"
                      options={domainList || []}
                      limitTags={2}
                      onChange={handleDomainStatus}
                      renderTags={(value, getTagProps) => {
                        if (value.length > 1) {
                          return [
                            <Chip sx={{ width: '8em' }} key={value[0]} label={value[0]} {...getTagProps({ index: 0 })} />,
                            <Box key="more" sx={{ marginLeft: '5px' }}>+{value.length - 1} more</Box>
                          ];
                        } else {
                          return value.map((option, index) => (
                            <Chip sx={{ width: '8em' }} key={option} label={option} {...getTagProps({ index })} />
                          ));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Domains"
                          placeholder="Domains"
                          // style={{width:'15em'}}
                          sx={{ width: '50x' }}
                        // fullWidth
                        />
                      )}
                      sx={{ width: '300px' }}

                      size="small"
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-center align-items-center'>

                  <div>Sprint Name : {sprintDetails.sprint_name}</div>
                  <div style={{ marginLeft: '1em' }}>({sprintDetails.start_date} -  {sprintDetails.end_date})</div>
                  <div></div>
                </div>
                <div className='d-flex align-items-center'>
                  <div>
                    {collapseState ?
                      (
                        <>
                          <IconButton onClick={expandAll} aria-label="expand all">
                            <Expand title="Expand All Task" />
                          </IconButton>
                        </>
                      ) :
                      (
                        <>
                          <IconButton onClick={handleCollapseAll} aria-label="collapse all">
                            <Collapse title="Collapse All Task" />
                          </IconButton>
                        </>
                      )
                    }
                  </div>
                  <div className="p-input-icon-left" style={{ textAlign: 'end' }}>
                    <InputText size="small" type="search"
                      value={searchText}
                      onChange={handleSearchChange}
                      placeholder="Search..."
                      style={{ width: '9em', height: '2em', borderRadius: '10px' }}
                    />
                  </div>

                </div>


              </div>
              <TreeTable
                value={filteredNodes}
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
                // onRowClick={onRowToggle}
                onRowClick={handleRowClick}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25]}
                scrollable
                frozenWidth="150px"
                scrollHeight="50vh"
                scrollWidth="100%"
                scrollBarWidth='thins'
                className="custom-tree-table"
                rowClassName={rowClassName}
                removableSort

              >
                {/* <Column expander frozen bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="task_name" header="Task" style={{ width: '150px', height: '57px', padding: 0 }} />
                         */}
                <Column expander sortable frozen bodyClassName={`${classes['custom-treetable-body']}`} headerClassName={'header-align-list'} field="assigned_by" header="Assign By" style={{ width: '120px', height: '57px', padding: 0 }} />
                <Column sortable body={taskNameTemplate} bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="task_name" header="Task" style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }} />
                {/* <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="domain_name" header="Domain Name" style={{ width: '150px', height: '57px', padding: 0 }} />
                 */}
                <Column
                  sortable
                  bodyClassName={classes['custom-treetable-body']}
                  headerClassName={'header-align-list'}
                  field="sub_task_name"
                  header="Sub task"
                  style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }}
                  body={(rowData) => (
                    <Tooltip
                      title={rowData.data.sub_task_name || ''}
                      arrow
                      enterTouchDelay={0}
                      sx={{
                        maxWidth: 200,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' ,cursor:'pointer'}}>
                        {rowData.data.sub_task_name}
                      </div>
                    </Tooltip>
                  )}
                />

                <Column
                  sortable
                  bodyClassName={classes['custom-treetable-body']}
                  headerClassName={'header-align-list'}
                  field="domain_name"
                  header="Domain Name"
                  style={{ width: '150px', height: '57px', padding: 0 }}
                  body={(rowData) => (
                    <Tooltip
                      title={rowData.data.domain_name || ''}
                      arrow
                      enterTouchDelay={0}
                      sx={{
                        maxWidth: 200,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',cursor:'pointer' }}>
                        {rowData.data.domain_name}
                      </div>
                    </Tooltip>
                  )}
                />
                <Column
                  sortable
                  bodyClassName={classes['custom-treetable-body']}
                  headerClassName={'header-align-list'}
                  field="project_name"
                  header="Project Name"
                  style={{ width: '130px', height: '57px', padding: 0 }}
                  body={(rowData) => (
                    <Tooltip
                      title={rowData.data.project_name || ''}
                      arrow
                      enterTouchDelay={0}
                      sx={{
                        maxWidth: 200,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',cursor:'pointer' }}>
                        {rowData.data.project_name}
                      </div>
                    </Tooltip>
                  )}
                />


                {/* <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="project_name" header="Project Name" style={{ width: '130px', height: '57px', padding: 0 }} /> */}
                {/* <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="sub_task_name" header="Sub task" style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }} /> */}
                {/*     
                        <Column field="assign_to" header="Assignee"
                            style={{ width: '150px', height: '57px', padding: 0 }}
                            bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'}
                        /> */}
                {/* <Column bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="sub_task_name" header="Subtask" style={{ width: '100px', height: '57px', padding: 0 }} /> */}
                <Column sortable body={assignToTemplate} field="assign_to" header="Assignee"
                  style={{ width: '150px', height: '57px', padding: 0, cursor: 'pointer' }}
                  bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'}
                />
                <Column bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list-status'} header={customStatusHeader} body={statusTemplate} style={{ width: '200px', height: '57px', padding: 0 }} />
                <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="created_at" header="Start Date" style={{ width: '120px', height: '57px', padding: 0 }} />
                <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="deadline" header="Due Date" style={{ width: '120px', height: '57px', padding: 0 }} />
                <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="completion_date" header="Completion Date" style={{ width: '180px', height: '57px', padding: 0 }} />
                <Column sortable bodyClassName={classes['custom-treetable-body']} headerClassName={'header-align-list'} field="effort_hours" header="Effort Hours" style={{ width: '150px', height: '57px' }} />
              </TreeTable>


            </div>
          </>
        ) : (
          <>
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </div>
          </>
        )
        }

      </div>
    </>
  )
}

export default SprintTable