import React from 'react'
import { getCookie } from '../../Services/Cookie/Cookie';
import { useState, useCallback } from 'react';
import axios from "axios"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Button,
  Box,
  IconButton,
  Typography,
  Snackbar,
  InputAdornment
} from '@mui/material';
import { Search, Edit, PauseCircleFilled, CheckCircle, } from '@mui/icons-material';

import CloseIcon from '@mui/icons-material/Close';
import classes from "./Project.module.css"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';

import { InputText } from 'primereact/inputtext'
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, InputLabel, Select, ListItemText } from '@mui/material';
import { useEffect, useRef } from 'react';


import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ProjectListView from './ProjectListView';
import { useLocation } from 'react-router-dom';

import DashboardTitle from './DashboardTitle';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import Swal from 'sweetalert2';


import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row'
import CircularProgress from '@mui/material/CircularProgress';

// import ProjectIcon from "../../Assets/Project2.png";//
import ProjectIcon from "../../Assets/project-management.png";
import DOMPurify from 'dompurify';
import { Paginator } from 'primereact/paginator';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Project = (props) => {
  const location = useLocation();
  const navigate = useNavigate();





  const { domainId, domainName, userPersonalPrivileges, domainOwner, randomColor } = location.state

  const [userDetails, setUserDetails] = useState(null);
  const [externalUserPresent, setExternalUserPresent] = useState(false)
  const [externalUserActive, setExternalUserActive] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
    }
    catch (error) {
      console.error("Error Occured")
    }
  }

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [users, setUsers] = useState([])
  const [externalUser, setExternalUser] = useState([])
  const [coreUser, setCoreUser] = useState([])
  const [deadline, setDeadline] = useState();
  const [editProject, setEditProject] = useState(false);
  const [editDomain, setEditDomain] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [open, setOpen] = useState(false)
  const [selectedUserTypes, setSelectedUserTypes] = useState([])

  const [selectedValuesCore, setSelectedValuesCore] = useState({});
  const [selectedValuesExternal, setSelectedValuesExternal] = useState({});

  const [finalUser, setFinalUser] = useState({});
  const [globalFilterCore, setGlobalFilterCore] = useState('');
  const [globalFilterExternal, setGlobalFilterExternal] = useState('');

  const [projectId, setProjectId] = useState('')

  const [userProject, setUserProject] = useState([])

  const [projectActivated, setProjectActivated] = useState(true)


  const [projectSnackBar, setProjectSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [dropOpen, setDropOpen] = useState(false)

  const [fullView, setFullView] = useState(false)


  const [projectEditOption, setProjectEditOption] = useState(false)


  const handleProjectEditClose = () => {
    setProjectEditOption(false)
  }

  const [projectIdEdit, setProjectIdEdit] = useState('')
  const [projectOwner, setProjectOwner] = useState('')
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });


  const handleProjectEditOpen = (project_id, project_owner, project_name, projectProgress, event) => {
    // const rect = event.target.getBoundingClientRect();
    // setDialogPosition({ top: rect.top, left: rect.right }); // Adjust position based on your requirements

    const rect = event.target.getBoundingClientRect();
    const dialogWidth = 20 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Convert 'em' to pixels
    const dialogHeight = 20 * parseFloat(getComputedStyle(document.documentElement).fontSize);

    let leftPosition = rect.right - 50;
    let topPosition = rect.top;
    let total_width = rect.right + dialogWidth
    if (window.innerWidth < total_width) {
      leftPosition = window.innerWidth - dialogWidth - 100;
    }

    // Check if dialog overflows the bottom edge of the window
    if (window.innerHeight < rect.top + dialogHeight) {
      topPosition = window.innerHeight - dialogHeight;
    }
    setDialogPosition({ top: topPosition, left: leftPosition });
    setProjectOwner(project_owner)
    setProjectIdEdit(project_id)
    setProjectName(project_name)
    setProjectProgress(projectProgress)
    setProjectEditOption(true)
  }
  const handleViewToggle = (event) => {
    setFullView(event.target.checked)
  }

  useEffect(() => {
    fetchUserDetails()
    fetchUserProjects()
    fetchAllUser()
    fetchDomain()
    fetchOptionList()
  }, [])

  const [domainsList, setDomainsList] = useState([])
  const [cardColors, setCardColors] = useState([])
  const [progressBarColors, setProgressBarColors] = useState([])


  const fetchOptionList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_all_options", config)
      let data = response.data
      setCardColors(data.card_color)
      setProgressBarColors(data.progress_bar_color)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };


  const fetchDomain = async () => {
    let is_active = 'All';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let domainResponse = await axios.get(
        `/backend/get_all_domain_list/ALL`,
        config
      );
      let domainData = domainResponse.data;
      setDomainsList(domainData);
      setIsLoaded(true)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };



  const createNewProject = () => {
    setProjectName('')
    setDeadline()
    setSelectedUser(null)
    setOpen(true)
    setExternalUserActive(false)
    setEditProject(true)
    setCoreUser([])
    setExternalUser([])
    setAssignOwner([])
    fetchAllUser()

  }



  const handleUserDropdownClose = () => {
    setDropOpen(false);
  };

  const handleUserDropdownOpen = () => {
    setDropOpen(true);
  };

  const fetchAllUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/1`, config);
      const coreUsers = [];
      const externalUsers = [];
      response.data.forEach(user => {
        if (user.core_domain === domainId) {
          coreUsers.push(user);
        } else {
          externalUsers.push(user);
        }
      });

      setCoreUser(coreUsers);
      setExternalUser(externalUsers);
      setUsers(response.data)


      // fetchProjectDetails()
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }


  const [activeProject, setActiveProject] = useState([])
  const [holdProject, setHoldProject] = useState([])
  const [successfullProject, setSuccessfullProject] = useState([])

  const fetchUserProjects = async () => {
    let is_active = 'ALL';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let projectResponse = await axios.get(
        `/backend/get_user_projects_list/${domainId}/${is_active}`,
        config
      );
      const onHold = [];
      const completed = [];
      const other = [];

      setActiveProject([])
      setHoldProject([])
      setSuccessfullProject([])
      const projectSorted = projectResponse.data.sort((a, b) => {
        return a.project_name.localeCompare(b.project_name);
      });



      setUserProject(projectSorted)
      projectSorted.forEach((project) => {
        if (project.status === "on hold") {
          onHold.push(project);
        } else if (project.status === "success") {
          completed.push(project);
        } else {
          other.push(project);
        }

      });
      setActiveProject(other)
      setHoldProject(onHold)
      setSuccessfullProject(completed)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };


  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => func.apply(this, args), delay);
    };
  }


  const debouncedSetGlobalFilterCore = useCallback(
    debounce((value) => {
      setGlobalFilterCore(value);
    }, 50),
    []
  );


  const debouncedSetGlobalFilterExternal = useCallback(
    debounce((value) => {
      setGlobalFilterExternal(value);
    }, 50),
    []
  );

  const handleCoreSearchChange = (e) => {
    debouncedSetGlobalFilterCore(e.target.value);
  };


  const handleExternalSearchChange = (e) => {
    debouncedSetGlobalFilterExternal(e.target.value);
  };


  const externalHeader = (
    <>
      <div className='d-flex justify-content-between'>
        <div>Add User From External Domain</div>
        <div style={{ textAlign: 'end' }}>
          <InputText size="small" type="search"
            // onChange={(e) => {
            //   setGlobalFilterExternal(e.target.value)
            // }}
            onChange={handleExternalSearchChange}
            placeholder="Search"
            style={{ width: '8em', height: '1.5em', borderRadius: '10px' }}
          />
        </div>
      </div>

    </>
  );

  const coreHeader = (
    <>
      <div className='d-flex justify-content-between'>
        <div>Add User From Core Domain</div>
        <div style={{ textAlign: 'end' }}>
          <InputText size="small" type="search"
            // onChange={(e) => {
            //   setGlobalFilterCore(e.target.value)
            // }}
            onChange={handleCoreSearchChange}
            placeholder="Search"
            style={{ width: '8em', height: '1.5em', borderRadius: '10px' }}
          />
        </div>
      </div>

    </>
  );

  const handleRadioChangeCore = (rowData, columnKey) => {
    setSwalActive(true)
    setSelectedValuesCore((prevSelectedValues) => {
      const currentSelection = prevSelectedValues[rowData.username] || {};
      const isCurrentlySelected = currentSelection[columnKey];

      // Toggle the selection
      const updatedValues = {
        ...prevSelectedValues,
        [rowData.username]: {
          view: false,
          edit: false,
          full: false,
          none: false,
          [columnKey]: !isCurrentlySelected,
        },
      };

      // Ensure only one of the columns is selected
      if (!isCurrentlySelected) {
        for (let key in updatedValues[rowData.username]) {
          if (key !== columnKey) {
            updatedValues[rowData.username][key] = false;
          }
        }
      }

      // Update finalUser state
      if (columnKey !== 'none' && !isCurrentlySelected) {
        setFinalUser(prevState => ({
          ...prevState,
          [rowData.username]: columnKey,
        }));
      } else {
        setFinalUser(prevState => {
          const updatedState = { ...prevState };
          delete updatedState[rowData.username];
          return updatedState;
        });
      }

      return updatedValues;
    });
  };



  const handleProjectSnackbar = () => {
    setProjectSnackBar(false)
  }



  const [extendedDomainList, setExtendedDomainList] = useState([])
  // const handleRadioChangeExternal = (rowData, columnKey) => {
  //   const updatedValues = {
  //     ...selectedValuesExternal,
  //     [rowData.username]: {
  //       view: false,
  //       edit: false,
  //       full: false,
  //       [columnKey]: true,
  //     },
  //   };

  //   setSelectedValuesExternal(updatedValues);

  //   if (columnKey !== 'none') {
  //     setFinalUser(prevState => ({
  //       ...prevState,
  //       [rowData.username]: columnKey,
  //     }));

  //     // if(rowData.username == )
  //     const hasAccessOtherThanView = rowData.personal_privileges[domainId]
  //       ? rowData.personal_privileges[domainId].access !== "view"
  //       : false;
  //     const hasGlobalPrivilege = rowData.global_privileges["domain_creation"] !== "none";
  //     let projectUserExist = projectUserList.includes(rowData.username)
  //     const result = hasAccessOtherThanView || hasGlobalPrivilege;
  //     if (!result) {
  //       setExtendedDomainList(prevExtendedList => {
  //         const domainId = rowData.core_domain;
  //         const domainIndex = prevExtendedList.findIndex(domain => domain.domain_id === domainId);

  //         if (domainIndex !== -1) {
  //           return prevExtendedList.map((domain, index) => {
  //             if (index === domainIndex) {
  //               return {
  //                 ...domain,
  //                 total_users: domain.total_users + 1,
  //                 username: [...domain.username, rowData.username]
  //               };
  //             }
  //             return domain;
  //           });
  //         } else {
  //           const domainDetails = domainsList.find(domain => domain.domain_id === domainId);
  //           if (domainDetails) {
  //             const newDomain = {
  //               domain_id: domainId,
  //               domain_name: domainDetails.domain_name,
  //               head: domainDetails.owner || '',
  //               total_users: 1,
  //               username: [rowData.username]
  //             };
  //             return [...prevExtendedList, newDomain];
  //           }
  //           return prevExtendedList;
  //         }
  //       });
  //     }
  //   } else {
  //     setFinalUser(prevState => {
  //       const updatedState = { ...prevState };
  //       delete updatedState[rowData.username];
  //       return updatedState;
  //     });

  //     setExtendedDomainList(prevExtendedList => {
  //       const domainId = rowData.core_domain;
  //       const domainIndex = prevExtendedList.findIndex(domain => domain.domain_id === domainId);

  //       if (domainIndex !== -1 && prevExtendedList[domainIndex].total_users > 0) {
  //         return prevExtendedList.map((domain, index) => {
  //           if (index === domainIndex) {
  //             // Create a new username array without the username to be removed
  //             const updatedUsernames = domain.username.filter(username => username !== rowData.username);

  //             return {
  //               ...domain,
  //               total_users: Math.max(domain.total_users - 1, 0), // Ensure total_users doesn't drop below zero
  //               username: updatedUsernames // Update the username array
  //             };
  //           }
  //           return domain;
  //         });
  //       }

  //       return prevExtendedList;
  //     });

  //   }
  // };


  const handleRadioChangeExternal = (rowData, columnKey) => {
    setSwalActive(true)
    setSelectedValuesExternal((prevSelectedValues) => {
      const currentSelection = prevSelectedValues[rowData.username] || {};
      const isCurrentlySelected = currentSelection[columnKey];

      // Toggle the selection
      const updatedValues = {
        ...prevSelectedValues,
        [rowData.username]: {
          view: false,
          edit: false,
          full: false,
          none: false,
          [columnKey]: !isCurrentlySelected,
        },
      };

      // Ensure only one of the columns is selected
      if (!isCurrentlySelected) {
        for (let key in updatedValues[rowData.username]) {
          if (key !== columnKey) {
            updatedValues[rowData.username][key] = false;
          }
        }
      }

      // Update finalUser state
      if (columnKey !== 'none' && !isCurrentlySelected) {
        setFinalUser(prevState => ({
          ...prevState,
          [rowData.username]: columnKey,
        }));

        const hasAccessOtherThanView = rowData.personal_privileges[domainId]
          ? (rowData.personal_privileges[domainId].access !== "view" && rowData.personal_privileges[domainId].access !== "view|a")
          : false;

        const hasGlobalPrivilege = rowData.global_privileges["domain_creation"] !== "none";
        let userDomainOwner = domainsList.filter(item => item.owner == userDetails.username)
        let doesUsernameExist = userDomainOwner.some(item => item.domain_id === rowData.core_domain);

        const result = hasAccessOtherThanView || doesUsernameExist;
        if (!result) {
          setExtendedDomainList(prevExtendedList => {
            const domainId = rowData.core_domain;
            const domainIndex = prevExtendedList.findIndex(domain => domain.domain_id === domainId);

            if (domainIndex !== -1) {
              return prevExtendedList.map((domain, index) => {
                if (index === domainIndex) {
                  return {
                    ...domain,
                    total_users: domain.total_users + 1,
                    username: [...domain.username, rowData.username]
                  };
                }
                return domain;
              });
            } else {
              const domainDetails = domainsList.find(domain => domain.domain_id === domainId);
              if (domainDetails) {
                const newDomain = {
                  domain_id: domainId,
                  domain_name: domainDetails.domain_name,
                  head: domainDetails.owner || '',
                  total_users: 1,
                  username: [rowData.username]
                };
                return [...prevExtendedList, newDomain];
              }
              return prevExtendedList;
            }
          });
        }
      } else {
        setFinalUser(prevState => {
          const updatedState = { ...prevState };
          delete updatedState[rowData.username];
          return updatedState;
        });

        setExtendedDomainList(prevExtendedList => {
          const domainId = rowData.core_domain;
          const domainIndex = prevExtendedList.findIndex(domain => domain.domain_id === domainId);

          if (domainIndex !== -1 && prevExtendedList[domainIndex].total_users > 0) {
            return prevExtendedList.map((domain, index) => {
              if (index === domainIndex) {
                const updatedUsernames = domain.username.filter(username => username !== rowData.username);
                return {
                  ...domain,
                  total_users: Math.max(domain.total_users - 1, 0),
                  username: updatedUsernames
                };
              }
              return domain;
            });
          }
          return prevExtendedList;
        });
      }

      return updatedValues;
    });
  };



  const [isCompleteDate, setIsCompleteDate] = useState(false);


  const handleDeadline = (e) => {
    const value = e.target.value;
    setDeadline(value)
    if (value.length === 10) {
      setIsCompleteDate(true);
    } else {
      setIsCompleteDate(false);
    }


    setSwalActive(true)
  }


  const handleDateBlur = () => {
    if (isCompleteDate && deadline < getCurrentDate()) {
      setProjectSnackBar(true);
      setMessage(`Project Deadline cannot be less than current date`);
      setSeverity('error');
      setDeadline('');
      setSwalActive(true);
      return
    } else if (isCompleteDate) {
      setSwalActive(true);
    }
  };


  const handleUserTypeChange = (event) => {
    const newSelectedUserTypes = event.target.value;
    let lastSelectedType;
    if (newSelectedUserTypes.length > 1) {
      lastSelectedType = newSelectedUserTypes[newSelectedUserTypes.length - 1];
    }
    else {
      lastSelectedType = newSelectedUserTypes
    }

    setSelectedUserTypes(newSelectedUserTypes);
    setDropOpen(false)

    setTimeout(() => {
      if (lastSelectedType.includes('core')) {
        coreUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      if (lastSelectedType.includes('external')) {
        externalUserRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100)



  };

  const [assignOwner, setAssignOwner] = useState([])
  const [approvalDescription, setApprovalDescription] = useState('')

  const handleNewAssignOwner = (newValue) => {

    setSelectedUser(newValue)
    let rowData = newValue
    setAssignOwner([])
    if (rowData) {
      const hasAccessOtherThanView = rowData.personal_privileges[domainId]
        ? (rowData.personal_privileges[domainId].access !== "view" && rowData.personal_privileges[domainId].access !== "view|a")
        : false;
      // const hasGlobalPrivilege = rowData.global_privileges["domain_creation"] !== "none";
      let project_user = false
      if (projectUserList.includes(rowData.username)) {
        project_user = true
      }
      let userDomainOwner = domainsList.filter(item => item.owner == userDetails.username)
      let doesUsernameExist = userDomainOwner.some(item => item.domain_id === rowData.core_domain);

      const result = hasAccessOtherThanView || doesUsernameExist || project_user;

      if (!result) {
        setAssignOwner(prevExtendedList => {
          const newDomain = {
            domain_id: rowData.core_domain,
            domain_name: rowData.domain_name,
            head: rowData.username || '',
            total_users: 1,
            project_owner: 1
          };
          return [...prevExtendedList, newDomain];

        });

      }
    }
    setDropOpen(false)
    setSelectedUserTypes([])
  }

  const handleAssignOwner = (newValue) => {

    setSelectedUser(newValue)
    setSwalActive(true)
    let rowData = newValue
    setAssignOwner([])
    if (rowData) {
      const hasAccessOtherThanView = rowData.personal_privileges[domainId]
        ? (rowData.personal_privileges[domainId].access !== "view" && rowData.personal_privileges[domainId].access !== "view|a")
        : false;
      const hasGlobalPrivilege = rowData.global_privileges["domain_creation"] !== "none";

      let project_user = false
      if (projectUserList.includes(rowData.username)) {
        project_user = true
      }
      let userDomainOwner = domainsList.filter(item => item.owner == userDetails.username)
      let doesUsernameExist = userDomainOwner.some(item => item.domain_id === rowData.core_domain);

      const result = hasAccessOtherThanView || doesUsernameExist || project_user;
      if (!result) {
        setAssignOwner(prevExtendedList => {
          const newDomain = {
            domain_id: rowData.core_domain,
            domain_name: rowData.domain_name,
            head: rowData.username || '',
            total_users: 1,
            project_owner: 1
          };
          return [...prevExtendedList, newDomain];

        });

      }
    }
    setDropOpen(false)
    setSelectedUserTypes([])
  }
  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }


  const [approval, setApproval] = useState(false)
  const DomainList = ({ domainList }) => {
    return (
      <div>
        {domainList.map((domain, index) => (
          domain.total_users >= 1 && (
            <div key={index} className="domain-container m-2 p-2" style={{ border: '1px solid black' }}>
              {domain.project_owner ? (
                <>
                  <div><strong>Project Owner Approval</strong></div>
                  <div className="domain-item p-1">Domain: <strong>{domain.domain_name}</strong></div>
                  <div className="domain-item p-1"><strong>Head: {domain.head}</strong></div>
                </>
              ) : (
                <>
                  <div className="domain-item p-1">Domain: <strong>{domain.domain_name}</strong></div>
                  <div className="domain-item p-1"><strong>Head: {domain.head}</strong></div>
                  <div className="domain-item p-1"><strong>User List:</strong>
                    <div style={{ width: '100%' }} className='d-flex flex-wrap'>
                      {domain.username.map((value, userIndex) => (
                        <span key={userIndex}>{value}</span>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          )
        ))}

        <div>
          <Typography className={`${classes['task-label']}`} variant="subtitle1">Approval Description</Typography>
          <TextField
            // label="Approval Description"
            multiline
            rows={4}
            value={approvalDescription}
            onChange={(e) => {
              const originalValue = e.target.value;
              const value = toSentenceCase(originalValue);

              setApprovalDescription(value);
            }}

            fullWidth
          />
        </div>
      </div>
    );
  };


  const projectCreation = async () => {


    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    const titleCaseProject = toTitleCase(projectName);

    if (deadline < getCurrentDate()) {
      setProjectSnackBar(true);
      setMessage(`Project Deadline cannot be less than current date`);
      setSeverity('error');
    }
    const data = {
      domain_id: DOMPurify.sanitize(domainId),
      project_name: DOMPurify.sanitize(titleCaseProject.trim()),
      dead_line: DOMPurify.sanitize(deadline.trim()),
      project_user: finalUser,
      owner: selectedUser,
      description: DOMPurify.sanitize(approvalDescription.trim()),
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };

    try {
      const response = await axios.post(`/backend/create_project`, data, config);
      setProjectSnackBar(true)
      setMessage('Project Created Successfully')
      setSeverity('success')
      setOpen(false)
      setExternalUserActive(false)
      fetchUserProjects()
      setSelectedUserTypes([])
      setCoreUser([])
      setExternalUser([])
      setSelectedValuesCore({})
      setSelectedValuesExternal({})
      setApprovalDescription('')
      setFinalUser({})
      setSwalActive(false)

    } catch (error) {
      setProjectSnackBar(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  }

  const [mergedApproval, setMergedApproval] = useState([])
  const createProject = async () => {
    if (projectName == "" || projectName == null) {
      setProjectSnackBar(true)
      setMessage('Please fill the project name')
      setSeverity('error')
      return
    }

    const ProjectNameRegex = /^[A-Za-z\s]+$/;

    if (!ProjectNameRegex.test(projectName)) {
      setProjectSnackBar(true);
      setMessage('Project name should not contain special characters or numbers');
      setSeverity('warning');
      return;
    }

    else if (deadline == "" || deadline == null) {
      setProjectSnackBar(true)
      setMessage('Please enter a valid deadline for the Project')
      setSeverity('error')
      return
    }
    else if (selectedUser == "" || selectedUser == null) {
      setProjectSnackBar(true)
      setMessage('Please Select a Owner to the Project')
      setSeverity('error')
      return
    }

    let newMergedApproval = []
    const shouldMergeAssignOwner = assignOwner.some(owner => owner.domain_id !== domainId);
    if (shouldMergeAssignOwner) {
      newMergedApproval = [...assignOwner, ...extendedDomainList];
    }
    else {
      newMergedApproval = [...extendedDomainList]
    }
    const hasUsers = newMergedApproval.some(domain => domain.total_users > 0);
    if (hasUsers) {
      // setMergedApproval(newMergedApproval);
      setExternalUserPresent(true)
      setExternalUserActive(true)
      // setApproval(true);
      return
    }

    projectCreation()


  }

  const [user_exists, setUserExists] = useState({})
  const [projectUsers, setProjectUsers] = useState([])

  const fetchProjectDetails = async (project_id, project_owner, project_name) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };

    try {
      const response = await axios.get(`/backend/get_project_details/${project_id}`, config);
      setOpen(true)
      setExternalUserActive(false)
      let data = response.data
      let project_user_list = response.data.order
      let projectUserList = data.project_users_list
      setProjectUsers(response.data.project_users_list)
      setProjectUserList(response.data.project_users_list)
      let users_already_present = project_user_list.reduce((acc, user) => {
        if (user.view && projectUserList.includes(user.username)) {
          acc[user.username] = "view";
        } else if (user.edit && projectUserList.includes(user.username)) {
          acc[user.username] = "edit";
        } else if (user.full && projectUserList.includes(user.username)) {
          acc[user.username] = "full";
        }
        return acc;
      }, {});
      setUserExists(users_already_present)

      // let updated_list = users.filter(user =>
      //   !project_user_list.some(filterUser => filterUser.username === user.username)
      // );
      let updated_list = users.filter(user =>
        !project_user_list.some(filterUser =>
          filterUser.username === user.username && !filterUser.is_view_a
        )
      );

      setProjectId(project_id)
      const coreUsers = [];
      const externalUsers = [];

      updated_list.forEach(user => {
        if (user.core_domain === domainId) {
          coreUsers.push(user);
        } else {
          externalUsers.push(user);
        }
      });

      // Update state variables
      let project_owner_dict = users.find(user => user.username === project_owner)
      setCoreUser(coreUsers);
      setExternalUser(externalUsers);
      setProjectName(data.project_name)
      setSelectedUser(project_owner_dict)
      setDeadline(response.data.deadline)
      setAssignOwner([{
        "domain_id": project_owner_dict.core_domain,
        "domain_name": project_owner_dict.domain_name,
        "head": project_owner_dict.username,
        "total_user": 1
      }])


      setEditProject(false)


    } catch (error) {
      // setSnackbarOpen(true)
      setProjectSnackBar(true)
      // setMessage(error.response.data.detail)
      setSeverity('error')
    }

  }
  const [latestType, setLatestType] = useState(null);

  const approvalRef = useRef(null)
  const coreUserRef = useRef(null)
  const externalUserRef = useRef(null)




  const [swalActive, setSwalActive] = useState(false)
  const handleClose = async () => {
    if (swalActive) {
      const title = 'Unsaved Changes';
      const confirmButtonText = 'OK';
      const cancelButtonText = 'Cancel';

      const result = await Swal.fire({
        title,
        text: 'Do you want to exit?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        customClass: {
          container: 'custom-swal-container'
        }
      });

      if (!result.isConfirmed) {
        return;
      }

      setOpen(false);
      setExternalUserActive(false)
      setSelectedUserTypes([])
      setCoreUser([])
      setExternalUser([])
      setSelectedValuesCore({})
      setSelectedValuesExternal({})
      setApprovalDescription('')
      setSwalActive(false)
    } else {
      setOpen(false);
      setExternalUserActive(false)
      setSelectedUserTypes([])
      setCoreUser([])
      setExternalUser([])
      setSelectedValuesCore({})
      setSelectedValuesExternal({})
      setApprovalDescription('')
      setSwalActive(false)
    }
  };


  // const handleClose = () => {
  //   setOpen(false);
  //   setExternalUserActive(false)
  //   setSelectedUserTypes([])
  //   setCoreUser([])
  //   setExternalUser([])
  //   setSelectedValuesCore({})
  //   setSelectedValuesExternal({})
  //   setApprovalDescription('')
  //   // setSnackbarOpen(false);
  // };

  const [projectUserList, setProjectUserList] = useState([])

  const projectEdit = async () => {
    let finalUserObject = {}
    if (editProject) {
      finalUserObject = { ...finalUser }
    }
    else {
      finalUserObject = { ...user_exists, ...finalUser };
    }

    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    const titleCaseProject = toTitleCase(projectName);

    const data = {
      domain_id: DOMPurify.sanitize(domainId),
      project_name: DOMPurify.sanitize(titleCaseProject.trim()),
      dead_line: DOMPurify.sanitize(deadline.trim()),
      project_user: finalUserObject,
      owner: selectedUser,
      project_id: DOMPurify.sanitize(projectId),
      approval_description: DOMPurify.sanitize(approvalDescription.trim()),
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };

    try {
      const response = await axios.post(`/backend/edit_project`, data, config);


      setSwalActive(false)
      setProjectSnackBar(true)
      setMessage(response.data.detail)
      setSeverity('success')
      setOpen(false)
      setExternalUserActive(false)
      setProjectEditOption(false)
      fetchUserProjects()
      setSelectedUserTypes([])
      setCoreUser([])
      setExternalUser([])
      setSelectedValuesCore({})
      setSelectedValuesExternal({})
      setApprovalDescription('')
      setFinalUser({})
      setUserExists({})
    } catch (error) {
      // setSnackbarOpen(true)
      setProjectSnackBar(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  }

  const handleEditProject = async () => {

    if (projectName == "" || projectName == null) {
      setProjectSnackBar(true)
      setMessage('Please Fill the Project Name')
      setSeverity('error')
      return
    }
    const ProjectNameRegex = /^[A-Za-z\s]+$/;

    if (!ProjectNameRegex.test(projectName)) {
      setProjectSnackBar(true);
      setMessage('Project name should not contain special characters or numbers');
      setSeverity('warning');
      return;
    }

    else if (deadline == "" || deadline == null) {
      setProjectSnackBar(true)
      setMessage('Please Enter a Deadline for the Project')
      setSeverity('error')
      return
    }
    else if (selectedUser == "" || selectedUser == null) {
      setProjectSnackBar(true)
      setMessage('Please Select a Owner to the Project')
      setSeverity('error')
      return
    }
    let newMergedApproval = []
    const shouldMergeAssignOwner = assignOwner.some(owner => (owner.domain_id !== domainId && owner.username != projectOwner));
    if (shouldMergeAssignOwner) {
      newMergedApproval = [...assignOwner, ...extendedDomainList,];
    }
    else {
      newMergedApproval = [...extendedDomainList]
    }


    const hasUsers = newMergedApproval.some(domain => domain.total_users > 0);
    if (hasUsers) {
      setMergedApproval(newMergedApproval);
      // setApproval(true);
      setExternalUserPresent(true)
      setExternalUserActive(true)

      setTimeout(() => {
        if (approvalRef.current) {
          approvalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);


    }
    else {
      projectEdit()
    }
  }


  const [dashboardActivated, setDashboardActivated] = useState(false)
  const [dashboard, setDashboard] = useState({
    "Dashboard": "Project Overview",
    "Info": "The Project Overview dashboard showcases all accessible projects for a user, including the status and number for open , completed , overdue , due today and due in 7 days tasks"
  })

  const groupedHeader = (
    // <ColumnGroup>
    //     <Row>
    //         <Column header="Username" rowSpan={2} />
    //         <Column header="Access Group" colSpan={4} />
    //         <Column header="Domain Name" rowSpan={2} />
    //         <Column header="Domain Head" rowSpan={2} />
    //     </Row>
    //     <Row>
    //         <Column header="View" />
    //         <Column header="Edit" />
    //         <Column header="Full" />
    //         <Column header="None" />
    //     </Row>
    // </ColumnGroup>


    <ColumnGroup>
      <Row>
        <Column header="Username" headerStyle={{ textAlign: 'center', width: '20%' }} headerClassName="center-align" rowSpan={2} />
        <Column header="Domain Name" headerStyle={{ textAlign: 'center', width: '20%' }} headerClassName="center-align" rowSpan={2} />
        <Column header="Domain Head" headerStyle={{ textAlign: 'center', width: '20%' }} headerClassName="center-align" rowSpan={2} />
        <Column header="Access Group" headerStyle={{ textAlign: 'center', width: '40%' }} headerClassName="center-align" colSpan={3} />
      </Row>
      <Row>
        <Column header="View" headerClassName="center-align" headerStyle={{ textAlign: 'center' }} />
        <Column header="Edit" headerClassName="center-align" headerStyle={{ textAlign: 'center' }} />
        <Column header="Full" headerClassName="center-align" headerStyle={{ textAlign: 'center' }} />
        {/* <Column header="None" headerClassName="center-align" headerStyle={{ textAlign: 'center' }} /> */}
      </Row>
    </ColumnGroup>
  );

  const addDomainHeadToUsers = (users, domains) => {
    return users.map(user => {
      const domain = domains.find(d => d.domain_id === user.core_domain);
      return domain ? { ...user, domain_head: domain.owner_name } : user;
    });
  };

  const renderUserLists = () => {
    return (
      <>

        <div>
          {selectedUserTypes.map((userType) => {
            if (userType === 'core') {
              let filteredCoreUser = []
              if (assignOwner.length != 0) {
                filteredCoreUser = coreUser.filter(user => user.username !== assignOwner[0].head);
              }
              else {
                filteredCoreUser = coreUser
              }

              filteredCoreUser = addDomainHeadToUsers(filteredCoreUser, domainsList);


              return (
                <div key={userType} ref={coreUserRef} className='core-users mt-4'>
                  <DataTable
                    value={filteredCoreUser}
                    headerColumnGroup={groupedHeader}
                    scrollable
                    scrollHeight="300px"
                    globalFilter={globalFilterCore}
                    className={`${classes['project-user-datatable']}`}
                    header={coreHeader}
                  >
                    <Column field="name" header="Username" sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                    <Column field="domain_name" header="Domain Name" sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                    <Column field="domain_head" header="Domain Head" headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable />
                    <Column field="view" header="View" body={(rowData) => radioTemplateCore(rowData, 'view')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                    <Column field="edit" header="Edit" body={(rowData) => radioTemplateCore(rowData, 'edit')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                    <Column field="full" header="Full" body={(rowData) => radioTemplateCore(rowData, 'full')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                    {/* <Column field="none" header="None" body={(rowData) => radioTemplateCore(rowData, 'none')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} /> */}
                  </DataTable>


                </div>
              );
            } else if (userType === 'external') {
              let filteredExternalUser = []
              if (assignOwner.length != 0) {
                filteredExternalUser = externalUser.filter(user => user.username !== assignOwner[0].head);
              }
              else {
                filteredExternalUser = externalUser
              }
              filteredExternalUser = addDomainHeadToUsers(filteredExternalUser, domainsList);
              return (
                <div key={userType} ref={externalUserRef} className='external-users mt-4'>
                  <div className="datatable-scrollable">
                    <DataTable
                      value={filteredExternalUser}
                      headerColumnGroup={groupedHeader}
                      scrollable
                      scrollHeight="300px"
                      globalFilter={globalFilterExternal}
                      className={`${classes['project-user-datatable']}`}
                      header={externalHeader}
                    >
                      <Column field="name" header="Username" sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                      <Column field="domain_name" header="Domain Name" sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                      <Column field="domain_head" header="Domain Head" sortable />
                      <Column field="view" header="View" body={(rowData) => radioTemplateExternal(rowData, 'view')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                      <Column field="edit" header="Edit" body={(rowData) => radioTemplateExternal(rowData, 'edit')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                      <Column field="full" header="Full" body={(rowData) => radioTemplateExternal(rowData, 'full')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                      {/* <Column field="none" header="None" body={(rowData) => radioTemplateExternal(rowData, 'none')} style={{ width: '5%' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} /> */}
                    </DataTable>
                    {/* <Paginator
                        first={0}
                        rows={filteredExternalUser.length}
                        totalRecords={filteredExternalUser.length}
                        template="RowsPerPageDropdown CurrentPageReport"
                        currentPageReportTemplate="Showing {totalRecords} records"
                        rowsPerPageOptions={[filteredExternalUser.length]} 
                        onPageChange={() => {}}
                      /> */}


                  </div>

                </div>
              );
            }
            return null;
          })}
        </div>
      </>
    );
  };


  const radioTemplateCore = (rowData, columnKey) => {
    return (
      // <RadioButton
      //   inputId={`${rowData.id}_${columnKey}`}
      //   name={`${rowData.id}_${columnKey}`}
      //   value={columnKey}
      //   checked={selectedValuesCore[rowData.username] && selectedValuesCore[rowData.username][columnKey]}
      //   onChange={() => handleRadioChangeCore(rowData, columnKey)}
      // />

      <div onClick={() => handleRadioChangeCore(rowData, columnKey)}>
        <RadioButton
          inputId={`${rowData.id}_${columnKey}`}
          name={`${rowData.id}_${columnKey}`}
          value={columnKey}
          checked={selectedValuesCore[rowData.username] && selectedValuesCore[rowData.username][columnKey]}
        // onChange={() => handleRadioChangeExternal(rowData, columnKey)}
        />
      </div>
    );
  };



  const radioTemplateExternal = (rowData, columnKey) => (
    // <RadioButton
    //   inputId={`${rowData.id}_${columnKey}`}
    //   name={`${rowData.id}_${columnKey}`}
    //   value={columnKey}
    //   checked={selectedValuesExternal[rowData.username] && selectedValuesExternal[rowData.username][columnKey]}
    //   onClick={() => handleRadioChangeExternal(rowData, columnKey)}
    // />

    <div onClick={() => handleRadioChangeExternal(rowData, columnKey)}>
      <RadioButton
        inputId={`${rowData.id}_${columnKey}`}
        name={`${rowData.id}_${columnKey}`}
        value={columnKey}
        checked={selectedValuesExternal[rowData.username] && selectedValuesExternal[rowData.username][columnKey]}
      // onChange={() => handleRadioChangeExternal(rowData, columnKey)}
      />
    </div>
  );

  const checkUserPrivileges = (domainId, projectId) => {

    const domainPrivileges = userPersonalPrivileges[domainId];

    if (!domainPrivileges) {
      return "none";
    }

    const projectAccess = domainPrivileges.project[projectId];

    if (projectAccess && projectAccess !== "view") {
      return projectAccess;
    }

    return "none";
  };

  const handleProjectDetails = (value) => {
    setProjectId(value)
    setListView(true)
  }


  const getRandomColor = (index) => {
    const colors = [
      "#E3F5FF", "#FF5733", "#33FF57", "#5733FF", "#FFFF33",
      "#33FFFF", "#FF33FF", "#FFC0CB", "#00CED1", "#FFD700",
      "#8A2BE2", "#FFA07A", "#00FF7F", "#9370DB", "#40E0D0",
      "#FF4500", "#20B2AA", "#FF1493", "#00BFFF", "#FFDAB9"
    ];
    return colors[index % colors.length];
  };

  const getColorByPercentage = (percentage) => {
    if (percentage >= 0 && percentage <= 100) {
      const index = Math.floor(percentage / 10);
      return progressBarColors[index - 1];
    }
    return null;
  };








  const progressLabels = [
    { label: 'Total Task', index: 0 },
    { label: 'Open Task', index: 1 },
    { label: 'Completed Task', index: 2 },
    { label: 'OverDue Task', index: 3 },
    { label: 'Due Today', index: 4 },
    { label: 'Due in 7 days', index: 5 }
  ];

  const useStyles = makeStyles({
    progressBarContainer: {
      position: 'relative',
      width: '100%',
      height: '1.5em',
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      cursor: 'pointer'
    },
    progressBar: {
      height: '100%',
      borderRadius: '4px',
      transition: 'width 0.2s ease-in-out',
    },
    progressText: {
      width: '1px',

      height: '1px',
      position: 'absolute',
      top: '2px',
      display: 'flex'
    },
    projectLabel: {
      fontSize: '1.25em',
    }
  });



  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(22px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'rgba(0,0,0,1)',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 15,
      height: 15,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 1)' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },

  }));

  const [listView, setListView] = useState(false)
  const styles = useStyles();

  const handleNavigateToProjectListView = (value, project_name, project_progress, user_progress, project_owner, projectUserList, projectDueDate, project_owner_approved) => {
    let state = {
      projectId: value,
      domainId: domainId,
      users: { users },
      dashboard: dashboard,
      dashboardActivated: dashboardActivated,
      domainName: domainName,
      userDetails: userDetails,
      projectName: project_name,
      project_progress: project_progress,
      user_progress: user_progress,
      toggleActive: fullView,
      project_owner: project_owner,
      domain_owner: domainOwner,
      projectUserList: projectUserList,
      projectDueDate: projectDueDate,
      project_owner_approved: project_owner_approved,
      tabValue: tabValue
    }
    // navigate('/dash/dashboard/project/projectListView', {
    //   state
    // })
    navigate('/dash/dashboard/project/Overview', {
      state
    })
  }


  const sortedUsers = Array.isArray(users) ? users.slice().sort((a, b) => {
    if (domainId == null) {
      return []
    }
    if (a.core_domain === domainId) return -1;
    if (b.core_domain === domainId) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;


  const handleApprovalClose = () => {
    setApproval(false);
    setExternalUserActive(false)
    setApprovalDescription('')
  };

  const handleApprovalConfirm = () => {
    setApproval(false);
    projectCreation()
    // setApprovalDescription('')
  };

  const handleApprovalCancel = () => {
    setApproval(false);
    setExternalUserActive(false)
    // setApprovalDescription('')
  };

  const handleEditApproval = () => {
    setApproval(false)
    projectEdit()

  }
  const confirmbuttonStyles = {
    border: "1px solid black",
    backgroundColor: "rgba(28, 28, 28, 1)",
    color: "rgba(238, 225, 213, 1)",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '10em',
    borderRadius: "28px",
  }
  const confirmHoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  }
  const buttonStyles2 = {
    border: "1px solid black",
    backgroundColor: "#EBEBEB",
    color: "black",
    fontWeight: 700,
    textTransform: "none",
    marginTop: "1rem",
    padding: "0.75rem",
    marginLeft: "1em",
    minWidth: '10em',
    borderRadius: "28px",
  };

  const hoverStyles = {
    backgroundColor: "transparent",
    border: "1px solid black",
    transition: "background-color 0.1s ease-in-out",
    color: "black",
  };

  const editProjectDialogStyles = {
    padding: '0.5em',
    display: 'flex',
    justifyContent: 'start'
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const handleProjectStatus = async (project_id, status, project_name) => {
    const data = {
      project_id: project_id,
      status: status,
      domain_id: domainId
    };

    const showConfirmationDialog = () => {
      const title = status === 0 ? 'Hold Project' : (status === 2 ? 'Successful Project' : 'Resume Project');
      const confirmButtonText = 'OK';
      const cancelButtonText = 'Cancel';
      return Swal.fire({
        title,
        text: 'Do you want to proceed?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        customClass: {
          container: 'custom-swal-container'
        }
      });
    };

    showConfirmationDialog().then(async (result) => {
      if (!result.isConfirmed) {

        return;
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        }
      };

      try {
        const response = await axios.post(`/backend/edit_project_status`, data, config);
        if (status === 1) {
          setProjectSnackBar(true);
          setMessage(`Project ${project_name} Resumed Successfully`);
          setSeverity('success');
          setOpen(false);
          // setTabValue(0);
        } else if (status === 0) {
          setProjectSnackBar(true);
          setMessage(`Project ${project_name} Moved to On Hold Successfully`);
          setSeverity('success');
          setOpen(false);
          // setTabValue(1);
        } else if (status === 2) {
          setProjectSnackBar(true);
          setMessage(`Project ${project_name} Completed Successfully`);
          setSeverity('success');
          setOpen(false);
          // setTabValue(2);
        }
        setProjectEditOption(false)
        fetchUserProjects();
      } catch (error) {
        // console.log(error.response);
        setProjectSnackBar(true);
        setMessage(error.response.data.detail);
        setSeverity('error');
      }
    });
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    if (tabValue == 0) {
      const filtered = activeProject.filter((project) => {
        // Convert searchTerm to lowercase for case-insensitive comparison
        const searchTermLower = searchTerm.toLowerCase();

        // Check if project_name includes searchTerm
        if (project.project_name.toLowerCase().includes(searchTermLower)) {
          return true;
        }

        // Check if any overall_progress value includes searchTerm (assuming overall_progress is an array)
        if (Object.keys(project.user_task_summary).length > 0) {
          for (const projectId in project.user_task_summary) {
            const projectDetails = project.user_task_summary[projectId];

            if (projectDetails.overall_progress &&
              projectDetails.overall_progress.some(progress => progress.toString().includes(searchTermLower))) {
              return true;
            }
          }
        }

        return false; // Return false if no match found
      });
      setFilteredProjects(filtered);

    }
    else if (tabValue == 1) {
      const filtered = holdProject.filter((project) => {
        // Convert searchTerm to lowercase for case-insensitive comparison
        const searchTermLower = searchTerm.toLowerCase();

        // Check if project_name includes searchTerm
        if (project.project_name.toLowerCase().includes(searchTermLower)) {
          return true;
        }

        // Check if any overall_progress value includes searchTerm (assuming overall_progress is an array)
        if (Object.keys(project.user_task_summary).length > 0) {
          for (const projectId in project.user_task_summary) {
            const projectDetails = project.user_task_summary[projectId];

            if (projectDetails.overall_progress &&
              projectDetails.overall_progress.some(progress => progress.toString().includes(searchTermLower))) {
              return true;
            }
          }
        }

        return false; // Return false if no match found
      });
      setFilteredProjects(filtered);
    }
    else {

      const filtered = successfullProject.filter((project) => {
        // Convert searchTerm to lowercase for case-insensitive comparison
        const searchTermLower = searchTerm.toLowerCase();

        // Check if project_name includes searchTerm
        if (project.project_name.toLowerCase().includes(searchTermLower)) {
          return true;
        }

        // Check if any overall_progress value includes searchTerm (assuming overall_progress is an array)
        if (Object.keys(project.user_task_summary).length > 0) {
          for (const projectId in project.user_task_summary) {
            const projectDetails = project.user_task_summary[projectId];

            if (projectDetails.overall_progress &&
              projectDetails.overall_progress.some(progress => progress.toString().includes(searchTermLower))) {
              return true;
            }
          }
        }

        return false; // Return false if no match found
      });
      setFilteredProjects(filtered);
    }





  };

  const projectsToDisplayTab1 = searchTerm ? filteredProjects : activeProject;
  const projectsToDisplayTab2 = searchTerm ? filteredProjects : holdProject;
  const projectsToDisplayTab3 = searchTerm ? filteredProjects : successfullProject;

  const renderProjects = (projectsToDisplay) => (
    <>
      <div className={classes['project-container']} style={{ position: 'relative' }}>
        <div className="d-flex flex-wrap ">
          {projectsToDisplay.map((details, index) => {
            const userPrivileges = checkUserPrivileges(details.domain_id, details.project_id);
            return (
              <div
                key={index}
                className="card m-3"
                style={{ width: '14em', height: '17em', border: '0.5px solid grey' }}
                onClick={() => { handleProjectDetails(details.project_id) }}
              >
                <div
                  className="card-header text-black d-flex"
                  style={{
                    // backgroundColor: getRandomColor(index),
                    backgroundColor: randomColor,
                    // cursor: 'pointer',
                    borderBottom: '0.5px solid grey',
                    fontWeight: '600',
                    height: '2.5em',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ width: '10%' }}></div>
                  <div title={details.project_name} className={classes['project-title-container']}>
                    {details.project_name}
                  </div>
                  <div style={{ width: '10%' }}>
                    {(userDetails && (userDetails.global_privileges?.domain_creation !== 'none' || (userDetails.personal_privileges?.[domainId]?.access !== 'view' && userDetails.personal_privileges?.[domainId]?.access !== 'view|a')) && (tabValue === 1 || tabValue === 0 || (tabValue === 2 && details.deadline > getCurrentDate()))) && (
                      <MoreHorizIcon
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleProjectEditOpen(details.project_id, details.project_owner, details.project_name, details.overall_progress, e)}
                      />
                    )}

                  </div>
                </div>
                <div className={classes['project-overall-details-container']} style={{ cursor: 'pointer' }} onClick={() => { handleNavigateToProjectListView(details.project_id, details.project_name, details.overall_progress, details.user_overall_progress, details.project_owner, details.project_users_list, details.deadline, details.is_owner_approved) }}>
                  {!fullView ? (
                    <>
                      {progressLabels.map((item, index) => (
                        <div key={index}>
                          {/* {details.user_task_summary && details.user_task_summary[details.project_id].overall_progress ? ( */}
                          {details.overall_task_summary.length !== 0 ? (
                            <div className='d-flex  align-items-center '>
                              <div style={{ fontSize: '1.1em', width: '30%', fontWeight: 'bold' }}>
                                {/* {details.user_task_summary[details.project_id].overall_progress[item.index]} */}
                                {details.overall_task_summary[index]}
                              </div>
                              <div>
                                {item.label}
                              </div>
                            </div>
                          ) : (
                            <div className='d-flex  align-items-center '>
                              <div style={{ fontSize: '1.1em', width: '30%', fontWeight: 'bold' }}>
                                
                              </div>
                              <div>
                                {item.label}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div style={{ padding: '0.2em 0.5em' }}>
                        <Box className={styles.progressBarContainer}>
                          <div
                            className={styles.progressBar}
                            style={{
                              width: `${details.overall_progress}%`,
                              backgroundColor: getColorByPercentage(details.overall_progress),
                            }}
                          >
                            {details.overall_progress < 15 ? (
                              <>
                                <Typography variant="body2" className={styles.progressText} style={{ left: `calc(${details.overall_progress}%  )` }}>
                                  {`${Math.round(details.overall_progress)}%`}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="body2" className={styles.progressText} style={{ left: `calc(${details.overall_progress}% - 35px)` }}>
                                  {`${Math.round(details.overall_progress)}%`}
                                </Typography>
                              </>
                            )}

                          </div>
                        </Box>
                      </div>
                    </>
                  ) : (
                    <>
                      {progressLabels.map((item, index) => (
                        <div key={index}>
                          {/* {details.project_task_summary && details.project_task_summary[details.project_id].overall_progress ? ( */}
                          {details.user_task_summary.length !== 0 ? (
                            <div className='d-flex  align-items-center '>
                              <div style={{ fontSize: '1.1em', width: '30%', fontWeight: 'bold' }}>
                                {details.user_task_summary[index]}
                              </div>
                              <div>
                                {item.label}
                              </div>
                            </div>
                          ) : (
                            <div className='d-flex  align-items-center '>
                              <div style={{ fontSize: '1.1em', width: '30%', fontWeight: 'bold' }}>
                                0
                              </div>
                              <div>
                                {item.label}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div style={{ padding: '0.2em 0.5em' }}>
                        <Box className={styles.progressBarContainer}>
                          <div
                            className={styles.progressBar}
                            style={{
                              width: `${details.user_overall_progress}%`,
                              backgroundColor: getColorByPercentage(details.user_overall_progress),
                            }}
                          >
                            {details.user_overall_progress < 15 ? (
                              <>
                                <Typography variant="body2" className={styles.progressText} style={{ left: `calc(${details.user_overall_progress}% )` }}>
                                  {/* {`${details.user_overall_progress}%`} */}
                                  {`${Math.round(details.user_overall_progress)}%`}

                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="body2" className={styles.progressText} style={{ left: `calc(${details.user_overall_progress}% - 37px)` }}>
                                  {/* {`${details.user_overall_progress}%`} */}
                                  {`${Math.round(details.user_overall_progress)}%`}
                                </Typography>
                              </>
                            )}
                          </div>
                        </Box>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {/* {(userDetails['global_privileges']['domain_creation'] != 'none' || userDetails['personal_privileges'][domainId]['access'] != 'view' ) && tabValue == 0 &&  */}
          {(userDetails && (userDetails.global_privileges?.domain_creation !== 'none' || (userDetails.personal_privileges?.[domainId]?.access !== 'view' && userDetails.personal_privileges?.[domainId]?.access !== 'view|a')) && tabValue === 0) ? (
            <div title={'Create a new project'} className="card m-3 d-flex justify-content-center align-items-center" style={{ width: '14em', height: '17em', border: '1px solid black', cursor: 'pointer' }} onClick={createNewProject}>
              <div><AddCircleOutlineIcon sx={{ fontSize: '3em' }} /></div>
            </div>
          ) : (<></>)
          }

        </div>
        <Dialog
          open={projectEditOption}
          onClose={handleProjectEditClose}
          PaperProps={{
            sx: {
              width: '10em',
              maxWidth: '20em',
              position: 'absolute', top: dialogPosition.top, left: dialogPosition.left
            },
          }}
        >
          <DialogContent style={{ backgroundColor: '#f1f3f4', padding: '0.5px', paddingBottom: '5px' }}>
            {tabValue == 0 && (
              <>
                <div style={{ textAlign: 'end' }}>
                  <IconButton style={{ padding: 0 }} onClick={handleProjectEditClose} aria-label="close">
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </div>
                <Box sx={{ margin: '5px', border: '0.5px solid grey', borderRadius: '10px', cursor: 'pointer' }} display="flex" alignItems="center" justifyContent="start" bgcolor="white" p={1} mb={2} onClick={() => { fetchProjectDetails(projectIdEdit, projectOwner) }}>
                  <Edit style={{ marginRight: '8px', marginLeft: '0.25em' }} />
                  <div>Edit</div>
                </Box>
                <Box sx={{ margin: '5px', border: '0.5px solid grey', borderRadius: '10px', cursor: 'pointer' }} display="flex" alignItems="center" justifyContent="start" bgcolor="white" p={1} mb={2} onClick={() => { handleProjectStatus(projectIdEdit, 0, projectName) }}>
                  <PauseCircleFilled style={{ marginRight: '8px', marginLeft: '0.25em' }} />
                  <div>On Hold</div>
                </Box>
                {projectProgress == 100 && !fullView && (
                  <Box sx={{ margin: '5px', border: '0.5px solid grey', borderRadius: '10px', cursor: 'pointer' }} display="flex" alignItems="center" justifyContent="start" bgcolor="white" p={1} mb={2} onClick={() => { handleProjectStatus(projectIdEdit, 2, projectName) }}>
                    <CheckCircle style={{ marginRight: '8px', marginLeft: '0.25em' }} />
                    <div>Successful</div>
                  </Box>
                )}

              </>
            )
            }
            {(tabValue === 1 || tabValue === 2) &&
              <Box sx={{ borderRadius: '10px', cursor: 'pointer' }} display="flex" alignItems="center" justifyContent="start" bgcolor="white" p={1} mb={2} onClick={() => { handleProjectStatus(projectIdEdit, 1, projectName) }}>
                <PlayCircleIcon style={{ marginRight: '8px', marginLeft: '0.25em' }} />
                <div>Resume</div>
              </Box>
            }


          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleProjectEditClose}>Close</Button>
          </DialogActions> */}
        </Dialog>
      </div>
    </>
  );

  const [projectProgress, setProjectProgress] = useState(null)


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '50em',
            maxWidth: '80em',
          },
        }}
      >
        <DialogTitle style={{ padding: 0, margin: '0 0.25em' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ borderBottom: '1px solid black', padding: '9px 0' }}
          >
            <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>
              {editProject ? `Create a new project under ${domainName}` : `Edit the project under ${domainName}`}
            </Typography>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={'d-flex flex-column justify-content-evenly hidden-scrollbar'}>
          <Box className="d-flex justify-content-evenly align-items-center mt-2">
            <Box style={{ width: '45%' }}>
              <Typography variant="subtitle1" className='mb-2'>
                Input Project Name<span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                style={{ width: '100%', marginTop: '0' }}
                variant="outlined"
                // label="Project Name"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                  setSwalActive(true);
                }}
                margin="normal"
                required
                size="small"
              />
            </Box>
            <Box style={{ width: '45%' }}>
              <Typography variant="subtitle1" className='mb-2'>
                Assign owner<span style={{ color: 'red' }}>*</span>
              </Typography>
              {editProject ? (
                <Autocomplete
                  options={sortedUsers}
                  groupBy={(option) => option.domain_name}
                  // getOptionLabel={(option) => option.username}
                  getOptionLabel={(option) => option.name}
                  value={selectedUser}
                  onChange={(event, newValue) => {
                    handleAssignOwner(newValue)
                    // setSelectedUser(newValue);
                    // setSwalActive(true);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Select User"
                      variant="outlined"
                      margin="normal"
                      required
                      size="small"
                      style={{ width: '100%', marginTop: '0' }}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  options={sortedUsers}
                  groupBy={(option) => option.domain_name}
                  // getOptionLabel={(option) => option.username}
                  getOptionLabel={(option) => option.name}
                  value={selectedUser}
                  onChange={(event, newValue) => handleAssignOwner(newValue)}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Select User"
                      variant="outlined"
                      margin="normal"
                      required
                      size="small"
                      style={{ width: '100%', marginTop: '0' }}
                    />
                  )}
                />
              )}



            </Box>
          </Box>
          <Box style={{ marginLeft: '1.35em' }} className="mt-2">
            <div className="form-group">
              <label htmlFor="dateInput">Project Deadline<span style={{ color: 'red' }}>*</span></label>
              <input
                type="date"
                id="dateInput"
                value={deadline}
                onChange={(e) => handleDeadline(e)}
                onBlur={handleDateBlur}

                className="form-control"
                style={{ padding: '0.5em', width: '14em' }}
                min={getCurrentDate()}
                disabled={!editProject} // Disable input when editProject is false
              />
            </div>
          </Box>
          <div style={{ paddingLeft: '1.35em' }}>
            <FormControl className="mt-4" size="small" sx={{ width: 400 }}>
              <InputLabel style={{ backgroundColor: 'white' }} id="user-type-label">
                Select Type of User to Add
              </InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type-select"
                multiple
                value={selectedUserTypes}
                onChange={handleUserTypeChange}
                onClose={handleUserDropdownClose}
                onOpen={handleUserDropdownOpen}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {selected.map((value) => (
                      <div key={value}>{value === 'core' ? 'Core Domain' : 'External Domain'}</div>
                    ))}
                  </Box>
                )}
                open={dropOpen}
                autoClose
              >
                <MenuItem value="core">
                  <Checkbox checked={selectedUserTypes.includes('core')} />
                  <ListItemText primary="Add Core Domain Users" />
                </MenuItem>
                <MenuItem value="external">
                  <Checkbox checked={selectedUserTypes.includes('external')} />
                  <ListItemText primary="Add External Domain Users" />
                </MenuItem>
              </Select>
            </FormControl>

            {renderUserLists()}

            {
              (externalUserPresent && externalUserActive) ? (
                <>
                  <div ref={approvalRef} className={classes.approvalContainer} style={{ marginTop: '1em' }}>
                    <div className={classes.approvalHeader}>
                      <strong>Proceed with the Approval Request?</strong>
                    </div>

                    <div>

                      {/* <Typography className={`${classes['task-label']}`} variant="subtitle1">Approval Description</Typography> */}
                      <TextField
                        // label="Approval Description"
                        multiline
                        rows={4}
                        value={approvalDescription}
                        onChange={(e) => {
                          setApprovalDescription(e.target.value);
                          setSwalActive(true);
                        }}
                        fullWidth
                      />
                    </div>


                    <div className={`${classes.approvalActions} d-flex justify-content-center mt-3`}>
                      {!editProject ? (
                        <>
                          <Button onClick={handleEditApproval} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
                            Send Request
                          </Button>
                          <Button onClick={handleApprovalClose} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={handleApprovalConfirm} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
                            Send Request
                          </Button>
                          <Button onClick={handleApprovalCancel} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
                            Cancel
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>

                  <Box mt={2} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: 'rgb(9, 94, 173)', color: 'white', fontWeight: '400', '&:hover': { backgroundColor: 'rgb(12, 97, 180)' } }}
                      onClick={editProject ? createProject : handleEditProject}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              )}
          </div>



        </DialogContent>
      </Dialog>

      <Dialog open={approval} onClose={handleApprovalClose}
        PaperProps={{
          sx: {
            width: '30em',
            maxWidth: '30em',
          },
        }}
      >
        <DialogTitle>
          <strong>
            Proceed with the Approval Request ?
          </strong></DialogTitle>
        <DialogContent dividers style={{ height: '13em', overflowY: 'scroll' }}>
          <p>Approval required from the requested domain head:</p>
          <DomainList domainList={mergedApproval} />
        </DialogContent>
        <div className="modal-actions d-flex justify-content-evenly mb-3">
          {!editProject ? (
            <>
              <Button onClick={handleEditApproval} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
                Send Request
              </Button>
              <Button onClick={handleApprovalClose} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleApprovalConfirm} sx={{ ...confirmbuttonStyles, "&:hover": confirmHoverStyles }}>
                Send Request
              </Button>
              <Button onClick={handleApprovalCancel} sx={{ ...buttonStyles2, "&:hover": hoverStyles }}>
                Cancel
              </Button>
            </>
          )}
        </div>

      </Dialog>


      <div className={classes['content']}>
        <Navbar dashboard={dashboard} userDetails={userDetails} setIsLoaded={setIsLoaded} isLoaded={isLoaded} />
        <DashboardTitle dashboardActivated={dashboardActivated} domainName={domainName} isLoaded={isLoaded} setIsLoaded={isLoaded} />
        {isLoaded ?
          (
            <>
              <div className={classes['project-outer-container']}>
                {projectActivated && (
                  <>
                    <Box sx={{ width: '100%' }} className={classes['tab-container']}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '1em' }} className='d-flex justify-content-between align-items-center'>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Active" {...a11yProps(0)} />
                          <Tab label="On Hold" {...a11yProps(1)} />
                          <Tab label="Successful" {...a11yProps(2)} />
                        </Tabs>
                        <div className='d-flex justify-content-between'>
                          {/* <div style={{ fontWeight: 'bold', fontSize: '1.5em' }}>Welcome to {domainName}! </div> */}

                          <div style={{ width: '100%' }} className='d-flex justify-content-center align-items-center'>
                            {(userDetails && (userDetails.global_privileges?.domain_creation !== 'none' || (userDetails.personal_privileges?.[domainId]?.access !== 'view' && userDetails.personal_privileges?.[domainId]?.access !== 'view|a')) && tabValue === 0 && projectsToDisplayTab1.length > 6) ? (
                              <>
                                <div style={{ marginRight: '1em', width: '14em' }}>
                                  <Button
                                    startIcon={<img src={ProjectIcon} alt="icon" style={{ width: 24, height: 24 }} />}
                                    variant="contained" className={classes['create-project-btn']} onClick={() => { createNewProject() }}>Create Project</Button>
                                </div>
                              </>
                            ) : (<><div></div></>)}

                            <div className='d-flex justify-content-center align-items-center'>
                              <div>
                                <TextField
                                  // label="Search projects"
                                  placeholder="Search projects"
                                  variant="outlined"
                                  fullWidth
                                  value={searchTerm}
                                  onChange={handleSearch}
                                  size='small'
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton>
                                          <Search />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                              </div>
                              <div style={{ marginLeft: '1em' }}>
                                <span>Full View</span>
                                <FormControlLabel
                                  style={{ margin: 0 }}
                                  control={
                                    <IOSSwitch
                                      sx={{ m: 1 }}
                                      checked={fullView}
                                      onChange={handleViewToggle}
                                    />

                                  }
                                />
                                <span>My View</span>
                              </div>
                              <div></div>
                            </div>
                          </div>

                        </div>
                      </Box>
                      <CustomTabPanel value={tabValue} index={0} className={classes['card-container']}>
                        {renderProjects(projectsToDisplayTab1)}
                      </CustomTabPanel>
                      <CustomTabPanel value={tabValue} index={1} className={classes['card-container']}>
                        {renderProjects(projectsToDisplayTab2)}
                      </CustomTabPanel>
                      <CustomTabPanel value={tabValue} index={2} className={classes['card-container']}>
                        {renderProjects(projectsToDisplayTab3)}
                      </CustomTabPanel>
                    </Box>
                  </>
                )}
              </div>
            </>
          )
          : (
            <>
              <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            </>
          )
        }


      </div>
      <Snackbar
        open={projectSnackBar}
        autoHideDuration={3000}
        onClose={handleProjectSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleProjectSnackbar} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

    </>

  )
}

export default Project




