import React from "react";

const NavigationButtons = ({ updateChartRange, selectedRange, handleRangeChange }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', justifyContent: 'center', alignItems: 'center' }}>
    <button
      onClick={() => updateChartRange("prev")}
      style={{
        padding: '4px 8px',
        backgroundColor: '#fbbf24',
        color: 'black',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
      }}
      onMouseOver={(e) => (e.target.style.backgroundColor = '#facc15')}
      onMouseOut={(e) => (e.target.style.backgroundColor = '#fbbf24')}
    >
      Prev
    </button>
    <select
      value={selectedRange.title}
      onChange={handleRangeChange}
      style={{
        border: '2px solid black',
        borderRadius: '4px',
        cursor: 'pointer',
        padding: '4px',
      }}
      onMouseOver={(e) => (e.target.style.borderRadius = '2px')}
      onMouseOut={(e) => (e.target.style.borderRadius = '4px')}
    >
      <option value="Month">Month</option>
      <option value="Week">Week</option>
      <option value="Day">Day</option>
    </select>
    <button
      onClick={() => updateChartRange("next")}
      style={{
        padding: '4px 8px',
        backgroundColor: '#fbbf24',
        color: 'black',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
      }}
      onMouseOver={(e) => (e.target.style.backgroundColor = '#facc15')}
      onMouseOut={(e) => (e.target.style.backgroundColor = '#fbbf24')}
    >
      Next
    </button>
  </div>
  
  );
};

export default NavigationButtons;
// D:\Sciative\gantt_chart_assignment\demo4\gantt-chart-app\src