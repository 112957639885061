import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../Services/Cookie/Cookie';



const RequireToken = ({ children }) => {


    const navigate = useNavigate()
    const accessToken = getCookie("dash_access_token")

    // const accessToken = null // used for testing
    useEffect(() => {
        if (accessToken === undefined || accessToken===null) {
            navigate("/dash/");
        }
    }, [accessToken, navigate]);

    return (
        <>
            {children}
        </>
    )

}

export default RequireToken