import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';

let progressBarColors = [];

const fetchOptionList = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      },
    };
    const response = await axios.get("/backend/get_all_options", config);
    let data = response.data;
    progressBarColors = data.progress_bar_color;
  } catch (error) {
    console.error('There was an error fetching the domain data!', error);
  }
};

export const getColorByPercentage = (percentage) => {
  if (percentage >= 0 && percentage <= 100) {
    const index = Math.floor(percentage / 10) ;
    return progressBarColors[index-1];
  }
  return null;
};
await fetchOptionList();