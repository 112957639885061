import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import RequireToken from './Components/Auth/Auth';
import Login from './Components/Login/Login';
import Sidebar from './Components/Sidebar/Sidebar';
import CreateUser from './Components/Admin/CreateUser';
import ModifyUser from './Components/Admin/ModifyUser';
import ManageUser from './Components/Admin/ManageUser';
import DomainManagement from './Components/Admin/DomainManagement';
import Dashboard from './Components/Dashboard/Dashboard';
import Verify from './Components/Verify/Verify';
import TaskPage from './Components/Task/TaskPage';
import Project from './Components/Dashboard/Project';
import ProjectListView from './Components/Dashboard/ProjectListView';
import Sprint from './Components/Sprint/Sprint';
import Notification from './Components/Notification/Notification';
import GanttView from './Components/Gantt/GanttChart';
import OverView from './Components/Dashboard/OverView';
import SprintTable from './Components/Sprint/SprintTable';
import { getCookie } from './Services/Cookie/Cookie';
function App() {
  const [isRoot, setIsRoot] = useState(true);
  const [userData, setUserData] = useState();
  const [lastValidPage, setLastValidPage] = useState('/dash/dashboard'); 

  const LocationTracker = () => {
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
      const path = location.pathname;
      const isVerifyPath = path.startsWith("/verify_account/");
  
      setIsRoot(
        path === "/dash" ||
        path === "/" ||
        path === "/dash/" ||
        isVerifyPath
      );
  
      const accessToken = getCookie("dash_access_token");
  
      if (accessToken && path.startsWith('/dash') && path !== "/dash/" && path !== "/dash" && !isVerifyPath) {
        setLastValidPage(path);
      } else if (accessToken && !isVerifyPath) {
        navigate(lastValidPage);
      }
    }, [location.pathname]);

    return null;
  };

  const RedirectToLogin = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const accessToken = getCookie("dash_access_token");
      if (accessToken) {
        navigate(lastValidPage); // Redirect to the last valid page
      } else {
        navigate('/dash/'); // Redirect to login if not authenticated
      }
    }, [navigate, lastValidPage]);

    return <Navigate to={lastValidPage} />;
  };

  return (
    <Router>
      <LocationTracker /> {/* Track location changes */}
      <div className="App">
        {!isRoot && <RequireToken><Sidebar userData={userData} /></RequireToken>}
        <Routes>
          <Route exact path='/verify_account/:url_param' element={<Verify />} />
          <Route exact path='/' element={<Navigate to="/dash/" />} />
          <Route exact path='/dash' element={<Navigate to="/dash/" />} />
          <Route exact path='/dash/' element={<Login setIsRoot={setIsRoot} handleLoginSuccess={setUserData} setUserData={setUserData} />} />
          <Route exact path='/dash/admin/createUser' element={<RequireToken><CreateUser /></RequireToken>} />
          <Route exact path='/dash/admin/modifyUser' element={<RequireToken><ModifyUser /></RequireToken>} />
          <Route exact path='/dash/admin/domainManagement' element={<RequireToken><DomainManagement /></RequireToken>} />
          <Route exact path='/dash/dashboard' element={<RequireToken><Dashboard /></RequireToken>} />
          <Route exact path='/dash/dashboard/project' element={<RequireToken><Project /></RequireToken>} />
          <Route exact path='/dash/dashboard/project/projectListView' element={<RequireToken><ProjectListView /></RequireToken>} />
          <Route exact path='/dash/task' element={<RequireToken><TaskPage /></RequireToken>} />
          <Route exact path='/dash/dashboard/sprint' element={<RequireToken><Sprint /></RequireToken>} />
          <Route exact path='/dash/notification' element={<RequireToken><Notification /></RequireToken>} />
          <Route exact path='/dash/dashboard/project/ganttView' element={<RequireToken><GanttView /></RequireToken>} />
          <Route exact path='/dash/admin/manageUser' element={<RequireToken><ManageUser /></RequireToken>} />
          <Route exact path='/dash/dashboard/project/overview' element={<RequireToken><OverView /></RequireToken>} />
          <Route exact path='/dash/dashboard/sprint/sprintView' element={<RequireToken><SprintTable /></RequireToken>} />
          <Route path='*' element={<RedirectToLogin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

