import { create } from 'zustand'
import axios from "axios";

import { getCookie } from '../../Services/Cookie/Cookie';

var notificationCount = 1
const fetchUserData = async () => {
    try {
        const response = await axios.get(`/b/backend/user`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getCookie('slm_access_token')}`
            }
        })
        notificationCount = await fetchNotificationCount(response.data._id)
        useStore.setState({ count: notificationCount })

    }
    catch (error) {
        console.error("Error Fetching Details", error)
    }
}
const getCount = async () => {
    try {
        const response = await axios.get(`/logs/get_notifications_count`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getCookie("dash_access_token")}`,
                },
            }
        );
        if (response) {
            useStore.getState().setCount(response.data)
            return response.data;
        }
    } catch (error) {
        console.error("Error Fetching Count", error);
        return 0;
    }
};
const fetchNotificationCount = async () => {
    try {
        const response = await axios.get(`/backend/get_notification_count}`)
        if (response) {
            return response.data
        }

    } catch (error) {
        console.error("Error Fetching Count", error)
        return 0;
    }
}
const useStore = create((set) => ({
    count: 0,
    inc: () => set((state) => ({ count: state.count + 1 })),
    getCount: () => getCount(),
    setCount: (count) => set({ count }),
}))

export default useStore;


