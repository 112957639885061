export function getFirstDates(timestamp) {
  const date = new Date(timestamp);
  
  // First date of the current month
  const firstDateOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 2);
  
  // First date of the next month
  const firstDateOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 2);

  const day1 = Date.UTC(
    firstDateOfMonth.getUTCFullYear(),
    firstDateOfMonth.getUTCMonth(),
    firstDateOfMonth.getUTCDate()
  );
  
  const day2 = Date.UTC(
    firstDateOfNextMonth.getUTCFullYear(),
    firstDateOfNextMonth.getUTCMonth(),
    firstDateOfNextMonth.getUTCDate()
  );
  
  return {
    day1,
    day2
  };
}


export function getFirstDatesPrev(timestamp) {
  const date = new Date(timestamp);
  const firstDateOfPrevMonth = new Date(date.getFullYear(), date.getMonth() -1, 2);

  const day2 = Date.UTC(
    firstDateOfPrevMonth.getUTCFullYear(),
    firstDateOfPrevMonth.getUTCMonth(),
    firstDateOfPrevMonth.getUTCDate()
  );
  
  return day2;
  
}





export const getMonthStartAndEnd = (date) => {
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return { firstDate, lastDate };
  };
  
  export function getDaysInMonth(n = 1) {
    // n= 1: current, 2: next, 0: prev
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

  
    // Adjust the month based on the value of n
    const firstDayOfTargetMonth = new Date(year, month + n, 1);
    const lastDayOfTargetMonth = new Date(firstDayOfTargetMonth);
    lastDayOfTargetMonth.setMonth(firstDayOfTargetMonth.getMonth() + 1);
    lastDayOfTargetMonth.setDate(0);
  
    return lastDayOfTargetMonth.getDate();
  }  
  export const getDateNDaysBefore = (date, n) => {
    const result = new Date(date);
    result.setUTCDate(result.getUTCDate() - n);
    return result.getTime();
  };
  
  export const getDateNDaysAfter = (date, n) => {
    

    const result = new Date(date);
    result.setUTCDate(result.getUTCDate() + n);
    return result.getTime();
  };

  export const getCurrentWeekStartAndEnd = (currentDate) => {
    const date = new Date(currentDate);
    const day = date.getUTCDay(); 
    
    const diffToMonday = day === 0 ? -6 : 1 - day; 
    
    const monday = new Date(date);
    monday.setUTCDate(date.getUTCDate() + diffToMonday);
    monday.setUTCHours(0, 0, 0, 0); 
    const sunday = new Date(monday);
    sunday.setUTCDate(monday.getUTCDate() + 7); 
    sunday.setUTCHours(23, 59, 59, 999);
    return { monday, sunday };
  };
  
  
  export const getDateTimeLabelFormats = (range) => {
    switch (range.title) {
      case 'Week':
        return {
          week: 'Week %W',
          day: '%A'
        };
      case 'Day':
        return {
          day: '%e %b'
        };
      case 'Month':
      default:
        return {
          day:'%e',
          week: '%e %b',
          month: '%b \'%y'
        };
    }
  };
  