import React, { useState, useEffect, useRef } from 'react';
import classes from './DomainManagement.module.css'
import { getCookie } from '../../Services/Cookie/Cookie';
import axios from "axios"
import './hideScrollbar.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Navbar from '../Navbar/Navbar';
import CreateUser from './CreateUser'
import ModifyUser from './ModifyUser'
import './ManageUser.module.css'


const ManageUser = () => {

  const [isLoaded,setIsLoaded] = useState(false)

    
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [userDetails, setUserDetails] = useState({})

  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserDetails(data)
      setIsLoaded(true)
    }
    catch (error) {

      if (error.response.status == 401) {
        const message = error.response.data.details || 'An error occurred';
      }
    }
  }

  useEffect(() => {
    fetchUserDetails()
  }, [])
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [dashboard, setDashboard] = useState({
    "Dashboard": "User Management",
    "Info": "Manage users, roles, and access permissions within Dash"
  })



  return (
    <>
    <div className={classes['content']}>
    <Navbar dashboard={dashboard} setIsLoaded={setIsLoaded} isLoaded={isLoaded} userDetails={userDetails} />
    {isLoaded && 
        <div >
        <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}
        >
        <Tab label="Create User" {...a11yProps(0)}
            sx={{
            backgroundColor: tabValue === 0 ? 'rgb(227, 242, 253)' : '',
            boxShadow: tabValue === 0 ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '',
            }}
        />
        <Tab label="Manage User" {...a11yProps(1)}
            sx={{
            backgroundColor: tabValue === 1 ? 'rgb(227, 242, 253)' : '',
            boxShadow: tabValue === 1 ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : '',
            }}
        />
        </Tabs>
    </div>
     }

    {tabValue == 0 ? (
        <>
        <CreateUser/>
        </>
    )
        :(
            <>
            <ModifyUser/>
            </>
        )
}

    </div>
    </>
  )
}

export default ManageUser