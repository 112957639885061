export function transformDataForProject(projectName, jsonData) {
  const projectData = jsonData.filter(item => item.project_name === projectName);
  const transformedData = {
    name: projectName,
    data: [],
  };

  projectData.forEach((task, mainTaskIndex) => {
    const mainTaskId = `main-task-${mainTaskIndex + 1}`;
    const mainTaskName = task.task_name;
    const assignUserName = task.assigned_by_name;
    if (mainTaskName) {
      // const start_date = task.created_at.split(" ")[0];
      const start_date = task.start_date;
      const end_date = task.deadline.split(" ")[0] + " 23:59";
      
      // Assign a dark color to the main task
      const mainTaskColor = getColor(mainTaskIndex, true);
      
      
      transformedData.data.push({
        id: mainTaskId,
        name: mainTaskName,
        start: Date.parse(start_date),
        end: Date.parse(end_date),
        description: task.task_description,
        collapsed: true,
        color: mainTaskColor,
        assigned_to:assignUserName,
      });

      const subTasks = task.sub_task;
      let subTaskCounter = 1;
      for (const subTaskId in subTasks) {
        if (subTasks.hasOwnProperty(subTaskId)) {
          const subTask = subTasks[subTaskId];
          const sb_start_date = subTask.start_datetime.split(" ")[0];
          const sb_end_date = subTask.end_datetime.split(" ")[0] + " 23:59";
          
          // Assign a lighter color to the sub-task based on the main task color
          const subTaskColor = getColor(mainTaskIndex, false);

          transformedData.data.push({
            id: `sub-task-${mainTaskIndex + 1}-${subTaskCounter}`,
            parent: mainTaskId,
            name: subTask.sub_task_name,
            description: subTask.sub_task_description,
            start: Date.parse(sb_start_date),
            end: Date.parse(sb_end_date),
            assigned_to: subTask.sub_task_assign_to_name,
            visible: true,
            color: subTaskColor
          });
          subTaskCounter++;
        }
      }
    }
  });

  return transformedData;
}

function getColor(index, isMainTask) {
  const colorPairs = [
    { main: "rgb(250, 156, 148)", light: "rgb(255, 199, 194)" },
    { main: "rgb(247, 182, 102)", light: "rgb(252, 209, 156)" },
    { main: "rgb(242, 208, 105)", light: "rgb(252, 209, 156)" },
    { main: "rgb(191, 212, 111)", light: "rgb(221, 236, 163)" },
    { main: "rgb(102, 205, 170)", light: "rgb(144, 238, 211)" },
    { main: "rgb(135, 206, 250)", light: "rgb(173, 216, 230)" },
    { main: "rgb(147, 112, 219)", light: "rgb(186, 154, 234)" },
    { main: "rgb(255, 182, 193)", light: "rgb(255, 209, 224)" },
    { main: "rgb(255, 160, 122)", light: "rgb(255, 204, 181)" },
    { main: "rgb(255, 99, 71)", light: "rgb(255, 160, 122)" },
    { main: "rgb(144, 238, 144)", light: "rgb(178, 255, 178)" },
    { main: "rgb(173, 255, 47)", light: "rgb(202, 255, 113)" }
  ];
  const colorPair = colorPairs[index % colorPairs.length];

  return isMainTask ? colorPair.main : colorPair.light;
}
  