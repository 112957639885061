import React, { useEffect, useState } from 'react'
import ProjectListView from "./ProjectListView"
import GantView from "../Gantt/GanttChart"
import Navbar from "../Navbar/Navbar"
import DashboardTitle from "./DashboardTitle"
import { useLocation } from 'react-router-dom';
import classes from "./OverView.module.css";
import NavigationButtons from "../Gantt//NavigationButtons";
import axios from 'axios';
import { getCookie } from '../../Services/Cookie/Cookie';

const OverView = () => {
    const currentDate = new Date().toISOString().slice(0, 16);
    const location = useLocation();
    const {project_owner_approved,domain_owner,project_owner, domainId, projectId, dashboard, dashboardActivated, domainName,projectName, project_progress, user_progress, toggleActive,projectUserList,projectDueDate,tabValue } = location.state;
    const [isLoaded, setIsLoaded] = useState(false)
    const [listViewActive, setListViewActive] = useState(false)
    const [fullView, setFullView] = useState(toggleActive)
    const [userDetails,setUserDetails] = useState({})
    const [userDetailsFetch,setUserDetailsFetch] = useState(false)
    const fetchUserDetails = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("dash_access_token")}`,
          },
        };
        const response = await axios.get("/backend/get_privileges", config)
        let data = response.data
        setUserDetails(data)
        setListViewActive(true)
        setUserDetailsFetch(true)
      }
      catch (error) {
  
        if (error.response.status == 401) {
          const message = error.response.data.details || 'An error occurred';
        }
      }
    }

    useEffect(() => {
      fetchUserDetails()
    }, [])
    

    const data = {
        domainId,
        projectId,
        dashboard,
        dashboardActivated,
        domainName,
        userDetails,
        projectName,
        project_progress,
        user_progress,
        toggleActive,
        setIsLoaded,
        isLoaded,
        setListViewActive,
        setFullView,
        fullView,
        project_owner,
        domainOwner:domain_owner,
        projectUserList:projectUserList,
        projectDueDate:projectDueDate,
        project_owner_approved:project_owner_approved,
        tabValue
    };
    const [listView,setListView] = useState(true)
    const [activeView, setActiveView] = useState('list'); 
    const handleToggle = (view) => {
        setActiveView(view);
        if (view == 'gantt') {
          let state = {
            task_id: 'TD-01'
          }
          setListViewActive(false)
        }
        else {
          setListViewActive(true)
        }
    
      };

    return (
        <>
            <div className={classes['content']}>
                <Navbar dashboard={dashboard} userDetails={userDetails} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
                <DashboardTitle dashboardActivated={dashboardActivated} domainName = {domainName} listView={listView} projectName={projectName} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
                {
                    userDetailsFetch ? (
                      listViewActive ? (
                        <ProjectListView {...data} />
                      ) : (
                        <GantView {...data} />
                      )
                    ) : null
                  }

            </div>
        </>
    );
};

            export default OverView