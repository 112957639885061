import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Button,
  Box,
  IconButton,
  Typography,
  Snackbar,
  Popover,
  Tooltip,
  InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Search } from '@mui/icons-material';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { getCookie } from '../../Services/Cookie/Cookie';
import Navbar from '../Navbar/Navbar';
import Swal from 'sweetalert2';
import classes from "./Dashboard.module.css"

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';

import { InputText } from 'primereact/inputtext'

import "../Admin/hideScrollbar.css"

import dayjs from 'dayjs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Project from "./Project"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DashboardTitle from './DashboardTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import DOMPurify from 'dompurify';
import DomainAddIcon from '@mui/icons-material/DomainAdd';



const Dashboard = () => {
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [domainName, setDomainName] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [projectName, setProjectName] = useState('Dash');
  const [completionPercentage, setCompletionPercentage] = useState(80);
  const [domains, setDomains] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([])
  // const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterCore, setGlobalFilterCore] = useState('');
  const [globalFilterExternal, setGlobalFilterExternal] = useState('');
  const [value, setValue] = useState(dayjs('2022-04-17'));
  const [domainId, setDomainId] = useState('')
  const [domainOwner, setDomainOwner] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [swalActive, setSwalActive] = useState(false)
  // const classes = useStyles(); // Ensure useStyles is defined

  const [userData, setUserData] = useState({})

  const [userPersonalPrivileges, setUserPersonalPrivileges] = useState({})

  const [domainActivated, setDomainActivated] = useState(true)
  const handleCreateTask = () => {

  }

  const [userDomain, setUserDomain] = useState([])


  const fetchDomain = async () => {
    let is_active = 'All';
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let domainResponse = await axios.get(
        `/backend/get_all_domain_list/ALL/${is_active}`,
        config
      );
      let domainData = domainResponse.data;
      setDomains(domainData);
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };


  const [cardColors, setCardColors] = useState([])
  const [progressBarColors, setProgressBarColors] = useState([])


  const fetchOptionList = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_all_options", config)
      let data = response.data
      setCardColors(data.card_color)
      setProgressBarColors(data.progress_bar_color)
    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };


  const getRandomColor = (index) => {
    return cardColors[index % cardColors.length];
  };



  const getColorByPercentage = (percentage) => {
    if (percentage >= 0 && percentage <= 100) {
      const index = Math.floor(percentage / 10);
      return progressBarColors[index-1];
    }
    return null; // Optionally handle cases where percentage is out of range
  };




  const [userDetails, setUserDetails] = useState()
  const fetchUserDetails = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("dash_access_token")}`,
        },
      };
      const response = await axios.get("/backend/get_privileges", config)
      let data = response.data
      setUserData(data.global_privileges)
      setUserPersonalPrivileges(data.personal_privileges)
      setUserDetails(data)
      setIsLoaded(true)
    }
    catch (error) {
      console.error("Error Occured")
    }
  }

  const fetchUserDomain = async () => {
    let is_active = 1;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('dash_access_token')}`,
      },
    };
    try {
      let domainResponse = await axios.get(
        `/backend/get_user_domain_list/${is_active}`,
        config
      );

      const sortedDomains = domainResponse.data.sort((a, b) => {
        if (a.domain_name < b.domain_name) {
          return -1;
        }
        if (a.domain_name > b.domain_name) {
          return 1;
        }
        return 0;
      });

      setUserDomain(sortedDomains)

    } catch (error) {
      console.error('There was an error fetching the domain data!', error);
    }
  };


  const handleDomainProject = (domain_id, domain_name, domain_owner) => {
    setDomainId(domain_id)
    setDomainName(domain_name)
    setDomainOwner(domain_owner)
  }

  const handleClose = async () => {
    if (swalActive) {
      const title = 'Unsaved Changes';
      const confirmButtonText = 'OK';
      const cancelButtonText = 'Cancel';

      const result = await Swal.fire({
        title,
        text: 'Do you want to exit ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        customClass: {
          container: 'custom-swal-container'
        }
      });

      if (!result.isConfirmed) {
        return;
      }

      setOpen(false);
      setSwalActive(false);
      setSnackbarOpen(false);
    } else {
      setOpen(false);
      setSwalActive(false);
      setSnackbarOpen(false);
    }
  };

  const handleSnackClose = () => {
    setSnackbarOpen(false);
  };

  const handleDomainCreation = async () => {


    if (!domainName) {
      setSnackbarOpen(true);
      setMessage('Please Enter Domain Name');
      setSeverity('warning');
      return;
    }
    const domainNameRegex = /^[A-Za-z\s]+$/;

    if (!domainNameRegex.test(domainName)) {
      setSnackbarOpen(true);
      setMessage('Domain name should not contain special characters or numbers');
      setSeverity('warning');
      return;
    }

    if (!selectedUser) {
      setSnackbarOpen(true);
      setMessage('Please Select the Domain Owner');
      setSeverity('warning');
      return;
    }

    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    const titleCaseDomainName = toTitleCase(domainName);


    const data = {
      domain_name: DOMPurify.sanitize(titleCaseDomainName.trim()),
      owner: DOMPurify.sanitize(selectedUser.username.trim()),
    };

    try {
      const response = await axios.post(`/backend/create_domain`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('dash_access_token')}`,
        },
      });
      if (response.status === 200) {
        setOpen(false);
        Swal.fire({
          title: 'Domain Creation',
          text: 'A new Domain has been Created Successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        fetchUserDomain();
        setSwalActive(false)
      }

    } catch (err) {
      setSnackbarOpen(true);
      setMessage(err.response.data.detail);
      setSeverity('error');
      return;
      // Swal.fire({
      //   title: 'Domain Creation',
      //   text: `${err.response?.data?.details || 'Unknown error'}`,
      //   icon: 'error',
      //   confirmButtonText: 'OK',
      // });
    }
  };

  function getUserByUsername(users, username) {
    return users.find(user => user.username === username);
  }

  const editDomainCreation = async () => {
    if (!domainName) {
      setSnackbarOpen(true);
      setMessage('Please Enter Domain Name');
      setSeverity('warning');
      return;
    }
    const domainNameRegex = /^[A-Za-z\s]+$/;

    if (!domainNameRegex.test(domainName)) {
      setSnackbarOpen(true);
      setMessage('Domain name should not contain special characters or numbers');
      setSeverity('warning');
      return;
    }
    if (!selectedUser) {
      setSnackbarOpen(true);
      setMessage('Please Select the Domain Owner');
      setSeverity('warning');
      return;
    }

    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    const titleCaseDomainName = toTitleCase(domainName);


    const data = {
      domain_name: DOMPurify.sanitize(titleCaseDomainName.trim()),
      owner: DOMPurify.sanitize(selectedUser.username.trim()),
      domain_id: DOMPurify.sanitize(domainId.trim()),
    };

    try {
      const response = await axios.post(`/backend/edit_domain`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('dash_access_token')}`,
        },
      });
      if (response.status === 200) {
        setOpen(false);
        fetchUserDomain()
        Swal.fire({
          // title: 'Domain Details',
          text: 'Domain Details Updated Successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        setSwalActive(false)
      }
    } catch (err) {
      setOpen(false);
      Swal.fire({
        // title: 'Error occured',
        text: `${err.response.data.detail || 'Unknown error'}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };


  const buttonHoverStyle = {
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid black',
  };
  const useStyles = makeStyles((theme) => ({
    progressBarContainer: {
      position: 'relative',
      width: '100%',
      height: '5px',
      backgroundColor: '#e0e0e0',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    progress: {
      height: '100%',
      borderRadius: '4px',
      transition: 'width 0.2s ease-in-out',
    },
    tooltip: {
      position: 'absolute !important',
      transform: 'translateX(-50%) !important',
      bottom: '100% !important',
      marginBottom: '8px !important',
      transition: 'left 0.2s ease-in-out !important',
      color: 'black !important',

      backgroundColor: 'white !important', // Changed to white background
      padding: '5px 10px !important',
      border: '1px solid #ccc !important', // Optional: add a border to make it more visible
      borderRadius: '4px !important',


    },
    tooltipPlaceholder: {
      width: '1px',
      height: '1px',
      position: 'absolute',
    },
  }));


  const [selectedDomain, setSelectedDomain] = useState(null)

  const sortedUsers = Array.isArray(users) ? users.slice().sort((a, b) => {

    if (domainId == null) {
      return []
    }
    if (a.core_domain === domainId) return -1;
    if (b.core_domain === domainId) return 1;
    return a.core_domain.localeCompare(b.core_domain);
  }) : null;


  const styles = useStyles()
  const [tooltipOpen, setTooltipOpen] = useState({});

  const handleMouseEnter = (domainIndex, projectIndex) => {
    setTooltipOpen(prev => ({
      ...prev,
      [domainIndex]: { ...prev[domainIndex], [projectIndex]: true }
    }));
  };

  const handleMouseLeave = (domainIndex, projectIndex) => {
    setTooltipOpen(prev => ({
      ...prev,
      [domainIndex]: { ...prev[domainIndex], [projectIndex]: false }
    }));
  };

  const [selectedValuesCore, setSelectedValuesCore] = useState({});
  const [selectedValuesExternal, setSelectedValuesExternal] = useState({});


  useEffect(() => {
    fetchAllUser()
    fetchUserDomain();
    fetchUserDetails();
    fetchOptionList();
  }, [])

  const [user, setUser] = useState([])
  const [externalUser, setExternalUser] = useState([])
  const [coreUser, setCoreUser] = useState([])

  const fetchAllUser = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.get(`/backend/get_all_user/ALL/1`, config);
      setUser(response.data)
      const coreUsers = [];
      const externalUsers = [];
      response.data.forEach(user => {
        if (user.core_domain === domainId) {
          coreUsers.push(user);
        } else {
          externalUsers.push(user);
        }
      });

      setCoreUser(coreUsers);
      setExternalUser(externalUsers);
      setUsers(response.data)


      // fetchProjectDetails()
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  // Columns configuration
  const columns = [
    { field: 'username', header: 'Username' },
    { field: 'view', header: 'View' },
    { field: 'edit', header: 'Edit' },
    { field: 'full', header: 'Full' },
    { field: 'none', header: 'None' },
  ];


  const [finalUser, setFinalUser] = useState({});

  const handleRadioChangeCore = (rowData, columnKey) => {
    const updatedValues = {
      ...selectedValuesCore,
      [rowData.username]: {
        view: false,
        edit: false,
        full: false,
        [columnKey]: true,
      },
    };

    if (columnKey !== 'none') {
      setFinalUser(prevState => ({
        ...prevState,
        [rowData.username]: columnKey,
      }));
    } else {
      setFinalUser(prevState => {
        const updatedState = { ...prevState };
        delete updatedState[rowData.username];
        return updatedState;
      });
    }

    setSelectedValuesCore(updatedValues);
  };






  const handleRadioChangeExternal = (rowData, columnKey) => {
    const updatedValues = {
      ...selectedValuesExternal,
      [rowData.username]: {
        view: false,
        edit: false,
        full: false,
        [columnKey]: true,
      },
    };

    setSelectedValuesExternal(updatedValues);

    if (columnKey !== 'none') {
      setFinalUser(prevState => ({
        ...prevState,
        [rowData.username]: columnKey,
      }));
    } else {
      setFinalUser(prevState => {
        const updatedState = { ...prevState };
        delete updatedState[rowData.username];
        return updatedState;
      });
    }
  };

  // Renderer for radio buttons
  const radioTemplateCore = (rowData, columnKey) => (
    <RadioButton
      inputId={`${rowData.id}_${columnKey}`}
      name={`${rowData.id}_${columnKey}`}
      value={columnKey}
      checked={selectedValuesCore[rowData.username] && selectedValuesCore[rowData.username][columnKey]}
      onChange={() => handleRadioChangeCore(rowData, columnKey)}
    />
  );
  const radioTemplateExternal = (rowData, columnKey) => (
    <RadioButton
      inputId={`${rowData.id}_${columnKey}`}
      name={`${rowData.id}_${columnKey}`}
      value={columnKey}
      checked={selectedValuesExternal[rowData.username] && selectedValuesExternal[rowData.username][columnKey]}
      onChange={() => handleRadioChangeExternal(rowData, columnKey)}
    />
  );

  const handleDeadline = (date) => {
    setDeadline(date)
  }

  const coreHeader = (
    <>
      <div className='d-flex justify-content-between'>
        <div>Add User From Core Domain</div>
        <div style={{ textAlign: 'end' }}>
          <InputText size="small" type="search" onChange={(e) => setGlobalFilterCore(e.target.value)} placeholder="Search"
            style={{ textAlign: 'center', width: '5em', height: '1.5em', borderRadius: '10px' }}
          />
        </div>
      </div>

    </>
  );



  const externalHeader = (
    <>
      <div className='d-flex justify-content-between'>
        <div>Add User From External Domain</div>
        <div style={{ textAlign: 'end' }}>
          <InputText size="small" type="search" onChange={(e) => setGlobalFilterExternal(e.target.value)} placeholder="Search"
            style={{ textAlign: 'center', width: '5em', height: '1.5em', borderRadius: '10px' }}
          />
        </div>
      </div>

    </>
  );

  const [selectedUserTypes, setSelectedUserTypes] = useState([]);

  const handleUserTypeChange = (event) => {
    setSelectedUserTypes(event.target.value);
  };

  const createProject = async () => {
    const data = {
      domain_id: domainId,
      project_name: projectName,
      dead_line: deadline,
      project_user: finalUser,
      owner: selectedUser
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.post(`/backend/create_project`, data, config);
      setSnackbarOpen(true)
      setMessage('Project Created Successfully')
      setSeverity('success')
      setOpen(false)

    } catch (error) {
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  }


  const handleEditProject = async () => {
    const data = {
      domain_id: domainId,
      project_name: projectName,
      dead_line: deadline,
      project_user: finalUser,
      owner: selectedUser
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };
    try {
      const response = await axios.post(`/backend/create_project`, data, config);
      setSnackbarOpen(true)
      setMessage('Project Created Successfully')
      setSeverity('success')
      setOpen(false)

    } catch (error) {
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }
  }


  const renderUserLists = () => {
    return (
      <div>
        {selectedUserTypes.map((userType) => {
          if (userType === 'core') {
            return (
              <div key={userType} className='core-users mt-4'>

                <div className="datatable-scrollable">
                  <DataTable value={coreUser} header={coreHeader} scrollable scrollHeight="300px" globalFilter={globalFilterCore} className="project-user-datatable">
                    <Column field="username" header="Username" sortable />
                    <Column field="view" header="View" body={(rowData) => radioTemplateCore(rowData, 'view')} style={{ width: '15%' }} />
                    <Column field="edit" header="Edit" body={(rowData) => radioTemplateCore(rowData, 'edit')} style={{ width: '15%' }} />
                    <Column field="full" header="Full" body={(rowData) => radioTemplateCore(rowData, 'full')} style={{ width: '15%' }} />
                    <Column field="full" header="None" body={(rowData) => radioTemplateCore(rowData, 'none')} style={{ width: '15%' }} />
                  </DataTable>
                </div>

              </div>
            );
          } else if (userType === 'external') {
            return (
              <div key={userType} className='external-users mt-4'>
                <div className="datatable-scrollable">
                  {/* Customize DataTable for External Domain */}
                  <DataTable value={externalUser} header={externalHeader} scrollable scrollHeight="300px" globalFilter={globalFilterExternal} className={`${classes['project-user-datatable']}`}>
                    <Column field="username" header="Username" sortable />
                    <Column field="view" header="View" body={(rowData) => radioTemplateExternal(rowData, 'view')} style={{ width: '15%' }} />
                    <Column field="edit" header="Edit" body={(rowData) => radioTemplateExternal(rowData, 'edit')} style={{ width: '15%' }} />
                    <Column field="full" header="Full" body={(rowData) => radioTemplateExternal(rowData, 'full')} style={{ width: '15%' }} />
                    <Column field="full" header="None" body={(rowData) => radioTemplateExternal(rowData, 'none')} style={{ width: '15%' }} />
                  </DataTable>
                </div>

              </div>
            );
          }
          return null; // Handle other cases if necessary
        })}
      </div>
    );
  };



  const [deadline, setDeadline] = useState('');
  const [editProject, setEditProject] = useState(false);
  const [editDomain, setEditDomain] = useState(false);



  const fetchDomainDetails = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie("dash_access_token")}`,
      }
    };

    try {
      let domainName = "Retail"
      setDomainName(domainName)
      setSelectedUser("hitesh.damal231004@sciative.com")

      setEditDomain(true)
      setOpen(true)

    } catch (error) {
      setSnackbarOpen(true)
      setMessage(error.response.data.detail)
      setSeverity('error')
    }

  }







  // Task 

  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [dropdown1, setDropdown1] = useState('');
  const [dropdown2, setDropdown2] = useState('');
  const [dropdown3, setDropdown3] = useState('');
  const [dropdown4, setDropdown4] = useState('');
  const [dropdown5, setDropdown5] = useState('');
  const [dropdown6, setDropdown6] = useState('');
  const [date, setDate] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };


  const [dashboard, setDashboard] = useState({
    "Dashboard": "Domain Overview",
    "Info": "The Domain Overview dashboard displays multiple projects and their completion status for a user's assigned domain, showing only the projects to which the user has access."
  })

  const editDomainDetais = (domain_id, domain_name, owner) => {
    let domain_owner_name = getUserByUsername(users, owner)
    setSelectedUser(domain_owner_name)
    setDomainName(domain_name)
    // setSelectedUser(owner)
    setDomainId(domain_id)
    setDomainOwner(owner)
    setEditDomain(true)
    setOpen(true)
    setStep(1)
  }

  const createDomain = () => {
    setDomainName('')
    setSelectedUser(null)
    setOpen(true)
    setStep(1)
    setEditDomain(false)
  }

  const [dashboardActivated, setDashboardActivated] = useState(true)

  const navigate = useNavigate();

  const handleNavigateToProject = (domain_id, domain_name, domainOwner, index) => {
    let color = getRandomColor(index)
    let state = {
      domainName: domain_name,
      domainId: domain_id,
      userPersonalPrivileges: userPersonalPrivileges,
      domainOwner: domainOwner,
      userDetails: userDetails,
      randomColor: color,
    }
    navigate('/dash/dashboard/project', {
      state
    });
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);


  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(event.target.value);
    const filtered = userDomain.filter((project) => {
      const searchTermLower = searchTerm.toLowerCase();
      if (project.domain_name.toLowerCase().includes(searchTermLower)) {
        return true;
      }
      for (const projectId in project.user_project_summary) {
        const projectDetails = project.user_project_summary[projectId];
        if (projectDetails.name.toLowerCase().includes(searchTermLower)) {
          return true;
        }
        if (projectDetails.overall_progress.some(progress => progress.toString().includes(searchTermLower))) {
          return true;
        }
      }

      return false; // Return false if no match found
    });



    setFilteredProjects(filtered);
  };

  const domainToDisplay = searchTerm ? filteredProjects : userDomain;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '580px',
            maxWidth: '600px',
          },
        }}
      >
        <>
          <DialogTitle style={{ padding: 0, margin: '0 0.25em' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ borderBottom: '1px solid black', padding: '9px 0' }}
            >
              <Typography variant="h6" style={{ paddingLeft: '1em', fontSize: '1.25em' }}>
                {!editDomain ? 'Create a new domain' : `Edit domain details`}
              </Typography>
              <IconButton onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ width: '35em', height: '11em', paddingBottom: 0 }} className={'d-flex flex-column justify-content-evenly'}>
            <Box className="d-flex justify-content-evenly align-items-center">
              <Box style={{ width: '45%' }}>
                <Typography variant="subtitle1">
                  Input Domain Name<span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  style={{ width: '100%' }}
                  variant="outlined"
                  // label="Domain Name"
                  value={domainName}
                  // onChange={(e) => setDomainName(e.target.value)}
                  onChange={(e) => {
                    setDomainName(e.target.value);
                    setSwalActive(true);
                  }}
                  margin="normal"
                  required
                  size="small"
                />
              </Box>
              <Box style={{ width: '45%' }}>
                <Typography variant="subtitle1">
                  Assign Owner <span style={{ color: 'red' }}>*</span>
                </Typography>
                {/* <Autocomplete
                  value={selectedUser}
                  onChange={(event, newValue) => setSelectedUser(newValue)}
                  options={users.map((user) => user.username)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User"
                      variant="outlined"
                      margin="normal"
                      required
                      size="small"
                      style={{ width: '100%' }}
                    />
                  )}
                /> */}

                <Autocomplete
                  options={sortedUsers}
                  groupBy={(option) => option.domain_name}
                  // getOptionLabel={(option) => option.username}
                  getOptionLabel={(option) => option.name}
                  value={selectedUser}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue);
                    setSwalActive(true);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Select User"
                      variant="outlined"
                      margin="normal"
                      required
                      size="small"
                      style={{ width: '100%' }}
                    />
                  )}
                />

              </Box>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  // backgroundColor: '#FFC50B', 
                  backgroundColor: 'rgb(9, 94, 173)',
                  color: 'white', fontWeight: '400', '&:hover': { backgroundColor: 'rgb(9,99,175)' }
                }}
                onClick={editDomain ? editDomainCreation : handleDomainCreation}
              >
                Save
              </Button>
            </Box>
          </DialogContent>
        </>

      </Dialog>

      <div className={classes.content}>
        <Navbar dashboard={dashboard} userDetails={userDetails} isLoaded={isLoaded} setIsLoaded={isLoaded} />

        {isLoaded ? (<>
          <DashboardTitle dashboardActivated={dashboardActivated} userDetails={userDetails} isLoaded={isLoaded} setIsLoaded={isLoaded} />
          {!domainActivated && (<Project dashboardActivated={dashboardActivated} domainName={domainName} setSnackbarOpen={setSnackbarOpen} domainId={domainId} setMessage={setMessage} setSeverity={setSeverity} setDomainActivated={setDomainActivated} userPersonalPrivileges={userPersonalPrivileges} />)}

          {domainActivated && (
            <>
              <div className='d-flex justify-content-between m-2' style={{ paddingLeft: '2em', paddingRight: '1em' }}>
                <div style={{ fontWeight: '400', fontSize: '1.25em' }}>Explore your involvements across Domain</div>
                <div style={{ width: '38%' }} className='d-flex justify-content-end align-items-center'>
                  {
                    (domainToDisplay.length >= 7 && userData['domain_creation'] != "none") ? (
                      <>
                        <div style={{ marginRight: '1em', width: '14em' }}>
                          <Button
                            startIcon={<DomainAddIcon />}
                            variant="contained" className={classes['create-domain-btn']} onClick={() => { createDomain() }}>Create Domain</Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div></div>
                      </>
                    )
                  }

                  <div>
                    <TextField
                      // label="Search Domain"
                      placeholder="Search Domain"
                      variant="outlined"
                      fullWidth
                      value={searchTerm}
                      onChange={handleSearch}
                      size='small'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                </div>

              </div>

              <div className={classes['domain-container']}>
                <div className="p-3 d-flex flex-wrap  ">
                  {domainToDisplay.map((details, index) => (
                    // {userDomain.map((details, index) => (
                    <div key={index} className="card m-3" style={{ width: '14em', height: '17em', border: '0.5px solid grey' }} onClick={() => { handleDomainProject(details.domain_id, details.domain_name, details.owner) }}>
                      <div
                        className="card-header text-black d-flex justify-content-between"
                        style={{
                          backgroundColor: getRandomColor(index),
                          borderBottom: '0.5px solid grey',
                          fontWeight: '600',
                          height: '2.5em'
                        }}
                      >
                        <div style={{ width: '10%' }}></div>
                        <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "100%" }}
                          title={details.domain_name} className={classes['domain-title-container']} > {details.domain_name}</div>
                        <div style={{ width: '10%' }}>
                          {userData['domain_creation'] !== "none" ? (
                            // {/* {userDetails && userDetails.username === details.owner?( */}
                            <>
                              <MoreHorizIcon style={{ cursor: 'pointer' }} onClick={() => editDomainDetais(details.domain_id, details.domain_name, details.owner)} />
                            </>) : <div style={{ width: '10%' }}></div>
                          }

                        </div>

                      </div>


                      <div className={classes['domain-overall-details-container']} style={{ cursor: 'pointer' }} onClick={() => { handleNavigateToProject(details.domain_id, details.domain_name, details.owner, index) }}>
                        {Object.keys(details.user_project_summary).length !== 0 ? (
                          <>
                            {Object.entries(details.user_project_summary)
                              .sort(([keyA, projectA], [keyB, projectB]) => projectA.name.localeCompare(projectB.name)) 
                              .slice(0, 4)
                              .map(([key, project], projectIndex) => (
                                <div key={key} className="card-body d-flex" style={{ padding: '0.25em 1em' }}>
                                  <div style={{ width: '100%' }}>
                                    <div style={{ textAlign: 'start',width:'100%',textOverflow:'ellipsis',textWrap:'nowrap',overflow:'hidden' }}>{project.name}</div>
                                    <div
                                      className={styles.progressBarContainer}
                                      onMouseEnter={() => handleMouseEnter(index, projectIndex)}
                                      onMouseLeave={() => handleMouseLeave(index, projectIndex)}
                                    >
                                      <Tooltip
                                        title={`${Math.round(project.overall_progress[0])}%`}
                                        arrow
                                        placement="top"
                                        classes={{ tooltip: styles.tooltip }}
                                        open={tooltipOpen[index]?.[projectIndex] || false}
                                      >
                                        <div
                                          className={styles.tooltipPlaceholder}
                                          style={{ left: `calc(${Math.round(project.overall_progress[0])}%)` }}
                                        />
                                      </Tooltip>
                                      <div
                                        className={styles.progress}
                                        style={{
                                          width: `${Math.round(project.overall_progress[0])}%`,
                                          backgroundColor: getColorByPercentage(Math.round(project.overall_progress[0])),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                            {Object.keys(details.user_project_summary).length > 4 && (
                              <div style={{ color: '#828282', textAlign: 'start', paddingLeft: '1em', paddingBottom: '1em' }}>
                                More
                              </div>
                            )}
                            {Object.keys(details.user_project_summary).length <= 4 && (
                              <div style={{ height: `calc(${4 - Object.keys(details.user_project_summary).length} * (14em + 2em) / 4)` }}></div>
                            )}
                          </>
                        ) : (
                          <div className='d-flex justify-content-center align-items-center mt-2'>
                            No Project to display
                          </div>
                        )}


                      </div>
                    </div>
                  ))}

                  {userData['domain_creation'] != "none" &&
                    <div title={'Create a New Domain'} className="card m-3 d-flex justify-content-center align-items-center" style={{ width: '13em', height: '17em', border: '1px solid black', cursor: 'pointer' }} onClick={() => { createDomain() }}>
                      <div><AddCircleOutlineIcon sx={{ fontSize: '3em' }} /></div>
                    </div>
                  }


                </div>
              </div>
            </>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleSnackClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>

        </>) : (
          <>
            <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </div>
          </>
        )}



      </div>
    </>
  );
};


export default Dashboard;



